import React, { useState } from 'react'
import { Create, CreateProps, Record, useNotify } from 'react-admin'
import {
  DeepPartial,
  IAddress,
  IClinic,
  IClinicExternalData,
  IClinicFreightValueCity,
  IClinicFreightValueState,
  IPixKey,
} from '@vacinas-net/shared'
import ClinicForm from '../ClinicForm'
import httpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'
import { omit } from 'lodash'
import { transformOpeningHours } from '../../../components/OpeningHours/utils'
import ClinicFormToolbar from '../ClinicFormToolbar'
import { defaultDeliveryInstructions } from '../utils'
import { transformAttendanceFreightValues } from '../FreightValueSession/utils'

export type GetAllExternalDataAndAddressType = {
  externalData: Omit<IClinicExternalData, '_id'>
} & { address: IAddress }

const devInitialValue = {
  cnpj: '1' + new Date().getTime().toString(),
  companyName: 'mocked company',
  name: 'mocked company',
  address: {
    city: {
      value: 'São Carlos',
      label: 'São Carlos',
      name: 'São Carlos',
    },
    state: {
      value: 'SP',
      label: 'SP',
      name: 'São Paulo',
      uf: 'SP',
      zipCodeRanges: [
        {
          endZipCode: '19999999',
          startZipCode: '01000001',
          _id: '651cb2b465f2f33532e17dbc',
        },
      ],
    },
    zipCode: '02124041',
    neighborhood: { value: 'Centro', label: 'Centro' },
    streetName: 'Rua Episcopal',
    streetNumber: '2116',
  },
  phone: '11966787658',
}

const initialFreightValueCity: Partial<IClinicFreightValueCity> = {
  fullCity: false,
  name: '',
  neighborhoods: [],
  priceInCents: undefined,
  zipCodeRanges: [],
}

const initialFreightValueState: DeepPartial<IClinicFreightValueState> = {
  cities: [initialFreightValueCity],
  fullState: false,
  name: '',
  uf: '',
  zipCodeRanges: [],
  priceInCents: undefined,
}

const initialValue = {
  ...(process.env.NODE_ENV === 'development' && devInitialValue),

  deliveryInstructions: defaultDeliveryInstructions,
  weekdayOpeningHours: {
    deliveryOpeningHours: {},
    pickupOpeningHours: {},
  },
  attendanceFreightValues: {
    delivery: [initialFreightValueState],
    pickup: [],
  },
}

const ClinicCreate = (props: CreateProps) => {
  const notify = useNotify()

  const [vtexData, setVtexData] = useState<GetAllExternalDataAndAddressType[]>(
    []
  )

  const fetchVtexData = async () => {
    return httpClient(`${apiUrl}/clinic/external/vtex`, {
      method: 'get',
    }).then((res) => setVtexData(res.json))
  }

  React.useEffect(() => {
    fetchVtexData()
  }, [])

  const onFailure = (error: { message: string }) => {
    console.log('error ->', error.message)
    if (
      error.message.includes('endTimeInMinutes') ||
      error.message.includes('startTimeInMinutes')
    ) {
      notify(
        `Erro ao criar clínica: Preencha todos os horários dos turnos selecionados`,
        'error'
      )
      return
    }
    notify(`Erro ao criar clínica: ${error.message}`, 'error')
  }

  const transform = async (data: Record) => {
    const openingHoursV2 = transformOpeningHours(data)
    const attendanceFreightValues = transformAttendanceFreightValues(data)
    const enabledAttendanceType: IClinic['enabledAttendanceType'] = []

    if (data.pickupAttendanceEnabled) {
      enabledAttendanceType.push('pickup')
    }

    if (data.deliveryAttendanceEnabled) {
      enabledAttendanceType.push('delivery')
    }

    const fieldsToOmit = [
      'pickupOpeningHours',
      'pickupAttendanceEnabled',
      'deliveryOpeningHours',
      'deliveryAttendanceEnabled',
      'weekdayOpeningHours',
    ]

    const parsedData = {
      ...data,
      logoUrl: data.logoUrl ? data.logoUrl : undefined,
      address: {
        ...data.address,
        state: data?.address?.state?.value,
        city: data?.address?.city?.value,
        neighborhood: data?.address?.neighborhood?.value,
      },
      financialGroupData: {
        ...data.financialGroupData,
        pixKeys: data?.financialGroupData?.pixKeys.filter(
          (key: IPixKey) => !!key.type && !!key.value
        ),
      },
      openingHoursV2,
      enabledAttendanceType,
      attendanceFreightValues,
    }

    return omit(parsedData, fieldsToOmit) as unknown as Record
  }

  return (
    <Create
      {...props}
      onFailure={onFailure}
      transform={(data) => transform(data)}
    >
      <ClinicForm
        initialValues={initialValue}
        vtexData={vtexData}
        readOnly={false}
        toolbar={<ClinicFormToolbar />}
      />
    </Create>
  )
}

export default ClinicCreate
