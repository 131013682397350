import { Button } from '@material-ui/core'
import { useNotify, useRefresh } from 'ra-core'
import React, { useState } from 'react'
import { Confirm } from 'react-admin'
import HttpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'
import { Delete } from '@material-ui/icons'

const DeleteInvoiceButton = (props: { invoiceId: string }) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleConfirm = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/attendance-invoice/${props.invoiceId}`, {
      method: 'delete',
    })
      .then(() => {
        notify(`Nota fiscal removida com sucesso`, 'success')
        refresh()
      })
      .catch((error) => {
        notify(`Erro ao remover nota fiscal: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
      })
  }
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenConfirm(true)}
        startIcon={<Delete />}
      >
        Deletar
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está deletando uma nota fiscal"
        content="Deseja confirmar essa ação?"
        onConfirm={handleConfirm}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default DeleteInvoiceButton
