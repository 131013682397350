import MultiAutocompleteInput from '../../../components/Inputs/MultiAutocompleteInput/MultiAutocompleteInput'
import * as React from 'react'
import { cnpjFormatter } from '../../../utils/formatters'
import _ from 'lodash'
import { DateInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const PayoutPerClinicListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      label: 'Data de início',
      value: 'payoutDate(min)',
      FilterInput: (
        <DateInput
          label="Data de início"
          source="payoutDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      label: 'Data de fim',
      value: 'payoutDate(max)',
      FilterInput: (
        <DateInput
          label="Data de fim"
          source="payoutDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->_id',
      label: 'CNPJ da Clínica',
      FilterInput: (
        <MultiAutocompleteInput
          resourceToFetch="clinic"
          source="clinic->_id"
          label="CNPJ da Clínica"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
            minWidth: 250,
            marginTop: 8,
            paddingBottom: 8,
          }}
          optionTypeLabel="cnpj"
          parseFilterToRequest={(filter: string) => {
            return filter.replace(/\D/g, '')
          }}
          parseLabel={(itemToParse: string) => {
            return String(cnpjFormatter.format(itemToParse))
          }}
        />
      ),
    },
  ],
  'label'
)
