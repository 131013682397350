import * as React from 'react'
import { usePermissions, useRedirect } from 'react-admin'
import { isAdmin, ReportTypeEnum } from '@vacinas-net/shared'
import { mainTheme } from '../../../utils/mainTheme'
import { CreateButton, useListContext } from 'react-admin'

import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterList'
import ExportButton from '../../../components/Buttons/ExportButton'
import PayoutListActionsPannel from '../../../components/PayoutListActionsPannel'
import { PayoutListFiltersArray } from './Filters'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
}))

const PayoutListActions = () => {
  const filtersAvailable = PayoutListFiltersArray.map((item) => ({
    label: item.label,
    value: item.value,
  }))
  const hasCreate = false
  const hasExport = true
  const exportType = ReportTypeEnum.Payout
  const exportButtonText = 'Exportar Repasses'
  const { displayedFilters, showFilter } = useListContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const redirect = useRedirect()
  const { permissions } = usePermissions()
  const showAdminContent = isAdmin(permissions)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const displayedFiltersKeys = Object.keys(displayedFilters || {})
  const hiddenFilters = filtersAvailable.filter(
    (item) => !displayedFiltersKeys.includes(item.value)
  )

  const classes = useStyles()

  return (
    <div style={{ width: '100%' }}>
      <div className={classes.container}>
        {hasExport && exportType && (
          <ExportButton
            exportType={exportType}
            buttonText={exportButtonText!}
          />
        )}
        {hasCreate && (
          <CreateButton
            style={{
              marginRight: mainTheme.spacing(2),
            }}
            size="medium"
            variant="contained"
            label="Criar"
            color="primary"
          />
        )}
        <>
          <Button
            color={'primary'}
            variant={'outlined'}
            onClick={handleClick}
            startIcon={<FilterIcon />}
          >
            Filtrar
          </Button>
          <Menu
            open={Boolean(anchorEl)}
            onClose={handleClose}
            keepMounted
            anchorEl={anchorEl}
          >
            {hiddenFilters.map((item, idx) => (
              <MenuItem
                key={String(idx)}
                onClick={() => {
                  if (showFilter) showFilter(item.value, '')
                  handleClose()
                }}
              >
                {item.label}
              </MenuItem>
            ))}
          </Menu>
          {showAdminContent && (
            <Button
              style={{ marginLeft: mainTheme.spacing(2) }}
              size="medium"
              variant="contained"
              color="primary"
              onClick={() => {
                redirect('/payout-settlement/create')
              }}
            >
              Registrar repasse
            </Button>
          )}
        </>
      </div>
      <div className={classes.container}>
        <PayoutListActionsPannel />
      </div>
    </div>
  )
}

export default PayoutListActions
