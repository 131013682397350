import {
  DeepPartial,
  IClinic,
  IClinicFreightValueCity,
  IClinicFreightValueState,
  IFreightValueCity,
  IFreightValueState,
  IZipCodeRange,
} from '@vacinas-net/shared'
import { omit } from 'lodash'
import { Record } from 'react-admin'

export type CityFullData = IClinicFreightValueCity & {
  fullData: IFreightValueCity
}

const parseZipCodeRanges = (zipCodeRanges: IZipCodeRange[]) => {
  return zipCodeRanges.map((item) =>
    omit(item, ['createdAt', 'updatedAt', '_id'])
  )
}

const transformCity = (city: CityFullData) => {
  const fullData = (city as CityFullData).fullData as IFreightValueCity

  const zipCodeRanges = city?.fullCity
    ? parseZipCodeRanges(fullData?.zipCodeRanges)
    : []

  return {
    name: fullData?.name,
    fullCity: city?.fullCity ?? false,
    priceInCents: city?.priceInCents || 0,
    zipCodeRanges,
    neighborhoods: city?.neighborhoods?.map((neighborhood) => {
      return {
        name: neighborhood.name,
        priceInCents: city.priceInCents || 0,
        zipCodeRanges: parseZipCodeRanges(neighborhood.zipCodeRanges),
      }
    }),
  }
}

const transformDelivery = (data: Record) => {
  if (!data.deliveryAttendanceEnabled) return []
  const freightValues: IClinicFreightValueState[] =
    data.attendanceFreightValues.delivery

  const result: Array<DeepPartial<IClinicFreightValueState>> =
    freightValues.map((state) => {
      const cities = state.fullState
        ? []
        : state.cities
            .map((city) => transformCity(city as CityFullData))
            .filter((city) => !!city.name)
      const zipCodeRanges = state.fullState
        ? parseZipCodeRanges(state.zipCodeRanges)
        : []

      return {
        name: state.name,
        priceInCents: state.priceInCents,
        zipCodeRanges,
        fullState: state.fullState,
        uf: state.uf,
        cities,
      }
    })

  return result
}

export const transformAttendanceFreightValues = (data: Record) => {
  const delivery = transformDelivery(data)

  const attendanceFreightValues: IClinic['attendanceFreightValues'] = {
    pickup: [],
    delivery: delivery as IFreightValueState[],
  }

  return attendanceFreightValues
}
