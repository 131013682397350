import * as React from 'react'
import { IconButton, Menu } from '@material-ui/core'
import showStyles from '../../../utils/showStyles'
import { MoreVert } from '@material-ui/icons'
import { IAttendance, isAdmin } from '@vacinas-net/shared'
import { usePermissions } from 'ra-core'
import UndoFinishAttendanceButton from '../UndoFinishAttendanceButton'

type AttendanceActionProps = Pick<
  IAttendance,
  '_id' | 'status' | 'itemList' | 'clinicId' | 'uuid'
>

const OrderActionButton = (props: {
  attendanceProps: AttendanceActionProps
}) => {
  const showClasses = showStyles()
  const attendanceStatus = props.attendanceProps.status

  const { permissions } = usePermissions()

  const mustRenderUndoFinishAttendance =
    isAdmin(permissions) && attendanceStatus === 'finished'

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleClick = (event: any) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={showClasses.headerButtons}>
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ marginLeft: 'auto' }}
        data-testid="vertical-menu"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {mustRenderUndoFinishAttendance && (
          <div
            data-testid={`undo-attendance-finished-${props.attendanceProps._id}`}
          >
            <UndoFinishAttendanceButton
              isMenuItem
              permissions={permissions}
              attendance={{
                _id: props.attendanceProps._id,
                itemList: props.attendanceProps.itemList,
                status: props.attendanceProps.status,
              }}
            />
          </div>
        )}
      </Menu>
    </div>
  )
}

export default OrderActionButton
