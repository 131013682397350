import React from 'react'
import { Typography } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const ProductsListHeader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: mainTheme.spacing(2),
      }}
    >
      <div
        style={{
          width: 50,
        }}
      >
        <Typography align="center" variant="body1">
          SKU
        </Typography>
      </div>
      <div
        style={{
          width: 200,
        }}
      >
        <Typography align="center" variant="body1">
          Produto
        </Typography>
      </div>
      <div
        style={{
          width: 200,
        }}
      >
        <Typography align="center" variant="body1">
          Valor
        </Typography>
      </div>
      <div
        style={{
          width: 125,
        }}
      >
        <Typography align="center" variant="body1">
          Quantidade
        </Typography>
      </div>
      <div
        style={{
          width: 150,
        }}
      >
        <Typography align="center" variant="body1">
          Valor Total
        </Typography>
      </div>
    </div>
  )
}
export default ProductsListHeader
