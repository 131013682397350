import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const AggregatedOfferStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'accepted':
      statusText = 'Aceito'
      statusColor = mainTheme.palette.primary.main
      break
    case 'awaiting_confirmation':
      statusText = 'Aguardando Confimação'
      statusColor = mainTheme.palette.warning.main
      break
    case 'rejected':
      statusText = 'Rejeitado'
      statusColor = mainTheme.palette.error.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default AggregatedOfferStatusChip
