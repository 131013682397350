import { CircularProgress, IconButton } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import { isAfter } from 'date-fns'
import * as React from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  Record,
  TextField,
  useNotify,
} from 'react-admin'
import JointPurchaseStatusChip from '../../../components/chips/JointPurchaseStatusChip'
import CustomTimer from '../../../components/CustomTimer'
import InsertItemOnOfferButton from '../../../components/Offer/InsertItemOnOfferButton'
import { DefaultPagination } from '../../../components/Pagination'
import { downloadOfferCsv } from '../utils/downloadOfferCsv'
import JointPurchaseActions from './JointPurchaseActions'
import JointPurchaseFilter from './JointPurchaseFilters'

const JointPurchaseList = ({ ...props }: ListProps) => {
  const [downloadingOfferId, setDownloadingOfferId] = React.useState('')
  const notify = useNotify()

  const handleDownloadOfferCsvClick = async (record: Record | undefined) => {
    try {
      setDownloadingOfferId(String(record?.id))
      await downloadOfferCsv(String(record?.id), String(record?.name))
    } catch (error) {
      console.error(error)
      notify('Erro ao baixar csv da oferta', 'error')
    } finally {
      setDownloadingOfferId('')
    }
  }
  return (
    <List
      pagination={<DefaultPagination />}
      actions={<JointPurchaseActions />}
      filters={<JointPurchaseFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid
        stickyHeader
        rowClick={(id, basePath, record) => {
          return `joint-purchase/aggregated-offer-items?jointPurchaseOfferId=${record?._id}`
        }}
      >
        <TextField textAlign="center" source="name" label="Nome da compra" />
        <DateField
          textAlign="center"
          source="range.startDate"
          label="Data de ínicio"
          locales="pt-BR"
        />
        <DateField
          textAlign="center"
          source="range.endDate"
          label="Data de fim"
          locales="pt-BR"
        />
        <TextField
          textAlign="center"
          source="productsAmount"
          label="Quantidade de itens"
        />
        <TextField
          textAlign="center"
          source="interestedClinicCount"
          label="Quantidade de clínicas interessadas"
        />
        <FunctionField
          textAlign="center"
          label="Tempo restante"
          render={(record: Record | undefined) => (
            <CustomTimer endDate={new Date(record?.range.endDate)} />
          )}
        />
        <FunctionField
          textAlign="center"
          label="Status"
          render={(record: Record | undefined) => {
            if (isAfter(new Date(record?.range.startDate), Date.now())) {
              return <JointPurchaseStatusChip status="future" />
            }
            return <JointPurchaseStatusChip status={record?.status} />
          }}
        />
        <FunctionField
          textAlign="center"
          label="Download"
          render={(record: Record | undefined) => {
            return (
              <IconButton
                color="primary"
                onClick={(e) => {
                  e.stopPropagation()
                  handleDownloadOfferCsvClick(record)
                }}
                disabled={record?.status !== 'finished' || !!downloadingOfferId}
              >
                {downloadingOfferId == record?.id ? (
                  <CircularProgress color="inherit" size={20} />
                ) : (
                  <CloudDownload />
                )}
              </IconButton>
            )
          }}
        />
        <FunctionField
          textAlign="center"
          label="Adicionar item à compra"
          render={(record: Record | undefined) => {
            return (
              <InsertItemOnOfferButton jointPurchaseOfferId={record?._id} />
            )
          }}
        />
      </Datagrid>
    </List>
  )
}

export default JointPurchaseList
