import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FunctionField,
  Record,
  Confirm,
  useNotify,
  useRefresh,
} from 'react-admin'
import { format as formatCnpj } from 'cnpj'
import ClinicStatusChip from '../../../components/chips/ClinicStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import ClinicListActions from './ClinicListActions'
import ClinicFilters from './ClinicFilters'
import { FormGroup, Switch } from '@material-ui/core'
import customHttpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'

const ClinicList = (props: ListProps) => {
  // TODO: move action to inside clinic edit form
  /*const rowClick: RowClickFunction = async (
    clinicId: Identifier
  ): Promise<string> => {
    const params = qs.stringify({
      filter: JSON.stringify({
        'clinic->_id': clinicId,
      }),
    })
    return `product?${params}`
  }*/
  const [clinicToToggle, setClinicToToggle] =
    React.useState<{ clinicId: string; currentStatus: string } | null>(null)

  const [loading, setLoading] = React.useState(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleSwitchClick = () => {
    if (!clinicToToggle) return
    setLoading(true)
    customHttpClient(
      `${apiUrl}/clinic/${clinicToToggle.clinicId}/status/${
        clinicToToggle.currentStatus === 'active' ? 'inactive' : 'active'
      }`,
      {
        method: 'PATCH',
      }
    )
      .then(() => {
        notify(`Clínica atualizada com sucesso!`)
      })
      .catch((err) => {
        notify(`Erro ao Ativar/Desativar clínica: ${err}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setClinicToToggle(null)
        refresh()
      })
  }

  return (
    <>
      <List
        {...props}
        pagination={<DefaultPagination />}
        bulkActionButtons={false}
        sort={{ field: 'name', order: 'ASC' }}
        actions={<ClinicListActions />}
        filters={<ClinicFilters />}
        filterDefaultValues={{
          status: 'active',
        }}
      >
        <Datagrid stickyHeader rowClick="edit">
          <TextField textAlign="center" source="name" label="Nome" />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) =>
              record?.cnpj ? formatCnpj(record?.cnpj) : ''
            }
            source="cnpj"
            label="CNPJ"
          />
          <TextField
            textAlign="center"
            source="clinicGroup.name"
            label="Rede"
          />
          <TextField
            textAlign="center"
            source="managerData.name"
            label="Gestor"
          />
          <FunctionField
            textAlign="center"
            label="Usuários"
            render={(record: Record | undefined) => {
              return record?.relatedUsers.length
            }}
          />
          <FunctionField
            textAlign="center"
            label="Status"
            render={(record: Record | undefined) => {
              return <ClinicStatusChip status={record?.status} />
            }}
          />
          <FunctionField
            label="Ativar/Desativar"
            textAlign="center"
            render={(record: Record | undefined) => (
              <FormGroup style={{ alignItems: 'center' }}>
                <Switch
                  checked={record?.status === 'active'}
                  onClick={(e) => {
                    e.stopPropagation()
                    setClinicToToggle({
                      clinicId: record?._id,
                      currentStatus: record?.status,
                    })
                  }}
                />
              </FormGroup>
            )}
            align="center"
          />
        </Datagrid>
      </List>
      <Confirm
        isOpen={!!clinicToToggle}
        loading={loading}
        title="Você está ativando/desativando uma clínica"
        content="Deseja confirmar essa ação?"
        onConfirm={() => handleSwitchClick()}
        onClose={() => setClinicToToggle(null)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </>
  )
}

export default ClinicList
