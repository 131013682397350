import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@material-ui/core'
import { CheckCircleRounded, ErrorOutline } from '@material-ui/icons'
import React, { useState } from 'react'
import { apiUrl } from '../AppDataProvider'
import customHttpClient from '../CustomHttpClient'
import { useNotify } from 'react-admin'
import TextToClipboard from '../TextToClipboard'

const TemporaryPasswordPopup = (props: {
  open: boolean
  handleClose: () => void
  userId: string
}) => {
  const { open, handleClose, userId } = props
  const [loading, setLoading] = useState(false)
  const [temporaryPassword, setTemporaryPassword] = useState('')
  const notify = useNotify()

  const requestTemporaryPassword = () => {
    setLoading(true)
    const url = `${apiUrl}/user/${userId}/reset-password`
    customHttpClient(url, {
      method: 'post',
    })
      .then((response) => {
        setTemporaryPassword(response.json.newPassword)
        notify('Senha temporária gerada com sucesso', 'success')
      })
      .catch((error) => {
        notify(`Erro ao gerar senha temporária: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Dialog
      onClose={() => {
        setTemporaryPassword('')
        handleClose()
      }}
      open={open}
      maxWidth={'sm'}
    >
      {!temporaryPassword ? (
        <>
          <DialogTitle>Confirmação de senha temporária</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Essa ação irá redefinir a senha atual do usuário, está certo que
              deseja continuar?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<ErrorOutline />}
              onClick={() => {
                handleClose()
                setTemporaryPassword('')
              }}
              size="large"
              disabled={loading}
            >
              Cancelar
            </Button>
            <Button
              startIcon={<CheckCircleRounded />}
              onClick={() => requestTemporaryPassword()}
              color="primary"
              autoFocus
              size="large"
              disabled={loading}
            >
              {!loading ? 'Confirmar' : <CircularProgress size={20} />}
            </Button>
          </DialogActions>
        </>
      ) : (
        <>
          <DialogTitle>Senha temporada gerada</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Guarde essa senha com atenção, a mesma não poderá ser consultada
              novamente.
            </DialogContentText>
            <TextToClipboard text={temporaryPassword} />
          </DialogContent>
          <DialogActions>
            <Button
              startIcon={<ErrorOutline />}
              onClick={() => {
                handleClose()
                setTemporaryPassword('')
              }}
              size="large"
              disabled={loading}
            >
              Sair
            </Button>
          </DialogActions>
        </>
      )}
    </Dialog>
  )
}

export default TemporaryPasswordPopup
