import { IOrderItem, IProductStock } from '@vacinas-net/shared'
import React, { useEffect, useState } from 'react'
import {
  Button,
  Typography,
  Divider,
  Checkbox,
  CircularProgress,
} from '@material-ui/core'
import { AddCircle } from '@material-ui/icons'
import { mainTheme } from '../../utils/mainTheme'
import customHttpClient from '../CustomHttpClient'
import { apiUrl } from '../AppDataProvider'
import { useNotify, useRefresh } from 'react-admin'

const AddProductsToAttendance = (props: {
  avaliableProducts: IOrderItem[]
  clinicId: string
  attendanceId: string
}) => {
  const [loading, setLoading] = useState(true)
  const [open, setOpen] = useState(false)
  const [selectAll, setSelectAll] = useState(false)
  const [filteredProductsArray, setFilteredProductsArray] = useState<
    {
      orderItemId: string
      isActive?: boolean
      isSelected: boolean
      skuId: string
      name: string
      quantity?: number
    }[]
  >(
    props.avaliableProducts.map((product) => {
      return {
        orderItemId: product._id!,
        skuId: String(product.product?.skuId),
        isSelected: false,
        name: String(product.product?.name),
      }
    })
  )

  const notify = useNotify()
  const refresh = useRefresh()

  const addProducts = async () => {
    setLoading(true)
    customHttpClient(`${apiUrl}/attendance/${props.attendanceId}/items`, {
      method: 'post',
      body: JSON.stringify({
        orderItemList: filteredProductsArray
          .filter((product) => product.isSelected)
          .map((product) => product.orderItemId),
      }),
    })
      .then(() => {
        notify('Produtos adicionados com sucesso!', 'success')
      })
      .catch((err) => {
        notify(`Erro ao adicionar produtos: ${err}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        refresh()
      })
  }

  const getProducts = async () => {
    setLoading(true)
    const params: URLSearchParams = new URLSearchParams()
    params.set('clinic._id', props.clinicId)
    const searchedSkus = props.avaliableProducts
      .map((product) => `&product.skuId=${product?.product?.skuId}`)
      .join('')

    customHttpClient(`${apiUrl}/product?${params}${searchedSkus}`, {
      method: 'get',
    })
      .then((res) => {
        const results: IProductStock[] = res.json.results
        const arrayCopy = [...filteredProductsArray]
        for (let index = 0; index < arrayCopy.length; index++) {
          arrayCopy[index].isActive = results.find(
            (item) => item.product?.skuId === arrayCopy[index].skuId
          )?.isActive

          arrayCopy[index].quantity = results.find(
            (item) => item.product?.skuId === arrayCopy[index].skuId
          )?.totalQuantity
        }
        setFilteredProductsArray(arrayCopy)
      })
      .catch((err) => {
        console.error(err)
        notify(`Erro ao obter o estoque dos produtos: ${err}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const updateProductState = (index: number) => {
    const listCopy = [...filteredProductsArray]
    listCopy[index].isSelected = !listCopy[index].isSelected
    if (listCopy.find((item) => item.isSelected === false)) {
      setSelectAll(false)
    } else {
      setSelectAll(true)
    }
    setFilteredProductsArray(listCopy)
  }

  const handleSelectAllClick = () => {
    setSelectAll(!selectAll)
    setFilteredProductsArray(
      filteredProductsArray.map((product) => {
        return {
          ...product,
          isSelected: !selectAll,
        }
      })
    )
  }

  useEffect(() => {
    setSelectAll(false)
    if (open) getProducts()
  }, [open])

  return (
    <div>
      {!open ? (
        <div
          style={{
            position: 'absolute',
            right: 0,
            left: 0,
            bottom: 0,
            margin: mainTheme.spacing(2),
          }}
        >
          <Button
            onClick={() => {
              setOpen(true)
            }}
            color="primary"
            variant="outlined"
            fullWidth
            startIcon={<AddCircle />}
          >
            Adicionar produtos
          </Button>
        </div>
      ) : !loading ? (
        <div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: mainTheme.spacing(1),
            }}
          >
            <Checkbox
              style={{ marginRight: mainTheme.spacing(2) }}
              size="medium"
              checked={selectAll}
              onChange={() => {
                handleSelectAllClick()
              }}
            />
            <Typography align="center" style={{ width: 100 }} variant="h3">
              Sku ID
            </Typography>
            <Typography align="center" style={{ width: 300 }} variant="h3">
              Produto
            </Typography>
            <Typography align="center" style={{ width: 300 }} variant="h3">
              Estoque
            </Typography>
          </div>
          <Divider color="primary" />

          {filteredProductsArray.map((product, index) => {
            return (
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  marginTop: mainTheme.spacing(2),
                }}
                key={index}
              >
                <Checkbox
                  style={{ marginRight: mainTheme.spacing(2) }}
                  size="medium"
                  checked={product.isSelected}
                  onChange={() => {
                    updateProductState(index)
                  }}
                />
                <Typography
                  align="center"
                  style={{ width: 100 }}
                  variant="body1"
                >
                  {product.skuId}
                </Typography>
                <Typography
                  align="center"
                  style={{ width: 300 }}
                  variant="body1"
                >
                  {product.name}
                </Typography>
                <Typography
                  align="center"
                  style={{ width: 300 }}
                  variant="body1"
                >
                  {product.isActive && product.quantity! > 0
                    ? product.quantity
                    : 'Sem produto no estoque'}
                </Typography>
              </div>
            )
          })}
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginTop: mainTheme.spacing(2),
              justifyContent: 'flex-end',
              marginRight: mainTheme.spacing(6),
            }}
          >
            <Button
              onClick={() => setOpen(false)}
              color="primary"
              variant="contained"
              style={{ marginRight: mainTheme.spacing(2) }}
            >
              Cancelar
            </Button>
            <Button
              onClick={() => addProducts()}
              color="primary"
              variant="contained"
            >
              Confirmar
            </Button>
          </div>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            width: '100%',
            justifyContent: 'center',
            alignItems: 'center',
            position: 'absolute',
            bottom: 0,
            paddingBottom: mainTheme.spacing(2),
          }}
        >
          <CircularProgress size={30} />
        </div>
      )}
    </div>
  )
}

export default AddProductsToAttendance
