import * as React from 'react'
import ChromeReaderModeIcon from '@material-ui/icons/ChromeReaderMode'
import MonetizationOn from '@material-ui/icons/MonetizationOn'
import PowerSettingsNewIcon from '@material-ui/icons/PowerSettingsNew'
import ReceiptIcon from '@material-ui/icons/Receipt'
import ListIcon from '@material-ui/icons/List'
import ProductionQuantityLimitsIcon from '@mui/icons-material/ProductionQuantityLimits'
import { makeStyles, MenuItem, ListItemIcon } from '@material-ui/core'
import {
  MenuItemLink,
  useLogout,
  usePermissions,
  useToggleSidebar,
} from 'react-admin'
import { mainTheme } from '../../utils/mainTheme'
import vacinasLogo from '../../assets/VacinasLogo.svg'
import vacinasWatermark from '../../assets/VacinasWatermarkWhite.svg'
import {
  AccountBalanceOutlined,
  ArrowDropDown,
  Home,
  LocalActivity,
  LocalAtm,
  LocalHospital,
  Help,
  MoneyOff,
  Notifications,
  Person,
  ShoppingCart,
  Today,
  Menu,
  MenuOpen,
  EventAvailable,
} from '@material-ui/icons'
import { Vaccines } from '@mui/icons-material'
import SubMenu from '../SubMenu'
import { isAdmin, isClinicAdmin, isClinicOperator } from '@vacinas-net/shared'
import NotificationCounter from '../NotificationCounter'
import { formatISO } from 'date-fns'
import {
  formatDateToEndDay,
  formatDateToStartDay,
  formatDateToUTC,
} from '../../utils/date'
import { Divider } from '@mui/material'
// import VaccinesIcon from '@mui/icons-material/Vaccines'

const menuStyles = makeStyles({
  container: {
    backgroundColor: mainTheme.palette.primary.main,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  containerClose: {
    backgroundColor: mainTheme.palette.primary.main,
    width: 55,
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  divider: {
    background: mainTheme.palette.primary.contrastText,
    margin: '5px 10px !important',
    fontWeight: 100,
  },
  toggleMenu: {
    marginTop: mainTheme.spacing(2),
    color: mainTheme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'center',
    cursor: 'pointer',
  },
  toggleOpen: {
    marginTop: mainTheme.spacing(2),
    color: mainTheme.palette.primary.contrastText,
    display: 'flex',
    justifyContent: 'right',
    marginRight: mainTheme.spacing(1),
    cursor: 'pointer',
  },
  logoContainer: {
    display: 'flex',
    justifyContent: 'center',
    marginBottom: mainTheme.spacing(2),
    marginTop: mainTheme.spacing(1),
  },
  logoutContainer: {
    flex: 1,
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    position: 'relative',
  },
  notificationCounter: {
    position: 'absolute',
    top: mainTheme.spacing(2),
    right: 0,
    marginRight: mainTheme.spacing(3),
  },
  notificationCounterSm: {
    position: 'absolute',
    top: mainTheme.spacing(1),
    right: -9,
    marginRight: mainTheme.spacing(3),
  },
  hideOnMobile: {
    [mainTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
})

const menuItemStyles = makeStyles({
  root: {
    color: mainTheme.palette.primary.contrastText,
    paddingBottom: mainTheme.spacing(2),
    paddingTop: mainTheme.spacing(2),
    fontSize: mainTheme.typography.body1.fontSize,
    fontWeight: 400,
    width: '100%',
  },
  active: {
    color: mainTheme.palette.secondary.contrastText,
    paddingBottom: mainTheme.spacing(2),
    paddingTop: mainTheme.spacing(2),
    fontSize: mainTheme.typography.body1.fontSize,
    fontWeight: 400,
    backgroundColor: mainTheme.palette.secondary.dark,
    '&:hover': {
      backgroundColor: mainTheme.palette.secondary.dark,
    },
  },
  icon: {
    minWidth: mainTheme.spacing(5),
    color: mainTheme.palette.primary.contrastText,
  },
})

const MyMenu = () => {
  const [sidebarIsOpen, setSidebarIsOpen] = useToggleSidebar()
  const classes = menuStyles()
  const menuItemClasses = menuItemStyles()
  const logout = useLogout()
  const { permissions } = usePermissions()

  const showAdminContent = isAdmin(permissions || [])
  const showClinicAdminContent = isClinicAdmin(permissions || [])
  const showClinicOperatorContent = isClinicOperator(permissions || [])

  const currentDate = new Date()
  const currentStartUTCDate = formatDateToUTC(formatDateToStartDay(currentDate))
  const currentEndUTCDate = formatDateToUTC(formatDateToEndDay(currentDate))

  const PayoutsSubMenuItems = () => (
    <>
      {showAdminContent && (
        <MenuItemLink
          primaryText={'Repasses'}
          to={{
            pathname: `/payout`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<LocalAtm />}
          classes={menuItemClasses}
          exact
        />
      )}
      {showClinicAdminContent && (
        <MenuItemLink
          primaryText={'Resumo dos repasses'}
          to={{
            pathname: `/payout-summary`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<LocalAtm />}
          classes={menuItemClasses}
        />
      )}
      {showAdminContent && (
        <MenuItemLink
          primaryText={'Repasses por Clínica'}
          to={{
            pathname: `/payout/grouped/clinic`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<AccountBalanceOutlined />}
          classes={menuItemClasses}
          exact
        />
      )}
      {showClinicAdminContent && (
        <MenuItemLink
          primaryText={'Repasses Realizados'}
          to={{
            pathname: `/payout-settlement`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<MoneyOff />}
          classes={menuItemClasses}
          exact
        />
      )}
    </>
  )

  const StockSubMenuItems = () => (
    <>
      {showAdminContent && (
        <>
          <MenuItemLink
            primaryText={'Todos os estoques'}
            to={{
              pathname: `/product`,
              state: { _scrollToTop: true },
            }}
            sidebarIsOpen={sidebarIsOpen}
            leftIcon={<ListIcon />}
            classes={menuItemClasses}
            exact
          />
          <MenuItemLink
            primaryText={'Produtos indisponíveis'}
            to={{
              pathname: `/unavailable-product`,
              state: { _scrollToTop: true },
            }}
            sidebarIsOpen={sidebarIsOpen}
            leftIcon={<ProductionQuantityLimitsIcon />}
            classes={menuItemClasses}
            exact
          />
        </>
      )}
    </>
  )

  return (
    <div className={sidebarIsOpen ? classes.container : classes.containerClose}>
      <div
        onClick={() => setSidebarIsOpen()}
        className={!sidebarIsOpen ? classes.toggleMenu : classes.toggleOpen}
      >
        {sidebarIsOpen ? <MenuOpen /> : <Menu />}
      </div>
      <div className={`${classes.logoContainer} ${classes.hideOnMobile}`}>
        <img
          src={sidebarIsOpen ? vacinasLogo : vacinasWatermark}
          style={{ width: 190 }}
        />
      </div>
      {showClinicAdminContent && (
        <MenuItemLink
          primaryText={'Dashboard'}
          to={{
            pathname: `/`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<Home />}
          classes={menuItemClasses}
          exact
        />
      )}
      {showAdminContent && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            position: 'relative',
          }}
        >
          <MenuItemLink
            primaryText={'Pedidos'}
            to={{
              pathname: `/order`,
              state: { _scrollToTop: true },
            }}
            sidebarIsOpen={sidebarIsOpen}
            leftIcon={<ReceiptIcon />}
            classes={menuItemClasses}
          />
          <div
            className={
              sidebarIsOpen
                ? classes.notificationCounter
                : classes.notificationCounterSm
            }
          >
            <NotificationCounter
              path={`order/counter?status=awaiting_clinic_confirmation`}
            />
          </div>
        </div>
      )}

      <MenuItemLink
        primaryText={'Atendimentos'}
        to={{
          pathname: `/not-accepted-attendance`,
          state: { _scrollToTop: true },
        }}
        sidebarIsOpen={sidebarIsOpen}
        leftIcon={<Today />}
        classes={menuItemClasses}
        exact
      />

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <MenuItemLink
          primaryText={'Agenda de atendimentos'}
          to={{
            pathname: `/attendance`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<EventAvailable />}
          classes={menuItemClasses}
        />
        <div
          className={
            sidebarIsOpen
              ? classes.notificationCounter
              : classes.notificationCounterSm
          }
        >
          <NotificationCounter
            path={`attendance/counter?deliveryWindow.startDate(min)=${formatISO(
              currentStartUTCDate
            )}&deliveryWindow.endDate(max)=${formatISO(currentEndUTCDate)}`}
          />
        </div>
      </div>

      {/* TODO: Check why it was planned */}
      {/* {showAdminContent && (
        <MenuItemLink
          primaryText={'Vacinas'}
          to={{
            pathname: `/vaccine-shot`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<VaccinesIcon />}
          classes={menuItemClasses}
        />
      )} */}
      {showAdminContent && (
        <MenuItemLink
          primaryText={'Produtos'}
          to={{
            pathname: `/product-sku`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<Vaccines />}
          classes={menuItemClasses}
        />
      )}
      {!showAdminContent && showClinicOperatorContent && (
        <MenuItemLink
          primaryText={'Estoque'}
          to={{
            pathname: `/product`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<ListIcon />}
          classes={menuItemClasses}
        />
      )}

      {sidebarIsOpen && showAdminContent && (
        <SubMenu icon={<ArrowDropDown />} dense={false} name="Estoque">
          <StockSubMenuItems />
        </SubMenu>
      )}

      {showAdminContent && (
        <MenuItemLink
          primaryText={'Usuários'}
          to={{
            pathname: `/user`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<Person />}
          classes={menuItemClasses}
        />
      )}

      {showAdminContent && (
        <MenuItemLink
          primaryText={'Clínicas'}
          to={{
            pathname: `/clinic`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<LocalHospital />}
          classes={menuItemClasses}
        ></MenuItemLink>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          position: 'relative',
        }}
      >
        <MenuItemLink
          primaryText={'Notificações'}
          to={{
            pathname: `/notification`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<Notifications />}
          classes={menuItemClasses}
        />
        <div
          className={
            sidebarIsOpen
              ? classes.notificationCounter
              : classes.notificationCounterSm
          }
        >
          <NotificationCounter path={'notification/counter'} />
        </div>
      </div>

      <Divider className={classes.divider} />

      {showAdminContent && (
        <>
          <MenuItemLink
            primaryText={'Campanhas'}
            to={{
              pathname: `/campaign`,
              state: { _scrollToTop: true },
            }}
            sidebarIsOpen={sidebarIsOpen}
            leftIcon={<ChromeReaderModeIcon />}
            classes={menuItemClasses}
          />
        </>
      )}

      {showAdminContent && (
        <MenuItemLink
          primaryText={'Vales'}
          to={{
            pathname: `/voucherPackage`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<LocalActivity />}
          classes={menuItemClasses}
        />
      )}

      {showClinicAdminContent && (
        <MenuItemLink
          primaryText={'Condições Comerciais'}
          to={{
            pathname: `/commercial-condition`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<MonetizationOn />}
          classes={menuItemClasses}
        />
      )}

      <Divider className={classes.divider} />

      {!sidebarIsOpen && showClinicAdminContent && <PayoutsSubMenuItems />}

      {sidebarIsOpen && showClinicAdminContent && (
        <SubMenu icon={<ArrowDropDown />} dense={false} name="Repasses">
          <PayoutsSubMenuItems />
        </SubMenu>
      )}

      {showClinicAdminContent && (
        <MenuItemLink
          primaryText={'Compras conjuntas'}
          to={{
            pathname: `/joint-purchase`,
            state: { _scrollToTop: true },
          }}
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<ShoppingCart />}
          classes={menuItemClasses}
        />
      )}

      <Divider className={classes.divider} />

      {showClinicOperatorContent && (
        <MenuItemLink
          primaryText={'Suporte'}
          to={{
            pathname: undefined,
            state: { _scrollToTop: true },
          }}
          onClick={() =>
            window.open('https://vacinas-net.tomticket.com', '_blank')
          }
          sidebarIsOpen={sidebarIsOpen}
          leftIcon={<Help />}
          classes={menuItemClasses}
        />
      )}

      <div className={classes.logoutContainer}>
        <MenuItem
          style={{ position: 'sticky', bottom: 0 }}
          className={menuItemClasses.root}
          onClick={() => logout()}
        >
          <ListItemIcon className={menuItemClasses.icon}>
            <PowerSettingsNewIcon />
          </ListItemIcon>
          {'Sair'}
        </MenuItem>
      </div>
    </div>
  )
}

export default MyMenu
