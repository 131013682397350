import * as React from 'react'
import { useState } from 'react'
import {
  EditContextProvider,
  FieldProps,
  EditProps,
  useEditController,
  Record,
  useNotify,
  useRefresh,
  Confirm,
} from 'react-admin'
import { apiUrl } from '../../../components/AppDataProvider'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import ClinicForm from '../ClinicForm'
import httpClient from '../../../components/CustomHttpClient'
import { GetAllExternalDataAndAddressType } from '../ClinicCreate'
import {
  brazilianStatesArray,
  IClinic,
  IPixKey,
  OpeningHours,
} from '@vacinas-net/shared'
import { Button } from '@material-ui/core'
import { localizeMinuteOfDay } from '../../../components/OpeningHours/OpeningHourRow/utils'
import { transformOpeningHours } from '../../../components/OpeningHours/utils'
import ClinicFormToolbar from '../ClinicFormToolbar'
import { defaultDeliveryInstructions } from '../utils'
import { transformAttendanceFreightValues } from '../FreightValueSession/utils'

const CustomClinicEdit: React.FC<FieldProps> = (props) => {
  const controllerProps = useEditController(props)
  const receivedRecord = controllerProps.record
  const [clinic, setClinic] = useState<IClinic | undefined>(undefined)

  React.useEffect(() => {
    const currentClinic = receivedRecord as IClinic
    const pickupOpeningHours: OpeningHours[] =
      currentClinic?.openingHoursV2?.pickup || []
    const deliveryOpeningHours: OpeningHours[] =
      currentClinic?.openingHoursV2?.delivery || []

    const localizedPickupOpeningHours = pickupOpeningHours.map((item) => ({
      ...item,
      startTimeInMinutes: localizeMinuteOfDay(item.startTimeInMinutes),
      endTimeInMinutes: localizeMinuteOfDay(item.endTimeInMinutes),
    }))

    const localizedDeliveryOpeningHours = deliveryOpeningHours.map((item) => ({
      ...item,
      startTimeInMinutes: localizeMinuteOfDay(item.startTimeInMinutes),
      endTimeInMinutes: localizeMinuteOfDay(item.endTimeInMinutes),
    }))

    const currentBrazilState = brazilianStatesArray.find(
      (item) => item.id === receivedRecord?.address?.state
    )

    const currentFullState = currentBrazilState
      ? {
          ...currentBrazilState,
          label: currentBrazilState.name,
          value: currentBrazilState?.id,
        }
      : null

    const city = {
      label: receivedRecord?.address?.city,
      value: receivedRecord?.address?.city,
    }

    const neighborhood = {
      label: receivedRecord?.address?.neighborhood,
      value: receivedRecord?.address?.neighborhood,
    }

    const delivery = currentClinic?.attendanceFreightValues?.delivery.map(
      (state) => {
        return {
          ...state,
          cities: state.cities.map((city) => {
            return {
              ...city,
              fullData: { ...city, label: city.name, value: city.name },
              neighborhoods: city.neighborhoods.map((neighborhood) => {
                return {
                  ...neighborhood,
                  label: neighborhood.name,
                  value: neighborhood.name,
                  city: {
                    name: city.name,
                  },
                }
              }),
            }
          }),
        }
      }
    )

    const record: Record = {
      ...currentClinic,
      attendanceFreightValues: {
        delivery: delivery,
        pickup: currentClinic?.attendanceFreightValues?.pickup,
      },
      openingHoursV2: {
        pickup: localizedPickupOpeningHours,
        delivery: localizedDeliveryOpeningHours,
      },
      pickupOpeningHours: localizedPickupOpeningHours,
      deliveryOpeningHours: localizedDeliveryOpeningHours,
      address: {
        ...receivedRecord?.address,
        state: currentFullState,
        city,
        neighborhood,
      },
      pickupAttendanceEnabled: currentClinic?.enabledAttendanceType?.find(
        (item) => item === 'pickup'
      ),
      deliveryAttendanceEnabled: currentClinic?.enabledAttendanceType?.find(
        (item) => item === 'delivery'
      ),
      id: String(currentClinic?._id),
      deliveryInstructions:
        currentClinic?.deliveryInstructions || defaultDeliveryInstructions,
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const weekdayOpeningHours: any = {
      deliveryOpeningHours: {},
      pickupOpeningHours: {},
    }

    for (let i = 0; i < 7; ++i) {
      const isWeekdayActive = localizedPickupOpeningHours.some(
        (openingHour) => openingHour.weekday === i
      )
      weekdayOpeningHours.pickupOpeningHours[`weekday-${i}`] = isWeekdayActive
    }

    for (let i = 0; i < 7; ++i) {
      const isWeekdayActive = localizedDeliveryOpeningHours.some(
        (openingHour) => openingHour.weekday === i
      )
      weekdayOpeningHours.deliveryOpeningHours[`weekday-${i}`] = isWeekdayActive
    }

    record.weekdayOpeningHours = weekdayOpeningHours

    setClinic(record as IClinic)
  }, [receivedRecord])

  const [clinicToToggle, setClinicToToggle] =
    React.useState<{ clinicId: string; currentStatus: string } | null>(null)

  const [loading, setLoading] = React.useState(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const handleSwitchClick = () => {
    if (!clinicToToggle) return
    setLoading(true)
    httpClient(
      `${apiUrl}/clinic/${clinicToToggle.clinicId}/status/${
        clinicToToggle.currentStatus === 'active' ? 'inactive' : 'active'
      }`,
      {
        method: 'PATCH',
      }
    )
      .then(() => {
        notify(`Clínica atualizada com sucesso!`)
      })
      .catch((err) => {
        notify(`Erro ao Ativar/Desativar clínica: ${err}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setClinicToToggle(null)
        refresh()
      })
  }

  if (!clinic) return <></>

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <HeaderWithGoBack title={`Clinic ${clinic.name}`} />
          <div style={{ alignItems: 'center', marginLeft: 'auto' }}>
            {clinic.status === 'active' ? (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setClinicToToggle({
                    clinicId: clinic._id!,
                    currentStatus: clinic.status!,
                  })
                }}
              >
                Desativar clínica
              </Button>
            ) : (
              <Button
                color="primary"
                variant="contained"
                onClick={() => {
                  setClinicToToggle({
                    clinicId: clinic._id!,
                    currentStatus: clinic.status!,
                  })
                }}
              >
                Ativar clínica
              </Button>
            )}
          </div>
        </div>
        <Confirm
          isOpen={!!clinicToToggle}
          loading={loading}
          title="Você está ativando/desativando uma clínica"
          content="Deseja confirmar essa ação?"
          onConfirm={() => handleSwitchClick()}
          onClose={() => setClinicToToggle(null)}
          confirm="Confirmar"
          cancel="Cancelar"
        />
        {React.cloneElement(props.children as React.ReactElement, {
          ...controllerProps,
          record: clinic,
        })}
      </div>
    </EditContextProvider>
  )
}

const ClinicEdit = (props: EditProps) => {
  const [vtexData, setVtexData] = useState<GetAllExternalDataAndAddressType[]>(
    []
  )

  const fetchVtexData = async () => {
    return httpClient(`${apiUrl}/clinic/external/vtex`, {
      method: 'get',
    }).then((res) => setVtexData(res.json))
  }

  const transform = async (data: Record) => {
    const openingHoursV2 = transformOpeningHours(data)
    const attendanceFreightValues = transformAttendanceFreightValues(data)
    const enabledAttendanceType: IClinic['enabledAttendanceType'] = []

    if (data.pickupAttendanceEnabled) {
      enabledAttendanceType.push('pickup')
    }

    if (data.deliveryAttendanceEnabled) {
      enabledAttendanceType.push('delivery')
    }

    const transformedData: IClinic = {
      name: data.name,
      cnpj: data.cnpj,
      phone: data.phone,
      companyName: data.companyName,
      website: data.website,
      logoUrl: data.logoUrl ? data.logoUrl : undefined,
      managerData: { ...data.managerData, _id: undefined },
      address: {
        ...data.address,
        state: data?.address?.state?.value,
        city: data?.address?.city?.value,
        neighborhood: data?.address?.neighborhood?.value,
      },
      financialGroupData: {
        ...data.financialGroupData,
        bankAccount: {
          ...data?.financialGroupData?.bankAccount,
          _id: undefined,
        },
        pixKeys: data?.financialGroupData?.pixKeys.filter(
          (key: IPixKey) => !!key.type && !!key.value
        ),
        _id: undefined,
      },
      clinicGroup: { _id: data.clinicGroup._id },
      payoutConfig: { ...data.payoutConfig, _id: undefined },
      openingHoursV2,
      attendanceCapacityPerHour: data.attendanceCapacityPerHour,
      deliveryAttendanceCapacityPerHour: data.deliveryAttendanceCapacityPerHour,
      enabledAttendanceType,
      deliveryInstructions: data.deliveryInstructions,
      attendanceFreightValues,
    }

    return transformedData
  }

  React.useEffect(() => {
    fetchVtexData()
  }, [])

  return (
    <CustomClinicEdit
      mutationMode="pessimistic"
      transform={transform}
      {...props}
    >
      <ClinicForm
        id={props.id}
        vtexData={vtexData}
        toolbar={<ClinicFormToolbar />}
        isEdit
      />
    </CustomClinicEdit>
  )
}

export default ClinicEdit
