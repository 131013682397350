import React, { useEffect, useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { Typography } from '@material-ui/core'
import { apiUrl } from '../AppDataProvider'
import customHttpClient from '../CustomHttpClient'
import { isAdmin } from '@vacinas-net/shared'
import { GetSummaryPayoutSettlementDTO } from '@vacinas-net/shared'
import { useListContext, useNotify, usePermissions } from 'react-admin'
import {
  getFilters,
  parseDeliveryWindowWithoutHours,
  valueInCentsToBRL,
} from '../../utils/utils'
import { stringify } from 'query-string'
import CustomPayoutSettlementPopup from '../CustomPayoutSettlementPopup'

const PayoutListActionsPannel = () => {
  const [summaryData, setSummaryData] =
    useState<GetSummaryPayoutSettlementDTO>()
  const notify = useNotify()
  const listContext = useListContext()
  const filters = getFilters(listContext.filterValues)
  const { permissions } = usePermissions()
  const showAdminContent = isAdmin(permissions)

  const getSummaryPayoutSettlement = () => {
    const url = `${apiUrl}/payout-settlement/summary?${stringify(filters)}`
    customHttpClient(url, {
      method: 'get',
    })
      .then((res) => {
        setSummaryData(res.json)
      })
      .catch((error) => {
        notify(`Erro ao obter resumo dos repasses: ${error}`, 'error')
      })
  }

  useEffect(() => {
    getSummaryPayoutSettlement()
  }, [listContext.filterValues])

  return (
    <div
      style={{
        marginTop: mainTheme.spacing(1),
        width: '100%',
      }}
    >
      {summaryData && (
        <div
          style={{
            display: 'flex',
            backgroundColor: '#F7F7F7',
            border: '1px solid #ECECEC',
            padding: mainTheme.spacing(2),
            alignItems: 'center',
          }}
        >
          <div
            style={{
              width: mainTheme.spacing(35),
            }}
          >
            <Typography
              align="left"
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              Valor a repassar
            </Typography>
            <Typography align="left" variant="body2" color="textSecondary">
              {summaryData?.processing?.summary.totalPayoutValueInCents &&
                valueInCentsToBRL(
                  summaryData.processing.summary.totalPayoutValueInCents
                )}{' '}
              (Em processamento)
            </Typography>
            <Typography align="left" variant="body1" color="primary">
              {summaryData?.readyToPay?.summary.totalPayoutValueInCents &&
                valueInCentsToBRL(
                  summaryData.readyToPay.summary.totalPayoutValueInCents
                )}{' '}
              (Pronto)
            </Typography>
          </div>

          <div
            style={{
              width: mainTheme.spacing(35),
            }}
          >
            <Typography
              align="left"
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              Valor Vacinas.net
            </Typography>
            <Typography align="left" variant="body2" color="textSecondary">
              {summaryData?.processing?.summary.totalHoldValueInCents &&
                valueInCentsToBRL(
                  summaryData.processing.summary.totalHoldValueInCents
                )}{' '}
              (Em processamento)
            </Typography>
            <Typography align="left" variant="body1" color="primary">
              {summaryData?.readyToPay?.summary.totalHoldValueInCents &&
                valueInCentsToBRL(
                  summaryData.readyToPay.summary.totalHoldValueInCents
                )}{' '}
              (Pronto)
            </Typography>
          </div>

          <div
            style={{
              width: mainTheme.spacing(25),
            }}
          >
            <Typography
              align="left"
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              Quantidade Clínicas
            </Typography>
            <Typography align="left" variant="body2" color="textSecondary">
              {summaryData?.processing?.summary.allClinics &&
                summaryData.processing.summary.allClinics.length}{' '}
              (Em processamento)
            </Typography>
            <Typography align="left" variant="body1" color="primary">
              {summaryData?.readyToPay?.summary.allClinics &&
                summaryData.readyToPay.summary.allClinics.length}{' '}
              (Pronto)
            </Typography>
          </div>

          <div
            style={{
              width: mainTheme.spacing(25),
            }}
          >
            <Typography
              align="left"
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              Quantidade Pedidos
            </Typography>
            <Typography align="left" variant="body2" color="textSecondary">
              {summaryData?.processing?.summary.ordersQuantity &&
                summaryData.processing.summary.ordersQuantity}{' '}
              (Em processamento)
            </Typography>
            <Typography align="left" variant="body1" color="primary">
              {summaryData?.readyToPay?.summary.ordersQuantity &&
                summaryData.readyToPay.summary.ordersQuantity}{' '}
              (Pronto)
            </Typography>
          </div>

          <div
            style={{
              width: mainTheme.spacing(40),
            }}
          >
            <Typography
              align="left"
              variant="body1"
              color="textSecondary"
              gutterBottom
            >
              Data Primeiro e Último Repasse
            </Typography>
            <Typography align="left" variant="body2" color="textSecondary">
              {parseDeliveryWindowWithoutHours(
                summaryData.processing?.summary.dateRange
              )}{' '}
              (Em processamento)
            </Typography>
            <Typography align="left" variant="body1" color="primary">
              {parseDeliveryWindowWithoutHours(
                summaryData.readyToPay?.summary.dateRange
              )}{' '}
              (Pronto)
            </Typography>
          </div>
          {showAdminContent && summaryData?.readyToPay?.payoutIdList && (
            <div style={{ marginLeft: 'auto' }}>
              <CustomPayoutSettlementPopup
                payoutIdList={summaryData.readyToPay.payoutIdList}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}

export default PayoutListActionsPannel
