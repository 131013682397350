import {
  TextField,
  Button,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { useNotify, useRefresh } from 'ra-core'
import React, { useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../CustomHttpClient'

const EditableQuantityTableField = (props: {
  initialValue?: string
  label?: string
  id: string
  hideButton?: boolean
}) => {
  const { initialValue, label, id, hideButton } = props

  const [value, setValue] = useState(initialValue || '')
  const [edit, setEdit] = useState(false)
  const [loading, setLoading] = useState(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const editNumber = () => {
    setLoading(true)
    const url = `${apiUrl}/joint-purchase/offer-item/${id}`
    httpClient(url, {
      method: 'PATCH',
      body: JSON.stringify({
        amount: value,
      }),
    })
      .then(() => {
        notify('Quantidade editada com sucesso!', 'success')
      })
      .catch((error) => {
        notify(`Erro ao editar quantidade: ${error}`, 'error')
      })
      .finally(() => {
        setEdit(false)
        setLoading(false)
        refresh()
      })
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {edit ? (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <TextField
            value={value}
            label={label}
            onChange={(event) => {
              setValue(event.target.value)
            }}
            variant="outlined"
            size="small"
            type="number"
            style={{ maxWidth: 115 }}
            inputProps={{
              'data-testid': 'edit-amount-input',
            }}
          />
          <Button
            style={{ marginLeft: mainTheme.spacing(1) }}
            variant="contained"
            size="medium"
            color="primary"
            onClick={() => editNumber()}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              `Salvar`
            )}
          </Button>
        </div>
      ) : (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography variant="body2">{value}</Typography>
          {!Boolean(hideButton) && (
            <Button
              style={{ marginLeft: mainTheme.spacing(1) }}
              variant="contained"
              size="medium"
              color="primary"
              onClick={() => setEdit(true)}
            >
              Editar
            </Button>
          )}
        </div>
      )}
    </div>
  )
}

export default EditableQuantityTableField
