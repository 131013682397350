import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { UnavailableProductsFiltersArray } from './Filters'
import { ReportTypeEnum } from '@vacinas-net/shared'

const UnavailableProductsActions = () => (
  <CustomListActions
    filtersAvailable={UnavailableProductsFiltersArray.map((item) => ({
      label: item.label,
      value: item.value,
    }))}
    hasExport
    exportType={ReportTypeEnum.UnavailableProduct}
    exportButtonText="Exportar Produtos Indisponíveis"
  />
)

export default UnavailableProductsActions
