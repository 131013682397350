import {
  CustomerAvailableCampaignForDoseRegisterDTO,
  IAttendance,
  IPatient,
  IVaccineShot,
} from '@vacinas-net/shared'
import GetAttendanceByDocumentInput from '../components/RegisterVaccineShotComponents/GetAttendanceByDocumentInput/GetAttendanceByDocumentInput'
import React, { useEffect, useState } from 'react'

import qs from 'qs'
import { ThemeProvider, makeStyles, Typography } from '@material-ui/core'
import { mainTheme } from '../utils/mainTheme'
import { useHistory } from 'react-router-dom'
import AttendanceDataModal from '../components/RegisterVaccineShotComponents/AttendanceDataModal'
import AvailableAttendanceFormHeader from '../components/RegisterVaccineShotComponents/AvailableAttendanceFormHeader'
import VaccinatedPatientsTable from '../components/RegisterVaccineShotComponents/VaccinatedPatientsTable'
import { apiUrl } from '../components/AppDataProvider'
import { Notification, useNotify } from 'react-admin'

export const useStyles = makeStyles({
  registerVaccineShotTableContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: mainTheme.spacing(6),
    padding: mainTheme.spacing(2),
  },
})

const RegisterVaccineShotTable = () => {
  const [documentValue, setDocumentValue] = useState<string>('')
  const [attendance, setAttendance] =
    useState<(IAttendance & { vaccineShots?: IVaccineShot[] }) | undefined>()
  const [patientDataArray, setPatientDataArray] =
    useState<
      {
        patient: IPatient
        shotDate?: Date
      }[]
    >()
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const history = useHistory()
  const classes = useStyles()
  const notify = useNotify()

  const params = qs.parse(history.location.search.replace('?', ''))
  const storageCampaignData = localStorage.getItem('campaignData')
  const campaignData = storageCampaignData
    ? (JSON.parse(
        storageCampaignData
      ) as CustomerAvailableCampaignForDoseRegisterDTO)
    : undefined
  const campaignDataAttendance = campaignData?.availableAttendance

  const campaignDataAttendanceStartDateTime =
    campaignDataAttendance && new Date(campaignDataAttendance.startDatetime)
  const campaignDataAttendanceEndDateTime =
    campaignDataAttendance && new Date(campaignDataAttendance.endDatetime)

  const fetchPatientsTableData = async () => {
    setLoadingTable(true)
    try {
      const response = await fetch(
        `${apiUrl}/campaign/available-attendance/${campaignDataAttendance?._id}/vaccinated-patients`,
        {
          method: 'get',
        }
      )
      if (!response.ok) {
        const text = await response.text()
        throw new Error(text)
      }
      const parsedContent = await response.json()
      setPatientDataArray(parsedContent)
    } catch (error) {
      console.error(error)
      notify(`Erro ao obter pacientes: ${error}`, 'error')
    } finally {
      setLoadingTable(false)
    }
  }

  useEffect(() => {
    fetchPatientsTableData()
  }, [])

  return (
    <ThemeProvider theme={mainTheme}>
      <div className={classes.registerVaccineShotTableContainer}>
        <div>
          <AvailableAttendanceFormHeader
            address={campaignDataAttendance?.address}
            camapaignName={campaignData?.name}
            clinicName={campaignDataAttendance?.clinic.name}
            endDatetime={campaignDataAttendanceEndDateTime}
            startDatetime={campaignDataAttendanceStartDateTime}
            totalAvailableAttendances={
              campaignData?.availableAttendance?.appointmentsTotal
            }
          />
          <GetAttendanceByDocumentInput
            campaignId={String(params.campaignId)}
            documentValue={documentValue}
            setDocumentValue={(inputValue: string) => {
              setDocumentValue(inputValue)
            }}
            setAttendance={(
              fetchedAttendance: IAttendance & {
                vaccineShots?: IVaccineShot[]
              }
            ) => {
              setAttendance(fetchedAttendance)
            }}
            setOpenModal={(open: boolean) => {
              setOpenModal(open)
            }}
            loading={loading}
            setLoading={(loading: boolean) => {
              setLoading(loading)
            }}
          />
          <Typography
            style={{ marginTop: mainTheme.spacing(2) }}
            variant="h2"
            color="primary"
          >
            Doses aplicadas
          </Typography>
          <VaccinatedPatientsTable
            patientDataArray={patientDataArray}
            loading={loadingTable}
          />
          <AttendanceDataModal
            open={openModal}
            setOpen={(open: boolean) => {
              setOpenModal(open)
            }}
            attendance={attendance}
            fetchPatientsTableData={async () => {
              await fetchPatientsTableData()
            }}
          />
        </div>
      </div>
      <Notification />
    </ThemeProvider>
  )
}

export default RegisterVaccineShotTable
