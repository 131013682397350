import { IconButton, Typography } from '@material-ui/core'
import { ArrowBack } from '@material-ui/icons'
import React from 'react'
import { NavLink, useHistory } from 'react-router-dom'
import showStyles from '../utils/showStyles'

const HeaderWithGoBack = (props: {
  title: string
  path?: string
  linkTo?: string
}) => {
  const history = useHistory()
  const classes = showStyles()

  const resolveTitle = () => {
    if (props.linkTo) {
      return (
        <NavLink
          className={`${classes.link} ${classes.marginLeftOnPrint}`}
          to={props.linkTo}
        >
          <Typography variant="h1">{props.title}</Typography>
        </NavLink>
      )
    }

    return <Typography variant="h1">{props.title}</Typography>
  }

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <IconButton
        className={classes.hideOnPrint}
        onClick={() =>
          props.path ? history.push(props.path) : history.goBack()
        }
      >
        <ArrowBack />
      </IconButton>

      {resolveTitle()}
    </div>
  )
}

export default HeaderWithGoBack
