import React from 'react'
import { Typography } from '@material-ui/core'

const EmptyList = () => {
  return (
    <div style={{ width: '100%' }}>
      <Typography
        color="textPrimary"
        variant="body1"
        style={{ textAlign: 'center' }}
      >
        Ainda não foi cadastrada nenhuma lista de beneficiários
      </Typography>
    </div>
  )
}

export default EmptyList
