import { Chip } from '@material-ui/core'
import { IOrder } from '@vacinas-net/shared'
import React from 'react'
import { mainTheme } from '../../utils/mainTheme'

interface ChipProps {
  statusText: string
  statusColor: string
}

const OrderStatusChip = (props: { status: IOrder['status'] }) => {
  let statusText
  let statusColor

  const defaultShipProps = {
    statusText: 'Indefinido',
    statusColor: 'default',
  }

  const statusChipsProps: Record<string, ChipProps> = {
    awaiting_clinic_confirmation: {
      statusText: 'Aguardando resposta clínica',
      statusColor: mainTheme.palette.warning.main,
    },
    readyForHandling: {
      statusText: 'Aguardando atendimentos',
      statusColor: mainTheme.palette.secondary.main,
    },
    rejected: {
      statusText: 'Rejeitado',
      statusColor: mainTheme.palette.error.main,
    },
    cancelled: {
      statusText: 'Cancelado',
      statusColor: mainTheme.palette.error.main,
    },
    finished: {
      statusText: 'Finalizado',
      statusColor: mainTheme.palette.info.main,
    },
    processing: {
      statusText: 'Em aberto',
      statusColor: mainTheme.palette.primary.main,
    },
    under_analysis: {
      statusText: 'Em análise',
      statusColor: '#bdb02d',
    },
  }
  if (!props.status || !statusChipsProps[props.status]) {
    statusText = defaultShipProps.statusText
    statusColor = defaultShipProps.statusColor
  } else {
    statusText = statusChipsProps[props.status].statusText
    statusColor = statusChipsProps[props.status].statusColor
  }

  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default OrderStatusChip
