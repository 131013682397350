import { Typography } from '@material-ui/core'
import { IOrder } from '@vacinas-net/shared'
import React from 'react'
import {
  DateField,
  FunctionField,
  SimpleShowLayout,
  TextField,
  Record,
} from 'react-admin'
import OrderActionButton from '../../../../components/Buttons/OrderActionButton'
import OrderStatusChip from '../../../../components/chips/OrderStatusChip'
import showStyles from '../../../../utils/showStyles'

const OrderSection = () => {
  const classes = showStyles()
  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Pedido
      </Typography>
      <SimpleShowLayout className={classes.cardContent}>
        <TextField className={classes.cardItem} source="orderId" label="n°" />
        <DateField
          locales="pt-BR"
          className={classes.cardItem}
          source="purchasedAt"
          label="Data"
        />
        <FunctionField
          className={classes.cardItem}
          label="Situação"
          render={(record: Record | undefined) => {
            return <OrderStatusChip status={record?.status} />
          }}
        />
        <DateField
          locales="pt-BR"
          className={classes.cardItem}
          source="finishedAt"
          label="Data da finalização"
        />
        <FunctionField
          className={classes.customFunctionField}
          addLabel={false}
          render={(record: Record | undefined) => {
            const order = record as IOrder

            const mustRenderUndoFinishAttendance =
              order.attendances?.length &&
              order.attendances[0].status === 'finished'

            if (!mustRenderUndoFinishAttendance) return <></>

            return (
              <div style={{ flex: 1 }}>
                <OrderActionButton
                  attendanceProps={{
                    _id: String(order.attendances![0]._id),
                    itemList: order.attendances![0].itemList,
                    status: order.attendances![0].status,
                    uuid: order.attendances![0].uuid!,
                  }}
                />
              </div>
            )
          }}
        />
      </SimpleShowLayout>
    </div>
  )
}

export default OrderSection
