import React from 'react'
import {
  Create,
  CreateProps,
  Record,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  required,
} from 'react-admin'
import { Typography, WithWidthProps } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'

const CustomToolbar = (props: ToolbarProps & WithWidthProps) => (
  <Toolbar {...props}>
    <SaveButton label="Salvar" />
  </Toolbar>
)

const ProductSkuCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    return {
      ...data,
      handler: 'jenner',
      category: 'vaccine',
    }
  }

  return (
    <Create transform={transform} {...props}>
      <SimpleForm toolbar={<CustomToolbar />} submitOnEnter={false}>
        <Typography style={{ marginBottom: mainTheme.spacing(1) }} variant="h1">
          Novo Produto
        </Typography>

        <TextInput
          color="primary"
          variant="outlined"
          label="Nome do produto"
          source="name"
          style={{ marginTop: mainTheme.spacing(2) }}
          validate={required()}
        />
      </SimpleForm>
    </Create>
  )
}

export default ProductSkuCreate
