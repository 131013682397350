import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'

export const VaccinesListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'shotDate(max)',
      label: 'Aplicado antes de',
      FilterInput: (
        <CustomDateInput
          label="Aplicado antes de"
          source="shotDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'shotDate(min)',
      label: 'Aplicado depois de',
      FilterInput: (
        <CustomDateInput
          label="Aplicado depois de"
          source="shotDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'patient->name',
      label: 'Paciente',
      FilterInput: (
        <SearchFilterInput
          label="Paciente"
          source="patient->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'patient->birthDate(min)',
      label: 'Nascido depois de',
      FilterInput: (
        <CustomDateInput
          label="Nascido depois de"
          source="patient->birthDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'patient->birthDate(max)',
      label: 'Nascido até',
      FilterInput: (
        <CustomDateInput
          label="Nascido até"
          source="patient->birthDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'clinic->name',
      label: 'Clínica',
      FilterInput: (
        <SearchFilterInput
          label="Clínica"
          source="clinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'vendorOrderId',
      label: 'Número do pedido',
      FilterInput: (
        <SearchFilterInput
          label="Número do pedido"
          source="vendorOrderId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'vaccineMeta->productSkuApplied->name',
      label: 'Nome do produto',
      FilterInput: (
        <SearchFilterInput
          label="Nome do produto"
          source="vaccineMeta->productSkuApplied->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'vaccineMeta->productSkuApplied->skuId',
      label: 'SKU',
      FilterInput: (
        <SearchFilterInput
          label="SKU"
          source="vaccineMeta->productSkuApplied->skuId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'dose',
      label: 'Dose',
      FilterInput: (
        <SelectInput
          source="dose"
          label="Dose"
          choices={[
            { id: 'first', name: 'Primeira' },
            { id: 'second', name: 'Segunda' },
            { id: 'third', name: 'Terceira' },
            { id: 'anual', name: 'Anual' },
            { id: 'booster', name: 'Reforço' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'status',
      label: 'Situação',
      FilterInput: (
        <SelectInput
          source="status"
          label="Situação"
          choices={[
            { id: 'applied', name: 'Aplicada' },
            { id: 'not_applied', name: 'Não aplicada' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'operationType',
      label: 'Tipo de operação',
      FilterInput: (
        <SelectInput
          source="operationType"
          label="Tipo de operação"
          choices={[
            { id: 'commission', name: 'Próprio da clínica(Comissão)' },
            { id: 'acquisition', name: 'Próprio da clínica(Aquisição)' },
            { id: 'gesture', name: 'Flipagem' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
