import { IconButton, Menu, MenuItem } from '@material-ui/core'
import { MoreVert } from '@material-ui/icons'
import React, { useState } from 'react'
import { useRecordContext } from 'react-admin'
import VouchersListActionsPopup from '../VoucherListActionsPopup'
import JennerVoucherListActionsPopup from '../VoucherListActionsPopup/JennerVoucherListActionsPopup'

const VouchersListActionsButton = (props: {
  voucherPackageId: string
  disabled: boolean
}) => {
  const { voucherPackageId, disabled } = props
  const record = useRecordContext()
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null)
  const openButton = Boolean(anchorEl)
  const [openDialog, setOpenDialog] = useState(false)

  const handleClick = (event: {
    currentTarget: React.SetStateAction<HTMLElement | null>
  }) => {
    setAnchorEl(event.currentTarget)
  }
  const handleCloseButton = () => {
    setAnchorEl(null)
  }

  return (
    <>
      <IconButton
        id="basic-button"
        aria-controls={openButton ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={openButton ? 'true' : undefined}
        onClick={handleClick}
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={openButton}
        onClose={handleCloseButton}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        <MenuItem
          onClick={() => {
            setOpenDialog(true)
            handleCloseButton()
          }}
          disabled={disabled}
        >
          Disponibilizar vales
        </MenuItem>
      </Menu>
      {record?.campaignHandler === 'jenner' ? (
        <JennerVoucherListActionsPopup
          openDialog={openDialog}
          handleCloseDialog={() => {
            setOpenDialog(false)
          }}
          voucherPackageId={voucherPackageId}
        />
      ) : (
        <VouchersListActionsPopup
          openDialog={openDialog}
          handleCloseDialog={() => {
            setOpenDialog(false)
          }}
          voucherPackageId={voucherPackageId}
        />
      )}
    </>
  )
}

export default VouchersListActionsButton
