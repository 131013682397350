import React from 'react'
import { Grid, Typography, TextField } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import ReactInputMask from 'react-input-mask'
import { AvailableAttendanceFormData } from './utils'
import { FormikErrors } from 'formik'

const AddressDataSession = (props: {
  formData?: AvailableAttendanceFormData
  setFormData: (field: string, value: unknown) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any
  setErrors: (errors: FormikErrors<AvailableAttendanceFormData>) => void
}) => {
  const { formData, setFormData, errors, setErrors } = props

  return (
    <Grid
      style={{ marginTop: mainTheme.spacing(2) }}
      container
      spacing={2}
      xs={12}
    >
      <Grid item xs={12}>
        <Typography color="primary" variant="body1">
          Endereço
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <ReactInputMask
          mask="99999-999"
          disabled={false}
          value={formData?.address?.zipCode}
          onChange={(ev) => {
            setFormData('address.zipCode', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                zipCode: '',
              },
            })
          }}
        >
          {() => (
            <TextField
              size="small"
              variant="outlined"
              label="CEP*"
              error={Boolean(errors.address?.zipCode)}
            />
          )}
        </ReactInputMask>
        {errors.address?.zipCode && (
          <Typography variant="body2" color="error">
            {errors.address?.zipCode}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Cidade*"
          variant="outlined"
          value={formData?.address?.city}
          onChange={(ev) => {
            setFormData('address.city', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                city: '',
              },
            })
          }}
          size="small"
          error={Boolean(errors.address?.city)}
        />
        {errors.address?.city && (
          <Typography variant="body2" color="error">
            {errors.address?.city}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Estado*"
          variant="outlined"
          value={formData?.address?.state}
          onChange={(ev) => {
            setFormData('address.state', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                state: '',
              },
            })
          }}
          size="small"
          error={Boolean(errors.address?.state)}
        />
        {errors.address?.state && (
          <Typography variant="body2" color="error">
            {errors.address?.state}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <TextField
          fullWidth
          label="Bairro*"
          variant="outlined"
          value={formData?.address?.neighborhood}
          onChange={(ev) => {
            setFormData('address.neighborhood', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                neighborhood: '',
              },
            })
          }}
          size="small"
          error={Boolean(errors.address?.neighborhood)}
        />
        {errors.address?.neighborhood && (
          <Typography variant="body2" color="error">
            {errors.address?.neighborhood}
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Logradouro*"
          variant="outlined"
          value={formData?.address?.streetName}
          onChange={(ev) => {
            setFormData('address.streetName', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                streetName: '',
              },
            })
          }}
          size="small"
          error={Boolean(errors.address?.streetName)}
        />
        {errors.address?.streetName && (
          <Typography variant="body2" color="error">
            {errors.address?.streetName}
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Número*"
          variant="outlined"
          value={formData?.address?.streetNumber}
          onChange={(ev) => {
            setFormData('address.streetNumber', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                streetNumber: '',
              },
            })
          }}
          size="small"
          error={Boolean(errors.address?.streetNumber)}
        />
        {errors.address?.streetNumber && (
          <Typography variant="body2" color="error">
            {errors.address?.streetNumber}
          </Typography>
        )}
      </Grid>
      <Grid item xs={4}>
        <TextField
          fullWidth
          label="Complemento"
          variant="outlined"
          value={formData?.address?.complement}
          onChange={(ev) => {
            setFormData('address.complement', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                complement: '',
              },
            })
          }}
          size="small"
        />
      </Grid>
      <Grid item xs={6}>
        <TextField
          fullWidth
          label="Local*"
          variant="outlined"
          value={formData?.address?.name}
          onChange={(ev) => {
            setFormData('address.name', ev.target.value)
            setErrors({
              ...errors,
              address: {
                ...errors.address,
                name: '',
              },
            })
          }}
          size="small"
          placeholder="Local: ex. Sede Empresa XPTO prédio II"
          error={Boolean(errors.address?.name)}
        />
        {errors.address?.name && (
          <Typography variant="body2" color="error">
            {errors.address?.name}
          </Typography>
        )}
      </Grid>
    </Grid>
  )
}

export default AddressDataSession
