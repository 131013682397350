import React from 'react'
import ToogleField from '../ToogleField'
import { Typography, CircularProgress } from '@material-ui/core'
import { IAvailableAttendance } from '@vacinas-net/shared'
import customHttpClient from '../../../../components/CustomHttpClient'
import { apiUrl } from '../../../../components/AppDataProvider'
import { useEditContext, useNotify } from 'react-admin'
import { mainTheme } from '../../../../utils/mainTheme'

interface StatusToogleFieldProps {
  availableAttendance: IAvailableAttendance
  patchLoading: boolean
  hasOrder: boolean
  checkOrderLoading: boolean
  setPatchLoading: (value: boolean) => void
}

const StatusToogleField = (props: StatusToogleFieldProps) => {
  const editContext = useEditContext()
  const notify = useNotify()

  const patchStatus = async (checked: boolean) => {
    try {
      props.setPatchLoading(true)
      await customHttpClient(
        `${apiUrl}/campaign/available-attendance/${props.availableAttendance._id}`,
        {
          body: JSON.stringify({ status: checked ? 'active' : 'disabled' }),
          method: 'PUT',
        }
      )
      if (editContext.refetch) editContext?.refetch()
    } catch (error) {
      notify(
        `Não foi possivel atualizar o formulário de agendamento ${error}`,
        { type: 'error' }
      )
    } finally {
      props.setPatchLoading(false)
    }
  }

  if (props.checkOrderLoading)
    return <CircularProgress style={{ height: 16, width: 16 }} />

  return (
    <ToogleField
      checked={props.availableAttendance.status === 'active'}
      loading={
        props.checkOrderLoading || !!editContext.loading || props.patchLoading
      }
      onCheckChange={(checked) => patchStatus(checked)}
      protected={props.hasOrder}
      protectedWarningMessage="Existem pedidos para este formulário de agendamento"
      Content={() => (
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: mainTheme.spacing(1),
          }}
        >
          <Typography variant="body1">
            Esse formulário de agendamento contém clientes que{' '}
            <b>já agendaram a aplicação.</b>
          </Typography>
          <Typography variant="body1">
            {'Você pode conferir os agendamentos em "Pedidos"'}
          </Typography>
          <Typography variant="body1">
            Tem certeza que deseja <b>desativar esse formulário?</b>
          </Typography>
        </div>
      )}
    />
  )
}

export default StatusToogleField
