import { Typography } from '@material-ui/core'
import React from 'react'
import {
  DateField,
  FunctionField,
  SimpleShowLayout,
  TextField,
  Record,
} from 'react-admin'
import showStyles from '../../../utils/showStyles'

const DataSection = () => {
  const classes = showStyles()
  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Dados
      </Typography>
      <SimpleShowLayout className={classes.cardContent}>
        <TextField
          className={classes.cardItem}
          source="clinic.name"
          label="Clínica"
        />
        <DateField
          locales="pt-BR"
          className={classes.cardItem}
          source="commercialCondition.dateRange.startDate"
          label="Data início"
        />
        <DateField
          locales="pt-BR"
          className={classes.cardItem}
          source="commercialCondition.dateRange.endDate"
          label="Data fim"
        />
        <FunctionField
          className={classes.cardItem}
          label="Quantidade"
          render={(record: Record | undefined) => {
            return record?.totalAmount ? `${record?.totalAmount} doses` : '-'
          }}
        />
        <TextField
          className={classes.cardItem}
          source="commercialCondition.description"
          label="Descrição"
        />
      </SimpleShowLayout>
    </div>
  )
}

export default DataSection
