import { formatCPF } from '@brazilian-utils/brazilian-utils'
import { Typography } from '@material-ui/core'
import React from 'react'
import { SimpleShowLayout, TextField, FunctionField, Record } from 'react-admin'
import showStyles from '../../../../utils/showStyles'
import { formatPhone } from '../../../../utils/utils'

const CustomerSection = () => {
  const classes = showStyles()
  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Cliente
      </Typography>
      <SimpleShowLayout className={classes.cardContent}>
        <TextField
          className={classes.cardItem}
          source="customer.name"
          label="Nome"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            formatCPF(record?.customer.document)
          }
          className={classes.cardItem}
          source="customer.document"
          label="CPF"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            record?.customer.phone ? formatPhone(record?.customer.phone) : '-'
          }
          className={classes.cardItem}
          source="customer.phone"
          label="Telefone"
        />
      </SimpleShowLayout>
    </div>
  )
}

export default CustomerSection
