import React from 'react'
import { Create, CreateProps, SimpleForm } from 'react-admin'
import PaymentsSession from '../../../components/OrderCreateComponents/PaymentsSession'
import ClientSession from '../../../components/OrderCreateComponents/ClientSession'
import ProductsSession from '../../../components/OrderCreateComponents/ProductsSession'
import CampaignSession from '../../../components/OrderCreateComponents/CampaignSession'
import VaccineShotSession from '../../../components/OrderCreateComponents/VaccineShotSession'
import UploadFileSession from '../../../components/OrderCreateComponents/UploadFileSession'
import { OrderCreateProvider } from '../../../components/Contexts/OrderCreateContext'
import CreateManyOrdersButton from '../../../components/OrderCreateComponents/CreateManyOrdersButton'
import AvaliableClinics from '../../../components/OrderCreateComponents/AvaliableClinics'

export const InnerOrderCreate = () => (
  <OrderCreateProvider>
    <ProductsSession />
    <AvaliableClinics />
    <ClientSession />
    <PaymentsSession />
    <CampaignSession />
    <VaccineShotSession />
    <UploadFileSession />
    <CreateManyOrdersButton />
  </OrderCreateProvider>
)

const OrdersCreate = (props: CreateProps) => {
  console.log(props)
  return (
    <Create {...props}>
      <SimpleForm toolbar={<></>}>
        <InnerOrderCreate />
      </SimpleForm>
    </Create>
  )
}

export default OrdersCreate
