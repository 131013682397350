import {
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
} from '@material-ui/core'
import { FinishJointPurchaseOfferResult } from '@vacinas-net/shared'
import { useNotify, useRefresh } from 'ra-core'
import React, { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'
import FinishOfferErrorContainer from './FinishOfferErrorContainer'
import { Confirm } from 'react-admin'

const FinishOfferButton = (props: { jointPurchaseOfferId: string }) => {
  const { jointPurchaseOfferId } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [errors, setErrors] = useState<
    FinishJointPurchaseOfferResult['errors']
  >([])

  const handleFinish = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/joint-purchase/${jointPurchaseOfferId}/finish`, {
      method: 'post',
    })
      .then(() => {
        notify(`Oferta finalizada com sucesso`, 'success')
        refresh()
      })
      .catch((error) => {
        if (error && error.status === 422) {
          if (!error.body?.success) {
            return setErrors(error.body?.errors)
          }
        }
        notify(`Erro ao finalizar oferta: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
      })
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        size="medium"
        onClick={() => {
          setOpenConfirm(true)
        }}
      >
        {loading ? <CircularProgress color="inherit" size={20} /> : 'Finalizar'}
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está finalizando a oferta"
        content="Deseja confirmar essa ação?"
        onConfirm={handleFinish}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />

      <Dialog
        open={Boolean(errors && errors.length > 0)}
        onClose={() => setErrors([])}
      >
        <DialogContent style={{ minWidth: '500px' }}>
          <FinishOfferErrorContainer errors={errors} />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default FinishOfferButton
