import React, { useContext } from 'react'
import { Button } from '@material-ui/core'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { ShoppingCart } from '@material-ui/icons'
import { mainTheme } from '../../utils/mainTheme'

const CreateManyOrdersButton = () => {
  const context = useContext(OrderCreateContext)
  const {
    loading,
    createMany,
    shouldRenderCreateManyButton,
    createManyOrders,
  } = context!
  return createMany ? (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'flex-end',
        width: 932,
        marginTop: mainTheme.spacing(4),
      }}
    >
      <Button
        startIcon={<ShoppingCart />}
        color="primary"
        variant="contained"
        disabled={loading || !shouldRenderCreateManyButton()}
        size="large"
        onClick={() => createManyOrders()}
      >
        Finalizar pedido
      </Button>
    </div>
  ) : (
    <></>
  )
}

export default CreateManyOrdersButton
