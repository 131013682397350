/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { useEffect } from 'react'
import { DropzoneArea } from 'material-ui-dropzone'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  CircularProgress,
} from '@material-ui/core'
import { useState } from 'react'
import { useNotify, useEditContext, Identifier } from 'react-admin'
import customHttpClient from '../../../../components/CustomHttpClient'
import { apiUrl } from '../../../../components/AppDataProvider'
import { Typography } from '@material-ui/core'
import qs from 'qs'
import { mainTheme } from '../../../../utils/mainTheme'
import AllowedCustomerListForUploadDialog from './AllowedCustomerListForUploadDialog'
import { IAllowedCustomer } from '@vacinas-net/shared'
import { parseCsvDataFromFile } from '../../../../utils/utils'
export interface UploadFileDialogProps {
  isOpen: boolean
  onClose: () => void
}

const UploadFileDialog = (props: UploadFileDialogProps): JSX.Element => {
  const notify = useNotify()
  const editContext = useEditContext()
  const { onClose, isOpen } = props
  const [step, setStep] = useState<0 | 1 | 2>(0)
  const [file, setFile] = useState<File | undefined>(undefined)
  const [loading, setLoading] = useState(false)
  const [allowedCustomerList, setAllowedCustomerList] = useState()

  const hasDuplicateDocuments = async (csvData: Record<string, any>[]) => {
    const documentsArray = csvData.map((row) => row['CPF'])
    const queryString = qs.stringify(
      {
        document: documentsArray,
        campaignId: editContext.record?.id,
      },
      { arrayFormat: 'repeat' }
    )

    const allowedCustomerResponse = await customHttpClient(
      `${apiUrl}/campaign/allowed-customer?${queryString}`,
      {
        method: 'get',
      }
    )

    setAllowedCustomerList(allowedCustomerResponse.json.results)

    return allowedCustomerResponse.json.total > 0
  }
  const uploadList = async (file: File, campaignId: Identifier) => {
    const body = new FormData()

    body.append('file', new Blob([file]), file.name)
    body.append('campaignId', String(campaignId))

    await customHttpClient(`${apiUrl}/campaign/allowed-customer-batch`, {
      method: 'POST',
      body,
    })

    notify('Lista de beneficiados criada com sucesso')
    if (editContext.refetch) editContext.refetch()

    onClose()
  }

  const onNextClick = async () => {
    if (step === 0) return setStep(1)

    if (file === undefined)
      return notify('Selecione um arquivo', { type: 'error' })

    if (editContext.record === undefined)
      return notify('Tente novamente mais tarde', { type: 'error' })

    try {
      setLoading(true)
      let shouldShowConfirmation = false

      if (step === 1) {
        const csvData = (await parseCsvDataFromFile(file)) as unknown as Record<
          string,
          any
        >[]
        shouldShowConfirmation = await hasDuplicateDocuments(csvData)

        if (shouldShowConfirmation) {
          setStep(2)
          return
        }
      }

      if (!shouldShowConfirmation) {
        await uploadList(file, editContext.record.id)
      }
    } catch (error: any) {
      notify(`Erro ao fazer o upload do arquivo: ${error?.message}`, {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  const onReturnClick = () => {
    if (step === 1) return setStep(0)
    if (step === 2) return setStep(1)
    onClose()
  }

  useEffect(() => {
    if (!isOpen) return

    setStep(0)
    setFile(undefined)
    setLoading(false)
  }, [isOpen])

  const nextButtonText = () => {
    return step !== 2 ? 'Upload da lista' : 'Continuar'
  }

  return (
    <Dialog
      open={isOpen}
      maxWidth="md"
      fullWidth
      onClose={onClose}
      style={{ margin: 0 }}
    >
      <DialogContent style={{ margin: 0, padding: 0 }}>
        {step === 0 && (
          <div style={{ padding: mainTheme.spacing(3) }}>
            <ol>
              <li>
                <Typography variant="body1">
                  Faça download do <b>modelo de lista</b>
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Não altere o título das colunas
                </Typography>
              </li>
              <li>
                <Typography variant="body1">
                  Preencha a planilha com as informações de 1 pessoa por linha
                  colocando as informações na coluna correspondente
                </Typography>
              </li>
            </ol>
          </div>
        )}
        {step === 1 && (
          <div style={{ padding: mainTheme.spacing(3) }}>
            <DropzoneArea
              acceptedFiles={['text/csv']}
              filesLimit={1}
              onChange={(files) => {
                setFile(files[0])
              }}
              dropzoneText="Arraste e solte um arquivo ou clique aqui"
              showAlerts={false}
            />
          </div>
        )}
        {step === 2 && (
          <div>
            <div style={{ padding: mainTheme.spacing(3) }}>
              <Typography color="primary" variant="h2">
                Usuários duplicados
              </Typography>
              <Typography
                style={{
                  marginTop: mainTheme.spacing(1),
                }}
                variant="body1"
              >
                Os usuários abaixo já estão cadastrados como beneficiários. A
                quantidade de vales inserida será incrementada a quantidade de
                vales já cadastrada.
              </Typography>
            </div>
            <div
              style={{
                paddingLeft: mainTheme.spacing(3),
                paddingRight: mainTheme.spacing(3),
                paddingBottom: mainTheme.spacing(1),
              }}
            >
              <AllowedCustomerListForUploadDialog
                record={allowedCustomerList as unknown as IAllowedCustomer[]}
              />
            </div>
          </div>
        )}
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onReturnClick()}
          size="large"
          color="primary"
          disabled={loading}
          variant="outlined"
        >
          Voltar
        </Button>
        <Button
          onClick={() => onNextClick()}
          color="primary"
          variant="contained"
          autoFocus
          size="large"
          disabled={loading}
          style={{
            width: '146px',
            height: '42px',
          }}
          data-testid={`confirm-button`}
        >
          {loading ? <CircularProgress size={16} /> : nextButtonText()}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default UploadFileDialog
