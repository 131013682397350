import {
  IAvailableAttendance,
  IAvailableAttendanceAddress,
  IClinic,
  IClinicCondition,
  IProductSku,
  IVaccineShot,
} from '@vacinas-net/shared'
import { addDays, format, setHours, setMinutes } from 'date-fns'
import { OptionType } from '../CustomAutocomplete'

export interface AvailableAttendanceFormData {
  selectedClinicId?: string
  clinicConditionId?: string
  shotDate?: string
  startTime?: string
  endTime?: string
  address?: Partial<IAvailableAttendanceAddress>
  expirationDate?: string
  batchNumber?: string
  dose?: string
  vaccine?: OptionType | null
  availableClinics?: Pick<IClinic, '_id' | 'name' | 'address'>[]
  availableClinicConditions?: IClinicCondition[]
}

export const parseAvailableAttendanceData = (
  formData: AvailableAttendanceFormData,
  campaignId: string,
  isEdit?: boolean
) => {
  const selectedClinic = formData?.availableClinics?.find(
    (clinic) => clinic._id === formData?.selectedClinicId
  )

  const clinic = {
    _id: selectedClinic?._id,
    name: selectedClinic?.name,
  }

  const selectedCondition = formData?.availableClinicConditions?.find(
    (condition) => condition._id === formData?.clinicConditionId
  )

  const clinicCondition: Pick<IClinicCondition, '_id' | 'shippingPolicy'> = {
    _id: selectedCondition?._id,
    shippingPolicy: selectedCondition?.shippingPolicy,
  }

  const parsedVaccine: Pick<IProductSku, 'name' | 'skuId'> | undefined = {
    name: formData?.vaccine?.label,
    skuId: formData?.vaccine?.value,
  }

  const parsedAddress: IAvailableAttendanceAddress = {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    name: formData?.address?.name, //this field is optional and can be undefined
    zipCode: String(formData?.address?.zipCode?.replace(/\D/g, '')),
    city: String(formData?.address?.city),
    complement: formData?.address?.complement, //this field is optional and can be undefined
    neighborhood: String(formData?.address?.neighborhood),
    state: String(formData?.address?.state),
    streetName: String(formData?.address?.streetName),
    streetNumber: String(formData?.address?.streetNumber),
  }

  const startTimeHours = formData?.startTime?.split(':')[0]
  const startTimeMinutes = formData?.startTime?.split(':')[1]

  let parsedStartDate = new Date(String(formData?.shotDate))
  parsedStartDate = setHours(parsedStartDate, parseInt(startTimeHours!))
  parsedStartDate = setMinutes(parsedStartDate, parseInt(startTimeMinutes!))
  parsedStartDate = addDays(parsedStartDate, 1)

  const endTimeHours = formData?.endTime?.split(':')[0]
  const endTimeMinutes = formData?.endTime?.split(':')[1]

  let parsedEndDate = new Date(String(formData?.shotDate))
  parsedEndDate = setHours(parsedEndDate, parseInt(endTimeHours!))
  parsedEndDate = setMinutes(parsedEndDate, parseInt(endTimeMinutes!))
  parsedEndDate = addDays(parsedEndDate, 1)

  const newAvailableAttendance: Partial<IAvailableAttendance> & {
    campaignId?: string
  } = {
    campaignId: campaignId,
    address: parsedAddress,
    paymentMethod: 'voucher',
    status: 'active',
    clinic: clinic,
    clinicCondition: clinicCondition,
    preVaccineShot: {
      dose: formData?.dose as IVaccineShot['dose'],
      vaccineMeta: {
        batchNumber: formData?.batchNumber,
        productSkuApplied: parsedVaccine,
        expirationDate: formData?.expirationDate
          ? addDays(new Date(formData?.expirationDate), 1)
          : undefined,
      },
    },
    endDatetime: parsedEndDate,
    startDatetime: parsedStartDate,
  }

  if (isEdit) {
    delete newAvailableAttendance.campaignId
    delete newAvailableAttendance.paymentMethod
  }

  return newAvailableAttendance
}

export const parseInitialData = (initialData?: IAvailableAttendance) => {
  if (initialData) {
    return {
      selectedClinicId: initialData.clinic._id,
      clinicConditionId: initialData.clinicCondition._id,
      address: initialData.address,
      batchNumber: initialData.preVaccineShot.vaccineMeta?.batchNumber,
      dose: initialData.preVaccineShot.dose,
      vaccine: {
        label: String(
          initialData.preVaccineShot.vaccineMeta?.productSkuApplied?.name
        ),
        value: String(
          initialData.preVaccineShot.vaccineMeta?.productSkuApplied?.skuId
        ),
      },
      expirationDate:
        initialData.preVaccineShot.vaccineMeta?.expirationDate &&
        format(
          new Date(
            String(initialData.preVaccineShot.vaccineMeta?.expirationDate)
          ),
          'yyyy-MM-dd'
        ),

      shotDate: format(
        new Date(String(initialData.startDatetime)),
        'yyyy-MM-dd'
      ),
      startTime: format(new Date(String(initialData.startDatetime)), 'HH:mm'),
      endTime: format(new Date(String(initialData.endDatetime)), 'HH:mm'),
    }
  }
  return {}
}
