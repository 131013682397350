import { Button, CircularProgress, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { INotification, isAdmin } from '@vacinas-net/shared'
import React, { useEffect, useState } from 'react'
import {
  CreateButton,
  useAuthenticated,
  useDataProvider,
  useNotify,
  usePermissions,
} from 'react-admin'
import NotificationCard from '../../../components/Notification/NotificationCard'
import { mainTheme } from '../../../utils/mainTheme'
import MarkAsReadButton from '../MarkAsReadButton'

const NotificationList = () => {
  useAuthenticated()
  const { permissions } = usePermissions()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [notificationsArray, setNotificationsArray] = useState<INotification[]>(
    []
  )
  const [page, setPage] = useState(1)
  const [loading, setLoading] = useState(true)
  const [total, setTotal] = useState<number>(0)

  useEffect(() => {
    fetchNotifications()
  }, [])

  const resetNotificationsArray = async () => {
    await fetchNotifications(true)
  }

  const fetchNotifications = (isReset?: boolean) => {
    setLoading(true)
    dataProvider
      .getList('/notification', {
        filter: undefined,
        pagination: {
          page: isReset ? 1 : page,
          perPage: 6,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      })
      .then((res) => {
        if (isReset) {
          setNotificationsArray([...(res.data as INotification[])])
          setPage(2)
        } else {
          setNotificationsArray([
            ...notificationsArray,
            ...(res.data as INotification[]),
          ])
          setPage(page + 1)
        }

        setTotal(res.total)
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao obter notificações: ${error}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div
      style={{
        width: '65%',
        marginLeft: 'auto',
        marginRight: 'auto',
        marginTop: mainTheme.spacing(4),
        backgroundColor: '#F7F7F7',
        border: '1px solid #ECECEC',
        borderTopRightRadius: mainTheme.spacing(1),
        borderTopLeftRadius: mainTheme.spacing(1),
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <Typography
          style={{
            marginTop: mainTheme.spacing(2),
            marginBottom: mainTheme.spacing(2),
            marginLeft: mainTheme.spacing(2),
          }}
          variant="h1"
        >
          Notificações
        </Typography>
        <div
          style={{
            marginLeft: 'auto',
            marginRight: mainTheme.spacing(1),
          }}
        >
          <MarkAsReadButton />
        </div>
        {isAdmin(permissions) && (
          <CreateButton
            basePath="/notification"
            label="Nova notificação"
            variant="outlined"
            color="primary"
            style={{
              marginRight: mainTheme.spacing(1),
            }}
          />
        )}
      </div>

      {notificationsArray.filter((notification) => !notification.viewed)
        .length > 0 && (
        <div>
          <Typography
            style={{
              marginBottom: mainTheme.spacing(2),
              marginLeft: mainTheme.spacing(2),
            }}
            variant="h2"
          >
            Novas
          </Typography>
          {notificationsArray
            .filter((notification) => !notification.viewed)
            .map((unseenNotification) => {
              return (
                <div
                  key={unseenNotification._id}
                  style={{
                    marginBottom: mainTheme.spacing(1),
                  }}
                >
                  <NotificationCard
                    resetNotificationArray={resetNotificationsArray}
                    notification={unseenNotification}
                  />
                </div>
              )
            })}
        </div>
      )}

      {notificationsArray.filter((notification) => notification.viewed).length >
        0 && (
        <div>
          <Typography
            style={{
              marginTop: mainTheme.spacing(2),
              marginBottom: mainTheme.spacing(2),
              marginLeft: mainTheme.spacing(2),
            }}
            variant="h2"
          >
            Antigas
          </Typography>
          {notificationsArray
            .filter((notification) => notification.viewed)
            .map((viewedNotification) => {
              return (
                <div
                  key={viewedNotification._id}
                  style={{
                    marginBottom: mainTheme.spacing(1),
                  }}
                >
                  <NotificationCard
                    resetNotificationArray={resetNotificationsArray}
                    notification={viewedNotification}
                  />
                </div>
              )
            })}
        </div>
      )}
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          marginTop: mainTheme.spacing(2),
          marginBottom: mainTheme.spacing(2),
        }}
      >
        {loading ? (
          <CircularProgress size={40} />
        ) : (
          notificationsArray.length < total && (
            <Button
              onClick={() => {
                fetchNotifications()
              }}
              variant="contained"
              endIcon={<Add />}
            >
              Ver mais
            </Button>
          )
        )}
      </div>
    </div>
  )
}

export default NotificationList
