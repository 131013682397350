import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  TextField,
  Record,
} from 'react-admin'
import AggregatedOfferStatusChip from '../../../components/chips/AggregatedOfferStatusChip'
import MinimumOfferStatusChip from '../../../components/chips/MinimumOfferStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import {
  getOfferIdFromQueryString,
  parseOfferType,
  valueInCentsToBRL,
} from '../../../utils/utils'
import AggregatedOfferItemsActions from './AggregatedOfferItemsActions'
import AggregatedOfferItemsFilter from './AggregatedOfferItemsFilters'

const AggregatedOfferItems = (props: ListProps) => {
  return (
    <List
      pagination={<DefaultPagination />}
      actions={<AggregatedOfferItemsActions />}
      filters={<AggregatedOfferItemsFilter />}
      filterDefaultValues={{
        jointPurchaseOfferId: getOfferIdFromQueryString(),
      }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid
        stickyHeader
        rowClick={(id, basePath, record) => {
          return `/joint-purchase/offer-items-clinic?jointPurchaseOfferId=${record?.jointPurchaseOfferId}&clinicId=${record?.clinic._id}`
        }}
      >
        <TextField
          textAlign="center"
          source="clinic.name"
          label="Nome da clínica"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => parseOfferType(record?.type)}
          label="Comprado por"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.currentValueInCents)
          }
          label="Valor"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.minValueInCents)
          }
          label="Pedido mínimo"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            `${Math.floor(
              record?.minPricePercentageAchieved * 100
            ).toLocaleString('pt-BR', {
              minimumFractionDigits: 0,
              maximumFractionDigits: 0,
            })}%`
          }
          label="% Comprada"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          label="Status do pedido mínimo"
          render={(record: Record | undefined) => (
            <MinimumOfferStatusChip status={record?.minPriceStatus} />
          )}
        />
        <FunctionField
          textAlign="center"
          label="Status do pedido"
          render={(record: Record | undefined) => (
            <AggregatedOfferStatusChip status={record?.status} />
          )}
        />
      </Datagrid>
    </List>
  )
}

export default AggregatedOfferItems
