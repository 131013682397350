import { Typography } from '@material-ui/core'
import React from 'react'

const SignatureField = (props: { role: string }) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
      }}
    >
      <div style={{ borderTop: '1px solid #000000', width: 225 }} />
      <Typography>{props.role}</Typography>
    </div>
  )
}

export default SignatureField
