import * as React from 'react'
import { Pagination, PaginationProps } from 'react-admin'

export const DefaultPagination = (props: PaginationProps) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50, 100, 200]} {...props} />
}

export const DashboardPagination = (props: PaginationProps) => {
  return <Pagination rowsPerPageOptions={[10]} {...props} />
}

export const MasterDataPagination = (props: PaginationProps) => {
  return <Pagination rowsPerPageOptions={[10, 25, 50, 100]} {...props} />
}
