import * as React from 'react'
import { FC, Fragment, ReactElement } from 'react'
import { useSelector } from 'react-redux'
import {
  List,
  MenuItem,
  ListItemIcon,
  Typography,
  Collapse,
  Tooltip,
} from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import ExpandMore from '@material-ui/icons/ExpandMore'
import { ReduxState } from 'react-admin'
import { mainTheme } from '../utils/mainTheme'
import { useState } from 'react'

const useStyles = makeStyles((theme) => ({
  icon: { minWidth: theme.spacing(5), color: '#FFFFFF' },
  sidebarIsOpen: {
    '& a': {
      paddingLeft: theme.spacing(4),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
  sidebarIsClosed: {
    '& a': {
      paddingLeft: theme.spacing(2),
      transition: 'padding-left 195ms cubic-bezier(0.4, 0, 0.6, 1) 0ms',
    },
  },
}))

interface Props {
  dense: boolean
  icon: ReactElement
  name: string
}

const SubMenu: FC<Props> = ({ name, icon, children, dense }) => {
  const [isOpen, setIsOpen] = useState(false)
  const classes = useStyles()
  const sidebarIsOpen = useSelector<ReduxState, boolean>(
    (state) => state.admin.ui.sidebarOpen
  )

  const header = (
    <MenuItem
      dense={dense}
      style={{
        paddingTop: mainTheme.spacing(2),
        paddingBottom: mainTheme.spacing(2),
      }}
      button
      onClick={() => setIsOpen(!isOpen)}
    >
      <ListItemIcon className={classes.icon}>
        {isOpen ? <ExpandMore /> : icon}
      </ListItemIcon>
      <Typography variant="inherit" style={{ color: '#FFFFFF' }}>
        {name}
      </Typography>
    </MenuItem>
  )

  return (
    <Fragment>
      {sidebarIsOpen || isOpen ? (
        header
      ) : (
        <Tooltip title={name} placement="right">
          {header}
        </Tooltip>
      )}
      <Collapse in={isOpen} timeout="auto" unmountOnExit>
        <List
          dense={dense}
          component="div"
          disablePadding
          className={
            sidebarIsOpen ? classes.sidebarIsOpen : classes.sidebarIsClosed
          }
        >
          {children}
        </List>
      </Collapse>
    </Fragment>
  )
}

export default SubMenu
