import {
  Divider,
  List,
  ListItem,
  ListItemText,
  Paper,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import TextField from '@material-ui/core/TextField'
import { mainTheme } from '../../../utils/mainTheme'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import ReactInputMask from 'react-input-mask'
import { IClinic } from '@vacinas-net/shared'
import httpClient from '../../CustomHttpClient'
import { apiUrl } from '../../AppDataProvider'
import { useNotify } from 'react-admin'
import MapWithMarkers from '../../ClinicMap/MapWithMarkers'
import { isValidCEP } from '@brazilian-utils/brazilian-utils'
import { parseAddress } from '../../../utils/utils'
import { phoneFormatter } from '../../../utils/formatters'

const ClinicMapByZipcode = () => {
  const [zipCode, setZipCode] = useState<string>('')
  const [product, setProduct] = useState<OptionType | null>(null)
  const [clinics, setClinics] = useState<IClinic[]>()

  const notify = useNotify()

  const fetchClinics = () => {
    httpClient(
      `${apiUrl}/clinic/${zipCode?.replace(/\D/g, '')}/distanceRangeByProduct${
        product ? `?sku=${product.value}` : ''
      }`,
      {
        method: 'get',
      }
    )
      .then((response) => {
        const clinics = response.json as IClinic[]
        setClinics(clinics)
      })
      .catch((error) => {
        notify(`Erro ao obter clínicas: ${error}`, 'error')
      })
  }

  useEffect(() => {
    if (!isValidCEP(zipCode)) {
      return
    }
    fetchClinics()
  }, [zipCode, product])

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
      }}
    >
      <Typography variant="h2">Cobertura de clínicas</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          marginTop: mainTheme.spacing(2 + 1 / 2),
        }}
      >
        <ReactInputMask
          mask="99999-999"
          disabled={false}
          value={zipCode}
          onChange={(ev) => {
            setZipCode(ev.target.value)
          }}
        >
          {() => (
            <TextField
              style={{
                marginRight: mainTheme.spacing(1),
              }}
              size="small"
              variant="outlined"
              label="CEP"
            />
          )}
        </ReactInputMask>
        <CustomAutocomplete
          currentValue={product}
          onChange={(ev) => {
            setProduct(ev)
          }}
          resource="product-sku"
          label="Produto"
          optionTypeValue="skuId"
        />
      </div>
      {clinics && (
        <div
          style={{
            height: 425,
            width: '100%',
            display: 'flex',
            alignItems: 'center',
            padding: mainTheme.spacing(3),
          }}
        >
          <MapWithMarkers
            markersCoordinates={clinics
              .filter(
                (clinic) =>
                  clinic.address?.geoCoordinates?.lat ||
                  clinic.address?.geoCoordinates?.lng
              )
              .map((clinic) => {
                return {
                  lat: Number(clinic.address?.geoCoordinates?.lat),
                  lng: Number(clinic.address?.geoCoordinates?.lng),
                }
              })}
            center={{ lat: -14.6222409, lng: -49.2810116 }}
            zoom={4}
          />
        </div>
      )}
      <List style={{ maxHeight: 580, overflow: 'auto' }}>
        {clinics ? (
          clinics.map((clinic) => (
            <>
              <ListItem key={clinic._id}>
                <ListItemText
                  primary={`${clinic.name} (${Math.round(
                    clinic.distance! / 1000
                  )}Km)`}
                  secondary={`${parseAddress(clinic.address)}, ${
                    clinic.address?.zipCode
                  }. ${
                    clinic.phone
                      ? phoneFormatter.format(
                          phoneFormatter.parse(clinic.phone)
                        )
                      : ''
                  }`}
                />
              </ListItem>
              <Divider />
            </>
          ))
        ) : (
          <ListItem>
            <ListItemText primary="Por favor insira um CEP para buscar as clínicas" />
          </ListItem>
        )}
        {clinics && clinics.length === 0 && (
          <ListItem>
            <ListItemText
              primary="Não há clínicas cadastradas no CEP buscado."
              secondary="Por favor mude o CEP ou o produto pesquisado."
            />
          </ListItem>
        )}
      </List>
    </Paper>
  )
}

export default ClinicMapByZipcode
