import React, { useContext, useState } from 'react'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import {
  Typography,
  TextField,
  RadioGroup,
  FormControlLabel,
  Radio,
} from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import InputMask from 'react-input-mask'

const ClientSession = () => {
  const orderCreateContext = useContext(OrderCreateContext)
  const {
    name,
    setName,
    cpf,
    setCpf,
    email,
    setEmail,
    phone,
    setPhone,
    cnpj,
    setCnpj,
    createMany,
  } = orderCreateContext!

  const [documentType, setDocumentType] = useState('cpf')

  return !createMany ? (
    <div
      style={{
        paddingTop: mainTheme.spacing(1),
      }}
    >
      <Typography variant="h1">Cliente</Typography>
      <RadioGroup
        row
        aria-labelledby="demo-row-radio-buttons-group-label"
        name="row-radio-buttons-group"
        style={{ marginTop: mainTheme.spacing(2) }}
        value={documentType}
        onChange={(event) => {
          setDocumentType(event.target.value)
          event.target.value === 'cpf' ? setCnpj('') : setCpf('')
        }}
      >
        <FormControlLabel
          value="cpf"
          control={<Radio />}
          label="Pessoa física"
        />
        <FormControlLabel
          value="cnpj"
          control={<Radio />}
          label="Pessoa jurídica"
        />
      </RadioGroup>
      <div
        style={{
          display: 'flex',
          paddingTop: mainTheme.spacing(2),
          paddingBottom: mainTheme.spacing(2),
        }}
      >
        <TextField
          value={name}
          onChange={(event) => {
            setName(event.target.value)
          }}
          size="small"
          variant="outlined"
          label={documentType === 'cpf' ? 'Nome' : 'Razão Social'}
          style={{
            width: 300,
          }}
          inputProps={{
            'data-testid': `name-input`,
          }}
          required
        />
        {documentType === 'cpf' ? (
          <InputMask
            mask="999.999.999-99"
            value={cpf}
            onChange={(event) => {
              setCpf(event.target.value)
            }}
          >
            {() => (
              <TextField
                style={{
                  marginLeft: mainTheme.spacing(4),
                  width: 300,
                }}
                size="small"
                variant="outlined"
                label="CPF"
                inputProps={{
                  'data-testid': `cpf-input`,
                }}
                required
              />
            )}
          </InputMask>
        ) : (
          <InputMask
            mask="99.999.999/9999-99"
            value={cnpj}
            onChange={(event) => {
              setCnpj(event.target.value)
            }}
          >
            {() => (
              <TextField
                style={{
                  marginLeft: mainTheme.spacing(4),
                  width: 300,
                }}
                size="small"
                variant="outlined"
                label="CNPJ"
                inputProps={{
                  'data-testid': `cnpj-input`,
                }}
                required
              />
            )}
          </InputMask>
        )}
      </div>
      <div
        style={{
          display: 'flex',
        }}
      >
        <TextField
          value={email}
          onChange={(event) => {
            setEmail(event.target.value)
          }}
          type="email"
          size="small"
          variant="outlined"
          label="E-mail"
          style={{
            width: 300,
          }}
          inputProps={{
            'data-testid': `email-input`,
          }}
        />
        <InputMask
          mask="(99) 9 9999-9999"
          value={phone}
          onChange={(event) => {
            setPhone(event.target.value)
          }}
        >
          {() => (
            <TextField
              style={{
                marginLeft: mainTheme.spacing(4),
                width: 300,
              }}
              size="small"
              variant="outlined"
              label="Telefone"
              inputProps={{
                'data-testid': `phone-input`,
              }}
            />
          )}
        </InputMask>
      </div>
    </div>
  ) : (
    <></>
  )
}
export default ClientSession
