import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { translateSystemRole } from '../../../utils/utils'
import { RolesEnum } from '@vacinas-net/shared'

const systemRoles = Object.values(RolesEnum)

export const UserListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'name',
      label: 'Nome',
      FilterInput: (
        <SearchFilterInput
          label="Nome"
          source="name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'email',
      label: 'E-mail',
      FilterInput: (
        <SearchFilterInput
          label="E-mail"
          source="email"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'relatedClinics->name',
      label: 'Clínicas',
      FilterInput: (
        <SearchFilterInput
          label="Clínicas"
          source="relatedClinics->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'roles',
      label: 'Perfil',
      FilterInput: (
        <SelectInput
          source="roles"
          label="Perfil"
          choices={systemRoles.map((role) => {
            return {
              id: role,
              name: translateSystemRole(role),
            }
          })}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'createdAt(min)',
      label: 'Criado em',
      FilterInput: (
        <CustomDateInput
          label="Criado em"
          source="createdAt(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'active', name: 'Ativo' },
            { id: 'inactive', name: 'Inativo' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
