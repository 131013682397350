import { makeStyles } from '@material-ui/core'
import { mainTheme } from './mainTheme'

export const showStyles = makeStyles({
  cardContent: {
    display: 'flex',
    backgroundColor: mainTheme.palette.grey[300],
    borderRadius: mainTheme.shape.borderRadius,
    paddingTop: mainTheme.spacing(2),
    ['@media print']: {
      display: 'flex',
      flexDirection: 'column',
    },
  },
  cardContentTop: {
    display: 'flex',
    backgroundColor: mainTheme.palette.grey[300],
    borderRadius: mainTheme.shape.borderRadius,
    borderBottomLeftRadius: 0,
    borderBottomRightRadius: 0,
    paddingTop: mainTheme.spacing(2),
    alignItems: 'center',
    paddingBottom: mainTheme.spacing(1),
  },
  cardContentMiddle: {
    display: 'flex',
    backgroundColor: mainTheme.palette.grey[300],
    borderRadius: 0,
    paddingTop: '16px !important',
  },
  cardContentMiddleColumn: {
    display: 'flex',
    flexDirection: 'column',
    backgroundColor: mainTheme.palette.grey[300],
    borderRadius: 0,
    paddingTop: '16px !important',
  },
  cardContentBottom: {
    display: 'flex',
    backgroundColor: mainTheme.palette.grey[300],
    borderRadius: mainTheme.shape.borderRadius,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingTop: mainTheme.spacing(2),
    alignItems: 'center',
    paddingBottom: mainTheme.spacing(1),
  },
  cardContentGrid: {
    display: 'flex',
    backgroundColor: mainTheme.palette.grey[300],
    borderRadius: mainTheme.shape.borderRadius,
    borderTopLeftRadius: 0,
    borderTopRightRadius: 0,
    paddingTop: mainTheme.spacing(1),
  },
  cardItem: {
    marginRight: mainTheme.spacing(4),
    fontSize: mainTheme.typography.body1.fontSize,
  },
  cardTitle: {
    margin: mainTheme.spacing(2),
    marginLeft: 0,
  },
  link: {
    color: 'black',
  },
  cardContainer: { paddingLeft: mainTheme.spacing(2) },
  serviceProductsCell: {
    fontSize: mainTheme.typography.body1.fontSize,
  },
  headerButtons: {
    marginLeft: 'auto',
    display: 'flex',
    alignItems: 'center',
    ['@media print']: {
      display: 'none',
    },
  },
  headerButtonItem: {
    margin: mainTheme.spacing(1),
  },
  cardTitleContainer: { display: 'flex', alignItems: 'center' },
  notes: {
    display: 'block',
    maxWidth: 700,
    overflowWrap: 'break-word',
    marginRight: mainTheme.spacing(4),
    fontSize: mainTheme.typography.body1.fontSize,
  },
  hideOnPrint: {
    '@media print': {
      display: 'none !important',
    },
  },
  marginLeftOnPrint: {
    '@media print': {
      marginLeft: mainTheme.spacing(1),
      textDecoration: 'none',
    },
  },
  customFunctionField: {
    flex: 1,
  },
  tableHeaderStyles: {
    backgroundColor: '#F2F2F2',
    border: '1px solid #C7C7C7',
  },
  tableCellStyles: {
    border: '1px solid #C7C7C7',
  },
})

export default showStyles
