import React, { useState } from 'react'
import { AppBar, Tabs, Tab, Box } from '@material-ui/core'
import AllowedCustomerBatchListTab from './AllowedCustomerBatchListTab'
import { mainTheme } from '../../../../utils/mainTheme'
import AllowedCustomersList from '../../../allowedCustomer/AllowedCustomerList'

const AllowedCustomerBatchTab = () => {
  const [tabValue, setTabValue] = useState(0)

  const TabPanel = (props: {
    children: JSX.Element
    value: number
    index: number
  }) => {
    const { children, value, index } = props

    return (
      <div hidden={value !== index}>
        {value === index && (
          <Box style={{ paddingTop: mainTheme.spacing(3) }}>{children}</Box>
        )}
      </div>
    )
  }

  return (
    <div>
      <AppBar color="transparent" elevation={0} position="static">
        <Tabs
          value={tabValue}
          onChange={(ev, value) => {
            setTabValue(value)
          }}
          TabIndicatorProps={{
            style: {
              backgroundColor: mainTheme.palette.primary.main,
            },
          }}
        >
          <Tab label="Listas" />
          <Tab label="Gerenciar beneficiários" />
        </Tabs>
      </AppBar>
      <TabPanel value={tabValue} index={0}>
        <AllowedCustomerBatchListTab />
      </TabPanel>
      <TabPanel value={tabValue} index={1}>
        <AllowedCustomersList />
      </TabPanel>
    </div>
  )
}

export default AllowedCustomerBatchTab
