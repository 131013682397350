import * as React from 'react'
import CustomListActions from '../../../../components/CustomListActions'
import { PayoutSettlementListFiltersArray } from './Filters'

const PayoutSettlementActions = () => (
  <CustomListActions
    filtersAvailable={PayoutSettlementListFiltersArray.map((item) => ({
      label: item.label,
      value: item.value,
    }))}
  />
)

export default PayoutSettlementActions
