const format = (phone?: string) => {
  if (!phone) return phone
  let onlyNumberValues = phone.match(/\d+/g)?.join('') || ''
  if (onlyNumberValues.length === 0) return ''
  if (onlyNumberValues.length > 10)
    onlyNumberValues = onlyNumberValues?.slice(0, 11)
  if (onlyNumberValues.length === 10)
    return onlyNumberValues
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{4})?(\d{4})?/, '($1) $2-$3')
  else if (onlyNumberValues.length == 11)
    return onlyNumberValues
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{5})?(\d{4})?/, '($1) $2-$3')
  return onlyNumberValues
}
const parse = (phone?: string) => {
  if (!phone) return phone
  return phone.match(/\d+/g)?.join('').slice(0, 11)
}

export const phoneFormatter = {
  format,
  parse,
}
