import * as React from 'react'
import { FilterProps, Filter } from 'react-admin'
import { OrderListFiltersArray } from './Filters'

const OrdersFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      {OrderListFiltersArray.map((item) => item.FilterInput)}
    </Filter>
  )
}

export default OrdersFilter
