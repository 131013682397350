import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const MinimumOfferStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'not_achieved':
      statusText = 'Não Atingido'
      statusColor = mainTheme.palette.error.main
      break
    case 'achieved':
      statusText = 'Atingido'
      statusColor = mainTheme.palette.primary.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default MinimumOfferStatusChip
