import { IClinicCondition, ShippingPolicy } from '@vacinas-net/shared'
import { valueInCentsToBRL } from '../../utils/utils'

export const parseClinicConditionType = (type: IClinicCondition['type']) => {
  const mapper: Record<IClinicCondition['type'], string> = {
    acquisition: 'Aquisição',
    commission: 'Comissão',
    gesture: 'Gesto',
  }
  return mapper[type]
}

export const parseClinicConditionValue = (
  value: number,
  type: IClinicCondition['type']
) => {
  if (type === 'commission') {
    return `${value * 100}%`
  }
  return valueInCentsToBRL(value)
}

type ShippingPolicyDictionary = {
  [key in ShippingPolicy]: string
}

export const shippingPolicyDictionary: ShippingPolicyDictionary = {
  [ShippingPolicy.Clinic]: 'Clínica',
  [ShippingPolicy.Company]: 'Empresa',
  [ShippingPolicy.Home]: 'Em Casa',
}
