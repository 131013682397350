import * as React from 'react'
import {
  DateInput,
  InputProps,
  required,
  SimpleForm,
  SimpleFormProps,
  TextInput,
  useInput,
} from 'react-admin'
import { OptionType } from '../../components/CustomAutocomplete'
import useStyles from '../../utils/creationFormStyles'
import ClinicSearch, { IHandleChangeData } from '../../components/ClinicSearch'

type CustomFormProps = Omit<SimpleFormProps, 'children'> & {
  readOnly?: boolean
  autoCompleteInitialValues?: OptionType | null
}

const JointPurchaseForm = (props: CustomFormProps) => {
  const classes = useStyles()

  const JointPurchaseClinicSearchInput = (props: InputProps) => {
    const { input } = useInput(props)

    const handleChange = (data: IHandleChangeData) => {
      input.onChange(data)
    }
    return <ClinicSearch filterByActiveClinics handleChange={handleChange} />
  }

  return (
    <SimpleForm {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <TextInput
          source="name"
          label="Nome da compra"
          variant="outlined"
          className={classes.inputWMagin}
          validate={required()}
        />
        <DateInput
          source="range.startDate"
          label="Data de início"
          variant="outlined"
          className={classes.inputWMagin}
          validate={required()}
        />
        <DateInput
          source="range.endDate"
          label="Data de fim"
          variant="outlined"
          className={classes.inputWMagin}
          validate={required()}
        />
      </div>
      <JointPurchaseClinicSearchInput source="clinics" />
    </SimpleForm>
  )
}

export default JointPurchaseForm
