import * as React from 'react'
import { ToolbarProps, Toolbar, SaveButton, useNotify } from 'react-admin'
import { WithWidthProps } from '@material-ui/core'
import { OpeningHours } from '@vacinas-net/shared'
import { isNumber } from 'lodash'
import { useFormState } from 'react-final-form'

const ClinicFormToolbar = (props: ToolbarProps & WithWidthProps) => {
  const { values } = useFormState()

  const notify = useNotify()

  const validateWeekdaysForSource = (source: string) => {
    const keys = [0, 1, 2, 3, 4, 5, 6] as Array<OpeningHours['weekday']>

    const weekdaysValid = keys.map((key) => {
      const weekdayIsActive =
        values?.weekdayOpeningHours &&
        values?.weekdayOpeningHours[source][`weekday-${key}`]
      if (!weekdayIsActive) return true

      const openingHours = values[source].filter(
        (item?: Partial<OpeningHours>) =>
          item &&
          isNumber(item.weekday) &&
          item.weekday === key &&
          isNumber(item.startTimeInMinutes) &&
          isNumber(item.endTimeInMinutes)
      )

      return !!openingHours.length
    })

    if (
      values.pickupAttendanceEnabled &&
      weekdaysValid.some((isValid) => isValid === false)
    ) {
      const message =
        'Preencha todos os horários dos dias escolhidos antes de salvar'
      return message
    }
  }

  return (
    <Toolbar {...props}>
      <SaveButton
        label="Salvar"
        handleSubmitWithRedirect={() => {
          if (!props?.handleSubmitWithRedirect) return
          const pickupOpeningHoursMessage =
            validateWeekdaysForSource('pickupOpeningHours')
          const deliveryOpeningHoursMessage = validateWeekdaysForSource(
            'deliveryOpeningHours'
          )
          const message =
            pickupOpeningHoursMessage || deliveryOpeningHoursMessage

          if (message) return notify(message, { type: 'error' })
          return props.handleSubmitWithRedirect()
        }}
      />
    </Toolbar>
  )
}

export default ClinicFormToolbar
