import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
} from '@material-ui/core'
import React from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { Record, useNotify, useRefresh } from 'react-admin'
import { useState } from 'react'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../CustomHttpClient'

const CancelOrderPopUp = (props: { record: Record | undefined }) => {
  const [loading, setLoading] = useState(false)
  const [openCancelOrder, setOpenCancelOrder] = useState(false)

  const refresh = useRefresh()
  const notify = useNotify()

  const cancelOrder = async () => {
    setLoading(true)
    const url = `${apiUrl}/order/${props.record?.id}/cancel`
    httpClient(url, {
      method: 'post',
    })
      .then(() => {
        notify('Pedido cancelado com sucesso')
        setOpenCancelOrder(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao cancelar pedido', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={() => setOpenCancelOrder(true)}
      >
        Cancelar Pedido
      </Button>

      <Dialog
        open={openCancelOrder}
        onClose={() => setOpenCancelOrder(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          style={{
            paddingTop: mainTheme.spacing(3),
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2">Cancelar Pedido</Typography>
          <div
            style={{
              marginTop: mainTheme.spacing(2),
            }}
          >
            <FormLabel>Tem certeza que deseja cancelar este pedido?</FormLabel>
            <div>
              <FormLabel>Essa ação não poderá ser revertida.</FormLabel>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: mainTheme.spacing(2),
            padding: mainTheme.spacing(3),
          }}
        >
          <Button onClick={() => setOpenCancelOrder(false)} variant="outlined">
            Retornar
          </Button>
          <Button
            onClick={() => {
              cancelOrder()
            }}
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            data-testid={'button-cancel-order-submit'}
          >
            Prosseguir
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CancelOrderPopUp
