import React, { useState } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { mainTheme } from '../../../utils/mainTheme'
import { IClinic, IClinicCondition, ShippingPolicy } from '@vacinas-net/shared'
import { useInput } from 'ra-core'
import ClinicCondition from '../ClinicCondition'
import { TextInputProps } from 'react-admin'
import { useFormState } from 'react-final-form'
import ImportClinicConditionsButton from '../ImportClinicConditionsButton'

const ClinicConditionInput = (props: TextInputProps) => {
  const [clinicConditionsArray, setClinicConditionsArray] = useState<
    Partial<IClinicCondition & { allClinics: boolean }>[]
  >([])

  const { input } = useInput(props)

  const addClinicCondition = () => {
    const newCommercialCondition: Partial<
      IClinicCondition & { allClinics: boolean }
    > = {
      shippingPolicy: ShippingPolicy.Company,
    }
    setClinicConditionsArray([...clinicConditionsArray, newCommercialCondition])
  }

  const removeClinicCondition = (index: number) => {
    const copyclinicConditions = JSON.parse(
      JSON.stringify(clinicConditionsArray)
    )
    copyclinicConditions.splice(index, 1)

    setClinicConditionsArray(copyclinicConditions)
  }

  const handleChange = (
    field: string,
    data: string | Pick<IClinic, '_id' | 'name'> | boolean | number,
    index: number
  ) => {
    const arrayCopy = JSON.parse(JSON.stringify(clinicConditionsArray))
    arrayCopy[index][field] = data
    setClinicConditionsArray(arrayCopy)
  }

  const updateReactAdminInput = () => {
    input.onChange(clinicConditionsArray)
  }

  React.useEffect(() => updateReactAdminInput(), [clinicConditionsArray])

  const importConditions = (importedConditions: IClinicCondition[]) => {
    setClinicConditionsArray([...importedConditions])
  }

  const form = useFormState()
  const handler = form.values.handler
  React.useEffect(() => {
    setClinicConditionsArray([])
  }, [handler])

  const canSelectAllClinics = () => {
    for (const condition of clinicConditionsArray) {
      if (condition.allClinics === true) {
        return false
      }
    }
    return true
  }

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Negociação por clínica</Typography>
        <IconButton
          color={'secondary'}
          onClick={() => addClinicCondition()}
          style={{
            marginLeft: mainTheme.spacing(1),
            marginRight: mainTheme.spacing(1),
          }}
        >
          <Add />
        </IconButton>
        <ImportClinicConditionsButton importConditions={importConditions} />
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {clinicConditionsArray.map((condition, index) => (
          <ClinicCondition
            clinicCondition={condition}
            index={index}
            key={index}
            removeClinicCondition={removeClinicCondition}
            handleInputChange={handleChange}
            canSelectAllClinics={canSelectAllClinics}
          />
        ))}
      </div>
    </div>
  )
}

export default ClinicConditionInput
