import React from 'react'
import { Chip } from '@material-ui/core'
import { IPayoutSettlement } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'

const PayoutSettlementStatusChip = (props: {
  status: IPayoutSettlement['status']
}) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'pending_receipt':
      statusText = 'Pendente'
      statusColor = mainTheme.palette.warning.main
      break
    case 'receipt_settled':
      statusText = 'Concluído'
      statusColor = mainTheme.palette.primary.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default PayoutSettlementStatusChip
