import * as React from 'react'
import DashboardOperationalCard from './DashboardOperationalCard'
import { AccessTime, CheckCircle, Search } from '@material-ui/icons'
import { apiUrl } from '../AppDataProvider'
import { format, formatISO, isValid, subDays } from 'date-fns'
import {
  Button,
  Divider,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Paper,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import ActiveCampaignsCard from './ActiveCampaignsCard'
import { usePermissions } from 'react-admin'
import {
  isAdmin,
  isOnlyClinicAdmin,
  isOnlyClinicOperator,
} from '@vacinas-net/shared'
import NoStocksClinicsCard from './NoStocksClinicsCard'
import ClinicMapByZipcode from './ClinicMapByZipcode'
import Header from './Header'
import { mainTheme } from '../../utils/mainTheme'
import StackedBarCard from './StackedBarCard'
import { useState } from 'react'
import LineChartCard from './LineChartCard'
import { formatDateToEndDay, formatDateToStartDay } from '../../utils/date'
import NotificationWidget from './NotificationsWidget'
import CampaignsDashboardTable from './CampaignsDashboardTable'
import DashboardHeaderBillingData from './DashboardHeaderBillingData'
import CustomAutocomplete, { OptionType } from '../CustomAutocomplete'

const Dashboard = () => {
  const { permissions } = usePermissions()

  const currentDate = new Date()
  const lastMonthDate = subDays(currentDate, 30)
  const lastWeekDate = subDays(currentDate, 7)
  const yesterdayDate = subDays(currentDate, 1)

  const [minDate, setMinDate] = useState(format(lastMonthDate, 'yyyy-MM-dd'))
  const [maxDate, setMaxDate] = useState(format(currentDate, 'yyyy-MM-dd'))

  const [timeInterval, setTimeInterval] = useState('lastMonth')
  const [makingRequest, setMakingRequest] = useState(true)
  const [clinic, setClinic] = useState<OptionType>()

  const [minUTCDateISOString, setMinUTCDateISOString] = useState(
    formatISO(formatDateToStartDay(new Date(lastMonthDate)))
  )
  const [maxUTCDateISOString, setMaxUTCDateISOString] = useState(
    formatISO(formatDateToEndDay(new Date(currentDate)))
  )

  const updateUTCDates = () => {
    if (!isValid(new Date(minDate)) || !isValid(new Date(maxDate))) {
      return
    }
    const minUTCDate = formatDateToStartDay(new Date(`${minDate}T00:00:00`))
    const maxUTCDate = formatDateToEndDay(new Date(`${maxDate}T23:59:59`))
    setMinUTCDateISOString(formatISO(minUTCDate))
    setMaxUTCDateISOString(formatISO(maxUTCDate))
  }

  const currentStartUTCDate = formatDateToStartDay(currentDate)
  const currentEndUTCDate = formatDateToEndDay(currentDate)

  const handleStartDateChange = (date: string) => {
    setTimeInterval('custom')

    setMinDate(date)
  }

  const handleEndDateChange = (date: string) => {
    setTimeInterval('custom')
    setMaxDate(date)
  }

  const handleTimeIntervalChange = (timeInterval: string) => {
    setTimeInterval(timeInterval)

    switch (timeInterval) {
      case 'lastMonth': {
        setMinDate(format(lastMonthDate, 'yyyy-MM-dd'))
        setMaxDate(format(currentDate, 'yyyy-MM-dd'))

        break
      }
      case 'lastWeek': {
        setMinDate(format(lastWeekDate, 'yyyy-MM-dd'))
        setMaxDate(format(currentDate, 'yyyy-MM-dd'))

        break
      }
      case 'yesterday': {
        setMinDate(format(yesterdayDate, 'yyyy-MM-dd'))
        setMaxDate(format(currentDate, 'yyyy-MM-dd'))

        break
      }

      default:
        break
    }
  }

  const stopRequest = () => {
    setMakingRequest(false)
  }

  return (
    <div>
      <Header />
      <Grid
        style={{ marginTop: mainTheme.spacing(1) }}
        container
        xs={12}
        spacing={2}
      >
        <Grid container xs={12} spacing={2}>
          <Grid item xs={9}>
            <Typography
              color="textSecondary"
              variant="h2"
              gutterBottom={false}
              style={{
                marginTop: mainTheme.spacing(2),
                marginBottom: mainTheme.spacing(4),
              }}
            >
              Selecione o intervalo que deseja visualizar seus dados
            </Typography>
            <Grid
              container
              xs={12}
              spacing={2}
              style={{
                padding: mainTheme.spacing(1),
              }}
            >
              <Grid item xs={4}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <FormControl
                    style={{
                      paddingBottom: mainTheme.spacing(1),
                      marginTop: mainTheme.spacing(1),
                      width: '100%',
                    }}
                    variant="outlined"
                    size={'small'}
                  >
                    <InputLabel shrink id="demo-simple-select-autowidth-label">
                      Período
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-autowidth-label"
                      id="demo-simple-select-autowidth"
                      value={timeInterval}
                      onChange={(event) =>
                        handleTimeIntervalChange(String(event.target.value))
                      }
                      label="Período"
                    >
                      <MenuItem value={'lastMonth'}>Últimos 30 dias</MenuItem>
                      <MenuItem value={'lastWeek'}>Últimos 7 dias</MenuItem>
                      <MenuItem value={'yesterday'}>Ontem</MenuItem>
                      <MenuItem value={'custom'}>Personalizado</MenuItem>
                    </Select>
                  </FormControl>
                </div>
              </Grid>
              <Divider
                orientation="vertical"
                flexItem
                color={mainTheme.palette.primary.main}
                style={{
                  margin: '16px 20px',
                }}
              />
              <Grid item xs={7}>
                <div
                  style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  <TextField
                    style={{
                      paddingBottom: mainTheme.spacing(1),
                      marginTop: mainTheme.spacing(1),
                      marginRight: mainTheme.spacing(1),
                      maxWidth: '100%',
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleStartDateChange(event.target.value)
                    }
                    variant={'outlined'}
                    value={minDate}
                    label="Data de ínicio"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size={'small'}
                    inputProps={{
                      max: '2999-12-31T23:59',
                    }}
                  />
                  <TextField
                    style={{
                      paddingBottom: mainTheme.spacing(1),
                      marginTop: mainTheme.spacing(1),
                      maxWidth: '100%',
                    }}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                      handleEndDateChange(event.target.value)
                    }
                    variant={'outlined'}
                    value={maxDate}
                    label="Data de fim"
                    type="date"
                    InputLabelProps={{ shrink: true }}
                    size={'small'}
                    inputProps={{
                      max: '2999-12-31T23:59',
                    }}
                  />
                  <CustomAutocomplete
                    style={{ width: 300, marginLeft: mainTheme.spacing(1) }}
                    resource="clinic"
                    filterByActiveClinics
                    currentValue={clinic || null}
                    onChange={(event) => setClinic(event!)}
                    size="medium"
                    label="Nome da clínica"
                  />
                </div>
              </Grid>
              <div
                style={{
                  marginLeft: mainTheme.spacing(1),
                  marginBottom: mainTheme.spacing(2),
                }}
              >
                <Button
                  style={{
                    alignSelf: 'flex-start',
                  }}
                  color="primary"
                  variant="contained"
                  onClick={() => {
                    setMakingRequest(true)
                    updateUTCDates()
                  }}
                  endIcon={<Search />}
                  disabled={makingRequest}
                >
                  Filtrar
                </Button>
              </div>
            </Grid>
          </Grid>
        </Grid>
        <Grid
          container
          xs={12}
          spacing={2}
          style={{
            marginTop: mainTheme.spacing(1),
            padding: mainTheme.spacing(1),
          }}
        >
          <Grid item xs={12}>
            <DashboardHeaderBillingData
              isAdmin={isAdmin(permissions)}
              requestUrl={`${apiUrl}/dashboard/header?purchasedAt(min)=${
                minUTCDateISOString && `${minUTCDateISOString}`
              }&purchasedAt(max)=${
                maxUTCDateISOString && `${maxUTCDateISOString}`
              }${clinic ? `&relatedClinics._id=${clinic.value}` : ''}`}
              makingRequest={makingRequest}
              stopRequest={stopRequest}
            ></DashboardHeaderBillingData>
          </Grid>
        </Grid>
        <Grid style={{ marginTop: mainTheme.spacing(3) }} item xs={8}>
          <Paper
            style={{
              padding: mainTheme.spacing(2),
              paddingRight: 0,
            }}
          >
            <Grid
              container
              style={{ marginTop: mainTheme.spacing(2) }}
              xs={12}
              spacing={2}
            >
              <Grid item xs={12}>
                <StackedBarCard
                  startDate={minUTCDateISOString}
                  endDate={maxUTCDateISOString}
                  makingRequest={makingRequest}
                  stopRequest={stopRequest}
                  clinicId={clinic?.value}
                />
              </Grid>
            </Grid>
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <LineChartCard
                  startDate={minUTCDateISOString}
                  endDate={maxUTCDateISOString}
                  makingRequest={makingRequest}
                  stopRequest={stopRequest}
                  clinicId={clinic?.value}
                />
              </Grid>
            </Grid>
            {isAdmin(permissions) && (
              <Grid container xs={12} spacing={2}>
                <Grid item xs={12}>
                  <CampaignsDashboardTable />
                </Grid>
              </Grid>
            )}
          </Paper>
        </Grid>
        <Grid item xs={4}>
          <Grid
            container
            xs={12}
            spacing={2}
            style={{ marginTop: mainTheme.spacing(2) }}
          >
            <Grid item xs={6}>
              <DashboardOperationalCard
                label="Atendimentos para hoje"
                requestUrl={`${apiUrl}/financial/attendance-counter?deliveryWindow.startDate(min)=${formatISO(
                  currentStartUTCDate
                )}&deliveryWindow.endDate(max)=${formatISO(currentEndUTCDate)}`}
                responseField="attendanceCounter"
                redirectTo={`/attendance?displayedFilters=%7B"deliveryWindow->endDate%28max%29"%3Atrue%7D&filter=%7B"deliveryWindow->startDate%28min%29"%3A"${formatISO(
                  currentStartUTCDate
                )}"%2C"deliveryWindow->endDate%28max%29"%3A"${formatISO(
                  currentEndUTCDate
                )}"%7D&order=ASC&page=1&perPage=10&sort=deliveryWindow.startDate`}
              >
                <AccessTime color="secondary" />
              </DashboardOperationalCard>
            </Grid>
            <Grid item xs={6}>
              <DashboardOperationalCard
                label="Pedidos aguardando aceite"
                requestUrl={`${apiUrl}/financial/order-counter?status=awaiting_clinic_confirmation`}
                responseField="orderCounter"
                redirectTo='/order?displayedFilters=%7B"status"%3Atrue%7D&filter=%7B"status"%3A"awaiting_clinic_confirmation"%7D&order=DESC&page=1&perPage=10&sort=purchasedAt'
              >
                <CheckCircle color="secondary" />
              </DashboardOperationalCard>
            </Grid>
          </Grid>
          {isAdmin(permissions) && (
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <ActiveCampaignsCard />
              </Grid>
            </Grid>
          )}
          {isOnlyClinicOperator(permissions) ||
          isOnlyClinicAdmin(permissions) ? (
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <NotificationWidget shrinkText />
              </Grid>
            </Grid>
          ) : (
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <ClinicMapByZipcode />
              </Grid>
            </Grid>
          )}

          <Grid container xs={12} spacing={2}>
            <Grid item xs={12}>
              <NoStocksClinicsCard />
            </Grid>
          </Grid>
          {isAdmin(permissions) && (
            <Grid container xs={12} spacing={2}>
              <Grid item xs={12}>
                <NotificationWidget shrinkText />
              </Grid>
            </Grid>
          )}
        </Grid>
      </Grid>
    </div>
  )
}

export default Dashboard
