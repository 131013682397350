import * as React from 'react'
import { valueInCentsToBRL, formatDate } from '../../../utils/utils'

import {
  Datagrid,
  FunctionField,
  List,
  Record,
  TextField,
  ListProps,
} from 'react-admin'

import VoucherPackageStatusChip from '../../../components/chips/VoucherPackageStatusChip'
import VoucherPackagesFilters from './VoucherPackagesFilters'
import VoucherPackagesActions from './VoucherPackagesActions'
import VouchersListActionsButton from '../../../components/VouchersListActionsButton'
import { DefaultPagination } from '../../../components/Pagination'

const VouchersPackageList = ({ ...props }: ListProps) => {
  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      filters={<VoucherPackagesFilters />}
      actions={<VoucherPackagesActions />}
      sort={{ field: 'requestDate', order: 'DESC' }}
    >
      <Datagrid stickyHeader rowClick="edit">
        <TextField source="campaignName" textAlign="center" label="Campanha" />
        <TextField
          source="relatedUserName"
          textAlign="center"
          label="Usuário"
        />
        <TextField
          source="requestedQuantity"
          textAlign="center"
          label="Quantidade"
        />
        <FunctionField
          textAlign="center"
          label="Data de solicitação"
          render={(record: Record | undefined) => {
            return formatDate(record?.requestDate)
          }}
        />
        <FunctionField
          textAlign="center"
          label="Data de validade"
          render={(record: Record | undefined) => {
            return formatDate(record?.expirationDate)
          }}
        />
        <FunctionField
          textAlign="center"
          label="Valor"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.voucherValueInCents)
          }
        />
        <FunctionField
          textAlign="center"
          label="Status"
          render={(record: Record | undefined) => (
            <VoucherPackageStatusChip status={record?.status} />
          )}
        />
        <FunctionField
          textAlign="center"
          label="Ações"
          render={(record: Record | undefined) => (
            <VouchersListActionsButton
              disabled={record?.status === 'approved'}
              voucherPackageId={record?._id}
            />
          )}
        />
      </Datagrid>
    </List>
  )
}
export default VouchersPackageList
