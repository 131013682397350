import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add, CheckCircleRounded, ErrorOutline } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { useListContext, useNotify, useRefresh } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import _ from 'lodash'
import { ShippingPolicy } from '@vacinas-net/shared'
import { shippingPolicyDictionary } from '../../../resources/clinicCondition/utils'

const AddClinicToCommercialCondition = (props: {
  commercialConditionId: string
}) => {
  const [loading, setLoading] = useState(false)
  const [openClinicModal, setOpenClinicModal] = useState(false)
  const [clinic, setClinic] = useState<OptionType>()
  const [comissionType, setComissionType] = useState('')
  const [value, setValue] = useState<number>()
  const [totalAmount, setTotalAmount] = useState<number>()
  const [shippingPolicy, setShippingPolicy] = useState('company')

  const refresh = useRefresh()
  const notify = useNotify()

  const context = useListContext()
  const handler = _.first(Object.values(context.data))?.commercialCondition
    .handler

  const selectedClinics = Object.values(context.data).map(
    (condition) => condition.clinic._id
  )

  const selectedConditions = Object.values(context.data)

  const addClinic = async () => {
    setLoading(true)
    const url = `${apiUrl}/commercial-condition/${props.commercialConditionId}/addClinicCondition`
    httpClient(url, {
      method: 'post',
      body: JSON.stringify({
        clinic: {
          _id: clinic?.value,
          name: clinic?.label,
        },
        ...(handler === 'jenner' && { shippingPolicy: shippingPolicy }),
        type: comissionType,
        value: value,
        totalAmount: totalAmount,
      }),
    })
      .then(() => {
        notify('Clinica adicionada com sucesso')
        setOpenClinicModal(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao adicionar a clinica: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        size="medium"
        color="secondary"
        variant="contained"
        onClick={() => setOpenClinicModal(true)}
        data-testid={'add-clinic-button'}
        startIcon={<Add />}
      >
        Adicionar clínica
      </Button>
      <Dialog
        open={openClinicModal}
        onClose={() => setOpenClinicModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Typography
            style={{ marginBottom: mainTheme.spacing(2) }}
            variant="h2"
          >
            Adicionar nova clínica à campanha
          </Typography>
          <CustomAutocomplete
            resource="clinic"
            filterByActiveClinics
            currentValue={clinic || null}
            onChange={(event) => {
              if (handler === 'vtex') {
                if (
                  selectedClinics.find((clinicId) => clinicId === event?.value)
                ) {
                  notify(
                    'Não é possível selecionar duas clínicas iguais quando a condição comercial é VTEX',
                    'error'
                  )
                  return
                }
              }
              setClinic(event!)
            }}
            size="medium"
            label="Nome da clínica"
            readOnly={loading}
            dataTestId={`add-clinic-autocomplete`}
          />
          <Typography style={{ marginTop: mainTheme.spacing(2) }} variant="h2">
            Condições comerciais
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginTop: mainTheme.spacing(2),
            }}
          >
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: 200, marginRight: mainTheme.spacing(1) }}
            >
              <InputLabel id="type-label">Tipo</InputLabel>
              <Select
                labelId="type-label"
                value={comissionType || null}
                label="Tipo"
                onChange={(event) =>
                  setComissionType(String(event.target.value))
                }
                SelectDisplayProps={{
                  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                  //@ts-ignore
                  'data-testid': 'optionPath',
                }}
              >
                <MenuItem value={'commission'}>Comissão</MenuItem>
                <MenuItem value={'gesture'}>Gesto</MenuItem>
                <MenuItem value={'acquisition'}>Aquisição</MenuItem>
              </Select>
            </FormControl>
            {(comissionType === 'acquisition' ||
              comissionType === 'gesture') && (
              <TextField
                style={{ width: 150, marginRight: mainTheme.spacing(1) }}
                type="number"
                label="Valor"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={parseFloat(String(value)) / 100 || null}
                onChange={(event) =>
                  setValue(parseFloat(event.target.value) * 100)
                }
                inputProps={{
                  'data-testid': `monetary-input`,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            )}
            {comissionType === 'commission' && (
              <TextField
                style={{ width: 150, marginRight: mainTheme.spacing(1) }}
                type="number"
                label="Valor"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={parseFloat(String(value)) * 100 || null}
                onChange={(event) =>
                  setValue(parseFloat(event.target.value) / 100)
                }
                inputProps={{
                  'data-testid': `percent-input`,
                }}
                InputProps={{
                  endAdornment: (
                    <InputAdornment position="end">%</InputAdornment>
                  ),
                }}
              />
            )}
            {handler === 'jenner' && (
              <FormControl
                style={{ width: 200 }}
                variant="outlined"
                size="small"
              >
                <InputLabel
                  shrink={true}
                  style={{
                    backgroundColor: 'white',
                    paddingLeft: 2,
                    paddingRight: 2,
                  }}
                >
                  Política de envio
                </InputLabel>
                <Select
                  disabled={!clinic?.value}
                  displayEmpty
                  value={shippingPolicy || null}
                  label="Política de envio"
                  onChange={(event) => {
                    if (event.target.value === 'clinic') {
                      if (
                        selectedConditions.find(
                          (condition) =>
                            condition.status !== 'removed' &&
                            condition.clinic._id === clinic?.value &&
                            condition.shippingPolicy === 'clinic'
                        )
                      ) {
                        notify(
                          'Não é possível a mesma clínica ter mais de uma negociação com a política de envio "clínica"',
                          'error'
                        )
                        return
                      }
                    }
                    setShippingPolicy(String(event.target.value))
                  }}
                  inputProps={{
                    'data-testid': `shipping-policy-input`,
                  }}
                >
                  <MenuItem value={ShippingPolicy.Clinic}>
                    {shippingPolicyDictionary[ShippingPolicy.Clinic]}
                  </MenuItem>
                  <MenuItem value={ShippingPolicy.Company}>
                    {shippingPolicyDictionary[ShippingPolicy.Company]}
                  </MenuItem>
                </Select>
              </FormControl>
            )}
          </div>
          <TextField
            style={{
              width: 'calc(50% - 4px)',
              marginTop: mainTheme.spacing(2),
              marginRight: mainTheme.spacing(2),
            }}
            type="number"
            label="Quantidade"
            variant="outlined"
            size="small"
            value={totalAmount}
            onChange={(event) => setTotalAmount(parseInt(event.target.value))}
            inputProps={{
              'data-testid': `totalAmount-input`,
              min: 0,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ErrorOutline />}
            onClick={() => setOpenClinicModal(false)}
            size="large"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<CheckCircleRounded />}
            onClick={() => {
              addClinic()
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={loading}
            data-testid={`confirm-button`}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default AddClinicToCommercialCondition
