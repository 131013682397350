import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const VouchersListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'campaignName',
      label: 'Campanha',
      FilterInput: (
        <SearchFilterInput
          label="Campanha"
          source="campaignName"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'relatedUserName',
      label: 'Usuário',
      FilterInput: (
        <SearchFilterInput
          label="Usuário"
          source="relatedUserName"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'requestDate(min)',
      label: 'Solicitado depois de',
      FilterInput: (
        <CustomDateInput
          label="Solicitado depois de"
          source="requestDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'requestDate(max)',
      label: 'Solicitado antes de',
      FilterInput: (
        <CustomDateInput
          label="Solicitado antes de"
          source="requestDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'approved', name: 'Aprovado' },
            { id: 'pending', name: 'Solicitado' },
            { id: 'rejected', name: 'Rejeitado' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
