import React, { useEffect } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { Delete } from '@material-ui/icons'
import { OpeningHourItem } from '../types'
import StartDateTimeInput from './StartDateTimeInput'
import EndDateTimeInput from './EndDateTimeInput'
import { useForm } from 'react-final-form'

import { useNotify } from 'react-admin'

export interface OpeningHourRowProps {
  disabled: boolean
  deleteShift: (globalIndex: number) => void
  openingHour: OpeningHourItem
  inputBaseSource: string
}

const OpeningHourRow = (props: OpeningHourRowProps) => {
  const rowSource = `${props.inputBaseSource}.${props.openingHour.globalIndex}`
  const startDateTimeSource = `${rowSource}.startTimeInMinutes`
  const form = useForm()
  const notify = useNotify()

  useEffect(() => {
    const fieldState = form.getFieldState(startDateTimeSource)
    if (!fieldState?.error) return

    notify(fieldState.error, { type: 'error' })
  }, [form.getFieldState(startDateTimeSource)?.error])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: mainTheme.spacing(2),
        alignItems: 'center',
      }}
    >
      <StartDateTimeInput {...props} />
      <EndDateTimeInput {...props} />

      <Typography variant="body1">
        {`${props.openingHour.shiftIndex + 1}° Turno`}
      </Typography>

      {props.openingHour.shiftIndex !== 0 && (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <IconButton
            onClick={() => props.deleteShift(props.openingHour.globalIndex)}
            style={{ marginLeft: mainTheme.spacing(1) }}
            size="small"
            color="secondary"
            disabled={props.disabled}
          >
            <Delete />
          </IconButton>
        </div>
      )}
    </div>
  )
}

export default OpeningHourRow
