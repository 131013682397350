import React from 'react'
import { Grid, Typography, FormControlLabel, Checkbox } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const PaymentSession = () => {
  return (
    <Grid
      style={{ marginTop: mainTheme.spacing(2) }}
      container
      spacing={2}
      xs={12}
    >
      <Grid item xs={12}>
        <Typography color="primary" variant="body1">
          Pagamento
        </Typography>
        <Grid item xs={12}>
          <FormControlLabel
            value="end"
            control={
              <Checkbox
                defaultChecked
                checked={true}
                color="primary"
                disabled
              />
            }
            label="Pagamento será com vale?"
          />
        </Grid>
      </Grid>
    </Grid>
  )
}

export default PaymentSession
