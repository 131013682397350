import React, { useContext } from 'react'
import { IconButton, Typography } from '@material-ui/core'
import { Add, Remove } from '@material-ui/icons'
import { CartProduct, OrderCreateContext } from '../Contexts/OrderCreateContext'
import { mainTheme } from '../../utils/mainTheme'

const QuantityHandler = (props: { product: CartProduct }) => {
  const { product } = props
  const orderCreateContext = useContext(OrderCreateContext)
  const { decreaseItemQuantity, upgradeItemQuantity } = orderCreateContext!

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      <IconButton
        data-testid={`decrease-product-quantity-button-${product.skuId}`}
        disabled={product.quantity === 1}
        size="small"
        onClick={() => decreaseItemQuantity(product.skuId)}
      >
        <Remove />
      </IconButton>
      <Typography
        data-testid={`total-quantity-${product.skuId}`}
        style={{
          marginLeft: mainTheme.spacing(1),
          marginRight: mainTheme.spacing(1),
        }}
        variant="body1"
      >
        {product.quantity}
      </Typography>
      <IconButton
        data-testid={`increase-product-quantity-button-${product.skuId}`}
        size="small"
        onClick={() => upgradeItemQuantity(product.skuId)}
      >
        <Add />
      </IconButton>
    </div>
  )
}

export default QuantityHandler
