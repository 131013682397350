import { ContentCopy } from '@mui/icons-material'
import { IconButton, Typography } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import React from 'react'

const LinkToClipboard = (props: { uuid: string }) => {
  const redirectLink =
    process.env.REACT_APP_NODE_ENV === 'development'
      ? `https://vacinas-client-dev.web.app/customer-attendance/${props.uuid}`
      : `https://app.vacinas.net/customer-attendance/${props.uuid}`
  return (
    <div
      onClick={() => {
        navigator.clipboard.writeText(redirectLink)
      }}
      style={{
        border: `2px solid ${mainTheme.palette.primary.main}`,
        borderRadius: 8,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
      }}
    >
      <Typography
        style={{
          margin: mainTheme.spacing(1),
          overflow: 'auto',
        }}
        variant="body1"
      >
        {redirectLink}
      </Typography>
      <IconButton color="primary">
        <ContentCopy />
      </IconButton>
    </div>
  )
}

export default LinkToClipboard
