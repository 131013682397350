import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Typography,
} from '@material-ui/core'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import { mainTheme } from '../../../utils/mainTheme'
import { IClinicCondition } from '@vacinas-net/shared'

const ImportClinicConditionsButton = (props: {
  importConditions: (importedConditions: IClinicCondition[]) => void
}) => {
  const { importConditions } = props
  const [open, setOpen] = useState(false)
  const [selectedCommercialCondition, setSelectedCommercialCondition] =
    useState<OptionType | null>()

  const closeAndResetModal = () => {
    setOpen(false)
    setSelectedCommercialCondition(null)
  }

  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => {
          setOpen(true)
        }}
      >
        Importar negociações
      </Button>
      <Dialog
        fullWidth
        maxWidth={'sm'}
        open={open}
        onClose={() => closeAndResetModal()}
      >
        <DialogTitle>Importar negociações</DialogTitle>
        <DialogContent>
          <Typography variant="body1">
            Escolha uma condição comercial para importar suas negociações
          </Typography>
          <CustomAutocomplete
            style={{
              marginTop: mainTheme.spacing(4),
            }}
            resource="commercial-condition"
            currentValue={selectedCommercialCondition || null}
            onChange={(event) => {
              setSelectedCommercialCondition(event)
            }}
            label="Condição comerical"
            optionTypeLabel="name"
            optionTypeValue="clinicConditions"
            size="small"
          />
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => closeAndResetModal()}
          >
            Cancelar
          </Button>
          <Button
            disabled={!selectedCommercialCondition}
            color="primary"
            variant="contained"
            onClick={() => {
              importConditions(
                selectedCommercialCondition?.value as unknown as IClinicCondition[]
              )
              setOpen(false)
            }}
          >
            Importar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ImportClinicConditionsButton
