import React from 'react'
import { Typography } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const PaymentsListHeader = () => {
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: mainTheme.spacing(2),
        paddingTop: mainTheme.spacing(4),
      }}
    >
      <div
        style={{
          width: 150,
        }}
      >
        <Typography align="center" variant="body1">
          Tipo
        </Typography>
      </div>
      <div
        style={{
          width: 200,
        }}
      >
        <Typography align="center" variant="body1">
          Descrição
        </Typography>
      </div>
      <div
        style={{
          width: 100,
        }}
      >
        <Typography align="center" variant="body1">
          Valor
        </Typography>
      </div>
    </div>
  )
}
export default PaymentsListHeader
