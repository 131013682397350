import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const CommercialConditionsFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'name',
      label: 'Nome',
      FilterInput: (
        <SearchFilterInput
          label="Nome"
          source="name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'dateRange->startDate(min)',
      label: 'Data de início',
      FilterInput: (
        <CustomDateInput
          label="Data de início"
          source="dateRange->startDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'dateRange->endDate(max)',
      label: 'Data de fim',
      FilterInput: (
        <CustomDateInput
          label="Data de fim"
          source="dateRange->endDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'active', name: 'Ativa' },
            { id: 'archived', name: 'Arquivada' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'handler',
      label: 'Tipo',
      FilterInput: (
        <SelectInput
          source="handler"
          label="Tipo"
          choices={[
            { id: 'jenner', name: 'Jenner' },
            { id: 'vtex', name: 'Vtex' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
