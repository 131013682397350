import {
  Badge,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { FiberManualRecord, LocalOffer } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useDataProvider, useNotify, useRedirect } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { ICampaignV2 } from '@vacinas-net/shared'
import { format } from 'date-fns'

const useStyles = makeStyles({
  campaignLink: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: mainTheme.palette.secondary.main,
    },
  },
  seeMoreLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

const ActiveCampaignsCard = () => {
  const [campaigns, setCampaigns] = useState<ICampaignV2[]>()
  const [total, setTotal] = useState<number>()

  const notify = useNotify()
  const redirect = useRedirect()
  const dataProvider = useDataProvider()
  const classes = useStyles()

  useEffect(() => {
    dataProvider
      .getList('campaign', {
        pagination: {
          page: 1,
          perPage: 5,
        },
        filter: {
          'dateRange.startDate(max)': `${format(
            Date.now(),
            'yyyy-MM-dd'
          )}T23:59:59-03:00`,
          'dateRange.endDate(min)': `${format(
            Date.now(),
            'yyyy-MM-dd'
          )}T00:00:00-03:00`,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      })
      .then((res) => {
        const data = res.data as ICampaignV2[]
        const total = res.total
        setCampaigns(data)
        setTotal(total)
      })
      .catch((error) => {
        notify(`Erro ao obter campanhas ativas: ${error}`, 'error')
      })
  }, [])
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
        cursor: 'pointer',
      }}
      onClick={() => {
        redirect('/campaign')
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
          marginBottom: mainTheme.spacing(2),
          paddingTop: mainTheme.spacing(1),
        }}
      >
        <LocalOffer
          style={{ marginLeft: mainTheme.spacing(1) }}
          color="secondary"
          fontSize="default"
        />
        <Typography
          style={{ marginLeft: mainTheme.spacing(2) }}
          align="center"
          variant="h2"
          color="textPrimary"
        >
          Campanhas ativas
        </Typography>
        <Badge
          style={{ position: 'absolute', right: 16 }}
          color="error"
          max={99}
          badgeContent={total}
        />
      </div>
      {campaigns ? (
        <List style={{ alignSelf: 'flex-start', width: '100%' }}>
          {campaigns.map((campaign) => (
            <ListItem
              style={{ paddingBottom: 0, paddingTop: 0 }}
              key={campaign._id}
              onClick={(ev: { stopPropagation: () => void }) => {
                ev.stopPropagation()
                redirect(`/campaign/${campaign._id}`)
              }}
            >
              <ListItemIcon style={{ minWidth: 16 }}>
                <FiberManualRecord color="secondary" style={{ fontSize: 10 }} />
              </ListItemIcon>
              <ListItemText
                className={classes.campaignLink}
                primary={campaign.name}
                primaryTypographyProps={{
                  color: 'secondary',
                  gutterBottom: false,
                }}
              />
            </ListItem>
          ))}
        </List>
      ) : (
        <CircularProgress size={40} color="secondary" />
      )}
      {total && total > 5 && (
        <div
          style={{
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
          }}
        >
          <Typography
            className={classes.seeMoreLink}
            style={{ marginRight: mainTheme.spacing(1) }}
            variant="body2"
          >
            Ver mais
          </Typography>
        </div>
      )}
    </Paper>
  )
}

export default ActiveCampaignsCard
