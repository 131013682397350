import { Typography } from '@material-ui/core'
import React from 'react'
import { SimpleShowLayout, TextField, Record } from 'react-admin'
import showStyles from '../../../../utils/showStyles'
import { getPaymentFromOrder } from '../utils'

const PaymentSection = (props: { record: Record | undefined }) => {
  const classes = showStyles()
  const payment = getPaymentFromOrder(props.record)
  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Pagamento
      </Typography>
      <SimpleShowLayout record={payment} className={classes.cardContent}>
        <TextField
          className={classes.cardItem}
          source="totalPaid"
          label="Total Pago"
        />
        <TextField
          className={classes.cardItem}
          source="paymentMethod"
          label="Meio de pagamento"
        />
        <TextField
          className={classes.cardItem}
          source="installments"
          label="Parcelas"
        />
        <TextField className={classes.cardItem} source="coupon" label="Cupom" />
        <TextField
          className={classes.cardItem}
          source="discount"
          label="Valor do desconto"
        />
        {payment.vouchersRedemptionCodes && (
          <TextField
            className={classes.cardItem}
            source="vouchersRedemptionCodes"
            label="Código do Vale"
          />
        )}
      </SimpleShowLayout>
    </div>
  )
}

export default PaymentSection
