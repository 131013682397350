import { Typography, WithWidthProps } from '@material-ui/core'
import * as React from 'react'
import {
  DateTimeInput,
  EditProps,
  SaveButton,
  SimpleForm,
  TextInput,
  Toolbar,
  ToolbarProps,
  Record,
  useNotify,
  useRefresh,
  FieldProps,
  useEditController,
  EditContextProvider,
  required,
} from 'react-admin'
import { useHistory } from 'react-router-dom'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { mainTheme } from '../../../utils/mainTheme'
import { setHours, getHours, getMinutes } from 'date-fns'
import { cloneElement } from 'react'
import { setMinutes } from 'date-fns/esm'
import { getDateHours } from '../../../utils/utils'

const CustomAttendanceEdit: React.FC<FieldProps> = (props) => {
  const controllerProps = useEditController(props)
  const { record } = controllerProps

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        <HeaderWithGoBack title="Detalhes do pedido" />
        {cloneElement(props.children as React.ReactElement, {
          ...controllerProps,
          record: {
            ...record,
            endHour:
              record?.deliveryWindow &&
              record?.deliveryWindow?.endDate &&
              getDateHours(new Date(record?.deliveryWindow.endDate)),
          },
        })}
      </div>
    </EditContextProvider>
  )
}

const CustomToolbar = (props: ToolbarProps & WithWidthProps) => (
  <Toolbar {...props}>
    <SaveButton label="Salvar" />
  </Toolbar>
)

const transform = (data: Record) => {
  let parseEndDate = new Date(data.deliveryWindow.startDate)
  const [hours, minutes] = data.endHour.split(':')
  parseEndDate = setHours(parseEndDate, hours)
  parseEndDate = setMinutes(parseEndDate, minutes)

  const trasnformedData = {
    id: data.id,
    notes: data.notes ?? '',
    deliveryWindow: {
      startDate: data.deliveryWindow.startDate,
      endDate: parseEndDate,
    },
  }
  return trasnformedData
}

const AttendanceEdit: React.FC<EditProps> = (props: EditProps) => {
  const notify = useNotify()
  const refresh = useRefresh()
  const history = useHistory()
  const onSuccess = () => {
    notify('Edição relizada com sucesso')
    history.goBack()
    refresh()
  }

  const validateAttendanceEdit = (data: Record) => {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const errors: any = {}

    if (data && data.deliveryWindow) {
      const startDate = new Date(data.deliveryWindow.startDate)
      const startDateHour = getHours(startDate)
      const startDateMinutes = getMinutes(startDate)
      const startDateTotalMinutes = 60 * startDateHour + startDateMinutes

      if (data.endHour) {
        const endHourSplitted = data.endHour.split(':')
        const endHourTotalMinutes =
          60 * Number(endHourSplitted[0]) + Number(endHourSplitted[1])

        if (endHourTotalMinutes < startDateTotalMinutes) {
          errors.endHour = 'The limit hour is less than de start hour'
        }
      }
    }

    return errors
  }

  return (
    <CustomAttendanceEdit
      transform={transform}
      onSuccess={onSuccess}
      mutationMode="pessimistic"
      component="div"
      {...props}
    >
      <SimpleForm validate={validateAttendanceEdit} toolbar={<CustomToolbar />}>
        <Typography style={{ marginBottom: mainTheme.spacing(2) }} variant="h2">
          Editar Atendimento
        </Typography>
        <DateTimeInput
          source="deliveryWindow.startDate"
          variant="outlined"
          label="Horario de Agendamento"
          validate={required()}
        />
        <TextInput
          source="endHour"
          label="Tolerância máxima"
          type="time"
          variant="outlined"
          validate={required()}
        />
        <TextInput
          source="notes"
          multiline
          variant="outlined"
          label="Observações"
        />
      </SimpleForm>
    </CustomAttendanceEdit>
  )
}

export default AttendanceEdit
