import { isAdmin } from '@vacinas-net/shared'
import * as React from 'react'
import { usePermissions } from 'react-admin'
import CustomListActions from '../../../components/CustomListActions'
import { ReportTypeEnum } from '@vacinas-net/shared'
import ExportButton from '../../../components/Buttons/ExportButton'
import { mainTheme } from '../../../utils/mainTheme'
import { OrderListFiltersArray } from './Filters'

const OrdersListActions = () => {
  const { permissions } = usePermissions()
  return (
    <CustomListActions
      filtersAvailable={OrderListFiltersArray.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      hasCreate={isAdmin(permissions)}
      hasExport
      exportType={ReportTypeEnum.Order}
      exportButtonText="Exportar Pedidos"
      requiredFields={{
        'purchasedAt(min)': { label: 'Comprado depois de', type: 'date' },
        'purchasedAt(max)': { label: 'Comprado antes de', type: 'date' },
      }}
    >
      {isAdmin(permissions || []) && (
        <div style={{ marginLeft: mainTheme.spacing(2) }}>
          <ExportButton
            color="secondary"
            variant="outlined"
            buttonText="Exportar Relatório Geral"
            exportType={ReportTypeEnum.General}
            requiredFields={{
              'purchasedAt(min)': { label: 'Comprado depois de', type: 'date' },
              'purchasedAt(max)': { label: 'Comprado antes de', type: 'date' },
            }}
          />
        </div>
      )}
    </CustomListActions>
  )
}

export default OrdersListActions
