import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add, CheckCircleRounded, ErrorOutline } from '@material-ui/icons'
import React, { useMemo } from 'react'
import { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { useNotify, usePermissions, useRefresh } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { DropzoneArea } from 'material-ui-dropzone'
import { stopPropagation, uploadFile } from '../../../utils/utils'
import { isAdmin } from '@vacinas-net/shared'

const UploadInvoiceButton = (props: {
  attendanceId: string
  isMenuItem?: boolean
}) => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [invoice, setInvoice] = useState<File>()
  const [invoiceNumber, setInvoiceNumber] = useState('')
  const [invoiceUrl, setInvoiceUrl] = useState('')
  const [value, setValue] = useState<number>()
  const [recipient, setRecipient] = useState('')

  const refresh = useRefresh()
  const notify = useNotify()
  const { permissions } = usePermissions()

  const uploadInvoice = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendanceId}/invoice`
    try {
      if (!invoice) {
        throw new Error('Select a file')
      }

      const invoiceCreateResponse = await httpClient(url, {
        method: 'post',
        body: JSON.stringify({
          number: invoiceNumber,
          valueInCents: value,
          recipient: recipient,
          url: invoiceUrl || undefined,
        }),
      })
      const invoiceId = invoiceCreateResponse.json._id

      const getSignedUrlPath = `${apiUrl}/attendance-invoice/${invoiceId}/upload`
      const signedUrlResponse = await httpClient(getSignedUrlPath, {
        method: 'put',
        body: JSON.stringify({
          contentType: invoice?.type,
        }),
      })
      const signedUrl = signedUrlResponse?.json?.signedUrl

      await uploadFile(signedUrl, invoice, true)

      notify('Nota fiscal registrada com sucesso!')
    } catch (error) {
      console.error(error)
      notify('Erro ao registrar a nota fiscal', 'error')
    } finally {
      setLoading(false)
      setOpenModal(false)
      refresh()
    }
  }

  const recipientOptions = useMemo(() => {
    if (isAdmin(permissions)) {
      return [
        { value: 'clinic', label: 'Clínica' },
        { value: 'customer', label: 'Cliente' },
      ]
    }
    return [
      { value: 'admin', label: 'Vacinas.net' },
      { value: 'customer', label: 'Cliente' },
    ]
  }, [permissions])

  return (
    <div>
      {props.isMenuItem ? (
        <MenuItem
          onClick={() => setOpenModal(true)}
          data-testid={'add-invoice-button'}
        >
          Registrar nota fiscal
        </MenuItem>
      ) : (
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={() => setOpenModal(true)}
          data-testid={'add-invoice-button'}
          startIcon={<Add />}
        >
          Registrar nota fiscal
        </Button>
      )}

      <Dialog
        onKeyDown={stopPropagation}
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Typography
            style={{ marginBottom: mainTheme.spacing(2) }}
            variant="h2"
          >
            Registrar nota fiscal
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextField
              value={invoiceNumber}
              label="Número"
              onChange={(event) => {
                setInvoiceNumber(event.target.value)
              }}
              variant="outlined"
              size="small"
              inputProps={{
                'data-testid': 'invoice-number-input',
              }}
              fullWidth
              required
            />
            <TextField
              style={{
                marginLeft: mainTheme.spacing(1),
                marginRight: mainTheme.spacing(1),
              }}
              type="number"
              label="Valor"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={parseFloat(String(value)) / 100 || null}
              onChange={(event) =>
                setValue(parseFloat(event.target.value) * 100)
              }
              inputProps={{
                'data-testid': `monetary-input`,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              fullWidth
              required
            />
            <div>
              <FormControl
                variant="outlined"
                size="small"
                style={{ width: 130 }}
                required
              >
                <InputLabel id="type-label">Receptor</InputLabel>
                <Select
                  labelId="type-label"
                  value={recipient || null}
                  label="Receptor"
                  onChange={(event) => setRecipient(String(event.target.value))}
                  SelectDisplayProps={{
                    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                    //@ts-ignore
                    'data-testid': 'admin-recipient-input',
                  }}
                >
                  {recipientOptions.map((recipientOption) => (
                    <MenuItem
                      key={recipientOption.value}
                      value={recipientOption.value}
                    >
                      {recipientOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
          </div>
          <div>
            <TextField
              value={invoiceUrl}
              label="Url"
              style={{ marginTop: mainTheme.spacing(1) }}
              onChange={(event) => {
                setInvoiceUrl(event.target.value)
              }}
              variant="outlined"
              size="small"
              inputProps={{
                'data-testid': 'invoice-url-input',
              }}
              fullWidth
            />
          </div>

          <div>
            <Typography
              style={{
                marginBottom: mainTheme.spacing(1),
                marginTop: mainTheme.spacing(1),
              }}
              variant="h2"
            >
              Nota fiscal
            </Typography>
            <DropzoneArea
              acceptedFiles={['application/pdf', 'image/png', 'image/jpeg']}
              filesLimit={1}
              onChange={(files) => {
                setInvoice(files[0])
              }}
              dropzoneText="Arraste Aqui o arquivo ou clique para Selecionar"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ErrorOutline />}
            onClick={() => setOpenModal(false)}
            size="large"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<CheckCircleRounded />}
            onClick={() => {
              uploadInvoice()
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={
              loading || !invoiceNumber || !value || !invoice || !recipient
            }
            data-testid={`confirm-button`}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UploadInvoiceButton
