import React, { useEffect } from 'react'
import { InputAdornment } from '@material-ui/core'
import CustomAutocompleteInput from '../../../components/Inputs/CustomAutocompleteInput'
import { mainTheme } from '../../../utils/mainTheme'
import qs from 'querystring'
import { BooleanInput, NumberInput, useInput } from 'react-admin'
import CustomMultiAutocomplete from '../../../components/CustomMultiAutocomplete'
import {
  IClinicFreightValueNeighborhood,
  IFreightValueNeighborhood,
} from '@vacinas-net/shared'
import { useFormState } from 'react-final-form'
import { CityFullData } from './utils'

interface FreightValueCityProps {
  uf: string
  idx: number
  type: 'delivery' | 'pickup'
  readOnly?: boolean
}

const FreightValueCity = (props: FreightValueCityProps) => {
  const baseSource = `attendanceFreightValues.${props.type}.0.cities.${props.idx}`
  const state = useFormState()

  const freightValueRowInput = useInput({ source: baseSource })
  const neighborhoodsInput = useInput({ source: `${baseSource}.neighborhoods` })
  const city = freightValueRowInput.input.value?.fullData?.value

  const getForbiddenNeighborhoods = () => {
    const clinicFreightValueState =
      state.values.attendanceFreightValues[props.type][0]

    const neighborhoods: string[] = []

    clinicFreightValueState?.cities.forEach(
      (city: CityFullData, idx: number) => {
        if (idx === props.idx) return

        if (
          city?.fullData?.name !==
          freightValueRowInput.input.value?.fullData?.value
        )
          return

        const cityNeighborhoods =
          city.neighborhoods?.map(
            (neighborhood: IFreightValueNeighborhood) => neighborhood.name
          ) || []

        neighborhoods.push(...cityNeighborhoods)
      }
    )

    return neighborhoods
  }

  const getForbiddenCities = () => {
    const clinicFreightValueState =
      state.values.attendanceFreightValues[props.type][0]

    const cities: string[] = []

    clinicFreightValueState?.cities.forEach(
      (city: CityFullData, idx: number) => {
        if (idx === props.idx) return

        if (city?.fullCity) {
          cities.push(city?.fullData?.name)
        }
      }
    )

    return cities
  }

  // we must reset neighborhoods when the city changes
  useEffect(() => {
    const currentValue: IClinicFreightValueNeighborhood[] =
      neighborhoodsInput.input.value || []
    const newValue = currentValue.filter(
      (item) =>
        item.city?.name === freightValueRowInput.input.value?.fullData?.value
    )

    neighborhoodsInput.input.onChange(newValue)
  }, [freightValueRowInput.input.value?.fullData?.value])

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'row',
        gap: mainTheme.spacing(1),
      }}
    >
      <CustomAutocompleteInput
        source={`${baseSource}.fullData`}
        readOnly={props.readOnly || !props.uf}
        disabled={!props.uf}
        resource="city"
        inputLabel="Cidade Atendida"
        style={{
          width: 200,
          marginRight: mainTheme.spacing(2),
          marginTop: mainTheme.spacing(1),
        }}
        optionTypeLabel="name"
        optionTypeValue="name"
        additionalFilters={qs.stringify({
          uf: props.uf,
          'name!': getForbiddenCities(),
        })}
        passFullOptionData={true}
      />

      <CustomMultiAutocomplete
        currentValueArray={neighborhoodsInput.input.value || []}
        onChange={(value) => neighborhoodsInput.input.onChange(value)}
        optionTypeLabel="name"
        optionTypeValue="name"
        resource="neighborhood"
        defaultFilters={qs.stringify({
          uf: props.uf,
          'city.name': city,
          'name!': getForbiddenNeighborhoods(),
        })}
        style={{
          width: 500,
          marginRight: mainTheme.spacing(2),
          marginTop: mainTheme.spacing(1),
        }}
        size="small"
        label="Bairros Atendidos"
        readOnly={
          props.readOnly || !city || freightValueRowInput.input.value.fullCity
        }
        passFullOptionData={true}
      />

      <BooleanInput
        source={`${baseSource}.fullCity`}
        label="Aplicar taxa para a cidade"
        disabled={props.readOnly}
      />

      <NumberInput
        source={`${baseSource}.priceInCents`}
        label="Taxa de Atendimento"
        variant="outlined"
        size="small"
        InputProps={{
          startAdornment: <InputAdornment position="start">R$</InputAdornment>,
        }}
        required={true}
        disabled={props.readOnly}
        min={0}
      />
    </div>
  )
}

export default FreightValueCity
