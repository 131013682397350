import { Typography } from '@material-ui/core'
import { isAdmin } from '@vacinas-net/shared'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Record,
  FunctionField,
  DateField,
  usePermissions,
} from 'react-admin'
import NewPayoutButton from '../../../../components/Buttons/NewPayoutButton'
import PayoutStatusChip from '../../../../components/chips/PayoutStatusChip'
import DeletePayoutPopUp from '../../../../components/DeletePayoutPopUp'
import { showStyles } from '../../../../utils/showStyles'
import {
  parsePayoutDescription,
  parsePayoutType,
  valueInCentsToBRL,
} from '../../../../utils/utils'

const PayoutSection = (props: { record: Record | undefined }) => {
  const classes = showStyles()
  const { record } = props
  const { permissions } = usePermissions()
  const newPayoutButtonShouldBeActive = isAdmin(permissions)

  const notCanceledPayouts = record?.payouts.filter(
    (payout: { status: string }) => payout.status !== 'cancelled'
  )

  return (
    <div className={classes.cardContainer}>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <Typography className={classes.cardTitle} variant="h2">
          Repasses
        </Typography>
        {newPayoutButtonShouldBeActive && (
          <NewPayoutButton attendances={record?.attendances} />
        )}
      </div>

      <SimpleShowLayout
        className={classes.cardContent}
        record={{ id: 'overwritePayout', payouts: notCanceledPayouts }}
      >
        <ArrayField source="payouts" label="">
          <Datagrid>
            <DateField
              textAlign="center"
              source="payoutDate"
              label="Data de repasse"
              locales="pt-BR"
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                record?.isCreatedManually
                  ? record.description
                  : parsePayoutDescription(record)
              }
              label="Descrição"
              sortable={false}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                parsePayoutType(record?.type)
              }
              label="Tipo"
              sortable={false}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                valueInCentsToBRL(record?.payoutValueInCents)
              }
              label="Repasse Clínica"
              source="payoutValueInCents"
              sortable={false}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                valueInCentsToBRL(record?.holdValueInCents)
              }
              label="Valor Vacinas.net"
              source="holdValueInCents"
              sortable={false}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <TextField
              textAlign="center"
              source="orderItem.product.name"
              label="Produto"
              emptyText="-"
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <TextField
              textAlign="center"
              source="clinic.name"
              label="Clínica"
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) => (
                <PayoutStatusChip status={record?.status} />
              )}
              label="Status"
              source="status"
              sortable={false}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              label="Ações"
              render={(record: Record | undefined) =>
                isAdmin(permissions) && record?.status !== 'paid' ? (
                  <DeletePayoutPopUp record={record} />
                ) : (
                  '-'
                )
              }
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </div>
  )
}

export default PayoutSection
