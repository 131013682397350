import {
  FormControl,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { apiUrl } from '../../../components/AppDataProvider'
import { mainTheme } from '../../../utils/mainTheme'
import httpClient from '../../../components/CustomHttpClient'
import { useField, useFormState } from 'react-final-form'

const CustomPromotionsArrayInput = () => {
  const ITEM_HEIGHT = 48
  const ITEM_PADDING_TOP = 8
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  }
  const field = useField('promotions')
  const form = useFormState()
  const initialValue = form.values.promotions
    ? form.values.promotions.map(
        (promotion: { vendorId: string }) => promotion.vendorId
      )
    : []

  const [promotionsOptions, setPromotionsOptions] = useState<
    { vendorId: string; name: string }[]
  >([])

  const [promotions, setPromotions] = useState<string[]>(initialValue)

  const fetchPromotions = async () => {
    return httpClient(`${apiUrl}/campaign/external/promotions`, {
      method: 'get',
    }).then((res) => setPromotionsOptions(res.json))
  }

  useEffect(() => {
    fetchPromotions()
  }, [])

  useEffect(() => {
    if (
      initialValue &&
      JSON.stringify(initialValue) !== JSON.stringify(promotions)
    ) {
      setPromotions(initialValue)
    }
  }, [initialValue])

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleChange = (event: { target: { value: any } }) => {
    setPromotions(event.target.value)
    const parsedValues = event.target.value
      .map((promotionVendorId: string) =>
        promotionsOptions.find((item) => item.vendorId === promotionVendorId)
      )
      .filter((item: { vendorId: string; name: string } | undefined) => !!item)
    field.input.onChange(parsedValues)
  }
  return (
    <FormControl style={{ minWidth: 300, marginTop: mainTheme.spacing(1) }}>
      <InputLabel
        style={{
          transform: 'translate(10px, -6px) scale(0.75)',
          backgroundColor: '#fafafa',
          zIndex: 1,
          paddingLeft: mainTheme.spacing(1),
          paddingRight: mainTheme.spacing(1),
        }}
        id="promotion-label"
      >
        Promoções
      </InputLabel>
      <Select
        labelId="promotion-label"
        multiple
        value={promotions}
        onChange={handleChange}
        input={<OutlinedInput id="select-multiple-chip" />}
        variant="outlined"
        MenuProps={MenuProps}
      >
        {promotionsOptions.map((promotion) => (
          <MenuItem key={promotion.vendorId} value={promotion.vendorId}>
            {promotion.name}
          </MenuItem>
        ))}
      </Select>
    </FormControl>
  )
}

export default CustomPromotionsArrayInput
