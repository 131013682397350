import {
  Checkbox,
  FormControlLabel,
  InputAdornment,
  makeStyles,
  TextField,
  Paper,
} from '@material-ui/core'
import Pagination from '@material-ui/lab/Pagination'
import { Search } from '@material-ui/icons'
import { useDataProvider } from 'ra-core'
import * as React from 'react'
import { IClinicGroupedByClinicGroup } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import { SelectedClinic } from '.'

export interface SearchMenuProps {
  selectAllClinics: boolean
  setSelectAllClinics: (selectAllClinics: boolean) => void
  selectClinicGroup: (clinicGroup: IClinicGroupedByClinicGroup) => void
  selectClinic: (clinic: SelectedClinic) => void

  isClinicGroupChecked: (clinicGroup: IClinicGroupedByClinicGroup) => boolean
  isClinicChecked: (clinic: SelectedClinic) => boolean

  readOnly?: boolean
  disableSelectAll?: boolean
  filterByActiveClinics?: boolean
}

const useStyles = makeStyles({
  container: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
    justifyContent: 'flex-start',
    width: 280,
    paddingTop: mainTheme.spacing(2),
    paddingBottom: mainTheme.spacing(2),
    paddingLeft: mainTheme.spacing(3),
    paddingRight: mainTheme.spacing(3),
  },
  textField: {
    marginTop: mainTheme.spacing(1),
    marginBottom: mainTheme.spacing(2),
  },
  paginationContainer: {
    width: '100%',
    display: 'flex',
    justifyContent: 'flex-end',
    marginTop: mainTheme.spacing(2),
  },
  listContainer: {
    width: '100%',
  },
})

const SearchMenu = (props: SearchMenuProps) => {
  const [clinicGroupFilter, setClinicGroupFilter] = React.useState('')
  const [page, setPage] = React.useState(1)
  const [totalItens, setTotalItems] = React.useState(0)
  const perPage = 10
  const [searchedClinicsResult, setSearchedClinicsResult] = React.useState<
    IClinicGroupedByClinicGroup[]
  >([])
  const {
    selectAllClinics,
    selectClinic,
    selectClinicGroup,
    setSelectAllClinics,
    isClinicGroupChecked,
    isClinicChecked,
    readOnly,
  } = props

  const dataProvider = useDataProvider()
  const classes = useStyles()

  const fetchClinicGroupedByClinicGroup = async () => {
    return dataProvider
      .getList('clinic/grouped/clinic-group', {
        sort: { field: 'id', order: 'ASC' },
        filter: {
          status: props.filterByActiveClinics ? 'active' : undefined,
          'clinicGroup.name': clinicGroupFilter
            ? `%${clinicGroupFilter}%`
            : undefined,
        },
        pagination: { page, perPage },
      })
      .then((res) => {
        setTotalItems(res.total)
        setSearchedClinicsResult(res.data as IClinicGroupedByClinicGroup[])
      })
  }

  React.useEffect(() => {
    fetchClinicGroupedByClinicGroup()
  }, [clinicGroupFilter, page])

  return (
    <Paper elevation={6} className={classes.container}>
      {!props.disableSelectAll && (
        <FormControlLabel
          control={
            <Checkbox
              checked={selectAllClinics}
              onChange={() => setSelectAllClinics(!selectAllClinics)}
              color="primary"
              disabled={readOnly}
              data-testid={'checkbox-select-all-clinics'}
            />
          }
          label="Selecionar todos"
        />
      )}
      <TextField
        value={clinicGroupFilter}
        onChange={(ev) => {
          setPage(1)
          setClinicGroupFilter(ev.target.value)
        }}
        label="Buscar por nome"
        variant="outlined"
        size="small"
        className={classes.textField}
        data-testid={'input-search-clinic-group'}
        InputProps={{
          endAdornment: (
            <InputAdornment position="end">
              <Search />
            </InputAdornment>
          ),
        }}
      />
      <div
        className={classes.listContainer}
        data-testid={'container-clinic-list'}
      >
        {searchedClinicsResult.map((clinicGroup) => {
          return (
            <div key={clinicGroup._id}>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={isClinicGroupChecked(clinicGroup)}
                    onChange={() => selectClinicGroup(clinicGroup)}
                    color="primary"
                    disabled={readOnly}
                    data-testid={`checkbox-clinic-group-${clinicGroup._id}`}
                  />
                }
                label={
                  clinicGroup &&
                  clinicGroup.clinics &&
                  clinicGroup.clinics[0].clinicGroup?.name
                }
              />
              {clinicGroup.clinics?.map((clinic) => {
                return (
                  <div
                    key={clinic._id}
                    style={{ marginLeft: mainTheme.spacing(3) }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={isClinicChecked(clinic)}
                          onChange={() => selectClinic(clinic)}
                          color="primary"
                          disabled={readOnly}
                          data-testid={`checkbox-clinic-${clinic._id}`}
                        />
                      }
                      label={clinic.name}
                    />
                  </div>
                )
              })}
            </div>
          )
        })}
      </div>
      <div className={classes.paginationContainer}>
        <Pagination
          page={page}
          count={Math.ceil(totalItens / perPage)}
          onChange={(event, value) => setPage(value)}
          color="primary"
          shape="rounded"
          data-testid={'pagination-clinic-list'}
        />
      </div>
    </Paper>
  )
}

export default SearchMenu
