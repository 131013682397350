import { Button, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { Confirm, useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'

const ClinicConditionAcceptButton = (props: { conditionId: string }) => {
  const { conditionId } = props
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleConfirm = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/clinic-condition/${conditionId}/accept`, {
      method: 'post',
    })
      .then(() => {
        notify(`Condição confirmada com sucesso`, 'success')
      })
      .catch((error) => {
        notify(`Erro ao confirmar condição: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
        refresh()
      })
  }
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={(e) => {
          e.stopPropagation()
          setOpenConfirm(true)
        }}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          'Confirmar condição'
        )}
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está confirmando a condição"
        content="Deseja confirmar essa ação?"
        onConfirm={handleConfirm}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </>
  )
}

export default ClinicConditionAcceptButton
