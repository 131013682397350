const format = (cnpj?: string) => {
  if (!cnpj) return cnpj
  let onlyNumberValues = cnpj.match(/\d+/g)?.join('') || ''
  if (onlyNumberValues.length === 0) return ''
  if (onlyNumberValues.length > 14)
    onlyNumberValues = onlyNumberValues?.slice(0, 14)
  if (onlyNumberValues.length === 14)
    return onlyNumberValues
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?(\d{4})?(\d{2})?/, '$1.$2.$3/$4-$5')
  return onlyNumberValues
}
const parse = (cnpj?: string) => {
  if (!cnpj) return cnpj
  return cnpj.match(/\d+/g)?.join('').slice(0, 14)
}

export const cnpjFormatter = {
  format,
  parse,
}
