import { FirebaseAuthProvider, RAFirebaseOptions } from 'react-admin-firebase'

export const firebaseConfig = {
  apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
  authDomain: process.env.REACT_APP_FIREBASE_AUTH_DOMAIN,
  projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
  storageBucket: process.env.REACT_APP_FIREBASE_STORAGE_BUCKET,
  messagingSenderId: process.env.REACT_APP_FIREBASE_MESAGING_SENDER_ID,
  appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const options: RAFirebaseOptions = {
  logging: process.env.REACT_APP_NODE_ENV !== 'production',
}

export const firebaseProvider = FirebaseAuthProvider(firebaseConfig, options)
