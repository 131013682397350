import * as React from 'react'
import _ from 'lodash'
import { SelectInput } from 'react-admin'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { isAdmin } from '@vacinas-net/shared'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const generateAttendanceListFilters = (props: { permissions: any }) => {
  const attendanceListFiltersArray: {
    value: string
    label: string
    FilterInput: JSX.Element
  }[] = [
    {
      value: 'customer->name',
      label: 'Cliente',
      FilterInput: (
        <SearchFilterInput
          label="Cliente"
          source="customer->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->name',
      label: 'Clínica',
      FilterInput: (
        <SearchFilterInput
          label="Clínica"
          source="clinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'aliasId',
      label: 'Número do atendimento',
      FilterInput: (
        <SearchFilterInput
          label="Número do atendimento"
          source="aliasId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          type="number"
        />
      ),
    },
    {
      value: 'customer->document',
      label: 'CPF',
      FilterInput: (
        <SearchFilterInput
          label="CPF"
          source="customer->document"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'type',
      label: 'Tipo',
      FilterInput: (
        <SelectInput
          source="type"
          label="Tipo"
          choices={[
            { id: 'delivery', name: 'Em casa' },
            { id: 'pickup', name: 'Na clínica' },
            { id: 'company', name: 'Na empresa' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'deliveryWindow->startDate(min)',
      label: 'Data de início',
      FilterInput: (
        <CustomDateInput
          label="Data de início"
          source="deliveryWindow->startDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'deliveryWindow->endDate(max)',
      label: 'Data de fim',
      FilterInput: (
        <CustomDateInput
          label="Data de fim"
          source="deliveryWindow->endDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'status',
      label: 'Situação',
      FilterInput: (
        <SelectInput
          source="status"
          label="Situação"
          choices={[
            {
              id: isAdmin(props.permissions) ? 'under_analysis' : '',
              name: isAdmin(props.permissions) ? 'Em análise' : '',
            },
            { id: 'finished', name: 'Finalizado' },
            {
              id: 'awaiting_clinic_confirmation',
              name: 'Pendente confirmação clínica',
            },
            {
              id: 'accepted',
              name: 'Pronto para aplicação',
            },
            {
              id: 'rejected',
              name: 'Rejeitado',
            },
            {
              id: 'cancelled',
              name: 'Cancelado',
            },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'finishedAt(min)',
      label: 'Finalizado depois de',
      FilterInput: (
        <CustomDateInput
          label="Finalizado depois de"
          source="finishedAt(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'finishedAt(max)',
      label: 'Finalizado antes de',
      FilterInput: (
        <CustomDateInput
          label="Finalizado antes de"
          source="finishedAt(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
  ]

  if (isAdmin(props.permissions)) {
    attendanceListFiltersArray.push({
      label: 'Número do pedido',
      value: 'vendorOrderId',
      FilterInput: (
        <SearchFilterInput
          label="Número do pedido"
          source="vendorOrderId"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
          }}
        />
      ),
    })
  }

  return _.sortBy(attendanceListFiltersArray, 'label')
}
