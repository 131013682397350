import { makeStyles } from '@material-ui/core'
import { mainTheme } from './mainTheme'

const useStyles = makeStyles({
  header: {
    marginTop: mainTheme.spacing(2),
    marginBottom: mainTheme.spacing(2),
  },
  tabContainer: {
    display: 'flex',
    flexDirection: 'column',
  },
  input: {
    maxWidth: 200,
  },
  inputWMagin: {
    maxWidth: 200,
    marginRight: mainTheme.spacing(2),
  },
  customLargerInput: {
    maxWidth: 300,
    marginRight: mainTheme.spacing(2),
  },
})

export default useStyles
