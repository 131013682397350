import React, { ReactElement, useMemo } from 'react'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import CircularProgress from '@material-ui/core/CircularProgress'
import { GetListParams, useDataProvider } from 'react-admin'
import _ from 'lodash'
import qs from 'querystring'

interface CustomAutocompleteProps {
  resource: string
  onChange: (value: OptionType | null) => void
  currentValue: OptionType | null
  label?: string
  style?: React.CSSProperties
  size?: 'small' | 'medium'
  className?: string
  optionTypeLabel?: string
  optionTypeValue?: string
  readOnly?: boolean
  dataTestId?: string
  required?: boolean
  searchTerm?: boolean
  filterByActiveClinics?: boolean

  additionalFilters?: string
  disabled?: boolean
  passFullOptionData?: boolean
}

export interface OptionType {
  label: string
  value: string
}

const CustomAutocomplete = (props: CustomAutocompleteProps): ReactElement => {
  const [open, setOpen] = React.useState(false)
  const [options, setOptions] = React.useState<OptionType[]>([])
  const [loading, setLoading] = React.useState(false)
  const [filter, setFilter] = React.useState('')

  const dataProvider = useDataProvider()

  const optionTypeLabel = props.optionTypeLabel || 'name'
  const optionTypeValue = props.optionTypeValue || '_id'

  React.useEffect(() => {
    fetchData()
  }, [
    filter,
    props.currentValue === null,
    props.additionalFilters,
    !props.disabled,
  ])

  React.useEffect(() => {
    setFilter('')
  }, [!props.currentValue])

  const debounceFilter = useMemo(
    () =>
      _.debounce((filter: string) => {
        setFilter(filter)
      }, 500),
    []
  )

  const fetchData = async () => {
    if (props.disabled) return

    try {
      setLoading(true)
      const additionalFilters = props.additionalFilters
        ? qs.parse(props.additionalFilters)
        : {}

      const params: GetListParams = {
        filter: (filter ||
          props.filterByActiveClinics ||
          props.additionalFilters) && {
          [props.searchTerm ? 'searchTerm' : optionTypeLabel]: props.searchTerm
            ? filter
            : `%${filter}%`,
          status: props.filterByActiveClinics ? 'active' : undefined,
          ...additionalFilters,
        },
        pagination: {
          page: 1,
          perPage: 30,
        },
        sort: {
          field: optionTypeLabel,
          order: 'asc',
        },
      }
      const response = await dataProvider.getList(props.resource, params)
      const data = response.data
      const formattedOptions = data.map((item) => ({
        ...(props.passFullOptionData ? item : {}),
        value: item[optionTypeValue],
        label: item[optionTypeLabel],
      }))
      setOptions(formattedOptions)
    } catch (error) {
      console.error(
        '[Error] - Error to fetch autocomplete for resource ' + props.resource,
        error
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <Autocomplete
      data-testid={props.dataTestId || 'input-autocomplete'}
      style={props.style ? props.style : { width: '100%' }}
      className={props.className}
      size={props.size ?? 'medium'}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label || ''}
      options={options}
      loading={loading}
      onChange={(e, value) => props.onChange(value)}
      value={props.currentValue}
      disabled={props.readOnly}
      filterOptions={(options) => {
        return options
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          className={props.className}
          size="small"
          label={props.label}
          variant="outlined"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            debounceFilter(e.target.value)
          }
          required={props.required}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
    />
  )
}

export default CustomAutocomplete
