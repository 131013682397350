import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { PayoutPerClinicListFiltersArray } from './Filters'

const PayoutPerClinicActions = () => (
  <CustomListActions
    filtersAvailable={PayoutPerClinicListFiltersArray.map((item) => ({
      label: item.label,
      value: item.value,
    }))}
    hasExport
  />
)

export default PayoutPerClinicActions
