import { makeStyles } from '@material-ui/core'
import * as React from 'react'
import { useRef } from 'react'
import {
  Record,
  Show,
  ShowProps,
  TopToolbar,
  useShowController,
} from 'react-admin'
import ClinicConditionStatusChip from '../../../components/chips/ClinicConditionStatusChip'
import ClinicConditionAcceptButton from '../../../components/ClinicConditions/ClinicConditionAcceptButton'
import ClinicConditionRejectButton from '../../../components/ClinicConditions/ClinicConditionRejectButton'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { mainTheme } from '../../../utils/mainTheme'
import showStyles from '../../../utils/showStyles'
import CommercialConditionSection from './CommercialConditionSection'
import DataSection from './DataSection'
import ProductsSection from './ProductsSection'

const parseClinicConditionHeaderText = (record: Record | undefined) => {
  return `${record?.commercialCondition.name || 'Condição comercial'}`
}

const useStyles = makeStyles({
  clinicConditionActionContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: mainTheme.spacing(1),
  },
})

const ClinicConditionShowActions = (props: {
  record: Record | undefined
  componentRef: React.MutableRefObject<null>
}) => {
  const classes = useStyles()
  return (
    <TopToolbar className={classes.clinicConditionActionContainer}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 'auto',
          width: '100%',
        }}
      >
        <HeaderWithGoBack
          title={parseClinicConditionHeaderText(props.record)}
          linkTo={`/clinic-condition?commercialConditionId=${props?.record?.commercialCondition._id}`}
        />
        <div style={{ marginLeft: mainTheme.spacing(2) }}>
          <ClinicConditionStatusChip status={props.record?.status} />
        </div>
        {props.record?.status === 'awaiting' && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
              marginLeft: 'auto',
            }}
          >
            <div style={{ marginRight: mainTheme.spacing(1) }}>
              <ClinicConditionAcceptButton conditionId={props.record?._id} />
            </div>
            <div>
              <ClinicConditionRejectButton conditionId={props.record?._id} />
            </div>
          </div>
        )}
      </div>
    </TopToolbar>
  )
}

const ClinicConditionShow = (props: ShowProps) => {
  const componentRef = useRef(null)
  const classes = showStyles()
  const { record, loaded } = useShowController(props)
  if (!loaded) {
    return <></>
  }
  return (
    <div ref={componentRef}>
      <Show
        actions={
          <ClinicConditionShowActions
            record={record}
            componentRef={componentRef}
          />
        }
        component="div"
        {...props}
      >
        <div className={classes.cardContainer}>
          <DataSection />
          <ProductsSection />
          <CommercialConditionSection />
        </div>
      </Show>
    </div>
  )
}

export default ClinicConditionShow
