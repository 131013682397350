import React from 'react'

import { FormControlLabel, Checkbox, Button } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import { Add } from '@material-ui/icons'
import { useInput } from 'react-admin'
import { OpeningHours } from '@vacinas-net/shared'
import { useFormState } from 'react-final-form'
import OpeningHourRow from './OpeningHourRow'
import { Weekdays, OpeningHourItem } from './types'

interface WeekdayGroupProps {
  addShift: (weekday: number) => void
  deleteShift: (shiftIndex: number) => void
  weekday: OpeningHours['weekday']
  inputBaseSource: string
  attendanceTypeEnabledSource: string
}

const WeekdayGroup = (props: WeekdayGroupProps) => {
  const enableWeekdayInput = useInput({
    source: `weekdayOpeningHours.${props.inputBaseSource}.weekday-${props.weekday}`,
    value: false,
  })
  const { values } = useFormState()
  const list =
    values && values[props.inputBaseSource] ? values[props.inputBaseSource] : []

  const openingHours: OpeningHourItem[] = list
    ?.map((shift: Partial<OpeningHours>, globalIndex: number) => ({
      ...shift,
      globalIndex,
    }))
    .filter(
      (openingHour: OpeningHourItem) => openingHour.weekday === props.weekday
    )
    .map((shift: Partial<OpeningHours>, shiftIndex: number) => ({
      ...shift,
      shiftIndex,
    }))

  return (
    <div style={{ display: 'flex', alignItems: 'flex-start' }}>
      <FormControlLabel
        style={{ width: 150 }}
        control={
          <Checkbox
            disabled={!values[props.attendanceTypeEnabledSource]}
            checked={enableWeekdayInput.input.value}
            onChange={() =>
              enableWeekdayInput.input.onChange(!enableWeekdayInput.input.value)
            }
          />
        }
        label={Weekdays[props.weekday]}
      />
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            gap: mainTheme.spacing(2),
          }}
        >
          {openingHours?.map((openingHour, shiftIndex) => {
            return (
              <OpeningHourRow
                disabled={
                  !enableWeekdayInput.input.value ||
                  !values[props.attendanceTypeEnabledSource]
                }
                key={shiftIndex}
                deleteShift={(index) => props.deleteShift(index)}
                openingHour={openingHour}
                inputBaseSource={props.inputBaseSource}
              />
            )
          })}
        </div>

        <Button
          onClick={() => props.addShift(props.weekday)}
          size="medium"
          color="primary"
          disabled={
            !enableWeekdayInput.input.value ||
            !values[props.attendanceTypeEnabledSource]
          }
          startIcon={<Add />}
          variant="text"
        >
          Adicionar turno
        </Button>
      </div>
    </div>
  )
}

export default WeekdayGroup
