import { ReportTypeEnum } from '@vacinas-net/shared'
import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { ProductsListFiltersArray } from './Filters'

const ProductsListActions = () => (
  <CustomListActions
    filtersAvailable={ProductsListFiltersArray.map((item) => ({
      label: item.label,
      value: item.value,
    }))}
    hasExport
    exportType={ReportTypeEnum.ProductStock}
    exportButtonText="Exportar Estoques"
  />
)

export default ProductsListActions
