import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

interface ChipProps {
  statusText: string
  statusColor: string
}

const AttendanceStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  const defaultShipProps = {
    statusText: 'Indefinido',
    statusColor: 'default',
  }

  const statusChipsProps: Record<string, ChipProps> = {
    finished: {
      statusText: 'Finalizado',
      statusColor: mainTheme.palette.secondary.main,
    },
    accepted: {
      statusText: 'Pronto para aplicação',
      statusColor: mainTheme.palette.success.dark,
    },
    rejected: {
      statusText: 'Rejeitado',
      statusColor: mainTheme.palette.error.main,
    },
    cancelled: {
      statusText: 'Cancelado',
      statusColor: mainTheme.palette.error.dark,
    },
    relocated: {
      statusText: 'Realocado',
      statusColor: mainTheme.palette.error.light,
    },
    awaiting_clinic_confirmation: {
      statusText: 'Pendente confirmação clínica',
      statusColor: mainTheme.palette.warning.main,
    },
    under_analysis: {
      statusText: 'Em análise',
      statusColor: '#bdb02d',
    },
  }

  if (!props.status || !statusChipsProps[props.status]) {
    statusText = defaultShipProps.statusText
    statusColor = defaultShipProps.statusColor
  } else {
    statusText = statusChipsProps[props.status].statusText
    statusColor = statusChipsProps[props.status].statusColor
  }

  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default AttendanceStatusChip
