import React, { useContext, useState } from 'react'
import { Typography, IconButton, TextField } from '@material-ui/core'
import { valueInCentsToBRL } from '../../utils/utils'
import { Check, Close, Edit, Replay } from '@material-ui/icons'
import { mainTheme } from '../../utils/mainTheme'
import { CartProduct, OrderCreateContext } from '../Contexts/OrderCreateContext'
import { InputAdornment } from '@mui/material'

const EditDiscountInput = (props: { product: CartProduct }) => {
  const orderCreateContext = useContext(OrderCreateContext)
  const { editItemDiscountedPrince } = orderCreateContext!
  const { product } = props

  const [editMode, setEditMode] = useState(false)
  const [inputValue, setInputValue] = useState(
    String(product.discountedPriceInCents / 100)
  )

  const showEditPriceButton = () => {
    return !editMode && product.priceInCents === product.discountedPriceInCents
  }

  const showResetDiscountValue = () => {
    return !editMode && product.priceInCents !== product.discountedPriceInCents
  }
  return (
    <div
      style={{
        width: 200,
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
      }}
    >
      {editMode ? (
        <TextField
          style={{ width: 135 }}
          type="number"
          variant="outlined"
          size="small"
          value={inputValue}
          onChange={(ev) => {
            setInputValue(ev.target.value)
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
          inputProps={{
            'data-testid': `edit-product-value-input-${product.skuId}`,
          }}
        />
      ) : (
        <Typography
          data-testid={`discounted-value-${product.skuId}`}
          align="center"
          variant="body1"
        >
          {valueInCentsToBRL(product.discountedPriceInCents)}
        </Typography>
      )}
      {showEditPriceButton() && (
        <IconButton
          style={{
            marginLeft: mainTheme.spacing(1),
          }}
          size="small"
          onClick={() => {
            setEditMode(true)
          }}
          data-testid={`edit-product-value-button-${product.skuId}`}
        >
          <Edit />
        </IconButton>
      )}
      {showResetDiscountValue() && (
        <IconButton
          style={{
            marginLeft: mainTheme.spacing(1),
          }}
          size="small"
          onClick={() => {
            editItemDiscountedPrince(product.skuId, product.priceInCents)
            setInputValue(String(product.priceInCents / 100))
          }}
        >
          <Replay />
        </IconButton>
      )}
      {editMode && (
        <IconButton
          style={{
            marginLeft: mainTheme.spacing(1),
          }}
          size="small"
          onClick={() => {
            editItemDiscountedPrince(
              product.skuId,
              parseFloat(inputValue) * 100
            )
            setEditMode(false)
          }}
          data-testid={`confirm-product-value-button-${product.skuId}`}
        >
          <Check />
        </IconButton>
      )}
      {editMode && (
        <IconButton
          style={{
            marginLeft: mainTheme.spacing(1),
          }}
          size="small"
          onClick={() => {
            setInputValue(String(product.discountedPriceInCents / 100))
            setEditMode(false)
          }}
        >
          <Close />
        </IconButton>
      )}
    </div>
  )
}

export default EditDiscountInput
