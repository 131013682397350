import React, { FC, useState } from 'react'
import { FormGroup, Switch, Tooltip } from '@material-ui/core'
import WarningIcon from '@mui/icons-material/Warning'
import { mainTheme } from '../../../utils/mainTheme'
import { Confirm } from 'react-admin'

interface ToogleFieldProps {
  loading: boolean
  checked: boolean
  protected: boolean
  onCheckChange: (checked: boolean) => void
  Content: FC
  protectedWarningMessage: string
}

const ToogleField = (props: ToogleFieldProps) => {
  const Content = props.Content
  const [isDialogOpen, setIsDialogOpen] = useState(false)

  const onCheck = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.stopPropagation()
    if (!props.protected || !props.checked)
      return props.onCheckChange(!props.checked)
    setIsDialogOpen(true)
  }

  return (
    <>
      <Confirm
        isOpen={isDialogOpen}
        onClose={() => setIsDialogOpen(false)}
        content={<Content />}
        title={'Confirmar ação?'}
        onConfirm={() => {
          props.onCheckChange(false)
          setIsDialogOpen(false)
        }}
        confirm="Confirmar"
        cancel="Cancelar"
      />
      <FormGroup
        style={{
          alignItems: 'center',
          display: 'flex',
          flexDirection: 'row',
          gap: mainTheme.spacing(1),
          justifyContent: 'center',
        }}
      >
        <Switch
          disabled={props.loading}
          checked={props.checked}
          onClick={(e) => onCheck(e)}
        />

        {props.protected && !props.checked && (
          <Tooltip title={props.protectedWarningMessage}>
            <WarningIcon style={{ color: mainTheme.palette.error.main }} />
          </Tooltip>
        )}
      </FormGroup>
    </>
  )
}

export default ToogleField
