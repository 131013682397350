import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const JointPurchaseStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'open':
      statusText = 'Aberta'
      statusColor = mainTheme.palette.secondary.main
      break
    case 'cancelled':
      statusText = 'Cancelada'
      statusColor = mainTheme.palette.error.main
      break
    case 'finished':
      statusText = 'Finalizada'
      statusColor = mainTheme.palette.primary.main
      break
    case 'future':
      statusText = 'Futura'
      statusColor = mainTheme.palette.secondary.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default JointPurchaseStatusChip
