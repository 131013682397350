import MultiAutocompleteInput from '../../../components/Inputs/MultiAutocompleteInput/MultiAutocompleteInput'
import * as React from 'react'
import { cnpjFormatter } from '../../../utils/formatters'
import _ from 'lodash'
import { DateInput, SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'

export const PayoutSummarizedListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'aliasId',
      label: 'Número do atendimento',
      FilterInput: (
        <SearchFilterInput
          label="Número do atendimento"
          source="aliasId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          type="number"
        />
      ),
    },
    {
      value: 'payoutDate(min)',
      label: 'Data de início (Repasse)',
      FilterInput: (
        <DateInput
          label="Data de início (Repasse)"
          source="payoutDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'payoutDate(max)',
      label: 'Data de fim (Repasse)',
      FilterInput: (
        <DateInput
          label="Data de fim (Repasse)"
          source="payoutDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'attendanceFinishedAt(min)',
      label: 'Data de início (Atendimento)',
      FilterInput: (
        <DateInput
          label="Data de início (Atendimento)"
          source="attendanceFinishedAt(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'attendanceFinishedAt(max)',
      label: 'Data de fim (Atendimento)',
      FilterInput: (
        <DateInput
          label="Data de fim (Atendimento)"
          source="attendanceFinishedAt(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->name',
      label: 'Clínica',
      FilterInput: (
        <SearchFilterInput
          label="Clínica"
          source="clinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'status',
      label: 'Situação',
      FilterInput: (
        <SelectInput
          source="status"
          label="Situação"
          choices={[
            { id: 'processing', name: 'Em Processamento' },
            { id: 'readyToPay', name: 'Pronto para Repasse' },
            { id: 'paid', name: 'Repasse Realizado' },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->_id',
      label: 'CNPJ da Clínica',
      FilterInput: (
        <MultiAutocompleteInput
          resourceToFetch="clinic"
          source="clinic->_id"
          label="CNPJ da Clínica"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
            minWidth: 250,
            marginTop: 8,
            paddingBottom: 8,
          }}
          optionTypeLabel="cnpj"
          parseFilterToRequest={(filter: string) => {
            return filter.replace(/\D/g, '')
          }}
          parseLabel={(itemToParse: string) => {
            return String(cnpjFormatter.format(itemToParse))
          }}
        />
      ),
    },
  ],
  'label'
)
