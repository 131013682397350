import * as React from 'react'
import { Filter, FilterProps, usePermissions } from 'react-admin'
import { generateAttendanceListFilters } from './Filters'

const AttendanceListFilters: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  const { permissions } = usePermissions()

  return (
    <Filter {...props}>
      {generateAttendanceListFilters({ permissions }).map(
        (item) => item.FilterInput
      )}
    </Filter>
  )
}

export default AttendanceListFilters
