import * as React from 'react'
import { FilterProps, SelectInput, DateInput, Filter } from 'react-admin'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { useStyles } from '../../../hooks/CustomListFilter'

const CampaignOrdersFilter: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <SearchFilterInput
        label="Nome do cliente"
        source="customer->name"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SearchFilterInput
        label="Cpf do cliente"
        source="customer->document"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SearchFilterInput
        label="Nome da Clínica"
        source="relatedClinics->name"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SearchFilterInput
        label="Ponto de retirada"
        source="packages->pickupPointName"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SearchFilterInput
        label="Número do pedido"
        source="orderId"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SearchFilterInput
        label="Código do cupom"
        source="marketingData->couponCode"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="status"
        label="Situação"
        choices={[
          {
            id: 'awaiting_clinic_confirmation',
            name: 'Aguardando resposta clínica',
          },
          { id: 'readyForHandling', name: 'Aguardando atendimentos' },
          { id: 'finished', name: 'Finalizado' },
        ]}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="payments->method"
        label="Meio de pagamento"
        choices={[
          { id: 'creditCard', name: 'Cartão de crédito' },
          { id: 'voucher', name: 'Voucher' },
          { id: 'bankPaymentSlip', name: 'Boleto' },
          { id: 'unknow', name: 'Indefinido' },
        ]}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
        style={{ minWidth: 220 }}
      />
      <SelectInput
        source="shippingAddress->type"
        label="Tipo de atendimento"
        choices={[
          { id: 'delivery', name: 'Em casa' },
          { id: 'pickup', name: 'Na clínica' },
          { id: 'company', name: 'Na empresa' },
        ]}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
        style={{ minWidth: 220 }}
      />
      <DateInput
        label="Comprado depois de"
        source="purchasedAt(min)"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <DateInput
        label="Comprado antes de"
        source="purchasedAt(max)"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <DateInput
        label="Finalizado depois de"
        source="finishedAt(min)"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <DateInput
        label="Finalizado antes de"
        source="finishedAt(max)"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
    </Filter>
  )
}

export default CampaignOrdersFilter
