import React, { useEffect, useRef, useState } from 'react'
import { Grid, Button, CircularProgress } from '@material-ui/core'
import { IAvailableAttendance, ICampaignV2 } from '@vacinas-net/shared'
import { useDataProvider, useEditContext, useNotify } from 'react-admin'
import _ from 'lodash'
import { mainTheme } from '../../utils/mainTheme'
import { Save } from '@material-ui/icons'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../CustomHttpClient'
import EventDataSession from './EventDataSession'
import AddressDataSession from './AddressDataSession'
import VaccineDataSession from './VaccineDataSession'
import PaymentSession from './PaymentSession'
import { parseAvailableAttendanceData, parseInitialData } from './utils'
import { useFormik } from 'formik'
import { AvailableAttendanceFormData } from './utils'
import WarningMessage from './WarningMessage'
import * as yup from 'yup'

const schema = yup.object().shape({
  selectedClinicId: yup.string().required('Campo obrigatório'),
  clinicConditionId: yup.string().required('Campo obrigatório'),
  shotDate: yup.string().required('Campo obrigatório'),
  startTime: yup.string().required('Campo obrigatório'),
  endTime: yup.string().required('Campo obrigatório'),
  address: yup.object().shape({
    name: yup.string().required('Campo obrigatório'),
    zipCode: yup.string().required('Campo obrigatório'),
    city: yup.string().required('Campo obrigatório'),
    complement: yup.string(),
    neighborhood: yup.string().required('Campo obrigatório'),
    state: yup.string().required('Campo obrigatório'),
    streetName: yup.string().required('Campo obrigatório'),
    streetNumber: yup.string().required('Campo obrigatório'),
  }),
  expirationDate: yup.string(),
  batchNumber: yup.string().required('Campo obrigatório'),
  dose: yup.string(),
  vaccine: yup.object().shape({
    label: yup.string().required('Campo obrigatório'),
    value: yup.string().required('Campo obrigatório'),
  }),
})

const CreateAvailableAttendanceForm = (props: {
  initialData?: IAvailableAttendance
  setOpen: (state: boolean) => void
  isEdit?: boolean
}) => {
  const { initialData, isEdit } = props
  const editContext = useEditContext()
  const campaginData = editContext.record as Partial<ICampaignV2>
  const [showWarning, setShowWarning] = useState(false)
  const [loading, setLoading] = useState(false)
  const dataProvider = useDataProvider()
  const initialRender = useRef(true)

  const createAvailableAttendance = async (
    newAvailableAttendance: Partial<IAvailableAttendance> & {
      campaignId?: string | undefined
    }
  ) => {
    setLoading(true)
    await httpClient(`${apiUrl}/campaign/available-attendance`, {
      method: 'post',
      body: JSON.stringify(newAvailableAttendance),
    })
      .then(() => {
        props.setOpen(false)
        notify('Formulário criado com sucesso')
        if (editContext.refetch) editContext?.refetch()
      })
      .catch((error) => {
        notify(`Erro ao criar formulário: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const editAvailableAttendance = async (
    newAvailableAttendance: Partial<IAvailableAttendance> & {
      campaignId?: string | undefined
    }
  ) => {
    setLoading(true)
    await httpClient(
      `${apiUrl}/campaign/available-attendance/${initialData?._id}`,
      {
        method: 'put',
        body: JSON.stringify(newAvailableAttendance),
      }
    )
      .then(() => {
        props.setOpen(false)
        notify('Formulário atualizado com sucesso')
        if (editContext.refetch) editContext?.refetch()
      })
      .catch((error) => {
        notify(`Erro ao atualizar formulário: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const shouldShowEditConfirm = async () => {
    const response = await dataProvider.getList('order', {
      pagination: {
        page: 1,
        perPage: 1,
      },
      sort: {
        field: 'availableAttendanceId',
        order: 'asc',
      },
      filter: {
        availableAttendanceId: initialData?._id,
      },
    })

    return response.data.length > 0
  }

  const submit = async () => {
    const newAvailableAttendance = parseAvailableAttendanceData(
      formData!,
      campaginData._id!,
      isEdit
    )

    if (!isEdit) return await createAvailableAttendance(newAvailableAttendance)
    const showMessage = await shouldShowEditConfirm()
    if (showMessage) return setShowWarning(true)

    return editAvailableAttendance(newAvailableAttendance)
  }

  const { values, setFieldValue, handleSubmit, errors, setErrors } =
    useFormik<AvailableAttendanceFormData>({
      initialValues: parseInitialData(initialData),
      onSubmit: submit,
      validationSchema: schema,
      validateOnChange: false,
    })

  const formData = values

  const setFormData = (field: string, value: unknown) => {
    setFieldValue(field, value)
  }

  const notify = useNotify()

  useEffect(() => {
    if (!formData?.selectedClinicId) return

    const commercialConditions = campaginData.fullCommercialConditions
      ?.map((condition) =>
        condition.clinicConditions.map((clinicCondition) => clinicCondition)
      )
      .flat()
      .filter((item) => item.clinic._id === formData.selectedClinicId)

    setFieldValue('availableClinicConditions', commercialConditions)

    if (initialRender.current) {
      initialRender.current = false
    } else {
      setFormData('clinicConditionId', undefined)
    }
  }, [formData?.selectedClinicId])

  useEffect(() => {
    setFieldValue(
      'availableClinics',
      _.uniqBy(
        campaginData.fullCommercialConditions
          ?.map((condition) =>
            condition.clinicConditions.map(
              (clinicCondition) => clinicCondition.clinic
            )
          )
          .flat(),
        '_id'
      )
    )
  }, [])

  if (showWarning) {
    return (
      <WarningMessage
        editAvailableAttendance={editAvailableAttendance}
        availableAttendanceId={String(initialData?._id)}
        campaignId={campaginData._id!}
        setOpen={props.setOpen}
        formData={formData}
        loading={loading}
      />
    )
  }

  return (
    <form onSubmit={handleSubmit}>
      <Grid container xs={12}>
        <EventDataSession
          setErrors={setErrors}
          errors={errors}
          setFormData={setFormData}
          formData={formData}
        />
        <AddressDataSession
          setErrors={setErrors}
          errors={errors}
          setFormData={setFormData}
          formData={formData}
        />
        <PaymentSession />
        <VaccineDataSession
          setErrors={setErrors}
          errors={errors}
          setFormData={setFormData}
          formData={formData}
        />
        <Grid
          style={{
            marginTop: mainTheme.spacing(4),
            marginBottom: mainTheme.spacing(2),
          }}
          container
          spacing={2}
          xs={12}
        >
          <Button
            style={{ marginLeft: 'auto', width: 170 }}
            size="medium"
            variant="contained"
            type="submit"
            color="primary"
            startIcon={<Save />}
          >
            {loading ? (
              <CircularProgress color="inherit" size={20} />
            ) : (
              'Salvar formulário'
            )}
          </Button>
        </Grid>
      </Grid>
    </form>
  )
}

export default CreateAvailableAttendanceForm
