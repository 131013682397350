import * as React from 'react'
import { Filter, FilterProps, useRecordContext } from 'react-admin'
import { ICampaignV2 } from '@vacinas-net/shared'
import { generateAllowedCustomerFiltersArray } from './Filters'

const AllowedCustomerFilters: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  const record = useRecordContext() as ICampaignV2

  if (!record) return <></>

  return (
    <Filter {...props}>
      {generateAllowedCustomerFiltersArray({ record }).map(
        (item) => item.FilterInput
      )}
    </Filter>
  )
}

export default AllowedCustomerFilters
