import MultiAutocompleteInput from '../../../components/Inputs/MultiAutocompleteInput/MultiAutocompleteInput'
import * as React from 'react'
import { cnpjFormatter } from '../../../utils/formatters'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const PayoutListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      label: 'Data de início',
      value: 'payoutDate(min)',
      FilterInput: (
        <CustomDateInput
          label="Data de início"
          source="payoutDate(min)"
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
          style={{
            ...inlineInputStyle,
          }}
        />
      ),
    },
    {
      label: 'Data de fim',
      value: 'payoutDate(max)',
      FilterInput: (
        <CustomDateInput
          label="Data de fim"
          source="payoutDate(max)"
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
          style={{
            ...inlineInputStyle,
          }}
        />
      ),
    },
    {
      label: 'Clínica',
      value: 'clinic->name',
      FilterInput: (
        <SearchFilterInput
          label="Clínica"
          source="clinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'processing', name: 'Em Processamento' },
            { id: 'readyToPay', name: 'Pronto para Repasse' },
            { id: 'paid', name: 'Repasse Realizado' },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      label: 'Número do pedido',
      value: 'vendorOrderId',
      FilterInput: (
        <SearchFilterInput
          label="Número do pedido"
          source="vendorOrderId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },

    {
      label: 'Produto',
      value: 'orderItem->product->name',
      FilterInput: (
        <SearchFilterInput
          label="Produto"
          source="orderItem->product->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'type',
      label: 'Tipo',
      FilterInput: (
        <SelectInput
          source="type"
          label="Tipo"
          choices={[
            { id: 'product', name: 'Produto' },
            { id: 'shipping', name: 'Frete' },
            { id: 'paymentMethod', name: 'Meio de pagamento' },
            { id: 'commission', name: 'Comissão' },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->_id',
      label: 'CNPJ da Clínica',
      FilterInput: (
        <MultiAutocompleteInput
          resourceToFetch="clinic"
          source="clinic->_id"
          label="CNPJ da Clínica"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
            minWidth: 250,
            marginTop: 8,
            paddingBottom: 8,
          }}
          optionTypeLabel="cnpj"
          parseFilterToRequest={(filter: string) => {
            return filter.replace(/\D/g, '')
          }}
          parseLabel={(itemToParse: string) => {
            return String(cnpjFormatter.format(itemToParse))
          }}
        />
      ),
    },
  ],
  'label'
)
