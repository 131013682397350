import { Button, CircularProgress } from '@material-ui/core'
import { IJointPurchaseItemEstimatedPrice } from '@vacinas-net/shared'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'

const EstimateButton = (props: {
  purchaseId: string
  clinicId: string
  skuId: string
  quantity: number
  disabled?: boolean
  handleResponse: (
    response: IJointPurchaseItemEstimatedPrice | undefined
  ) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  handleRequestError: (error: any) => void
}) => {
  const {
    purchaseId,
    clinicId,
    skuId,
    quantity,
    disabled,
    handleResponse,
    handleRequestError,
  } = props
  const notify = useNotify()
  const [loading, setLoading] = useState(false)
  return (
    <Button
      disabled={disabled}
      variant="contained"
      color="primary"
      size="medium"
      onClick={() => {
        setLoading(true)
        handleRequestError(undefined)
        handleResponse(undefined)

        HttpClient(
          `${apiUrl}/joint-purchase/check-estimated-prices?jointPurchaseOfferId=${purchaseId}&clinicId=${clinicId}&skuId=${skuId}&quantity=${quantity}`,
          {
            method: 'get',
          }
        )
          .then((res) => handleResponse(res.json))
          .catch((error) => {
            handleRequestError(error)
            console.error(error)
            notify(`Erro ao checar a estimativa: ${error}`)
          })
          .finally(() => {
            setLoading(false)
          })
      }}
    >
      {loading ? (
        <CircularProgress color="inherit" size={25} />
      ) : (
        'Consultar estimativa'
      )}
    </Button>
  )
}

export default EstimateButton
