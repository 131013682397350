import * as React from 'react'
import { useState } from 'react'
import { IClinic, IClinicGroupedByClinicGroup } from '@vacinas-net/shared'
import SelectedClinicsList from './SelectedClinicsList'
import SearchMenu from './SearchMenu'
import { useDataProvider } from 'react-admin'

export type SelectedClinic = Pick<IClinic, '_id' | 'name' | 'clinicGroup'>

export interface IHandleChangeData {
  selectAllClinics: boolean
  selectedClinics: SelectedClinic[]
}

export interface IInitialValue {
  selectAllClinics: boolean
  selectedClinics: string[]
}

export interface ClinicSearchProps {
  readOnly?: boolean
  handleChange: (data: IHandleChangeData) => void
  initialValue?: IInitialValue
  disableSelectAll?: boolean
  filterByActiveClinics?: boolean
}

const ClinicSearch = (props: ClinicSearchProps) => {
  const dataProvider = useDataProvider()
  const {
    handleChange,
    initialValue: initialValueProps,
    readOnly,
    disableSelectAll,
    filterByActiveClinics,
  } = props
  const initialSelectAllClinics = !!initialValueProps?.selectAllClinics

  const [initialValue, setInitialValue] =
    useState<IInitialValue | undefined>(undefined)
  const [selectedClinics, setSelectedClinics] = useState<SelectedClinic[]>([])
  const [selectAllClinics, setSelectAllClinics] = useState(
    initialSelectAllClinics
  )

  const fetchClinicsById = async (ids: string[]) => {
    return dataProvider.getMany('clinic', { ids }).then((response) => {
      const clinics = response.data as IClinic[]
      setSelectedClinics(
        clinics.map((clinic) => ({
          _id: clinic._id,
          clinicGroup: clinic.clinicGroup,
          name: clinic.name,
        }))
      )
    })
  }

  /**
   * The use effect bellow is necessary because React Admin insert all data after make a Get One request,
   * The first data can be empty if user does not came by List Page
   *
   * eg: click on a specifc link instead of clicking on a campaign table row
   *
   */
  React.useEffect(() => {
    if (JSON.stringify(initialValueProps) !== JSON.stringify(initialValue)) {
      setInitialValue(initialValueProps)
    }
  }, [initialValueProps])

  React.useEffect(() => {
    if (initialValue && initialValue.selectedClinics) {
      fetchClinicsById(initialValue.selectedClinics)
      setSelectAllClinics(initialValue.selectAllClinics)
    }
  }, [initialValue])

  React.useEffect(() => {
    handleChange({
      selectAllClinics,
      selectedClinics,
    })
  }, [selectedClinics, selectAllClinics])

  const isClinicChecked = (clinic: SelectedClinic): boolean => {
    const isSelected = selectedClinics.find(
      (selectedClinic) => selectedClinic._id === clinic._id
    )
    if (selectAllClinics) return !isSelected
    return !!isSelected
  }

  const isClinicGroupChecked = (clinicGroup: IClinicGroupedByClinicGroup) => {
    let checked = true
    clinicGroup.clinics?.forEach((clinic) => {
      if (!isClinicChecked(clinic)) {
        checked = false
      }
    })
    return checked
  }

  const selectClinic = (clinic: SelectedClinic) => {
    const clinicIndex = selectedClinics.findIndex(
      (selectedClinic) => selectedClinic._id === clinic._id
    )
    if (clinicIndex !== -1) {
      const copySelectedClinics = JSON.parse(JSON.stringify(selectedClinics))
      copySelectedClinics.splice(clinicIndex, 1)
      setSelectedClinics(copySelectedClinics)
    } else {
      setSelectedClinics([...selectedClinics, clinic])
    }
  }

  const insertClinicsFromClinicGroup = (
    clinicGroup: IClinicGroupedByClinicGroup
  ) => {
    const copySelectedClinics: SelectedClinic[] = JSON.parse(
      JSON.stringify(selectedClinics)
    )
    clinicGroup.clinics?.forEach((clinic) => {
      const clinicIndex = selectedClinics.findIndex(
        (selectedClinic) => selectedClinic._id === clinic._id
      )
      if (clinicIndex === -1) {
        copySelectedClinics.push(clinic)
      }
    })
    setSelectedClinics(copySelectedClinics)
    return
  }

  const selectClinicGroup = (clinicGroup: IClinicGroupedByClinicGroup) => {
    const isToUnselect =
      (selectAllClinics && !isClinicGroupChecked(clinicGroup)) ||
      (!selectAllClinics && isClinicGroupChecked(clinicGroup))

    if (isToUnselect) unselectClinicGroup(clinicGroup)
    else insertClinicsFromClinicGroup(clinicGroup)
  }

  const unselectClinicGroup = (clinicGroup: IClinicGroupedByClinicGroup) => {
    setSelectedClinics(
      selectedClinics.filter((clinic) => {
        return clinic.clinicGroup?._id !== clinicGroup._id
      })
    )
  }

  return (
    <div style={{ display: 'flex' }}>
      <SearchMenu
        readOnly={readOnly}
        selectAllClinics={selectAllClinics}
        setSelectAllClinics={setSelectAllClinics}
        isClinicChecked={isClinicChecked}
        isClinicGroupChecked={isClinicGroupChecked}
        selectClinic={selectClinic}
        selectClinicGroup={selectClinicGroup}
        disableSelectAll={disableSelectAll}
        filterByActiveClinics={filterByActiveClinics}
      />
      <SelectedClinicsList
        readOnly={readOnly}
        selectAllClinics={selectAllClinics}
        selectClinic={selectClinic}
        selectedClinics={selectedClinics}
        unselectClinicGroup={unselectClinicGroup}
      />
    </div>
  )
}

export default ClinicSearch
