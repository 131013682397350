import {
  FormControl,
  FormControlLabel,
  FormLabel,
  Radio,
  RadioGroup,
  TextField,
  Typography,
  Button,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
} from '@material-ui/core'
import { IAddress, IAttendance } from '@vacinas-net/shared'
import React, { useEffect, useState } from 'react'
import { Record, useNotify } from 'react-admin'
import { mainTheme } from '../../utils/mainTheme'
import { parseAddress } from '../../utils/utils'
import { apiUrl } from '../AppDataProvider'
import CustomAutocomplete from '../CustomAutocomplete'
import customHttpClient from '../CustomHttpClient'

const NewAttendanceForm = (props: {
  initialValues?: Record
  onClose: () => void
  onSubmit: (data: IAttendance) => void
  loading: boolean
  isEdit?: boolean
  initialData?: IAttendance
}) => {
  const { onClose, onSubmit, loading, isEdit } = props
  const [formValues, setFormValues] = useState<IAttendance>({
    type: 'delivery',
  })
  const [pickupPointData, setPickupPointData] = useState<
    {
      pickupPointName: string
      pickupPointId: string
      pickupPointAddress: IAddress
    }[]
  >([])
  const [pickupPointAddress, setPickupPointAddress] = useState('')

  const notify = useNotify()

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    onSubmit(formValues)
  }

  const getRetreatPointData = () => {
    if (!formValues.clinic?._id) return
    customHttpClient(
      `${apiUrl}/listClinicPickupPoints/${formValues.clinic?._id}`,
      {
        method: 'get',
      }
    )
      .then((response) => {
        setPickupPointData(response.json)
      })
      .catch((error) => {
        notify(`Erro ao obter pontos de retirada: ${error}`, 'error')
      })
  }

  useEffect(() => {
    getRetreatPointData()
  }, [formValues.clinic])

  return (
    <form onSubmit={submitForm}>
      <div style={{ padding: mainTheme.spacing(2) }}>
        <Typography variant="h2">
          {isEdit ? 'Trocar Clínica' : 'Novo atendimento'}
        </Typography>
        <FormControl
          style={{
            marginTop: mainTheme.spacing(2),
            marginBottom: mainTheme.spacing(2),
          }}
        >
          <FormLabel id="attendance-type-radio-group">
            Tipo de atendimento:
          </FormLabel>
          <RadioGroup
            row
            aria-labelledby="attendance-type-radio-group"
            name="row-radio-buttons-group"
            value={formValues.type}
          >
            <FormControlLabel
              onChange={() => {
                setFormValues({
                  ...formValues,
                  type: 'delivery',
                  pickupPointName: undefined,
                })
              }}
              value="delivery"
              control={<Radio required={true} />}
              label="Em casa"
            />
            <FormControlLabel
              onChange={() => {
                setFormValues({
                  ...formValues,
                  type: 'pickup',
                  address: undefined,
                })
                setPickupPointAddress('')
              }}
              value="pickup"
              control={<Radio required={true} />}
              label="Na clínica"
            />
            <FormControlLabel
              onChange={() => {
                setFormValues({
                  ...formValues,
                  type: 'company',
                  address: undefined,
                })
              }}
              value="company"
              control={<Radio required={true} />}
              label="Na empresa"
            />
          </RadioGroup>
        </FormControl>
        <CustomAutocomplete
          style={{
            marginBottom: mainTheme.spacing(2),
          }}
          resource="clinic"
          filterByActiveClinics
          currentValue={
            {
              label: formValues.clinic?.name || '',
              value: formValues.clinic?._id || '',
            } || null
          }
          onChange={(event) => {
            setFormValues({
              ...formValues,
              clinic: {
                _id: event?.value,
                name: event?.label,
              },
              pickupPointName: undefined,
            })
            setPickupPointAddress('')
            setPickupPointData([])
          }}
          size="medium"
          label="Nome da clínica"
          readOnly={loading}
          dataTestId={`add-clinic-autocomplete`}
          required
        />
        {formValues.type === 'pickup' &&
          formValues.clinic?._id &&
          pickupPointData && (
            <FormControl
              variant="outlined"
              size="small"
              style={{ width: '100%', marginRight: mainTheme.spacing(2) }}
              required
            >
              <InputLabel>Ponto de retirada</InputLabel>
              <Select
                value={formValues.pickupPointName || null}
                label="Ponto de retirada"
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    pickupPointName: String(event.target.value), //this variable will store the pickupPointId instead of name
                  })
                }}
                inputProps={{
                  'data-testid': `pickup-point-select`,
                }}
              >
                {pickupPointData.map((pickupPoint) => {
                  return (
                    <MenuItem
                      key={pickupPoint.pickupPointId}
                      value={pickupPoint.pickupPointId}
                      onClick={() => {
                        setPickupPointAddress(
                          parseAddress(pickupPoint.pickupPointAddress)
                        )
                      }}
                    >
                      {pickupPoint.pickupPointName}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
          )}

        {(formValues.type === 'delivery' || formValues.type === 'company') && (
          <div
            style={{
              marginBottom: mainTheme.spacing(2),
            }}
          >
            <Typography
              style={{
                marginBottom: mainTheme.spacing(1),
              }}
              variant="body1"
              color="textSecondary"
            >
              Entrega:
            </Typography>
            <TextField
              label="Logradouro"
              variant="outlined"
              value={formValues.address?.streetName}
              onChange={(event) => {
                setFormValues({
                  ...formValues,
                  address: {
                    ...formValues.address,
                    streetName: event.target.value,
                  },
                })
              }}
              inputProps={{
                'data-testid': `input-street-name`,
              }}
              fullWidth
              size="small"
              required
            />
            <div
              style={{
                display: 'flex',
                marginTop: mainTheme.spacing(1),
                marginBottom: mainTheme.spacing(1),
              }}
            >
              <TextField
                style={{
                  marginRight: mainTheme.spacing(1),
                }}
                label="Número"
                variant="outlined"
                value={formValues.address?.streetNumber}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      streetNumber: event.target.value,
                    },
                  })
                }}
                inputProps={{
                  'data-testid': `input-street-number`,
                }}
                fullWidth
                size="small"
                required
              />
              <TextField
                style={{
                  marginLeft: mainTheme.spacing(1),
                  marginRight: mainTheme.spacing(1),
                }}
                label="Bairro"
                variant="outlined"
                value={formValues.address?.neighborhood}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      neighborhood: event.target.value,
                    },
                  })
                }}
                inputProps={{
                  'data-testid': `input-neighborhood-name`,
                }}
                fullWidth
                size="small"
                required
              />
              <TextField
                style={{
                  marginLeft: mainTheme.spacing(1),
                }}
                label="Complemento"
                variant="outlined"
                value={formValues.address?.complement}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      complement: event.target.value,
                    },
                  })
                }}
                inputProps={{
                  'data-testid': `input-complement`,
                }}
                fullWidth
                size="small"
              />
            </div>
            <div
              style={{ display: 'flex', marginBottom: mainTheme.spacing(4) }}
            >
              <TextField
                style={{
                  marginRight: mainTheme.spacing(1),
                }}
                label="Cidade"
                variant="outlined"
                value={formValues.address?.city}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      city: event.target.value,
                    },
                  })
                }}
                inputProps={{
                  'data-testid': `input-complement`,
                }}
                fullWidth
                size="small"
                required
              />
              <TextField
                style={{
                  marginLeft: mainTheme.spacing(1),
                  marginRight: mainTheme.spacing(1),
                }}
                label="Estado"
                variant="outlined"
                value={formValues.address?.state}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    address: {
                      ...formValues.address,
                      state: event.target.value,
                    },
                  })
                }}
                inputProps={{
                  'data-testid': `input-complement`,
                }}
                fullWidth
                size="small"
                required
              />
              {!isEdit && (
                <TextField
                  style={{
                    marginLeft: mainTheme.spacing(1),
                  }}
                  label="Frete"
                  variant="outlined"
                  type="number"
                  value={
                    parseFloat(String(formValues.shippingPriceInCents)) / 100 ||
                    null
                  }
                  onChange={(event) => {
                    setFormValues({
                      ...formValues,
                      shippingPriceInCents:
                        parseFloat(event.target.value) * 100,
                    })
                  }}
                  inputProps={{
                    'data-testid': `monetary-input`,
                  }}
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">R$</InputAdornment>
                    ),
                  }}
                  fullWidth
                  size="small"
                  required
                />
              )}
            </div>
          </div>
        )}
        {formValues.type === 'pickup' && pickupPointAddress && (
          <div
            style={{
              marginTop: mainTheme.spacing(2),
              marginBottom: mainTheme.spacing(2),
            }}
          >
            <Typography
              style={{
                marginBottom: mainTheme.spacing(1),
              }}
              variant="body1"
              color="textSecondary"
            >
              Entrega:
            </Typography>
            <Typography
              style={{
                marginBottom: mainTheme.spacing(1),
              }}
              variant="body1"
              color="textPrimary"
            >
              {pickupPointAddress}
            </Typography>
          </div>
        )}
        {!isEdit && (
          <div>
            <Typography variant="body1" color="textSecondary">
              Agendamento:
            </Typography>
            <div style={{ display: 'flex', marginTop: mainTheme.spacing(1) }}>
              <TextField
                type="datetime-local"
                style={{
                  marginRight: mainTheme.spacing(1),
                }}
                label="Horário de Agendamento"
                variant="outlined"
                value={formValues.deliveryWindow?.startDate}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    deliveryWindow: {
                      ...formValues.deliveryWindow,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      startDate: event.target.value,
                    },
                  })
                }}
                inputProps={{
                  max: '2999-12-31T23:59',
                  'data-testid': `input-start-date`,
                }}
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                required
              />
              <TextField
                type="time"
                style={{
                  marginLeft: mainTheme.spacing(1),
                }}
                label="Tolerância máxima"
                variant="outlined"
                value={formValues.deliveryWindow?.endDate}
                onChange={(event) => {
                  setFormValues({
                    ...formValues,
                    deliveryWindow: {
                      ...formValues.deliveryWindow,
                      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
                      //@ts-ignore
                      endDate: event.target.value, //parse data on send request
                    },
                  })
                }}
                inputProps={{
                  'data-testid': `input-end-date`,
                }}
                fullWidth
                size="small"
                InputLabelProps={{ shrink: true }}
                required
              />
            </div>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            justifyContent: 'flex-end',
            alignItems: 'center',
            marginTop: mainTheme.spacing(2),
          }}
        >
          <Button variant="outlined" onClick={onClose}>
            Cancelar
          </Button>
          <Button
            style={{ marginLeft: mainTheme.spacing(1) }}
            variant="contained"
            type="submit"
            disabled={loading}
            data-testid={'button-attendance-form-submit'}
          >
            Confirmar
          </Button>
        </div>
      </div>
    </form>
  )
}

export default NewAttendanceForm
