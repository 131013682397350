import { Button, CircularProgress } from '@material-ui/core'
import { useNotify } from 'ra-core'
import React, { useState } from 'react'
import { apiUrl } from '../../../../components/AppDataProvider'
import customHttpClient from '../../../../components/CustomHttpClient'
import { downloadFile } from '../../../../utils/downloadFile'

const DownloadTemplateButton = (): JSX.Element => {
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  const onClick = async () => {
    setLoading(true)

    try {
      const result = await customHttpClient(
        `${apiUrl}/campaign/allowed-customer-batch/template`
      )

      downloadFile(result.body, 'modelo_lista.csv')
    } catch (err) {
      notify('Erro ao buscar o modelo de lista', { type: 'error' })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Button
      color="primary"
      variant="outlined"
      onClick={onClick}
      disabled={loading}
      style={{
        width: '174px',
        height: '36px',
      }}
    >
      {loading ? <CircularProgress size={16} /> : 'Baixar modelo de lista'}
    </Button>
  )
}

export default DownloadTemplateButton
