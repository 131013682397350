import React, { useEffect, useState } from 'react'
import { Box, InputAdornment, TextField, Typography } from '@material-ui/core'
import { Search } from '@material-ui/icons'
import ClinicMapList from '../../../../components/ClinicMap/ClinicMapList'
import MapWithMarkers from '../../../../components/ClinicMap/MapWithMarkers'
import { mainTheme } from '../../../../utils/mainTheme'
import { IClinic, IClinicCondition } from '@vacinas-net/shared'
import { parseAddress } from '../../../../utils/utils'

interface Props {
  campaignName?: string
  clinics: IClinicCondition['clinic'][]
}

const ClinicListWithMap = ({ clinics, campaignName }: Props) => {
  const [searchValue, setSearchValue] = useState('')
  const [filteredClinics, setFilteredClinics] = useState<IClinic[]>([])

  const filterClinicsList = () => {
    setFilteredClinics(
      clinics.filter(
        (clinic) =>
          clinic.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          parseAddress(clinic.address)
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      )
    )
  }

  useEffect(() => {
    filterClinicsList()
  }, [searchValue])
  return (
    <div style={{ display: 'flex', padding: '8px' }}>
      <div
        style={{
          width: '100%',
          maxWidth: 360,
        }}
      >
        <Typography
          style={{
            marginTop: mainTheme.spacing(1),
            marginLeft: mainTheme.spacing(1),
            marginBottom: mainTheme.spacing(2),
          }}
          variant="h2"
        >{`Rede de aceitação da campanha ${campaignName || ''}`}</Typography>
        <Box>
          <TextField
            value={searchValue}
            onChange={(ev) => {
              setSearchValue(ev.target.value)
            }}
            label="Buscar por clínica ou endereço"
            variant="outlined"
            size="small"
            style={{
              marginLeft: mainTheme.spacing(1),
              marginBottom: mainTheme.spacing(2),
            }}
            data-testid={'input-search-clinic-group'}
            InputProps={{
              endAdornment: (
                <InputAdornment position="end">
                  <Search />
                </InputAdornment>
              ),
            }}
          />
          <ClinicMapList clinicsArray={filteredClinics}></ClinicMapList>
        </Box>
      </div>
      <div
        style={{
          height: 700,
          width: '100%',
          marginLeft: mainTheme.spacing(3),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <MapWithMarkers
          markersCoordinates={filteredClinics
            .filter(
              (clinic) =>
                clinic.address?.geoCoordinates?.lat ||
                clinic.address?.geoCoordinates?.lng
            )
            .map((clinic) => {
              return {
                lat: Number(clinic.address?.geoCoordinates?.lat),
                lng: Number(clinic.address?.geoCoordinates?.lng),
              }
            })}
          center={{ lat: -14.6222409, lng: -49.2810116 }}
          zoom={4}
        />
      </div>
    </div>
  )
}
export default ClinicListWithMap
