/* eslint-disable @typescript-eslint/ban-ts-comment */
import React, { useContext, useEffect, useState } from 'react'
import {
  Dialog,
  Button,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
  InputAdornment,
} from '@material-ui/core'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { IPaymentMethodConfig } from '@vacinas-net/shared'
import customHttpClient from '../CustomHttpClient'
import { apiUrl } from '../AppDataProvider'
import { useNotify } from 'react-admin'
import { mainTheme } from '../../utils/mainTheme'
import InputMask from 'react-input-mask'

const AddPaymentForm = (props: { open: boolean; onClose: () => void }) => {
  const context = useContext(OrderCreateContext)
  const [paymentMethodsArray, setPaymentMethodsArray] = useState<
    IPaymentMethodConfig[]
  >([])
  const [selectedPaymentMethodId, setSelectedPaymentMethodId] = useState('')
  const [priceInput, setPriceInput] = useState('')
  const [voucher, setVoucher] = useState('')
  const [installments, setInstallments] = useState('')

  const { addPayment } = context!
  const notify = useNotify()

  const getPaymentMethods = () => {
    customHttpClient(`${apiUrl}/payment-method-config`)
      .then((response) => {
        setPaymentMethodsArray(response.json)
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao obter métodos de pagamento: ${error}`, 'error')
      })
  }

  const parsePayment = () => {
    const paymentMethod = getSelectedPaymentMethod()
    if (paymentMethod?.type === 'pix') {
      addPayment({
        method: 'pix',
        installmentCount: 1,
        chargedPriceInCents: parseFloat(priceInput) * 100,
        referencePriceInCents: parseFloat(priceInput) * 100,
      })
    }
    if (paymentMethod?.type === 'bankPaymentSlip') {
      addPayment({
        method: 'bankPaymentSlip',
        installmentCount: 1,
        chargedPriceInCents: parseFloat(priceInput) * 100,
        referencePriceInCents: parseFloat(priceInput) * 100,
      })
    }
    if (paymentMethod?.type === 'creditCard') {
      addPayment({
        method: 'creditCard',
        creditCard: {
          creditCardBrand: paymentMethod.creditCard?.creditCardBrand,
        },
        installmentCount: parseFloat(installments),
        chargedPriceInCents: parseFloat(priceInput) * 100,
        referencePriceInCents: parseFloat(priceInput) * 100,
      })
    }
    //@ts-ignore
    if (paymentMethod?.type === 'voucher') {
      addPayment({
        //@ts-ignore
        method: 'voucher',
        installmentCount: 1,
        chargedPriceInCents: parseFloat(priceInput) * 100,
        referencePriceInCents: parseFloat(priceInput) * 100,
        voucher: {
          redemptionCode: voucher,
          valueInCents: parseFloat(priceInput),
        },
      })
    }
    //@ts-ignore
    if (paymentMethod?.type === 'cash') {
      addPayment({
        //@ts-ignore
        method: 'cash',
        installmentCount: 1,
        chargedPriceInCents: parseFloat(priceInput) * 100,
        referencePriceInCents: parseFloat(priceInput) * 100,
      })
    }
    resetPaymentMethod()
    props.onClose()
  }

  const resetPaymentMethod = () => {
    setSelectedPaymentMethodId('')
    setPriceInput('')
    setVoucher('')
    setInstallments('')
  }

  const disableAddButton = () => {
    if (!getSelectedPaymentMethod()) {
      return true
    }
    if (
      getSelectedPaymentMethod()?.type === 'creditCard' &&
      (!priceInput || !installments)
    ) {
      return true
    }
    if (
      (getSelectedPaymentMethod()?.type === 'pix' ||
        //@ts-ignore
        getSelectedPaymentMethod()?.type === 'cash') &&
      !priceInput
    ) {
      return true
    }
    if (
      //@ts-ignore
      getSelectedPaymentMethod()?.type === 'voucher' &&
      (!voucher || !priceInput)
    ) {
      return true
    }
    return false
  }

  const getSelectedPaymentMethod = () => {
    return paymentMethodsArray.find(
      (method) => method._id === selectedPaymentMethodId
    )
  }

  const parsePaymentMethod = (paymentMethod: IPaymentMethodConfig) => {
    switch (paymentMethod.type) {
      case 'bankPaymentSlip':
        return 'Boleto'
        break
      case 'pix':
        return 'Pix'
        break
      case 'creditCard':
        return `Cartão ${paymentMethod.creditCard?.creditCardBrand}`
        break
      //@ts-ignore
      case 'voucher':
        return 'Voucher'
        break
      //@ts-ignore
      case 'cash':
        return 'Dinheiro'
        break
      default:
        return '-'
        break
    }
  }

  useEffect(() => {
    if (props.open) getPaymentMethods()
  }, [props.open])

  return (
    <Dialog fullWidth maxWidth="sm" onClose={props.onClose} open={props.open}>
      <DialogTitle>Adicionar pagamento</DialogTitle>
      <DialogContent style={{ display: 'flex', flexDirection: 'column' }}>
        <FormControl style={{ width: 270 }} variant="outlined" size="small">
          <InputLabel>Método de pagamento</InputLabel>
          <Select
            value={selectedPaymentMethodId || null}
            label="Método de pagamento"
            onChange={(event) =>
              setSelectedPaymentMethodId(String(event.target.value))
            }
            SelectDisplayProps={{
              //@ts-ignore
              'data-testid': `select-payment-method`,
            }}
          >
            {paymentMethodsArray.map((paymentMethod) => {
              return (
                <MenuItem key={paymentMethod._id} value={paymentMethod._id}>
                  {parsePaymentMethod(paymentMethod)}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
        {(getSelectedPaymentMethod()?.type === 'pix' ||
          getSelectedPaymentMethod()?.type === 'bankPaymentSlip' ||
          //@ts-ignore
          getSelectedPaymentMethod()?.type === 'cash') && (
          <div style={{ display: 'flex', marginTop: mainTheme.spacing(2) }}>
            <TextField
              label="Valor total"
              style={{ width: 135 }}
              type="number"
              variant="outlined"
              size="small"
              value={priceInput}
              onChange={(ev) => {
                setPriceInput(ev.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              inputProps={{
                'data-testid': `add-payment-value`,
              }}
            />
          </div>
        )}
        {getSelectedPaymentMethod()?.type === 'creditCard' && (
          <div style={{ display: 'flex', marginTop: mainTheme.spacing(2) }}>
            <TextField
              label="Valor total"
              style={{ width: 135 }}
              type="number"
              variant="outlined"
              size="small"
              value={priceInput}
              onChange={(ev) => {
                setPriceInput(ev.target.value)
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              inputProps={{
                'data-testid': `add-payment-value`,
              }}
            />
            <TextField
              label="Parcelas"
              style={{ width: 120, marginLeft: mainTheme.spacing(2) }}
              type="number"
              variant="outlined"
              size="small"
              value={installments}
              onChange={(ev) => {
                setInstallments(ev.target.value)
              }}
              inputProps={{
                'data-testid': `add-payment-installments`,
              }}
            />
          </div>
        )}

        {
          //@ts-ignore
          getSelectedPaymentMethod()?.type === 'voucher' && (
            <div style={{ display: 'flex', marginTop: mainTheme.spacing(2) }}>
              <InputMask
                mask="****-****-****-****"
                value={voucher}
                onChange={(event) => {
                  setVoucher(event.target.value)
                }}
              >
                {() => (
                  <TextField
                    label="Voucher"
                    style={{ width: 200 }}
                    variant="outlined"
                    size="small"
                    inputProps={{
                      'data-testid': `add-voucher-code`,
                    }}
                  />
                )}
              </InputMask>

              <TextField
                label="Valor total"
                style={{ width: 135, marginLeft: mainTheme.spacing(2) }}
                type="number"
                variant="outlined"
                size="small"
                value={priceInput}
                onChange={(ev) => {
                  setPriceInput(ev.target.value)
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                inputProps={{
                  'data-testid': `add-payment-value`,
                }}
              />
            </div>
          )
        }
      </DialogContent>
      <DialogActions>
        <Button
          disabled={disableAddButton()}
          onClick={() => parsePayment()}
          color="primary"
          variant="contained"
        >
          Adicionar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AddPaymentForm
