import { Typography } from '@material-ui/core'
import React from 'react'
import {
  CheckboxGroupInput,
  Create,
  CreateProps,
  InputProps,
  required,
  SimpleForm,
  TextInput,
  useInput,
  Record,
} from 'react-admin'
import ClinicSearch, {
  IHandleChangeData,
} from '../../../components/ClinicSearch'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { mainTheme } from '../../../utils/mainTheme'

const ClinicSearchInput = (props: InputProps) => {
  const { input } = useInput(props)

  const handleChange = (data: IHandleChangeData) => {
    input.onChange(data)
  }
  return <ClinicSearch filterByActiveClinics handleChange={handleChange} />
}

const NotificationCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    const clinicCampaignData: IHandleChangeData = data.clinics
    const resolveClinicsToInclude = () => {
      if (clinicCampaignData.selectAllClinics) {
        return []
      }

      return clinicCampaignData.selectedClinics.map((clinic) =>
        String(clinic._id)
      )
    }

    const resolveClinicsToExclude = () => {
      if (!clinicCampaignData.selectAllClinics) {
        return []
      }

      return clinicCampaignData.selectedClinics.map((clinic) =>
        String(clinic._id)
      )
    }

    const clinicsIdToInclude = resolveClinicsToInclude()
    const clinicsIdToExclude = resolveClinicsToExclude()

    const generateToAllClinics = clinicCampaignData
      ? clinicCampaignData.selectAllClinics
      : false

    return {
      ...data,
      colorHex: mainTheme.palette.primary.main,
      clinicHeuristic: {
        clinicsIdToInclude,
        clinicsIdToExclude,
        generateToAllClinics,
      },
      clinics: undefined,
    } as Record
  }

  return (
    <Create transform={transform} {...props}>
      <SimpleForm redirect="">
        <div
          style={{
            width: '100%',
          }}
        >
          <HeaderWithGoBack title="Enviar notificação" />
          <div
            style={{
              marginLeft: mainTheme.spacing(3),
              marginTop: mainTheme.spacing(2),
            }}
          >
            <Typography
              style={{ marginBottom: mainTheme.spacing(1 / 2) }}
              variant="h2"
            >
              Título
            </Typography>
            <TextInput
              source="title"
              label="Título"
              variant="outlined"
              validate={required()}
              style={{ width: 350 }}
            />
            <Typography
              style={{ marginBottom: mainTheme.spacing(1 / 2) }}
              variant="h2"
            >
              Mensagem
            </Typography>
            <TextInput
              source="message"
              label="Mensagem"
              variant="outlined"
              validate={required()}
              rows={3}
              style={{ width: 350 }}
              multiline
            />
            <Typography
              style={{ marginBottom: mainTheme.spacing(1 / 2) }}
              variant="h2"
            >
              Link
            </Typography>
            <TextInput
              source="url"
              label="Link"
              variant="outlined"
              validate={required()}
              style={{ width: 350 }}
            />
            <Typography variant="h2">Tipo</Typography>
            <CheckboxGroupInput
              label={false}
              source="roles"
              row={false}
              choices={[
                { id: 'admin', name: 'Vacinas.net' },
                { id: 'clinic_operator', name: 'Operadores de Clínicas' },
                { id: 'clinic_admin', name: 'Administradores de Clínicas' },
              ]}
            />
            <ClinicSearchInput source="clinics" />
          </div>
        </div>
      </SimpleForm>
    </Create>
  )
}

export default NotificationCreate
