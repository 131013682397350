/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react'
import * as Sentry from '@sentry/react'
import { Typography } from '@material-ui/core'

const logErrorToSentry = (error: any, errorInfo: any) => {
  console.log('ERROR DATA', error, errorInfo)
  const sentryEventId = Sentry.captureException(error)
  console.log('Sentry event ID', sentryEventId)
}

interface ErrorBoundaryProps {
  message?: string
}

class ErrorBoundary extends React.Component<
  ErrorBoundaryProps,
  { hasError: boolean }
> {
  constructor(props: any) {
    super(props)
    this.state = { hasError: false }
  }

  componentDidCatch(error: any, errorInfo: any) {
    logErrorToSentry(error, errorInfo)
  }

  static getDerivedStateFromError() {
    return { hasError: true }
  }

  render() {
    if (this.state.hasError) {
      return (
        <Typography variant="h4">
          {this.props.message ||
            `Ops... Algo de errado aconteceu, contacte o suporte.`}
        </Typography>
      )
    }
    return this.props.children
  }
}

export default ErrorBoundary
