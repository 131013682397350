import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const ProductsSkuListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'name',
      label: 'Nome',
      FilterInput: (
        <SearchFilterInput
          label="Nome"
          source="name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'skuId',
      label: 'SKU',
      FilterInput: (
        <SearchFilterInput
          label="SKU"
          source="skuId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'handler',
      label: 'Tipo',
      FilterInput: (
        <SelectInput
          source="handler"
          label="Tipo"
          choices={[
            { id: 'jenner', name: 'Jenner' },
            { id: 'vtex', name: 'VTEX' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
