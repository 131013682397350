import React, { useContext } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import { CartProduct, OrderCreateContext } from '../Contexts/OrderCreateContext'
import { Delete } from '@material-ui/icons'
import QuantityHandler from './QuantityHandler'
import EditDiscountInput from './EditDiscountInput'
import { valueInCentsToBRL } from '../../utils/utils'

const ProductListRow = (props: { product: CartProduct }) => {
  const orderCreateContext = useContext(OrderCreateContext)
  const { removeItemFromCart } = orderCreateContext!
  const { product } = props
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div
        style={{
          width: 50,
        }}
      >
        <Typography align="center" variant="body1">
          {product.skuId}
        </Typography>
      </div>
      <div
        style={{
          width: 200,
        }}
      >
        <Typography align="center" variant="body1">
          {product.name}
        </Typography>
      </div>
      <div>
        <EditDiscountInput product={product} />
      </div>
      <div
        style={{
          width: 125,
        }}
      >
        <QuantityHandler product={product} />
      </div>
      <div
        style={{
          width: 150,
        }}
      >
        <Typography
          align="center"
          variant="body1"
          data-testid={`total-value-${product.skuId}`}
        >
          {valueInCentsToBRL(product.discountedPriceInCents * product.quantity)}
        </Typography>
      </div>
      <div
        style={{
          width: 70,
          display: 'flex',
          justifyContent: 'center',
        }}
      >
        <IconButton
          data-testid={`delete-product-button-${product.skuId}`}
          onClick={() => removeItemFromCart(product.skuId)}
        >
          <Delete />
        </IconButton>
      </div>
    </div>
  )
}

export default ProductListRow
