import React, { useContext } from 'react'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { TextField } from '@material-ui/core'
import InputMask from 'react-input-mask'
import { mainTheme } from '../../utils/mainTheme'

const CouponAndZipcodeInputs = () => {
  const orderCreateContext = useContext(OrderCreateContext)
  const { zipCode, setZipcode, coupon, setCoupon } = orderCreateContext!

  return (
    <>
      <TextField
        value={coupon}
        onChange={(event) => {
          setCoupon(event.target.value)
        }}
        size="small"
        variant="outlined"
        label="Cupom"
        style={{
          width: 160,
        }}
        inputProps={{
          'data-testid': `coupon-input`,
        }}
      />
      <InputMask
        mask="99999-999"
        value={zipCode}
        onChange={(event) => {
          setZipcode(event.target.value)
        }}
      >
        {() => (
          <TextField
            style={{
              marginLeft: mainTheme.spacing(8),
              width: 160,
            }}
            size="small"
            variant="outlined"
            label="CEP"
            inputProps={{
              'data-testid': `zipcode-input`,
            }}
          />
        )}
      </InputMask>
    </>
  )
}

export default CouponAndZipcodeInputs
