import React from 'react'
import { Filter, FilterProps } from 'react-admin'
import { VouchersListFiltersArray } from './Filters'

const VoucherPackagesFilters: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  return (
    <Filter {...props}>
      {VouchersListFiltersArray.map((item) => item.FilterInput)}
    </Filter>
  )
}

export default VoucherPackagesFilters
