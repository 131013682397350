import { IClinicCondition, isAdmin } from '@vacinas-net/shared'
import React from 'react'
import { usePermissions } from 'react-admin'
import ClinicConditionAcceptButton from '../../components/ClinicConditions/ClinicConditionAcceptButton'
import ClinicConditionRejectButton from '../../components/ClinicConditions/ClinicConditionRejectButton'
import RemoveClinicFromCommercialConditionButton from '../../components/CommercialCondition/RemoveClinicFromCommercialConditionButton'
import { mainTheme } from '../../utils/mainTheme'

interface ClinicConditionActionFieldProps {
  clinicCondition?: IClinicCondition
}

const ClinicConditionActionField = (props: ClinicConditionActionFieldProps) => {
  const { permissions } = usePermissions()

  if (props.clinicCondition?.status === 'awaiting') {
    return (
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <div style={{ marginRight: mainTheme.spacing(1) }}>
          <ClinicConditionAcceptButton
            conditionId={props.clinicCondition._id!}
          />
        </div>
        <div style={{ marginRight: mainTheme.spacing(1) }}>
          <ClinicConditionRejectButton
            conditionId={props.clinicCondition._id!}
          />
        </div>
        {isAdmin(permissions) && (
          <div
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <RemoveClinicFromCommercialConditionButton
              conditionId={props.clinicCondition._id!}
            />
          </div>
        )}
      </div>
    )
  }

  if (props.clinicCondition?.status !== 'removed' && isAdmin(permissions)) {
    return (
      <div
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <RemoveClinicFromCommercialConditionButton
          conditionId={props.clinicCondition!._id!}
        />
      </div>
    )
  }

  return <div> - </div>
}

export default ClinicConditionActionField
