import { makeStyles } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

export const authStyles = makeStyles({
  formInput: {
    marginBottom: mainTheme.spacing(2),
  },
  loginContainer: {
    display: 'flex',
    padding: 0,
    backgroundColor: mainTheme.palette.background.default,
    height: '100vh',
  },
  innerContainer: {
    display: 'flex',
    position: 'relative',
    flexDirection: 'column',
    justifyContent: 'center',
    alignItems: 'center',
    width: '50%',
  },
  hideMobile: {
    [mainTheme.breakpoints.down('sm')]: {
      display: 'none',
    },
  },
  showOnlyMobile: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    [mainTheme.breakpoints.up('sm')]: {
      display: 'none',
    },
  },
  expandMobile: {
    [mainTheme.breakpoints.down('sm')]: {
      width: '100%',
      justifyContent: 'unset',
      marginTop: mainTheme.spacing(6),
    },
  },
  formStyles: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'center',
    width: '45%',
    [mainTheme.breakpoints.down('sm')]: {
      width: '65%',
    },
  },
  watermark: {
    left: '-20%',
    width: '100%',
    bottom: '-90px',
    opacity: 0.4,
    position: 'relative',
    [mainTheme.breakpoints.down('sm')]: {
      bottom: '-60px',
    },
  },
  watermarkWraper: {
    overflow: 'hidden',
    width: '42%',
    left: 0,
    bottom: 0,
    position: 'absolute',
  },
})
