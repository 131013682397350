import { IAvailableAttendance } from '@vacinas-net/shared'
import React, { useState } from 'react'
import AvailableAttendancesTable from './AvailableAttendancesTable'
import { Button, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import CreateAvailableAttendanceForm from '../../../components/CreateAvailableAttendanceForm/CreateAvailableAttendanceForm'
import { useLocation } from 'react-router-dom'
import qs from 'query-string'

const AvailableAttendancesTab = (props: {
  availableAttendances: IAvailableAttendance[]
}) => {
  const location = useLocation()
  const locationSearch = location.search.split('?')[1]
  const queryString = qs.parse(locationSearch)
  const focusedAvailableAttendance = props.availableAttendances?.find(
    (item) => item._id === queryString.availableAttendanceId
  )

  const [openAvailableAttendanceModal, setOpenAvailableAttendanceModal] =
    useState(!!focusedAvailableAttendance)
  const [attendanceToEdit, setAttendanceToEdit] = useState<
    IAvailableAttendance | undefined
  >(focusedAvailableAttendance)

  return (
    <>
      <Button
        onClick={() => setOpenAvailableAttendanceModal(true)}
        variant="contained"
        color="primary"
      >
        Criar formulário
      </Button>{' '}
      <AvailableAttendancesTable
        availableAttendances={props.availableAttendances}
        setAttendanceToEdit={(attendance: IAvailableAttendance) =>
          setAttendanceToEdit(attendance)
        }
        setOpenAvailableAttendanceModal={(state: boolean) => {
          setOpenAvailableAttendanceModal(state)
        }}
      />
      <Dialog
        maxWidth={'md'}
        fullWidth
        open={openAvailableAttendanceModal}
        onClose={() => {
          setOpenAvailableAttendanceModal(false)
          setAttendanceToEdit(undefined)
        }}
      >
        <DialogTitle>
          {attendanceToEdit ? 'Editar formulário' : 'Novo formulário'}
        </DialogTitle>
        <DialogContent>
          <CreateAvailableAttendanceForm
            setOpen={(state: boolean) => {
              setOpenAvailableAttendanceModal(state)
              setAttendanceToEdit(undefined)
            }}
            initialData={attendanceToEdit}
            isEdit={Boolean(attendanceToEdit)}
          />
        </DialogContent>
      </Dialog>
    </>
  )
}

export default AvailableAttendancesTab
