import * as React from 'react'
import { CreateButton, ListActionsProps, useListContext } from 'react-admin'

import { Button, makeStyles, Menu, MenuItem } from '@material-ui/core'
import FilterIcon from '@material-ui/icons/FilterList'
import { mainTheme } from '../../utils/mainTheme'
import ExportButton from '../Buttons/ExportButton'
import { ReportTypeEnum } from '@vacinas-net/shared'
import { RequiredFiltersFieldsMap } from '../Buttons/ExportButton/RequiredFiltersModal'

const useStyles = makeStyles((theme) => ({
  container: {
    width: '100%',
    marginBottom: theme.spacing(1),
    display: 'flex',
    flexDirection: 'row',
  },
}))

const CustomListActions = (
  props: ListActionsProps & {
    filtersAvailable: { label: string; value: string }[]
    hasCreate?: boolean
    hasExport?: boolean
    exportType?: ReportTypeEnum
    exportButtonText?: string
    requiredFields?: RequiredFiltersFieldsMap
  }
) => {
  const { displayedFilters, showFilter } = useListContext()
  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)

  const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget)
  }

  const handleClose = () => {
    setAnchorEl(null)
  }

  const displayedFiltersKeys = Object.keys(displayedFilters || {})
  const hiddenFilters = props.filtersAvailable.filter(
    (item) => !displayedFiltersKeys.includes(item.value)
  )

  const classes = useStyles()

  return (
    <div className={classes.container}>
      {props.hasExport && props.exportType && (
        <ExportButton
          exportType={props.exportType}
          buttonText={props.exportButtonText!}
          requiredFields={props.requiredFields}
        />
      )}
      {props.hasCreate && (
        <CreateButton
          style={{
            marginRight: mainTheme.spacing(2),
          }}
          size="medium"
          variant="contained"
          label="Criar"
          color="primary"
        />
      )}
      <>
        <Button
          color={'primary'}
          variant={'outlined'}
          onClick={handleClick}
          startIcon={<FilterIcon />}
        >
          Filtrar
        </Button>
        <Menu
          open={Boolean(anchorEl)}
          onClose={handleClose}
          keepMounted
          anchorEl={anchorEl}
        >
          {hiddenFilters.map((item, idx) => (
            <MenuItem
              key={String(idx)}
              onClick={() => {
                if (showFilter) showFilter(item.value, '')
                handleClose()
              }}
            >
              {item.label}
            </MenuItem>
          ))}
        </Menu>
        {props.children}
      </>
    </div>
  )
}

export default CustomListActions
