import React from 'react'
import {
  EditContextProvider,
  EditProps,
  FieldProps,
  useEditController,
  Record,
} from 'react-admin'
import { SelectedClinic } from '../../../components/ClinicSearch'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import UserForm from '../UserForm'

const CustomUserEdit: React.FC<FieldProps> = (props) => {
  const controllerProps = useEditController(props)
  const record = controllerProps.record

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        <HeaderWithGoBack title={`Editar usuário`} />
        {React.cloneElement(props.children as React.ReactElement, {
          ...controllerProps,
          record: {
            ...record,
            role: record?.roles[0],
          },
        })}
      </div>
    </EditContextProvider>
  )
}

const UserEdit = (props: EditProps) => {
  const transform = (data: Record) => {
    return {
      name: data.name,
      email: data.email,
      roles: [data.role],
      relatedClinics:
        data.role !== 'admin'
          ? data.clinicData.selectedClinics.map((clinic: SelectedClinic) => {
              return { _id: clinic._id }
            })
          : [],
    }
  }
  return (
    <CustomUserEdit
      mutationMode="pessimistic"
      transform={transform}
      component="div"
      {...props}
    >
      <UserForm />
    </CustomUserEdit>
  )
}

export default UserEdit
