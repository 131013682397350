import { Chip } from '@material-ui/core'
import { IClinicCondition } from '@vacinas-net/shared'
import React from 'react'
import { mainTheme } from '../../utils/mainTheme'

const ClinicConditionChip = (props: { status: IClinicCondition['status'] }) => {
  let statusText
  let statusColor
  if (props.status === 'awaiting') {
    statusText = 'Sem resposta'
    statusColor = mainTheme.palette.warning.light
  } else if (props.status === 'accepted') {
    statusText = 'Confirmada'
    statusColor = mainTheme.palette.secondary.main
  } else if (props.status === 'rejected') {
    statusText = 'Rejeitada'
    statusColor = mainTheme.palette.error.light
  } else if (props.status === 'removed') {
    statusText = 'Removida'
    statusColor = mainTheme.palette.error.light
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default ClinicConditionChip
