import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { Map, Search } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import ClinicMapList from './ClinicMapList'

import { useFormState } from 'react-final-form'
import httpClient from '../CustomHttpClient'
import { apiUrl } from '../AppDataProvider'
import { OptionType } from '../CustomAutocomplete'
import { IClinic } from '@vacinas-net/shared'
import { useNotify } from 'react-admin'
import MapWithMarkers from './MapWithMarkers'
import { parseAddress } from '../../utils/utils'

const ClinicMap = () => {
  const notify = useNotify()
  const form = useFormState()
  const { name, commercialConditions } = form.values

  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [searchValue, setSearchValue] = useState('')
  const [initialData, setInitialData] = useState<IClinic[]>([])
  const [filteredClinics, setFilteredClinics] = useState<IClinic[]>([])

  const fetchClinics = async () => {
    setLoading(true)
    const commercialConditionsIds =
      commercialConditions.map(
        (commercialCondition: OptionType) => commercialCondition.value
      ) || undefined

    const url = `${apiUrl}/clinic/listByCommercialCondition?commercialConditionIds=${commercialConditionsIds.join()}`
    httpClient(url, {
      method: 'get',
    })
      .then((res) => {
        setInitialData(res.json)
        setFilteredClinics(res.json)
      })
      .catch((err) => {
        console.error(err)
        notify(`Erro ao carregar clínicas: ${err}`, 'error')
        setInitialData([])
        setFilteredClinics([])
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const filterClinicsList = () => {
    setFilteredClinics(
      initialData.filter(
        (clinic) =>
          clinic.name?.toLowerCase().includes(searchValue.toLowerCase()) ||
          parseAddress(clinic.address)
            ?.toLowerCase()
            .includes(searchValue.toLowerCase())
      )
    )
  }

  useEffect(() => {
    filterClinicsList()
  }, [searchValue])

  return (
    <div>
      <Button
        size="medium"
        color="secondary"
        variant="contained"
        onClick={() => {
          fetchClinics()
          setOpenModal(true)
        }}
        data-testid={'open-map-button'}
        startIcon={<Map />}
      >
        Abrir Mapa
      </Button>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="lg"
        fullWidth
      >
        <DialogContent style={{ height: 750 }}>
          {loading ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                height: '100%',
              }}
            >
              <CircularProgress size={80} color="secondary" />
            </div>
          ) : (
            <div style={{ display: 'flex' }}>
              <div
                style={{
                  width: '100%',
                  maxWidth: 360,
                }}
              >
                <Typography
                  style={{
                    marginTop: mainTheme.spacing(1),
                    marginLeft: mainTheme.spacing(1),
                    marginBottom: mainTheme.spacing(2),
                  }}
                  variant="h2"
                >{`Rede de aceitação da campanha ${name || ''}`}</Typography>
                <Box>
                  <TextField
                    value={searchValue}
                    onChange={(ev) => {
                      setSearchValue(ev.target.value)
                    }}
                    label="Buscar por clínica ou endereço"
                    variant="outlined"
                    size="small"
                    style={{
                      marginLeft: mainTheme.spacing(1),
                      marginBottom: mainTheme.spacing(2),
                    }}
                    data-testid={'input-search-clinic-group'}
                    InputProps={{
                      endAdornment: (
                        <InputAdornment position="end">
                          <Search />
                        </InputAdornment>
                      ),
                    }}
                  />
                  <ClinicMapList clinicsArray={filteredClinics}></ClinicMapList>
                </Box>
              </div>
              <div
                style={{
                  height: 700,
                  width: '100%',
                  marginLeft: mainTheme.spacing(3),
                  display: 'flex',
                  alignItems: 'center',
                }}
              >
                <MapWithMarkers
                  markersCoordinates={filteredClinics
                    .filter(
                      (clinic) =>
                        clinic.address?.geoCoordinates?.lat ||
                        clinic.address?.geoCoordinates?.lng
                    )
                    .map((clinic) => {
                      return {
                        lat: Number(clinic.address?.geoCoordinates?.lat),
                        lng: Number(clinic.address?.geoCoordinates?.lng),
                      }
                    })}
                  center={{ lat: -14.6222409, lng: -49.2810116 }}
                  zoom={4}
                />
              </div>
            </div>
          )}
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ClinicMap
