import {
  IAttendance,
  IAttendanceItem,
  IOrder,
  IOrderItem,
  parseAttendanceType,
} from '@vacinas-net/shared'
import { Record } from 'react-admin'
import {
  parseAddress,
  parseDeliveryWindow,
  valueInCentsToBRL,
} from '../../../utils/utils'

export interface AttendanceRenderType {
  id: string
  attendanceData?: {
    type?: string
    address?: string
    clinicName?: string
    clinicId?: string
    customerApointment?: string
    status?: IAttendance['status']
    notes?: string
    shippingPriceInCents?: number
    itemList: IAttendance['itemList']
    pickupPointName?: string
    uuid?: string
  }
  products: AttendanceProductRenderType[]
}
type AttendanceProductRenderType = Pick<
  IOrderItem,
  '_id' | 'quantity' | 'product' | 'priceInCents' | 'discountInCents'
> &
  Pick<IAttendanceItem, 'vaccineShot'>

interface Payment {
  id: number
  totalPaid?: string
  paymentMethod?: string
  installments?: string
  seller?: string
  campaign?: string
  coupon?: string
  discount?: string
}

export const getAttendancesFromOrder = (record: Record | undefined) => {
  const attendances: AttendanceRenderType[] = record?.attendances.map(
    (attendance: IAttendance): AttendanceRenderType => {
      const parseProduct = (
        attendanceItem: IAttendanceItem
      ): AttendanceProductRenderType => {
        const currentItem: IOrderItem = record?.items.find(
          (item: IOrderItem) => item._id === attendanceItem.orderItemId
        )
        return {
          _id: currentItem?._id,
          priceInCents: currentItem?.priceInCents,
          product: currentItem.product,
          quantity: attendanceItem.amount,
          vaccineShot: attendanceItem.vaccineShot,
          discountInCents: currentItem?.discountInCents,
        }
      }

      return {
        id: String(attendance._id),
        attendanceData: {
          type: parseAttendanceType(attendance.type),
          address: parseAddress(attendance.address),
          clinicName: attendance.clinic?.name,
          clinicId: attendance.clinic?._id,
          customerApointment: parseDeliveryWindow(attendance.deliveryWindow),
          status: attendance.status,
          notes: attendance.notes,
          shippingPriceInCents: attendance.shippingPriceInCents,
          itemList: attendance.itemList,
          pickupPointName: attendance.pickupPointName,
          uuid: attendance.uuid,
        },
        products: attendance.itemList?.map(parseProduct) || [],
      }
    }
  )
  return attendances
}

export const getPaymentFromOrder = (record: Record | undefined) => {
  let totalPaid = 0
  const installments = []
  const paymentMethod = []
  const voucherRedemptionCodes: string[] = []
  const discount = record?.items.reduce(
    (accumulator: number, item: IOrderItem) => {
      return item.discountInCents
        ? accumulator + item.discountInCents
        : accumulator
    },
    0
  )

  const payments = (record?.payments || []) as Required<IOrder>['payments']

  for (const payment of payments) {
    totalPaid += payment?.chargedPriceInCents || 0
    payment.method === 'creditCard'
      ? paymentMethod.push(payment?.creditCard?.creditCardBrand)
      : paymentMethod.push(payment.method)
    installments.push(payment.installmentCount)

    if (payment.voucher?.redemptionCode) {
      voucherRedemptionCodes.push(payment.voucher.redemptionCode)
    }
  }
  const orderPayment = {
    id: record?.id,
    totalPaid: valueInCentsToBRL(totalPaid),
    installments: installments.join(', '),
    paymentMethod: paymentMethod.join(', '),
    coupon: record?.marketingData?.couponCode || '',
    discount: valueInCentsToBRL(discount),
    vouchersRedemptionCodes: voucherRedemptionCodes.join(', '),
  } as Payment & { vouchersRedemptionCodes: string }

  return orderPayment
}
