import { Typography } from '@material-ui/core'
import React from 'react'
import { Record, FunctionField, SimpleShowLayout } from 'react-admin'
import showStyles from '../../../utils/showStyles'
import { parseClinicConditionType, parseClinicConditionValue } from '../utils'

const CommercialConditionSection = () => {
  const classes = showStyles()
  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Condições comerciais
      </Typography>
      <SimpleShowLayout className={classes.cardContent}>
        <FunctionField
          className={classes.cardItem}
          label="Condição"
          render={(record: Record | undefined) => {
            return `${parseClinicConditionType(record?.type)}`
          }}
        />
        <FunctionField
          className={classes.cardItem}
          label="Valor"
          render={(record: Record | undefined) => {
            return `${parseClinicConditionValue(record?.value, record?.type)}`
          }}
        />
      </SimpleShowLayout>
    </div>
  )
}

export default CommercialConditionSection
