import React, { useContext } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { IPayment } from '@vacinas-net/shared'
import { parsePaymentMethod, valueInCentsToBRL } from '../../utils/utils'
import { Close } from '@material-ui/icons'
import { mainTheme } from '../../utils/mainTheme'

const PaymentsListRow = (props: { payment: IPayment; position: number }) => {
  const context = useContext(OrderCreateContext)
  const { removePayment } = context!
  const { payment, position } = props

  const parseParsePaymentMethodDesciption = (payment: IPayment) => {
    switch (payment.method) {
      case 'voucher':
        return payment.voucher?.redemptionCode
        break
      case 'creditCard':
        return `${payment.creditCard?.creditCardBrand} (${
          payment.installmentCount
        } x ${valueInCentsToBRL(
          payment.referencePriceInCents! / payment.installmentCount!
        )})`
      default:
        return 'Pagamento à vista'
        break
    }
  }
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        paddingBottom: mainTheme.spacing(1),
      }}
    >
      <div
        style={{
          width: 150,
        }}
      >
        <Typography align="center" variant="body1">
          {parsePaymentMethod(payment)}
        </Typography>
      </div>
      <div
        style={{
          width: 200,
        }}
      >
        <Typography align="center" variant="body1">
          {parseParsePaymentMethodDesciption(payment)}
        </Typography>
      </div>
      <div
        style={{
          width: 100,
        }}
      >
        <Typography align="center" variant="body1">
          {valueInCentsToBRL(payment.referencePriceInCents!)}
        </Typography>
      </div>
      <div>
        <IconButton onClick={() => removePayment(position)} size="small">
          <Close />
        </IconButton>
      </div>
    </div>
  )
}

export default PaymentsListRow
