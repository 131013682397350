import React, { useState } from 'react'
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  TextField,
} from '@material-ui/core'
import { Confirm, useNotify, usePermissions, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import {
  ATTENDANCE_ACCEPTABLE_STATUS,
  IAttendance,
  isAdmin,
} from '@vacinas-net/shared'
import { mainTheme } from '../../../utils/mainTheme'
import {
  Check,
  CheckCircleRounded,
  Close,
  ErrorOutline,
} from '@material-ui/icons'
import { stopPropagation } from '../../../utils/utils'

interface ManageAttendanceButtonProps {
  attendance: IAttendance
  isMenuItem?: boolean
}

const acceptButtonShouldBeActive = (
  attendance: IAttendance,
  permissions: string[] | undefined
) => {
  const attendanceStatus = attendance.status
  if (attendanceStatus === 'accepted') return false
  if (isAdmin(permissions || [])) return true
  return attendanceStatus === ATTENDANCE_ACCEPTABLE_STATUS
}

const ManageAttendanceButtons = (props: ManageAttendanceButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [openRejectDialog, setOpenRejectDialog] = useState(false)
  const [rejectionReason, setRejectionReason] = useState('')

  const { permissions } = usePermissions()

  const notify = useNotify()
  const refresh = useRefresh()

  const acceptAttendance = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendance._id}/accept`
    httpClient(url, { method: 'post' })
      .then(() => {
        notify('Atendimento aceito com sucesso')
        setOpenConfirm(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao aceitar o atendimento', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const rejectAttendance = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendance._id}/reject`
    httpClient(url, {
      method: 'post',
      body: JSON.stringify({
        rejectedReason: rejectionReason,
      }),
    })
      .then(() => {
        notify('Atendimento rejeitado com sucesso')
        setOpenRejectDialog(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao rejeitar o atendimento', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        disabled={!acceptButtonShouldBeActive(props.attendance, permissions)}
        size="medium"
        color="secondary"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation()
          setOpenConfirm(true)
        }}
        data-testid={`order-id-${props.attendance._id}`}
        startIcon={<Check />}
      >
        Aceitar
      </Button>

      <Button
        disabled={!acceptButtonShouldBeActive(props.attendance, permissions)}
        size="medium"
        variant="outlined"
        onClick={(e) => {
          e.stopPropagation()
          setOpenRejectDialog(true)
        }}
        data-testid={`reject-order-id-${props.attendance._id}`}
        style={{
          marginRight: mainTheme.spacing(2),
          marginLeft: mainTheme.spacing(2),
          borderColor: mainTheme.palette.error.main,
          color: mainTheme.palette.error.main,
        }}
        startIcon={<Close />}
      >
        Rejeitar
      </Button>

      <Dialog
        onKeyDown={stopPropagation}
        open={openRejectDialog}
        onClose={() => setOpenRejectDialog(false)}
        onClick={(e) => {
          e.stopPropagation()
        }}
      >
        <DialogTitle>Você está rejeitando o atendimento</DialogTitle>
        <DialogContent>
          <TextField
            label="Razão da rejeição"
            variant="outlined"
            size="small"
            value={rejectionReason}
            onChange={(event) => setRejectionReason(event.target.value)}
            multiline
            required
            fullWidth
            inputProps={{
              'data-testid': `reject-order-id-${props.attendance._id}-input`,
            }}
            disabled={loading}
          />
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ErrorOutline />}
            onClick={() => setOpenRejectDialog(false)}
            size="large"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<CheckCircleRounded />}
            onClick={() => rejectAttendance()}
            color="primary"
            autoFocus
            size="large"
            disabled={loading}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está aceitando o atendimento"
        content="Deseja confirmar essa ação?"
        onConfirm={() => acceptAttendance()}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default ManageAttendanceButtons
export { acceptButtonShouldBeActive }
