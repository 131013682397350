import * as React from 'react'
import { Switch } from '@material-ui/core'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Record,
  useNotify,
  useRefresh,
} from 'react-admin'
import UserStatusChip from '../../../components/chips/UserStatusChip'
import {
  parseUserRelatedClinics,
  parseUserRelatedGroups,
  translateSystemRole,
} from '../../../utils/utils'
import UserListActions from './UserListActions'
import UserListFilters from './UserListFilters'
import { toggleUserStatus } from '../utils/toggleUserStatus'
import { DefaultPagination } from '../../../components/Pagination'

const UserList = ({ ...props }: ListProps) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const handleSwitchOnClick = async (record: Record | undefined) => {
    toggleUserStatus(String(record?.id), record?.status)
      .then(() => {
        notify('Status alterado com sucesso', 'success')
      })
      .catch((err: Error) => {
        console.error(err)
        notify('Erro ao alterar status do usuário', 'error')
      })
      .finally(refresh)
  }

  return (
    <List
      sort={{ field: 'name', order: 'ASC' }}
      pagination={<DefaultPagination />}
      actions={<UserListActions />}
      filters={<UserListFilters />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid stickyHeader rowClick="edit">
        <TextField textAlign="center" source="name" label="Nome" />
        <TextField textAlign="center" source="email" label="E-mail" />
        <DateField
          textAlign="center"
          source="createdAt"
          label="Criado em"
          locales="pt-BR"
          emptyText="-"
        />
        <FunctionField
          textAlign="center"
          label="Perfil"
          render={(record: Record | undefined) => {
            return translateSystemRole(record?.roles[0])
          }}
        />
        <FunctionField
          textAlign="center"
          label="Clínicas"
          render={(record: Record | undefined) =>
            record?.relatedClinics && record?.relatedClinics.length !== 0
              ? parseUserRelatedClinics(record?.relatedClinics)
              : '-'
          }
        />
        <FunctionField
          textAlign="center"
          label="Redes"
          render={(record: Record | undefined) =>
            record?.relatedClinicGroups &&
            record?.relatedClinicGroups.length !== 0
              ? parseUserRelatedGroups(record?.relatedClinicGroups)
              : '-'
          }
        />
        <FunctionField
          textAlign="center"
          label="Status"
          source="status"
          render={(record: Record | undefined) => (
            <UserStatusChip status={record?.status} />
          )}
        />
        <FunctionField
          textAlign="center"
          label=""
          source="status"
          render={(record: Record | undefined) => (
            <Switch
              color="primary"
              checked={record?.status === 'active'}
              onClick={(e) => {
                e.stopPropagation()
                handleSwitchOnClick(record)
              }}
            />
          )}
        />
      </Datagrid>
    </List>
  )
}

export default UserList
