import * as React from 'react'
import {
  Create,
  CreateProps,
  DateInput,
  InputProps,
  SimpleForm,
  TopToolbar,
  useInput,
  Record,
} from 'react-admin'
import HeaderWithGoBack from '../../../../components/HeaderWithGoBack'
import { Typography } from '@material-ui/core'

import { mainTheme } from '../../../../utils/mainTheme'
import ClinicSearch, {
  IHandleChangeData,
} from '../../../../components/ClinicSearch'
import { formatISO, setHours, setMinutes, setSeconds } from 'date-fns'
import { dateToCorrectISOString } from '../../../../utils/utils'

const PayoutSettlementCreateActions = () => {
  return (
    <TopToolbar>
      <HeaderWithGoBack title="Liquidar repasse" path="/payout-settlement" />
    </TopToolbar>
  )
}

interface PayoutSettlementCreation {
  id?: string
  payoutSettlementData: {
    settlementDate: string
    payoutDateRange: {
      startDate: string
      endDate: string
    }
  }
  clinicsHeuristicData: {
    clinicsIdToInclude: string[]
    clinicsIdToExclude: string[]
    generateToAllClinics: boolean
  }
}

const ClinicSearchInput = (props: InputProps) => {
  const { input } = useInput(props)

  const handleChange = (data: IHandleChangeData) => {
    input.onChange(data)
  }
  return <ClinicSearch handleChange={handleChange} />
}

export const PayoutSettlementCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    const clinicCampaignData: IHandleChangeData = data.clinicData

    const resolveClinicsToInclude = () => {
      if (clinicCampaignData.selectAllClinics) {
        return []
      }

      return clinicCampaignData.selectedClinics.map((clinic) =>
        String(clinic._id)
      )
    }

    const resolveClinicsToExclude = () => {
      if (!clinicCampaignData.selectAllClinics) {
        return []
      }

      return clinicCampaignData.selectedClinics.map((clinic) =>
        String(clinic._id)
      )
    }

    const clinicsIdToInclude = resolveClinicsToInclude()
    const clinicsIdToExclude = resolveClinicsToExclude()

    const generateToAllClinics = clinicCampaignData
      ? clinicCampaignData.selectAllClinics
      : false

    let startDate = new Date(
      dateToCorrectISOString(data.payoutDateRange.startDate)
    )
    startDate = setHours(startDate, 0)
    startDate = setMinutes(startDate, 0)
    startDate = setSeconds(startDate, 0)

    let endDate = new Date(dateToCorrectISOString(data.payoutDateRange.endDate))
    endDate = setHours(endDate, 23)
    endDate = setMinutes(endDate, 59)
    endDate = setSeconds(endDate, 59)

    const dataToSend: PayoutSettlementCreation = {
      payoutSettlementData: {
        settlementDate: formatISO(Date.now()),
        payoutDateRange: {
          startDate: formatISO(startDate),
          endDate: formatISO(endDate),
        },
      },
      clinicsHeuristicData: {
        clinicsIdToInclude: clinicsIdToInclude,
        clinicsIdToExclude: clinicsIdToExclude,
        generateToAllClinics: generateToAllClinics,
      },
    }

    return dataToSend as Record
  }

  return (
    <Create
      transform={transform}
      actions={<PayoutSettlementCreateActions />}
      {...props}
    >
      <SimpleForm redirect="list">
        <Typography variant="h2">Filtrar repasses</Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            width: '100%',
            marginTop: mainTheme.spacing(2),
          }}
        >
          <DateInput
            source="payoutDateRange.startDate"
            label="Data de início"
            variant="outlined"
            style={{ marginRight: mainTheme.spacing(2) }}
          />
          <DateInput
            source="payoutDateRange.endDate"
            label="Data de fim"
            variant="outlined"
          />
        </div>

        <Typography
          style={{
            marginBottom: mainTheme.spacing(2),
          }}
          variant="h2"
        >
          Participantes
        </Typography>
        <ClinicSearchInput source="clinicData" />
      </SimpleForm>
    </Create>
  )
}

export default PayoutSettlementCreate
