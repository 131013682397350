import * as React from 'react'
import { Typography } from '@material-ui/core'
import { IClinicGroupedByClinicGroup } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import groupBy from 'lodash/groupBy'
import ClinicGroupChips from './ClinicGroupChips'
import { SelectedClinic } from '.'

export const HeaderTitle = (props: { selectAllClinics: boolean }) => {
  const text = props.selectAllClinics
    ? 'Clínicas excluídas'
    : 'Clínicas participantes'

  return <Typography variant="h2">{text}</Typography>
}

export const HeaderSubTitle = (props: { selectAllClinics: boolean }) => {
  const text = props.selectAllClinics
    ? 'Todas as clínicas estão selecionadas. Busque as que deseja excluir da campanha'
    : 'Nenhum participante selecionado. Busque as clínicas que deseja incluir na campanha.'

  return (
    <Typography
      style={{
        marginLeft: mainTheme.spacing(3),
        marginTop: mainTheme.spacing(1),
      }}
      variant="body2"
      color="textSecondary"
    >
      {text}
    </Typography>
  )
}

export const resolveClinicsGroups = (
  selectedClinics: SelectedClinic[]
): IClinicGroupedByClinicGroup[] => {
  const clinicsGrouped = groupBy(selectedClinics, 'clinicGroup._id')
  const keys = Object.keys(clinicsGrouped)
  return keys.map((key) => ({
    _id: key,
    clinics: clinicsGrouped[key],
  }))
}

const SelectedClinicsList = (props: {
  selectAllClinics: boolean
  selectedClinics: SelectedClinic[]
  selectClinic: (clinic: SelectedClinic) => void
  unselectClinicGroup: (clinicGroup: IClinicGroupedByClinicGroup) => void
  readOnly?: boolean
}) => {
  const { selectAllClinics, selectedClinics, selectClinic, readOnly } = props
  const clinicsGroups = resolveClinicsGroups(selectedClinics)

  return (
    <div style={{ marginLeft: mainTheme.spacing(4) }}>
      <HeaderTitle selectAllClinics={selectAllClinics} />
      {selectedClinics.length === 0 && (
        <HeaderSubTitle selectAllClinics={selectAllClinics} />
      )}
      <div
        style={{
          marginTop: mainTheme.spacing(1),
          marginLeft: mainTheme.spacing(2),
        }}
      >
        {clinicsGroups.map((clinicGroup) => (
          <ClinicGroupChips
            readOnly={readOnly}
            selectClinic={selectClinic}
            clinicGroup={clinicGroup}
            key={clinicGroup._id}
            unselectClinicGroup={props.unselectClinicGroup}
          />
        ))}
      </div>
    </div>
  )
}

export default SelectedClinicsList
