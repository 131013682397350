import * as React from 'react'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { useStyles } from '../../../hooks/CustomListFilter'
import { SelectInput, Filter, FilterProps } from 'react-admin'

const AggregatedOfferItemsFilter: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <SearchFilterInput
        label="Nome da Clínica"
        source="clinic->name"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="type"
        label="Comprado por"
        choices={[
          { id: 'flipped', name: 'Vacinas.net' },
          { id: 'own', name: 'Clínica' },
          { id: 'hybrid', name: 'Vacinas.net e Clínica' },
        ]}
        style={{ minWidth: 200 }}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="minPriceStatus"
        label="Status do pedido mínimo"
        choices={[
          { id: 'achieved', name: 'Atingido' },
          { id: 'not_achieved', name: 'Não Atingido' },
        ]}
        style={{ minWidth: 200 }}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="status"
        label="Status do pedido"
        choices={[
          { id: 'accepted', name: 'Aceito' },
          { id: 'rejected', name: 'Rejeitado' },
          { id: 'awaiting_confirmation', name: 'Aguardando Confimação' },
        ]}
        style={{ minWidth: 200 }}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
    </Filter>
  )
}

export default AggregatedOfferItemsFilter
