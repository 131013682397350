import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const JointPurchaseFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'name',
      label: 'Nome',
      FilterInput: (
        <SearchFilterInput
          label="Nome"
          source="name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'range->startDate(min)',
      label: 'Data de início',
      FilterInput: (
        <CustomDateInput
          label="Data de início"
          source="range->startDate(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },
    {
      value: 'range->endDate(max)',
      label: 'Data de fim',
      FilterInput: (
        <CustomDateInput
          label="Data de fim"
          source="range->endDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'open', name: 'Aberta' },
            { id: 'cancelled', name: 'Cancelada' },
            { id: 'finished', name: 'Finalizada' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
