import React, { useEffect, useState } from 'react'
import { ImageInput, ImageField, ImageInputProps, useInput } from 'react-admin'
import { Button, Typography } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { convertFileToBase64 } from '../../../utils/fileAndImageUtils'

const CustomImageInput = (props: ImageInputProps) => {
  const { input } = useInput(props)
  const [image, setImage] = useState(input.value)

  const parseInputToBase64 = async () => {
    const base64Image = (await convertFileToBase64(input.value)) as string
    setImage(base64Image)
  }

  useEffect(() => {
    if (input.value.rawFile) {
      parseInputToBase64()
    }
  }, [input.value])

  if (!input.value)
    return (
      <div
        style={{
          width: 150,
          border: '1px solid rgba(0, 0, 0, 0.34)',
          padding: mainTheme.spacing(1),
          borderRadius: mainTheme.spacing(1),
          marginTop: mainTheme.spacing(1),
        }}
      >
        <ImageInput
          source={props.source}
          label={props.labelSingle}
          accept="image/*"
          placeholder="Clique para selecionar uma imagem"
        >
          <ImageField source="src" title="title" />
        </ImageInput>
      </div>
    )

  return (
    <div
      style={{
        marginTop: mainTheme.spacing(1),
        border: '1px solid rgba(0, 0, 0, 0.34)',
        padding: '8px',
        borderRadius: '8px',
      }}
    >
      <Typography color="textSecondary" variant="caption">
        {props.labelSingle}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
        }}
      >
        <img
          src={image}
          alt={props.labelSingle}
          style={{
            width: 150,
            height: 100,
            margin: mainTheme.spacing(1),
          }}
        />
        <Button
          variant="text"
          color="primary"
          onClick={() => input.onChange(null)}
        >
          Alterar Imagem
        </Button>
      </div>
    </div>
  )
}

export default CustomImageInput
