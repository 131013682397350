import React from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../components/AppDataProvider'
import customHttpClient from '../../components/CustomHttpClient'

export const handleSwitchClick = async (
  clinicToToggle: {
    clinicId: string
    currentStatus: string
  } | null,
  setLoading: (value: React.SetStateAction<boolean>) => void,
  setClinicToToggle: (
    value: React.SetStateAction<{
      clinicId: string
      currentStatus: string
    } | null>
  ) => void
) => {
  const notify = useNotify()
  const refresh = useRefresh()
  if (!clinicToToggle) return
  setLoading(true)
  customHttpClient(
    `${apiUrl}/clinic/${clinicToToggle.clinicId}/status/${
      clinicToToggle.currentStatus === 'active' ? 'inactive' : 'active'
    }`,
    {
      method: 'PATCH',
    }
  )
    .then(() => {
      notify(`Clínica atualizada com sucesso!`)
    })
    .catch((err) => {
      notify(`Erro ao Ativar/Desativar clínica: ${err}`, 'error')
    })
    .finally(() => {
      setLoading(false)
      setClinicToToggle(null)
      refresh()
    })
}

export const defaultDeliveryInstructions =
  'Para efetuar a vacinação na clínica, apresente na recepção o email de confirmação do pagamento.'
