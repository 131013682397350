import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
  MenuItem,
} from '@material-ui/core'
import React from 'react'
import { mainTheme } from '../../../utils/mainTheme'
import { useNotify, useRefresh } from 'react-admin'
import { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { IAttendance } from '@vacinas-net/shared'
import { stopPropagation } from '../../../utils/utils'

interface ReprocessPayoutProps {
  attendance: IAttendance
  isMenuItem?: boolean
}

const ReprocessPayoutButton = (props: ReprocessPayoutProps) => {
  const [loading, setLoading] = useState(false)
  const [openReprocessPayout, setOpenReprocessPayout] = useState(false)

  const refresh = useRefresh()
  const notify = useNotify()

  const reprocessPayout = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendance._id}/payout/reprocess`
    httpClient(url, {
      method: 'post',
    })
      .then(() => {
        notify('Reprocessamento solicitado com sucesso')
        setOpenReprocessPayout(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao reprocessar repasse: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {props.isMenuItem ? (
        <MenuItem onClick={() => setOpenReprocessPayout(true)}>
          Reprocessar Repasse
        </MenuItem>
      ) : (
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={() => setOpenReprocessPayout(true)}
        >
          Reprocessar Repasse
        </Button>
      )}

      <Dialog
        onKeyDown={stopPropagation}
        open={openReprocessPayout}
        onClose={() => setOpenReprocessPayout(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          style={{
            paddingTop: mainTheme.spacing(3),
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2">Reprocessar Repasse</Typography>
          <div
            style={{
              marginTop: mainTheme.spacing(2),
            }}
          >
            <FormLabel>
              Os repasses serão reprocessados com as configurações atuais. Está
              certo que deseja continuar?
            </FormLabel>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: mainTheme.spacing(2),
            padding: mainTheme.spacing(3),
          }}
        >
          <Button
            onClick={() => setOpenReprocessPayout(false)}
            variant="outlined"
          >
            Cancelar
          </Button>
          <Button
            onClick={() => {
              reprocessPayout()
            }}
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            data-testid={'button-reprocess-payout-submit'}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ReprocessPayoutButton
