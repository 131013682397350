import React, { useContext, useState } from 'react'
import CustomAutocomplete from '../CustomAutocomplete'
import { OptionType } from '../CustomAutocomplete'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { mainTheme } from '../../utils/mainTheme'

const AddProductsInput = () => {
  const [currenctProduct, setCurrentProduct] = useState<OptionType | null>(null)
  const orderCreateContext = useContext(OrderCreateContext)
  const {
    searchedOrderProductsArray,
    setSearchedOrderProductsArray,
    productsArray,
    upgradeItemQuantity,
  } = orderCreateContext!
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <CustomAutocomplete
        style={{
          width: 300,
        }}
        currentValue={currenctProduct}
        onChange={(ev) => {
          setCurrentProduct(ev)
        }}
        resource="product-sku"
        label="SKU ou Nome"
        optionTypeValue="skuId"
        dataTestId="vaccinedSkuId-autocomplete"
        searchTerm
      />
      <IconButton
        data-testid="addButton"
        aria-label="addButton"
        disabled={!currenctProduct}
        style={{
          marginLeft: mainTheme.spacing(1),
          marginRight: mainTheme.spacing(1),
        }}
        color="primary"
        onClick={() => {
          if (!currenctProduct) {
            return
          }
          if (
            productsArray
              .map((product) => product.skuId)
              .includes(String(currenctProduct?.value))
          ) {
            upgradeItemQuantity(String(currenctProduct?.value))
          } else {
            setSearchedOrderProductsArray([
              ...searchedOrderProductsArray,
              {
                skuId: String(currenctProduct?.value),
                name: String(currenctProduct?.label),
              },
            ])
          }
        }}
      >
        <Add />
      </IconButton>
    </div>
  )
}

export default AddProductsInput
