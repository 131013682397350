import React from 'react'
import { useStyles } from '..'
import SignatureField from '../../../../components/SignatureField'
import { mainTheme } from '../../../../utils/mainTheme'

const PrintSignatureSection = () => {
  const classes = useStyles()

  return (
    <div
      style={{
        marginTop: mainTheme.spacing(12),
        marginBottom: mainTheme.spacing(6),
      }}
      className={classes.showOnlyOnPrint}
    >
      <div style={{ display: 'flex', justifyContent: 'center' }}>
        <div>
          <SignatureField role="Nome do vacinador" />
        </div>
        <div style={{ marginLeft: mainTheme.spacing(10) }}>
          <SignatureField role="Nome do cliente" />
        </div>
      </div>
    </div>
  )
}

export default PrintSignatureSection
