import { keyBy, uniq } from 'lodash'
import {
  Datagrid,
  FunctionField,
  ListContextProvider,
  NumberField,
  Record,
  RecordMap,
} from 'react-admin'
import React from 'react'
import { IAllowedCustomer } from '@vacinas-net/shared'
import { formatCPF } from '@brazilian-utils/brazilian-utils'

const AllowedCustomerListForUploadDialog = (props: {
  record: IAllowedCustomer[]
}) => {
  const { record } = props
  return (
    <ListContextProvider
      value={{
        data: keyBy(record, '_id') as unknown as RecordMap<Record>,
        ids: record.map((item) => item._id),
        currentSort: { field: 'name', order: 'ASC' },
      }}
    >
      <Datagrid>
        <FunctionField
          textAlign="center"
          label="Nome"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            if (!allowedCustomer.fullNames?.length) return '-'

            return uniq(allowedCustomer.fullNames).join(', ')
          }}
        />
        <FunctionField
          textAlign="center"
          label="E-mail"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            if (!allowedCustomer.emails?.length) return '-'

            return uniq(allowedCustomer.emails).join(', ')
          }}
        />
        <FunctionField
          textAlign="center"
          label="CPF"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            return formatCPF(allowedCustomer.document)
          }}
        />
        <NumberField
          textAlign="center"
          label="Quantidade de vales"
          source="givenVoucherAmount"
        />
        <NumberField
          textAlign="center"
          label="Vales utilizados"
          source="usedVouchersAmount"
        />
      </Datagrid>
    </ListContextProvider>
  )
}

export default AllowedCustomerListForUploadDialog
