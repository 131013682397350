import React from 'react'
import { TextField } from '@material-ui/core'
import { useInput, Validator } from 'react-admin'
import { OpeningHours } from '@vacinas-net/shared'
import { minutesToHoursPattern, hoursPatternToMinutes } from './utils'
import { OpeningHourRowProps } from './index'
import { isNumber } from 'lodash'

const validator: Validator = (value, formValues, inputProps) => {
  const name = inputProps.name as string
  const keys = name.split('.')
  const inputBaseSource = keys[0]
  const globalIndex = Number(keys[1])
  const openingHours: OpeningHours[] = formValues[inputBaseSource] || []
  const targetOpeningHour = openingHours[globalIndex]

  if (!targetOpeningHour) return null
  const isWeekdayActive =
    formValues?.weekdayOpeningHours[inputBaseSource][
      `weekday-${targetOpeningHour.weekday}`
    ]
  if (!isWeekdayActive) return null

  if (!isNumber(value)) return

  const endDateTimeInputValue = targetOpeningHour.endTimeInMinutes

  if (isNumber(endDateTimeInputValue) && endDateTimeInputValue < value) {
    return 'O horário de início não pode ser posterior ao horário de fim'
  }

  const beforeElement = openingHours
    .slice(0, globalIndex)
    .reverse()
    .find((openingHour) => openingHour?.weekday === targetOpeningHour.weekday)

  if (
    beforeElement &&
    isNumber(beforeElement.endTimeInMinutes) &&
    beforeElement.endTimeInMinutes > value
  ) {
    return 'O horário de início não pode ser anterior ao horário de fim do turno anterior'
  }

  return null
}

const StartDateTimeInput = (props: OpeningHourRowProps) => {
  const rowSource = `${props.inputBaseSource}.${props.openingHour.globalIndex}`
  const startDateTimeSource = `${rowSource}.startTimeInMinutes`
  const input = useInput({
    source: startDateTimeSource,
    value: null,
    validate: validator,
  })

  return (
    <TextField
      error={input.meta.error}
      disabled={props.disabled}
      type="time"
      style={{
        width: 150,
      }}
      label="Início"
      variant="outlined"
      value={minutesToHoursPattern(input.input.value)}
      onChange={(event) => {
        const value = event.target.value
        const newValue = hoursPatternToMinutes(value)

        input.input.onChange(newValue)
      }}
      inputProps={{
        'data-testid': `input-start-date`,
      }}
      size="small"
      InputLabelProps={{ shrink: true }}
    />
  )
}

export default StartDateTimeInput
