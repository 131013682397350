import React, { useContext } from 'react'
import { Typography, Button } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { valueInCentsToBRL } from '../../utils/utils'
import { ShoppingCart } from '@material-ui/icons'

const OrderSummary = () => {
  const context = useContext(OrderCreateContext)
  const {
    getTotalCartValue,
    getTotalPaymentValue,
    getTotalDiscountValue,
    getTotalOrderValue,
    loading,
    createOrder,
  } = context!

  return (
    <div style={{ width: 280 }}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'flex-start',
          border: `1px solid ${mainTheme.palette.primary.main}`,
          paddingTop: mainTheme.spacing(2),
          paddingBottom: mainTheme.spacing(2),
          paddingLeft: mainTheme.spacing(1),
          paddingRight: mainTheme.spacing(1),
          borderRadius: mainTheme.spacing(1),
          marginBottom: mainTheme.spacing(2),
        }}
      >
        <Typography style={{ paddingLeft: mainTheme.spacing(1) }} variant="h1">
          Resumo do pedido:
        </Typography>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Typography
            gutterBottom
            style={{
              marginTop: mainTheme.spacing(2),
              paddingLeft: mainTheme.spacing(1),
            }}
            variant="body1"
          >
            Valor total do pedido:{' '}
          </Typography>
          <Typography
            gutterBottom
            style={{ marginLeft: 'auto', paddingRight: mainTheme.spacing(1) }}
            variant="body1"
          >
            {valueInCentsToBRL(getTotalCartValue)}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Typography
            style={{ paddingLeft: mainTheme.spacing(1) }}
            gutterBottom
            variant="body1"
          >
            Descontos:{' '}
          </Typography>
          <Typography
            style={{ marginLeft: 'auto', paddingRight: mainTheme.spacing(1) }}
            gutterBottom
            variant="body1"
          >
            {valueInCentsToBRL(getTotalDiscountValue)}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'baseline',
            width: '100%',
          }}
        >
          <Typography
            gutterBottom
            variant="body1"
            style={{ paddingLeft: mainTheme.spacing(1) }}
          >
            Pagamentos:{' '}
          </Typography>
          <Typography
            style={{ marginLeft: 'auto', paddingRight: mainTheme.spacing(1) }}
            gutterBottom
            variant="body1"
          >
            {valueInCentsToBRL(getTotalPaymentValue)}
          </Typography>
        </div>
        {getTotalOrderValue !== 0 && (
          <div
            style={{
              backgroundColor: mainTheme.palette.error.light,
              display: 'flex',
              width: '100%',
              alignItems: 'center',
              borderRadius: mainTheme.spacing(1),
              paddingTop: mainTheme.spacing(1),
              paddingBottom: mainTheme.spacing(1),
            }}
          >
            <Typography
              style={{ color: '#ffffff', marginLeft: mainTheme.spacing(1) }}
              variant="body1"
            >
              Saldo total:
            </Typography>
            <Typography
              style={{
                marginLeft: 'auto',
                color: '#ffffff',
                marginRight: mainTheme.spacing(1),
              }}
              variant="body1"
            >
              {valueInCentsToBRL(getTotalOrderValue)}
            </Typography>
          </div>
        )}
      </div>
      {getTotalOrderValue === 0 && (
        <Button
          startIcon={<ShoppingCart />}
          color="primary"
          fullWidth
          variant="contained"
          disabled={loading}
          onClick={() => createOrder()}
        >
          Finalizar pedido
        </Button>
      )}
    </div>
  )
}

export default OrderSummary
