import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Record,
} from 'react-admin'
import PayoutStatusChip from '../../../components/chips/PayoutStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import {
  parsePayoutDescription,
  parsePayoutType,
  valueInCentsToBRL,
} from '../../../utils/utils'
import PayoutListActions from './PayoutListActions'
import PayoutListFilter from './PayoutListFilter'

const PayoutList = ({ ...props }: ListProps) => {
  return (
    <List
      pagination={<DefaultPagination />}
      actions={<PayoutListActions />}
      filters={<PayoutListFilter />}
      bulkActionButtons={false}
      sort={{ field: 'payoutDate', order: 'ASC' }}
      {...props}
    >
      <Datagrid stickyHeader>
        <TextField textAlign="center" source="vendorOrderId" label="Pedido" />
        <DateField
          textAlign="center"
          source="payoutDate"
          label="Data de repasse"
          locales="pt-BR"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.isCreatedManually
              ? record.description
              : parsePayoutDescription(record)
          }
          label="Descrição"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => parsePayoutType(record?.type)}
          label="Tipo"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.payoutValueInCents)
          }
          label="Repasse Clínica"
          source="payoutValueInCents"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.holdValueInCents)
          }
          label="Valor Vacinas.net"
          source="holdValueInCents"
          sortable={false}
        />
        <TextField
          textAlign="center"
          source="orderItem.product.name"
          label="Produto"
          emptyText="-"
        />
        <TextField textAlign="center" source="clinic.name" label="Clínica" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => (
            <PayoutStatusChip status={record?.status} />
          )}
          label="Status"
          source="status"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

export default PayoutList
