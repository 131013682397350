import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormControl,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import React, { useEffect, useMemo } from 'react'
import { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { useNotify, useRefresh } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { IAttendance, IOrderItem } from '@vacinas-net/shared'

const NewPayoutButton = (props: { attendances: IAttendance[] }) => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const { attendances } = props
  const [description, setDescription] = useState('')
  const [valuePayout, setValuePayout] = useState<number>()
  const [valueHold, setValueHold] = useState<number>()
  const [type, setType] = useState('')
  const [datePayout, setDatePayout] = useState('')
  const [attendanceId, setAttendanceId] = useState('')
  const [orderItemId, setOrderItemId] = useState('')
  const [orderItems, setOrderItems] = useState<IOrderItem[]>([])
  const refresh = useRefresh()
  const notify = useNotify()
  const getAttendanceProducts = async () => {
    const url = `${apiUrl}/attendance/${attendanceId}`
    httpClient(url, {
      method: 'get',
    })
      .then((res) => {
        setOrderItems(res.json.order.items)
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao obter produtos', 'error')
      })
  }
  const newPayout = async () => {
    const payoutDate = new Date(`${datePayout}T00:00:00.000Z`).toISOString()
    setLoading(true)
    const url = `${apiUrl}/payout`
    httpClient(url, {
      method: 'post',
      body: JSON.stringify({
        attendanceId: attendanceId,
        orderItemId: orderItemId,
        type: type,
        payoutValueInCents: valuePayout,
        holdValueInCents: !valueHold ? 0 : valueHold,
        description: description,
        payoutDate,
      }),
    })
      .then(() => {
        notify('Repasse criado com sucesso')
        setOpenModal(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao criar o repasse', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  useEffect(() => {
    if (attendanceId) {
      getAttendanceProducts()
    }
  }, [attendanceId])

  const filterAttendanceItems = (
    orderItemId: string,
    attendances: IAttendance[]
  ) => {
    return (
      orderItemId ===
      attendances
        .find((attendance) => attendance._id === attendanceId)
        ?.itemList?.find((item) => item.orderItemId === orderItemId)
        ?.orderItemId
    )
  }

  const typeOptions = useMemo(() => {
    return [
      { value: 'product', label: 'Produto' },
      { value: 'commission', label: 'Comissão' },
      { value: 'paymentMethod', label: 'Meio de pagamento' },
    ]
  }, [])

  return (
    <div>
      <Button
        size="medium"
        color="secondary"
        variant="contained"
        onClick={() => setOpenModal(true)}
      >
        Novo repasse
      </Button>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Typography
            style={{
              marginBottom: mainTheme.spacing(2),
              textAlign: 'center',
            }}
            variant="h2"
          >
            Novo Repasse
          </Typography>
          <div
            style={{
              marginLeft: mainTheme.spacing(2),
              marginRight: mainTheme.spacing(2),
            }}
          >
            <div
              style={{ display: 'flex', marginBottom: mainTheme.spacing(1) }}
            >
              <TextField
                style={{ width: 168 }}
                label="Data de repasse"
                variant="outlined"
                type="date"
                InputLabelProps={{ shrink: true }}
                onChange={(event) => {
                  setDatePayout(event.target.value)
                }}
                inputProps={{
                  'data-testid': `payout-date-input`,
                }}
                size="small"
                required
              />
              <FormControl
                variant="outlined"
                size="small"
                required
                style={{ marginLeft: mainTheme.spacing(3) }}
              >
                <InputLabel id="type-label">Tipo</InputLabel>
                <Select
                  style={{ width: 168 }}
                  required
                  variant="outlined"
                  labelId="type-label"
                  label="Tipo"
                  onChange={(event) => setType(String(event.target.value))}
                  inputProps={{
                    'data-testid': `type-select`,
                  }}
                >
                  {typeOptions.map((typeOption) => (
                    <MenuItem key={typeOption.value} value={typeOption.value}>
                      {typeOption.label}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </div>
            <div style={{ marginBottom: mainTheme.spacing(1) }}>
              <TextField
                style={{ width: 360 }}
                label="Descrição"
                variant="outlined"
                onChange={(event) => {
                  setDescription(event.target.value)
                }}
                multiline
                size="small"
                required
                inputProps={{
                  'data-testid': `input-description`,
                }}
              />
            </div>
            <div style={{ marginBottom: mainTheme.spacing(1) }}>
              <TextField
                style={{ width: 168 }}
                type="number"
                label="Valor Repasse"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={parseFloat(String(valuePayout)) / 100 || null}
                onChange={(event) =>
                  setValuePayout(parseFloat(event.target.value) * 100)
                }
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
                inputProps={{
                  'data-testid': `payout-input`,
                }}
                required
              />
              <TextField
                style={{ width: 168, marginLeft: mainTheme.spacing(3) }}
                type="number"
                label="Valor Vacinas.net"
                variant="outlined"
                size="small"
                InputLabelProps={{ shrink: true }}
                value={parseFloat(String(valueHold)) / 100 || null}
                onChange={(event) =>
                  setValueHold(parseFloat(event.target.value) * 100)
                }
                inputProps={{
                  'data-testid': `hold-value-input`,
                }}
                InputProps={{
                  startAdornment: (
                    <InputAdornment position="start">R$</InputAdornment>
                  ),
                }}
              />
            </div>
            <FormControl
              variant="outlined"
              size="small"
              required
              style={{ width: 360, marginBottom: mainTheme.spacing(1) }}
            >
              <InputLabel>Atendimento</InputLabel>
              <Select
                value={attendanceId || null}
                label="atendimento"
                onChange={(event) =>
                  setAttendanceId(String(event.target.value))
                }
                inputProps={{
                  'data-testid': `attendance-select`,
                }}
              >
                {attendances.map((attendance, index) => {
                  return (
                    <MenuItem
                      disabled={
                        attendance.status === 'cancelled' ||
                        attendance.status === 'rejected' ||
                        attendance.status === 'relocated'
                      }
                      key={attendance._id}
                      value={attendance._id}
                    >
                      Atendimento {index + 1} - {attendance.clinic?.name}
                    </MenuItem>
                  )
                })}
              </Select>
            </FormControl>
            <FormControl
              variant="outlined"
              size="small"
              required
              style={{ width: 360 }}
            >
              <InputLabel>Produto</InputLabel>
              <Select
                value={orderItemId || null}
                label="produto"
                onChange={(event) => setOrderItemId(String(event.target.value))}
                inputProps={{
                  'data-testid': `product-select`,
                }}
              >
                {orderItems
                  .filter((orderItem) =>
                    filterAttendanceItems(orderItem._id!, attendances)
                  )
                  ?.map((product, index) => {
                    return (
                      <MenuItem key={product._id} value={product._id}>
                        {index + 1} - {product.product?.name}
                      </MenuItem>
                    )
                  })}
              </Select>
            </FormControl>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            marginBottom: mainTheme.spacing(1),
            marginRight: mainTheme.spacing(4),
          }}
        >
          <Button onClick={() => setOpenModal(false)} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              newPayout()
            }}
            style={{ marginLeft: mainTheme.spacing(2) }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            data-testid={'button-reprocess-payout-submit'}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}
export default NewPayoutButton
