import * as React from 'react'
import { useListContext, useNotify } from 'react-admin'
import { useEffect, useState } from 'react'
import _ from 'lodash'
import { mainTheme } from '../../../utils/mainTheme'
import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { OptionType } from '../../CustomAutocomplete'
import { brazilianStatesArray } from '@vacinas-net/shared'

export interface LocationFilterProps {
  source: string
  stateKey?: string
  cityKey?: string
  style?: React.CSSProperties | undefined
}

const StateAndCitySearchInput = (props: LocationFilterProps) => {
  const listContext = useListContext()
  const [choosenCities, setChoosenCities] = useState<OptionType[]>([])

  const cityKey = props.cityKey || 'city'
  const stateKey = props.stateKey || 'state'

  const filterValues = listContext.filterValues
  const values = (filterValues[props.source] || {}) as {
    [key: string]: string
  }

  const [cityChoicesArray, setCityChoicesArray] = useState<OptionType[]>([])
  const [loading, setLoading] = useState(false)

  const notify = useNotify()

  const fetchCitiesByState = async () => {
    try {
      setLoading(true)
      const fetchedData = await fetch(
        `https://servicodados.ibge.gov.br/api/v1/localidades/estados/${values[stateKey]}/municipios`
      )
      const results: {
        id: number
        nome: string
      }[] = await fetchedData.json()
      setCityChoicesArray(
        _.sortBy(
          results.map((cities) => ({
            value: cities.nome,
            label: cities.nome,
          })),
          'value'
        )
      )
    } catch (error) {
      notify(`Erro ao obter municípios: ${error}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (!values[stateKey]) {
      return
    }
    fetchCitiesByState()
  }, [values[stateKey]])

  return (
    <div
      style={{
        display: 'flex',
        paddingBottom: mainTheme.spacing(1),
        marginTop: mainTheme.spacing(1),
      }}
    >
      <FormControl size="small" variant="outlined">
        <InputLabel>Estado</InputLabel>
        <Select
          style={{ minWidth: 150 }}
          value={values[stateKey] || ''}
          label="Estado"
          onChange={async (event) => {
            listContext.setFilters(
              {
                ...filterValues,
                [props.source]: {
                  ...filterValues[props.source],
                  [stateKey]: event.target.value,
                  [cityKey]: [],
                },
              },
              listContext.displayedFilters,
              false
            )
            setChoosenCities([])
          }}
        >
          {brazilianStatesArray.map((state) => (
            <MenuItem key={state.id} value={state.id}>
              {state.name}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <Autocomplete
        disabled={loading || cityChoicesArray.length === 0}
        value={choosenCities}
        size="small"
        multiple
        limitTags={2}
        options={cityChoicesArray}
        getOptionLabel={(option) => option.label}
        onChange={(ev, value) => {
          setChoosenCities(value)
          listContext.setFilters(
            {
              ...filterValues,
              [props.source]: {
                ...filterValues[props.source],
                [cityKey]: value.map((obj) => obj.value),
              },
            },
            listContext.displayedFilters,
            false
          )
        }}
        renderInput={(params) => (
          <TextField {...params} label="Cidade" variant="outlined" />
        )}
        style={{ width: 300, marginLeft: mainTheme.spacing(1) }}
      />
    </div>
  )
}

export default StateAndCitySearchInput
