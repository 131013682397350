import React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FunctionField,
  Record,
  usePermissions,
} from 'react-admin'
import { format } from 'date-fns'
import { isAdmin } from '@vacinas-net/shared'

import CommercialConditionsListActions from './CommercialConditionsActions'
import CommercialConditionsFilters from './CommercialConditionsFilters'
import { ICommercialCondition_V2 } from '@vacinas-net/shared'
import CommercialConditionStatusChip from '../../../components/chips/CommercialConditionStatusChip'
import CommercialConditionRejectedCountChip from '../../../components/chips/CommercialConditionRejectedCountChip'
import { DefaultPagination } from '../../../components/Pagination'

const CommercialConditionsList = ({ ...props }: ListProps) => {
  const getNumberOfRejectedClinicConditions = (
    clinicConditions: ICommercialCondition_V2['clinicConditions']
  ) => {
    const rejecteds = clinicConditions.filter(
      (condition) => condition.status === 'rejected'
    )
    return rejecteds.length > 0 ? `${rejecteds.length}` : '-'
  }
  const { permissions } = usePermissions()
  const mustRenderRejectedColumns = isAdmin(permissions)

  const getNumberOfAcceptedClinicConditions = (
    clinicConditions: ICommercialCondition_V2['clinicConditions']
  ) => {
    const accepted = clinicConditions.filter(
      (condition) => condition.status === 'accepted'
    )
    return `${accepted.length}/${clinicConditions.length}`
  }

  return (
    <List
      {...props}
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      filters={<CommercialConditionsFilters />}
      actions={<CommercialConditionsListActions />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid
        stickyHeader
        rowClick={(id, basePath, record) => {
          return `clinic-condition/?commercialConditionId=${record?._id}`
        }}
      >
        <TextField textAlign="center" source="name" label="Nome" />
        <FunctionField
          textAlign="center"
          label="Período"
          render={(record: Record | undefined) => {
            return `${format(
              new Date(record?.dateRange.startDate),
              'dd/MM/yy'
            )} - ${format(new Date(record?.dateRange.endDate), 'dd/MM/yy')}`
          }}
        />
        <FunctionField
          textAlign="center"
          label="Clínicas"
          render={(record: Record | undefined) => {
            return `${getNumberOfAcceptedClinicConditions(
              record?.clinicConditions
            )}`
          }}
        />
        {mustRenderRejectedColumns && (
          <FunctionField
            textAlign="center"
            label="Rejeitados"
            render={(record: Record | undefined) => (
              <CommercialConditionRejectedCountChip
                rejectedCount={getNumberOfRejectedClinicConditions(
                  record?.clinicConditions
                )}
              />
            )}
          />
        )}
        <TextField textAlign="center" label="Tipo" source="handler" />
        <FunctionField
          textAlign="center"
          label="Status"
          render={(record: Record | undefined) => (
            <CommercialConditionStatusChip status={record?.status} />
          )}
        />
      </Datagrid>
    </List>
  )
}

export default CommercialConditionsList
