import { Button } from '@material-ui/core'
import { useNotify, useRedirect } from 'ra-core'
import React, { useState } from 'react'
import { Confirm } from 'react-admin'
import HttpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'

const DeletePayoutSettlementButton = (props: {
  payoutSettlementId: string
}) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const redirect = useRedirect()

  const handleConfirm = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/payout-settlement/${props.payoutSettlementId}`, {
      method: 'delete',
    })
      .then(() => {
        notify(`Liquidação de repasse rejeitada com sucesso`, 'success')
        redirect('/payout-settlement')
      })
      .catch((error) => {
        notify(`Erro ao rejeitar a liquidação de repasse: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
      })
  }
  return (
    <div>
      <Button
        color="primary"
        variant="contained"
        onClick={() => setOpenConfirm(true)}
      >
        Reverter liquidação
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está revertendo a liquidação do repasse"
        content="Deseja confirmar essa ação?"
        onConfirm={handleConfirm}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default DeletePayoutSettlementButton
