import * as React from 'react'
import { IconButton, Menu, MenuItem } from '@material-ui/core'
import showStyles from '../../../utils/showStyles'
import { useHistory } from 'react-router'
import { MoreVert } from '@material-ui/icons'
import FinishAttendanceButton from '../../../components/FinishAttendanceButton'
import {
  ATTENDANCE_ACCEPTABLE_STATUS,
  ATTENDANCE_FINISHABLE_STATUS,
  ATTENDANCE_REVIEWABLE_STATUS,
  IAttendance,
  IPayout,
  isAdmin,
} from '@vacinas-net/shared'
import ManageAttendanceButtons from '../ManageAttendanceButtons'
import UndoAcceptedAttendanceButton from '../UndoAcceptedAttendanceButton'
import ChangeClinicButton from '../ChangeClinicButton'
import { usePermissions } from 'ra-core'
import UploadInvoiceButton from '../../Invoices/UploadInvoiceButton'
import ReprocessPayoutButton from '../ReprocessPayoutButton'
import ReactToPrint from 'react-to-print'
import ReviewAttendanceButton from '../ReviewAttendanceButton'
import GetRegisterPatientLinkPopup from '../GetRegisterPatientLinkPopup'
import ResetRegisterPatientLinkPopup from '../ResetRegisterPatientLinkPopup'
import ResendPatientLinkMail from '../ResendPatientLinkMail'
import UndoFinishAttendanceButton from '../UndoFinishAttendanceButton'

type AttendanceActionProps = Pick<
  IAttendance,
  '_id' | 'status' | 'itemList' | 'clinicId' | 'uuid'
>

const AttendanceActions = (props: {
  attendanceProps: AttendanceActionProps
  payoutProps?: IPayout[]
  componentRef?: React.MutableRefObject<null>
}) => {
  const history = useHistory()
  const showClasses = showStyles()
  const attendanceStatus = props.attendanceProps.status
  const itemList = props.attendanceProps.itemList
  const { permissions } = usePermissions()
  const mustRenderEditButton = attendanceStatus === ATTENDANCE_FINISHABLE_STATUS
  const mustRenderFinishButton =
    attendanceStatus === ATTENDANCE_FINISHABLE_STATUS
  const mustRenderManageAttendanceButtons =
    attendanceStatus === ATTENDANCE_ACCEPTABLE_STATUS
  const mustRenderChangeClinicButton =
    isAdmin(permissions) &&
    attendanceStatus === 'rejected' &&
    itemList?.findIndex(
      (item) => !!item.vaccineShot?.vaccineMeta || !!item.vaccineShot?.shotDate
    ) === -1
  const mustRenderUndoAcceptedAttendance =
    isAdmin(permissions) && attendanceStatus === 'accepted'
  const mustRenderReprocessPayout =
    isAdmin(permissions) &&
    !props.payoutProps?.some((payout) => payout.status === 'paid')
  const mustRenderUndoFinishAttendance =
    isAdmin(permissions) && attendanceStatus === 'finished'

  const mustRenderReviewAttendanceButtons =
    attendanceStatus === ATTENDANCE_REVIEWABLE_STATUS

  const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
  const open = Boolean(anchorEl)
  const { componentRef } = props

  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  //@ts-ignore
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget)
  }
  const handleClose = () => {
    setAnchorEl(null)
  }

  return (
    <div className={showClasses.headerButtons}>
      {mustRenderManageAttendanceButtons && (
        <div data-testid={`attendance-accept-${props.attendanceProps._id}`}>
          <ManageAttendanceButtons
            isMenuItem
            attendance={{
              _id: props.attendanceProps._id,
              itemList: props.attendanceProps.itemList,
              status: props.attendanceProps.status,
            }}
          />
        </div>
      )}
      {mustRenderReviewAttendanceButtons && (
        <div>
          <ReviewAttendanceButton
            attendance={{
              _id: props.attendanceProps._id,
              itemList: props.attendanceProps.itemList,
              status: props.attendanceProps.status,
            }}
          />
        </div>
      )}
      <IconButton
        id="basic-button"
        aria-controls={open ? 'basic-menu' : undefined}
        aria-haspopup="true"
        aria-expanded={open ? 'true' : undefined}
        onClick={handleClick}
        style={{ marginLeft: 'auto' }}
        data-testid="vertical-menu"
      >
        <MoreVert />
      </IconButton>
      <Menu
        id="basic-menu"
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
        MenuListProps={{
          'aria-labelledby': 'basic-button',
        }}
      >
        {isAdmin(permissions) &&
          props.attendanceProps.status === 'accepted' && (
            <GetRegisterPatientLinkPopup
              attendanceUUID={props.attendanceProps.uuid}
            />
          )}
        {isAdmin(permissions) &&
          props.attendanceProps.status === 'accepted' && (
            <ResetRegisterPatientLinkPopup
              attendanceId={props.attendanceProps._id}
            />
          )}
        {isAdmin(permissions) &&
          props.attendanceProps.status === 'accepted' && (
            <ResendPatientLinkMail attendanceId={props.attendanceProps._id} />
          )}

        {componentRef && (
          <div>
            <ReactToPrint
              trigger={() => <MenuItem>Imprimir</MenuItem>}
              content={() => componentRef.current}
            />
          </div>
        )}
        {mustRenderEditButton && (
          <div data-testid={`attendance-edit-${props.attendanceProps._id}`}>
            <MenuItem
              color="secondary"
              onClick={() => {
                history.push(`/attendance/${props.attendanceProps._id}`)
              }}
            >
              Editar agendamento
            </MenuItem>
          </div>
        )}
        {mustRenderFinishButton && (
          <div data-testid={`attendance-finish-${props.attendanceProps._id}`}>
            <FinishAttendanceButton
              isMenuItem
              attendance={{
                _id: props.attendanceProps._id,
                itemList: props.attendanceProps.itemList,
                status: props.attendanceProps.status,
              }}
            />
          </div>
        )}
        {mustRenderChangeClinicButton && (
          <div
            data-testid={`change-attendance-clinic-${props.attendanceProps._id}`}
          >
            <ChangeClinicButton
              isMenuItem
              attendance={{
                _id: props.attendanceProps._id,
                itemList: props.attendanceProps.itemList,
                status: props.attendanceProps.status,
              }}
            />
          </div>
        )}
        {isAdmin(permissions) && (
          <div
            data-testid={`attendance-upload-invoice-${props.attendanceProps._id}`}
          >
            <UploadInvoiceButton
              isMenuItem
              attendanceId={String(props.attendanceProps._id)}
            />
          </div>
        )}
        {mustRenderUndoAcceptedAttendance && (
          <div
            data-testid={`undo-attendance-accept-${props.attendanceProps._id}`}
          >
            <UndoAcceptedAttendanceButton
              isMenuItem
              permissions={permissions}
              attendance={{
                _id: props.attendanceProps._id,
                itemList: props.attendanceProps.itemList,
                status: props.attendanceProps.status,
              }}
            />
          </div>
        )}
        {mustRenderUndoFinishAttendance && (
          <div
            data-testid={`undo-attendance-finished-${props.attendanceProps._id}`}
          >
            <UndoFinishAttendanceButton
              isMenuItem
              permissions={permissions}
              attendance={{
                _id: props.attendanceProps._id,
                itemList: props.attendanceProps.itemList,
                status: props.attendanceProps.status,
              }}
            />
          </div>
        )}
        {mustRenderReprocessPayout && (
          <div data-testid={`reprocess-payout-${props.attendanceProps._id}`}>
            <ReprocessPayoutButton
              isMenuItem
              attendance={{
                _id: props.attendanceProps._id,
                itemList: props.attendanceProps.itemList,
                status: props.attendanceProps.status,
              }}
            />
          </div>
        )}
      </Menu>
    </div>
  )
}

export default AttendanceActions
