import { DropzoneArea } from 'material-ui-dropzone'
import React, { useContext } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { makeStyles } from '@material-ui/core'
import { Typography } from '@material-ui/core'
import { excelTemplateUrl } from '../../utils/utils'

const useStyles = makeStyles({
  dropzone: {
    minHeight: 52,
    borderColor: mainTheme.palette.primary.main,
    border: 'solid 2px',
  },
  textContainer: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
  },
  text: {
    fontSize: '1.3rem',
  },
  icon: {
    marginLeft: mainTheme.spacing(1),
    width: 40,
    height: 40,
  },
})

const UploadFileSession = () => {
  const context = useContext(OrderCreateContext)
  const { createMany, setFile } = context!
  const classes = useStyles()

  return createMany ? (
    <div style={{ marginTop: mainTheme.spacing(2), width: 932 }}>
      <DropzoneArea
        classes={{
          root: classes.dropzone,
          textContainer: classes.textContainer,
          icon: classes.icon,
          text: classes.text,
        }}
        acceptedFiles={[
          'application/vnd.oasis.opendocument.spreadsheet',
          'application/vnd.ms-excel',
          'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet',
        ]}
        filesLimit={1}
        onChange={(files) => {
          setFile(files[0])
        }}
        inputProps={{
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          'data-testid': 'dropzone',
        }}
        dropzoneText="Arraste e solte um arquivo ou clique aqui"
      />
      <div style={{ display: 'flex', width: '100%' }}>
        <Typography
          onClick={() => {
            window.open(excelTemplateUrl, '_blank')
          }}
          style={{
            marginTop: mainTheme.spacing(2),
            marginLeft: 'auto',
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          color="primary"
          variant="body1"
        >
          Clique aqui para baixar o template de aplicações
        </Typography>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default UploadFileSession
