import React from 'react'
import { Typography, Grid } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'

const HeaderDataCard = (props: {
  cardPrimary: {
    title: string
    value: string
  }
  cardSecondary?: {
    title: string
    value: string
  }
}) => {
  const { cardPrimary, cardSecondary } = props
  return (
    <Grid
      item
      xs
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: '1% 0px',
      }}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          justifyContent: 'center',
        }}
      >
        <Typography
          style={{
            marginBottom: mainTheme.spacing(1),
          }}
          align="center"
          variant="h3"
          color="textSecondary"
        >
          {cardPrimary.title}
        </Typography>
        <Typography
          style={{
            marginBottom: mainTheme.spacing(1),
            color: mainTheme.palette.primary.main,
          }}
          align="center"
          variant="h1"
        >
          {cardPrimary.value}
        </Typography>
      </div>
      {cardSecondary && (
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
            justifyContent: 'center',
          }}
        >
          <Typography
            style={{
              marginBottom: mainTheme.spacing(1),
              marginRight: mainTheme.spacing(1),
            }}
            align="center"
            variant="subtitle2"
            color="textSecondary"
          >
            {cardSecondary.title}
          </Typography>
          <Typography
            style={{
              marginBottom: mainTheme.spacing(1),
              fontWeight: 'bold',
              color: '#1D1D1B',
            }}
            align="center"
            variant="subtitle2"
          >
            {cardSecondary.value}
          </Typography>
        </div>
      )}
    </Grid>
  )
}
export default HeaderDataCard
