import useStyles from '../../utils/creationFormStyles'
import * as React from 'react'
import { Typography } from '@material-ui/core'
import OpeningHoursScheduleInput from '../../components/OpeningHours/OpeningHoursScheduleInput'
import { mainTheme } from '../../utils/mainTheme'
import { BooleanInput, TextInput } from 'react-admin'
import { useFormState } from 'react-final-form'
import FreightValuesState from './FreightValueSession/FreightValuesState'

interface ScheduleTimeFormProps {
  source: string
  attendanceTypeEnabledSource: string
  attendanceCapacityPerHourSource: string
  enableCheckboxLabel: string
  showFreightValue?: boolean
}

const ScheduleTimeForm = (props: ScheduleTimeFormProps) => {
  const classes = useStyles()
  const { values } = useFormState()

  return (
    <div className={classes.tabContainer}>
      <div style={{ width: '100%' }}>
        <BooleanInput
          source={props.attendanceTypeEnabledSource}
          label={props.enableCheckboxLabel}
        />
      </div>

      {props.showFreightValue && (
        <div>
          <FreightValuesState
            disabled={!values[props.attendanceTypeEnabledSource]}
          />
        </div>
      )}

      <div>
        <Typography variant="h2" className={classes.header}>
          Horários de funcionamento
        </Typography>
        <OpeningHoursScheduleInput
          attendanceTypeEnabledSource={props.attendanceTypeEnabledSource}
          source={props.source}
        />
      </div>

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          marginTop: mainTheme.spacing(2),
          maxWidth: 300,
        }}
      >
        <Typography variant="h2">Atendimentos disponíveis por hora</Typography>
        <TextInput
          source={props.attendanceCapacityPerHourSource}
          label="Atendimentos / Hora"
          variant="outlined"
          type="number"
          disabled={!values[props.attendanceTypeEnabledSource]}
        />
      </div>
    </div>
  )
}

export default ScheduleTimeForm
