import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const CommercialConditionStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'active':
      statusText = 'Ativa'
      statusColor = mainTheme.palette.secondary.main
      break
    case 'archived':
      statusText = 'Arquivada'
      statusColor = mainTheme.palette.error.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default CommercialConditionStatusChip
