import * as React from 'react'
import { format } from 'date-fns'

import {
  Datagrid,
  FunctionField,
  List,
  Record,
  TextField,
  useNotify,
  ListProps,
  NumberField,
} from 'react-admin'
import { IconButton } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import CircularProgress from '@material-ui/core/CircularProgress'
import CampaignStatusChip from '../../../components/chips/CampaignStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import CampaignListFilters from './CampaignListFilters'
import CampaignListActions from './CampaignListActions'
import httpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'
import { useState } from 'react'
import { downloadContent, valueInCentsToBRL } from '../../../utils/utils'

const CampaignList = ({ ...props }: ListProps) => {
  const notify = useNotify()
  const [loadingCampaignId, setLoadingCampaignId] = useState('')

  const handleDownloadVouchersClick = async (record: Record | undefined) => {
    try {
      setLoadingCampaignId(String(record?.id))
      const url = `${apiUrl}/${props.resource}/${record?.id}/vouchers`
      const { body } = await httpClient(url)
      downloadContent(body, `${record?.name}.csv`)
    } catch (error) {
      console.error(error)
      notify('Erro ao baixar vouchers da campanha', 'error')
    } finally {
      setLoadingCampaignId('')
    }
  }
  return (
    <List
      {...props}
      pagination={<DefaultPagination />}
      bulkActionButtons={false}
      filters={<CampaignListFilters />}
      actions={<CampaignListActions />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid stickyHeader rowClick="edit">
        <TextField source="name" textAlign="center" label="Nome" />
        <FunctionField
          textAlign="center"
          label="Período"
          render={(record: Record | undefined) => {
            return `${format(
              new Date(record?.dateRange.startDate),
              'dd/MM/yy'
            )} - ${format(new Date(record?.dateRange.endDate), 'dd/MM/yy')}`
          }}
        />
        <NumberField
          textAlign="center"
          label="Clínicas"
          source="clinicsQuantity"
        />
        <FunctionField
          textAlign="center"
          label="Status"
          render={(record: Record | undefined) => (
            <CampaignStatusChip
              startDate={record?.dateRange.startDate}
              endDate={record?.dateRange.endDate}
            />
          )}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => record?.ordersQuantity || '-'}
          label="Quantidade de pedidos"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.campaignTotalSoldInCents
              ? valueInCentsToBRL(record?.campaignTotalSoldInCents)
              : '-'
          }
          label="Valor total de vendas"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.campaignTotalHoldValueInCents
              ? valueInCentsToBRL(record?.campaignTotalHoldValueInCents)
              : '-'
          }
          label="Valor faturado Vacinas.net"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.campaignTotalPayoutValueCents
              ? valueInCentsToBRL(record?.campaignTotalPayoutValueCents)
              : '-'
          }
          label="Valor faturado Clínica"
        />
        <FunctionField
          textAlign="center"
          label="Vouchers"
          render={(record: Record | undefined) => (
            <IconButton
              color="primary"
              onClick={(e) => {
                e.stopPropagation()
                handleDownloadVouchersClick(record)
              }}
              disabled={!!loadingCampaignId}
            >
              {loadingCampaignId == record?.id ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                <CloudDownload />
              )}
            </IconButton>
          )}
        />
      </Datagrid>
    </List>
  )
}
export default CampaignList
