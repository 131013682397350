import { Container, ThemeProvider } from '@material-ui/core'
import React from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { authStyles } from './AuthStyles'
import ResetPasswordForm from './ResetPasswordForm'
import vacinasLogo from '../../assets/VacinasLogo.svg'
import vacinasWatermark from '../../assets/VacinasWatermark.svg'
import { Notification } from 'react-admin'

const ResetPasswordPage = () => {
  const classes = authStyles()
  return (
    <ThemeProvider theme={mainTheme}>
      <Container className={classes.loginContainer} maxWidth={false}>
        <div className={classes.innerContainer}>
          <ResetPasswordForm />
          <div className={classes.watermarkWraper}>
            <img className={classes.watermark} src={vacinasWatermark} />
          </div>
          <Notification />
        </div>
        <div
          className={classes.innerContainer}
          style={{
            backgroundColor: mainTheme.palette.primary.main,
          }}
        >
          <img src={vacinasLogo} />
        </div>
      </Container>
    </ThemeProvider>
  )
}

export default ResetPasswordPage
