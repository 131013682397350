import * as React from 'react'
import { Button, Dialog, DialogContent, makeStyles } from '@material-ui/core'
import {
  Show,
  ShowProps,
  TopToolbar,
  useShowController,
  ShowActionsProps,
  useNotify,
  useRefresh,
  usePermissions,
} from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import OrderSection from './ShowSections/OrderSection'
import CustomerSection from './ShowSections/CustomerSection'
import PaymentSection from './ShowSections/PaymentSection'
import AttendancesSection from './ShowSections/AttendancesSection'
import PayoutSection from './ShowSections/PayoutSection'
import CampaignLabel from './CampaignLabel'
import NewAttendanceForm from '../../../components/NewAttendanceForm'
import { IAttendance, isAdmin } from '@vacinas-net/shared'
import customHttpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'
import { setHours, setMinutes } from 'date-fns'
import { formatISO } from 'date-fns/esm'
import CancelOrderPopUp from '../../../components/CancelOrderPopUp'
import { AddCircle } from '@material-ui/icons'
import { stopPropagation } from '../../../utils/utils'

export const useStyles = makeStyles({
  ordersActionContainer: {
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'row',
    justifyContent: 'flex-start',
    marginBottom: mainTheme.spacing(1),
  },
})

const OrdersShow = (props: ShowProps) => {
  const [loading, setLoading] = React.useState(false)
  const [openNewAttendanceForm, setOpenNewAttendanceForm] =
    React.useState(false)
  const { record, loaded } = useShowController(props)

  const notify = useNotify()
  const refresh = useRefresh()
  const { permissions } = usePermissions()

  const cancelOrderButtonShouldBeActive =
    record?.status === 'awaiting_clinic_confirmation' && isAdmin(permissions)

  const OrdersShowActions = (props: ShowActionsProps) => {
    const classes = useStyles()

    return (
      <TopToolbar className={classes.ordersActionContainer}>
        <HeaderWithGoBack title="Detalhes do pedido" />
        <CampaignLabel data={props.data} />

        {cancelOrderButtonShouldBeActive && (
          <div
            style={{ marginRight: mainTheme.spacing(2), marginLeft: 'auto' }}
          >
            <CancelOrderPopUp record={record} />
          </div>
        )}
      </TopToolbar>
    )
  }

  const AddNewAttendance = (data: IAttendance) => {
    setLoading(true)
    let parsedEndDate = new Date(data!.deliveryWindow!.startDate!)
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    const [hours, minutes] = data.deliveryWindow?.endDate!.split(':')
    parsedEndDate = setHours(parsedEndDate, hours)
    parsedEndDate = setMinutes(parsedEndDate, minutes)
    customHttpClient(`${apiUrl}/attendance/create`, {
      method: 'post',
      body: JSON.stringify({
        vendorOrderId: record?.orderId,
        orderId: record?.id,
        clinicId: data.clinic?._id,
        clinic: {
          _id: data.clinic?._id,
          name: data.clinic?.name,
        },
        type: data.type,
        address: data.address || undefined,
        deliveryWindow: {
          ...data.deliveryWindow,
          startDate: formatISO(new Date(data.deliveryWindow!.startDate!)),
          endDate: formatISO(new Date(parsedEndDate)),
        },
        customer: record?.customer,
        pickupPointId: data.pickupPointName || undefined,
        shippingPriceInCents: data.shippingPriceInCents || undefined,
      }),
    })
      .then(() => {
        notify(`Atendimento criado com sucesso`, 'success')
        setOpenNewAttendanceForm(false)
      })
      .catch((error) => {
        notify(`Erro ao criar atendimento: ${error}`, 'error')
        setOpenNewAttendanceForm(false)
      })
      .finally(() => {
        setLoading(false)
        refresh()
      })
  }

  const showCreateAttendanceButton = () => {
    return (
      isAdmin(permissions) &&
      record?.status !== 'finished' &&
      record?.status !== 'cancelled'
    )
  }

  return (
    <Show actions={<OrdersShowActions />} component="div" {...props}>
      <>
        {loaded && (
          <>
            <OrderSection />
            <CustomerSection />
            <AttendancesSection record={record} />
            {showCreateAttendanceButton() && (
              <div
                style={{
                  marginLeft: mainTheme.spacing(2),
                }}
              >
                <Button
                  onClick={() => {
                    setOpenNewAttendanceForm(true)
                  }}
                  color="primary"
                  variant="outlined"
                  fullWidth
                  startIcon={<AddCircle />}
                >
                  Novo atendimento
                </Button>
              </div>
            )}
            {record?.payments && <PaymentSection record={record} />}
            <PayoutSection record={record} />
            <Dialog
              onKeyDown={stopPropagation}
              maxWidth="md"
              fullWidth
              open={openNewAttendanceForm}
              onClose={() => setOpenNewAttendanceForm(false)}
            >
              <DialogContent data-testid={'dialog-content-new-attendance'}>
                <NewAttendanceForm
                  onClose={() => {
                    setOpenNewAttendanceForm(false)
                  }}
                  onSubmit={(data) => {
                    AddNewAttendance(data)
                  }}
                  loading={loading}
                />
              </DialogContent>
            </Dialog>
          </>
        )}
      </>
    </Show>
  )
}

export default OrdersShow
