import MultiAutocompleteInput from '../../../components/Inputs/MultiAutocompleteInput/MultiAutocompleteInput'
import * as React from 'react'
import { cnpjFormatter } from '../../../utils/formatters'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import CustomDateInput from '../../../components/Inputs/CustomDateInput'
import { BooleanInput, SelectArrayInput, SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import StateAndCitySearchInput from '../../../components/Inputs/StateAndCitySearchInput'

export const OrderListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'relatedClinics->_id',
      label: 'CNPJ da Clínica',
      FilterInput: (
        <MultiAutocompleteInput
          resourceToFetch="clinic"
          source="relatedClinics->_id"
          label="CNPJ da Clínica"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
            minWidth: 250,
            marginTop: 8,
            paddingBottom: 8,
          }}
          optionTypeLabel="cnpj"
          parseFilterToRequest={(filter: string) => {
            return filter.replace(/\D/g, '')
          }}
          parseLabel={(itemToParse: string) => {
            return String(cnpjFormatter.format(itemToParse))
          }}
        />
      ),
    },
    {
      value: 'customer->document',
      label: 'CPF do Cliente',
      FilterInput: (
        <SearchFilterInput
          label="Cpf do Cliente"
          source="customer->document"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'marketingData->couponCode',
      label: 'Código do cupom',
      FilterInput: (
        <SearchFilterInput
          label="Código do cupom"
          source="marketingData->couponCode"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'payments->voucher->redemptionCode',
      label: 'Código de vale',
      FilterInput: (
        <SearchFilterInput
          label="Código de vale"
          source="payments->voucher->redemptionCode"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'purchasedAt(max)',
      label: 'Comprado antes de',
      FilterInput: (
        <CustomDateInput
          label="Comprado antes de"
          source="purchasedAt(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'purchasedAt(min)',
      label: 'Comprado depois de',
      FilterInput: (
        <CustomDateInput
          label="Comprado depois de"
          source="purchasedAt(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },

    {
      value: 'customer->email',
      label: 'Email do cliente',
      FilterInput: (
        <SearchFilterInput
          label="Email do cliente"
          source="customer->email"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },

    {
      value: 'finishedAt(max)',
      label: 'Finalizado antes de',
      FilterInput: (
        <CustomDateInput
          label="Finalizado antes de"
          source="finishedAt(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={23}
          minutes={59}
          seconds={59}
        />
      ),
    },
    {
      value: 'finishedAt(min)',
      label: 'Finalizado depois de',
      FilterInput: (
        <CustomDateInput
          label="Finalizado depois de"
          source="finishedAt(min)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
          hours={0}
          minutes={0}
          seconds={0}
        />
      ),
    },

    {
      value: 'payments->method',
      label: 'Meio de pagamento',
      FilterInput: (
        <SelectInput
          source="payments->method"
          label="Meio de pagamento"
          choices={[
            { id: 'creditCard', name: 'Cartão de crédito' },
            { id: 'voucher', name: 'Voucher' },
            { id: 'bankPaymentSlip', name: 'Boleto' },
            { id: 'pix', name: 'Pix' },
            { id: 'unknow', name: 'Indefinido' },
          ]}
          variant={'outlined'}
          size={'small'}
          style={{ ...inlineInputStyle, minWidth: 220 }}
        />
      ),
    },

    {
      value: 'relatedCampaigns->name',
      label: 'Nome da Campanha',
      FilterInput: (
        <SearchFilterInput
          label="Nome da Campanha"
          source="relatedCampaigns->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'customer->name',
      label: 'Nome do Cliente',
      FilterInput: (
        <SearchFilterInput
          label="Nome do Cliente"
          source="customer->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'relatedClinics->name',
      label: 'Nome da Clínica',
      FilterInput: (
        <SearchFilterInput
          label="Nome da Clínica"
          source="relatedClinics->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },

    {
      value: 'orderId',
      label: 'Número do pedido',
      FilterInput: (
        <SearchFilterInput
          label="Número do pedido"
          source="orderId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },

    {
      value: 'relatedCampaigns->0(exists)',
      label: 'Pedido de campanha',
      FilterInput: (
        <BooleanInput
          label="Pedido de campanha"
          source="relatedCampaigns->0(exists)"
          style={{
            marginTop: 8,
            marginBottom: 8,
          }}
          variant={'outlined'}
        />
      ),
    },
    {
      value: 'isCreatedManually(exists)',
      label: 'Pedido manual',
      FilterInput: (
        <BooleanInput
          label="Pedido manual"
          source="isCreatedManually(exists)"
          style={{
            marginTop: 8,
            marginBottom: 8,
          }}
          variant={'outlined'}
        />
      ),
    },
    {
      value: 'packages->pickupPointName',
      label: 'Ponto de retirada',
      FilterInput: (
        <SearchFilterInput
          label="Ponto de retirada"
          source="packages->pickupPointName"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },

    {
      value: 'status',
      label: 'Situação',
      FilterInput: (
        <SelectInput
          source="status"
          label="Situação"
          choices={[
            { id: 'under_analysis', name: 'Em análise' },
            {
              id: 'awaiting_clinic_confirmation',
              name: 'Aguardando resposta clínica',
            },
            { id: 'rejected', name: 'Rejeitado' },
            { id: 'readyForHandling', name: 'Aguardando atendimentos' },
            { id: 'finished', name: 'Finalizado' },
            { id: 'cancelled', name: 'Cancelado' },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },

    {
      value: 'payouts->status',
      label: 'Situação do repasse',
      FilterInput: (
        <SelectArrayInput
          source="payouts->status"
          label="Situação do repasse"
          choices={[
            { id: 'processing', name: 'Em Processamento' },
            { id: 'readyToPay', name: 'Pronto para Repasse' },
            { id: 'paid', name: 'Repasse Realizado' },
            { id: 'cancelled', name: 'Cancelado' },
          ]}
          variant={'outlined'}
          size={'small'}
          style={{
            minWidth: 250,
          }}
        />
      ),
    },

    {
      value: 'items->product->skuId',
      label: 'SKU',
      FilterInput: (
        <MultiAutocompleteInput
          resourceToFetch="product-sku"
          source="items->product->skuId"
          label="SKU"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
            minWidth: 250,
            marginTop: 8,
            paddingBottom: 8,
          }}
          optionTypeLabel="skuId"
          optionTypeValue="skuId"
        />
      ),
    },

    {
      value: 'shippingAddress->type',
      label: 'Tipo de atendimento',
      FilterInput: (
        <SelectInput
          source="shippingAddress->type"
          label="Tipo de atendimento"
          choices={[
            { id: 'delivery', name: 'Em casa' },
            { id: 'pickup', name: 'Na clínica' },
            { id: 'company', name: 'Na empresa' },
          ]}
          variant={'outlined'}
          size={'small'}
          style={{ ...inlineInputStyle, minWidth: 220 }}
        />
      ),
    },
    {
      value: 'productsAmount(min)',
      label: 'Total de Produtos em Pedido',
      FilterInput: (
        <SearchFilterInput
          label="Total de Produtos em Pedido"
          source="productsAmount(min)"
          style={{
            ...inlineInputStyle,
            minWidth: 250,
          }}
          variant={'outlined'}
          size={'small'}
          type="number"
        />
      ),
    },
    {
      value: 'shippingAddress_multiple',
      label: 'Região',
      FilterInput: (
        <StateAndCitySearchInput
          source="shippingAddress_multiple"
          stateKey="shippingAddress.state"
          cityKey="shippingAddress.city"
        />
      ),
    },
    {
      value: 'campaings->handler',
      label: 'Tipo de campanha',
      FilterInput: (
        <SelectInput
          source="campaings->handler"
          label="Tipo de campanha"
          choices={[
            { id: 'jenner', name: 'Jenner' },
            {
              id: 'vtex',
              name: 'VTEX',
            },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 200,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
