import React, { useState } from 'react'
import { Button, CircularProgress } from '@material-ui/core'
import AddSingleAllowedCustomerFormModal from './AddSingleAllowedCustomerFormModal'
import { Add } from '@material-ui/icons'
import { isBefore } from 'date-fns'
import { useRecordContext } from 'react-admin'
import { ICampaignV2 } from '@vacinas-net/shared'
import { mainTheme } from '../../../../utils/mainTheme'

const AddSingleAllowedCustomerButton = () => {
  const record = useRecordContext() as ICampaignV2
  const [openModal, setOpenModal] = useState(false)
  const [loading, setLoading] = useState(false)

  const isCampaignActive = () => {
    const endDateFormatted = new Date(String(record.dateRange?.endDate))
    return isBefore(Date.now(), endDateFormatted)
  }
  if (!isCampaignActive()) return <></>

  return (
    <div style={{ marginLeft: mainTheme.spacing(1) }}>
      <Button
        startIcon={<Add />}
        color="primary"
        variant="contained"
        onClick={() => setOpenModal(true)}
        disabled={loading}
      >
        {loading ? <CircularProgress size={16} /> : 'Adicionar beneficiário'}
      </Button>
      <AddSingleAllowedCustomerFormModal
        open={openModal}
        setOpen={(state: boolean) => {
          setOpenModal(state)
        }}
        loading={loading}
        setLoading={(state: boolean) => {
          setLoading(state)
        }}
      />
    </div>
  )
}

export default AddSingleAllowedCustomerButton
