import { ReportTypeEnum } from '@vacinas-net/shared'
import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { VouchersListFiltersArray } from './Filters'

const VoucherPackagesActions = () => {
  return (
    <CustomListActions
      filtersAvailable={VouchersListFiltersArray.map((item) => ({
        value: item.value,
        label: item.label,
      }))}
      hasExport
      exportType={ReportTypeEnum.VoucherPackage}
      exportButtonText="Exportar Pacotes de Vales"
    />
  )
}

export default VoucherPackagesActions
