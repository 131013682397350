import * as React from 'react'
import _ from 'lodash'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import MultiAutocompleteInput from '../../../components/Inputs/MultiAutocompleteInput/MultiAutocompleteInput'
import { formatCPF } from '@brazilian-utils/brazilian-utils'
import { ICampaignV2 } from '@vacinas-net/shared'

export const generateAllowedCustomerFiltersArray = (props: {
  record: ICampaignV2
}) => {
  const allowedCustomerFiltersArray: {
    value: string
    label: string
    FilterInput: JSX.Element
  }[] = _.sortBy(
    [
      {
        value: 'document',
        label: 'CPF',
        FilterInput: (
          <MultiAutocompleteInput
            resourceToFetch="campaign/allowed-customer"
            source="document"
            label="CPF"
            style={{
              ...inlineInputStyle,
              minWidth: 250,
              marginTop: 8,
              paddingBottom: 8,
            }}
            variant={'outlined'}
            size={'small'}
            defaultFilters={{
              campaignId: props.record._id,
            }}
            optionTypeLabel="document"
            optionTypeValue="document"
            parseFilterToRequest={(filter: string) => {
              return filter.replace(/\D/g, '')
            }}
            parseLabel={(itemToParse: string) => {
              return formatCPF(itemToParse)
            }}
          />
        ),
      },
      {
        value: 'fullNames',
        label: 'Nome',
        FilterInput: (
          <MultiAutocompleteInput
            resourceToFetch="campaign/allowed-customer/name"
            source="fullNames"
            label="Nome"
            style={{
              ...inlineInputStyle,
              minWidth: 250,
              marginTop: 8,
              paddingBottom: 8,
            }}
            variant={'outlined'}
            size={'small'}
            defaultFilters={{
              campaignsId: props.record._id,
            }}
            optionTypeLabel="fullName"
            optionTypeValue="fullName"
          />
        ),
      },
      {
        value: 'emails',
        label: 'E-mail',
        FilterInput: (
          <MultiAutocompleteInput
            resourceToFetch="campaign/allowed-customer/email"
            source="emails"
            label="E-mail"
            style={{
              ...inlineInputStyle,
              minWidth: 250,
              marginTop: 8,
              paddingBottom: 8,
            }}
            variant={'outlined'}
            size={'small'}
            defaultFilters={{
              campaignsId: props.record._id,
            }}
            optionTypeLabel="email"
            optionTypeValue="email"
          />
        ),
      },
    ],
    'label'
  )

  return allowedCustomerFiltersArray
}
