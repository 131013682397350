import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../../components/CustomHttpClient'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'

const JennerVoucherListActionsPopup = (props: {
  voucherPackageId: string
  openDialog: boolean
  handleCloseDialog: () => void
}) => {
  const record = useRecordContext()
  const { voucherPackageId, openDialog, handleCloseDialog } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)

  const requestVouchers = async () => {
    setLoading(true)

    const url = `${apiUrl}/voucherPackage/${voucherPackageId}/addVouchers`
    httpClient(url, {
      method: 'post',
    })
      .then(() => {
        notify('Vales disponibilizados com sucesso')
        handleCloseDialog()
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao disponibilizar vales', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth="sm">
      <DialogTitle
        style={{
          textAlign: 'center',
          paddingBottom: 0,
        }}
      >
        Disponibilização de vales
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Tem certeza que gostaria de disponibilizar os vouchers pedidos?
        </DialogContentText>
      </DialogContent>
      <Typography
        style={{
          marginLeft: mainTheme.spacing(4),
        }}
        variant="body1"
        color="textSecondary"
      >
        Vales solicitados: {record?.requestedQuantity}
      </Typography>
      <DialogActions
        style={{
          marginTop: mainTheme.spacing(1),
          marginBottom: mainTheme.spacing(1),
          marginRight: mainTheme.spacing(2),
        }}
      >
        <Button color="primary" variant="contained" onClick={handleCloseDialog}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            requestVouchers()
          }}
        >
          {loading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default JennerVoucherListActionsPopup
