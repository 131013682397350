import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import { isAfter, isBefore } from 'date-fns'

const CampaignStatusChip = (props: { startDate: string; endDate: string }) => {
  let statusText
  let statusColor
  const startDateFormatted = new Date(props.startDate)
  const endDateFormatted = new Date(props.endDate)
  if (isBefore(Date.now(), startDateFormatted)) {
    statusText = 'Futura'
    statusColor = mainTheme.palette.primary.main
  } else if (
    isAfter(Date.now(), startDateFormatted) &&
    isBefore(Date.now(), endDateFormatted)
  ) {
    statusText = 'Ativa'
    statusColor = mainTheme.palette.secondary.main
  } else {
    statusText = 'Arquivada'
    statusColor = mainTheme.palette.error.main
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default CampaignStatusChip
