import { Button, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { Confirm, useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'

const OfferClinicItemsAcceptButton = (props: {
  clinicId: string
  offerId: string
}) => {
  const { clinicId, offerId } = props
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleConfirm = () => {
    setLoading(true)
    HttpClient(
      `${apiUrl}/joint-purchase/offer-clinic-items/${offerId}/accept`,
      {
        method: 'post',
        body: JSON.stringify({
          clinicId: clinicId,
        }),
      }
    )
      .then(() => {
        notify(`Pedido confirmado com sucesso`, 'success')
        refresh()
      })
      .catch((error) => {
        notify(`Erro ao confirmar compra: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
      })
  }
  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={() => {
          setOpenConfirm(true)
        }}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          'Confirmar pedido'
        )}
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está confirmando o pedido"
        content="Deseja confirmar essa ação?"
        onConfirm={handleConfirm}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </>
  )
}

export default OfferClinicItemsAcceptButton
