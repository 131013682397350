import React, { useState } from 'react'
import { Button } from '@material-ui/core'
import { Confirm, useNotify, usePermissions, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import {
  ATTENDANCE_ACCEPTABLE_STATUS,
  IAttendance,
  isAdmin,
} from '@vacinas-net/shared'
import { Check } from '@material-ui/icons'

interface ReleaseeAttendanceButtonProps {
  attendance: IAttendance
  isMenuItem?: boolean
}

const acceptButtonShouldBeActive = (
  attendance: IAttendance,
  permissions: string[] | undefined
) => {
  const attendanceStatus = attendance.status
  if (attendanceStatus === 'accepted') return false
  if (isAdmin(permissions || [])) return true
  return attendanceStatus === ATTENDANCE_ACCEPTABLE_STATUS
}

const ReviewAttendanceButton = (props: ReleaseeAttendanceButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const { permissions } = usePermissions()

  const notify = useNotify()
  const refresh = useRefresh()

  const reviewAttendance = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendance._id}/review`
    httpClient(url, { method: 'post' })
      .then(() => {
        notify('Atendimento liberado com sucesso')
        setOpenConfirm(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao liberar o atendimento', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        disabled={!acceptButtonShouldBeActive(props.attendance, permissions)}
        size="medium"
        color="secondary"
        variant="outlined"
        onClick={() => {
          setOpenConfirm(true)
        }}
        data-testid={`order-id-${props.attendance._id}`}
        startIcon={<Check />}
      >
        Liberar atendimento
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está liberando o atendimento"
        content="Deseja confirmar essa ação?"
        onConfirm={() => reviewAttendance()}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default ReviewAttendanceButton
export { acceptButtonShouldBeActive }
