import * as React from 'react'
import { List, ListProps, Datagrid, TextField, DateField } from 'react-admin'
import { MasterDataPagination } from '../../../components/Pagination'
import UnavailableProductsActions from './UnavailableProductsActions'
import UnavailableProductsFilters from './UnavailableProductsFilters'

const UnavailableProductsList = (props: ListProps) => {
  return (
    <>
      <List
        {...props}
        pagination={<MasterDataPagination />}
        bulkActionButtons={false}
        sort={{ field: 'createdIn', order: 'DESC' }}
        actions={<UnavailableProductsActions />}
        filters={<UnavailableProductsFilters />}
      >
        <Datagrid>
          <DateField
            textAlign="center"
            source="createdIn"
            label="Data de criação"
          />
          <TextField textAlign="center" source="name" label="Nome" />
          <TextField textAlign="center" source="email" label="E-mail" />
          <TextField textAlign="center" source="sku" label="SKU" />
          <TextField
            textAlign="center"
            source="skuName"
            label="Nome do produto"
          />
          <TextField textAlign="center" source="postalcode" label="CEP" />
          <TextField textAlign="center" source="state" label="Estado" />
          <TextField textAlign="center" source="city" label="Cidade" />
          <TextField textAlign="center" source="neighborhood" label="Bairro" />
        </Datagrid>
      </List>
    </>
  )
}

export default UnavailableProductsList
