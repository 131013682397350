import {
  IAddress,
  IPayment,
  IPayout,
  IDateRange,
  RolesEnum,
  IOrder,
  IUserClinic,
  IUserClinicGroup,
  IAttendanceInvoice,
} from '@vacinas-net/shared'
import { format, formatDistanceStrict } from 'date-fns'
import { fetchUtils, Record } from 'react-admin'
import utcToZonedTime from 'date-fns-tz/utcToZonedTime'
import { ptBR } from 'date-fns/locale'
import queryString from 'query-string'
import { formatCurrency } from '@brazilian-utils/brazilian-utils'
import { ProductsBulkActionsForm } from '../resources/products/ProductsList/ProductsBulkActions'
import Papa from 'papaparse'

export const googleApiUrl = process.env.REACT_APP_GOOGLE_MAP_URL_KEY as string
export const excelTemplateUrl = process.env
  .REACT_APP_XLSX_ORDER_BATCH_TEMPLATE_URL as string

export const resolveRelatedCampaignsLabel = (
  relatedCampaigns: IOrder['relatedCampaigns']
) => {
  const campaignNames = relatedCampaigns
    ?.map((campaign) => campaign.name)
    .join(', ')

  return campaignNames
}

export const dateToCorrectISOString = (date: string) => {
  const getTimeZone = () => {
    return Intl.DateTimeFormat().resolvedOptions().timeZone
  }
  const timeZone = getTimeZone()
  const zonedTime = utcToZonedTime(date, timeZone)
  return zonedTime.toISOString()
}

export const valueInCentsToBRL = (centsValue: number) => {
  return centsValue ? `R$${formatCurrency(centsValue / 100)}` : '-'
}

export const valueInCentsToBRLWithSpace = (centsValue: number) => {
  return centsValue ? `R$ ${formatCurrency(centsValue / 100)}` : '-'
}

export const formatDate = (date: string) => {
  return date ? format(new Date(date), 'dd/MM/yy') : '-'
}

export const formatPhone = (phone: string) => {
  if (!phone) return '-'
  const phoneParts = phone
    .replace(/D/, '')
    .match(/(\d{2})(\d{2})(\d{4,5})(\d{4})$/)
  return (
    phoneParts &&
    `+${phoneParts[1]} (${phoneParts[2]}) ${phoneParts[3]}-${phoneParts[4]}`
  )
}

export const parsePaymentMethod = (payment: IPayment) => {
  switch (payment.method) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    //@ts-ignore
    case 'cash':
      return 'Dinheiro'
    case 'voucher':
      return 'Voucher'
    case 'creditCard':
      return 'Cartão de Crédito'
    case 'bankPaymentSlip':
      return 'Boleto'
    case 'pix':
      return 'Pix'
    default:
      return '-'
  }
}

export const parsePayoutPaymentInfo = (
  payoutPaymentInfo: IPayout['paymentInfo']
) => {
  switch (payoutPaymentInfo?.method) {
    case 'voucher':
      return 'Voucher'
    case 'bankPaymentSlip':
      return 'Boleto'
    case 'creditCard':
      if (!payoutPaymentInfo.creditCard) return 'Cartão de crédito'
      return `${payoutPaymentInfo.creditCard?.creditCardBrand}, ${payoutPaymentInfo.creditCard?.currentInstallmentNumber}ª parcela de ${payoutPaymentInfo.creditCard?.totalInstallmentCount}`
    case 'pix':
      return 'Pix'
    default:
      return '-'
  }
}

export const parsePayoutType = (payoutType: IPayout['type']) => {
  switch (payoutType) {
    case 'product':
      return 'Produto'
    case 'shipping':
      return 'Frete'
    case 'paymentMethod':
      return 'Meio de pagamento'
    case 'commission':
      return 'Comissão'
    default:
      return '-'
  }
}

export const parseAddress = (adressObject: IAddress | undefined) => {
  if (!adressObject) return '-'
  const streetName = adressObject.streetName || ''
  const streetNumber = adressObject.streetNumber || ''
  const neighborhood = adressObject.neighborhood || ''
  const city = adressObject.city || ''
  const state = adressObject.state || ''
  const complement = adressObject.complement || ''
  return [streetName, streetNumber, neighborhood, complement, city, state]
    .filter((item) => !!item?.trim())
    .join(', ')
}

export const parseDeliveryWindow = (deliveryWindow: IDateRange | undefined) => {
  if (
    !deliveryWindow ||
    !deliveryWindow?.startDate ||
    !deliveryWindow?.endDate
  ) {
    return 'Não agendado'
  }
  return `${format(new Date(deliveryWindow.startDate), 'dd/MM/yy')}, ${format(
    new Date(deliveryWindow.startDate),
    'HH:mm'
  )} às ${format(new Date(deliveryWindow.endDate), 'HH:mm')}.`
    .split(':')
    .join('h')
}

export const getDateHours = (date: Date) => format(date, 'HH:mm')

export const downloadContent = (
  content: string,
  nameWithExtension: string
): void => {
  const downloadUrl = window.URL.createObjectURL(new Blob([content]))
  const link = document.createElement('a')
  link.target = '_blank'
  link.download = nameWithExtension
  link.href = downloadUrl
  document.body.appendChild(link)
  link.click()
  link.remove()
}

export const parsePayoutDescription = (record: Record | undefined) => {
  const parsedPaymentMethod = parsePayoutPaymentInfo(record?.paymentInfo)
  if (parsedPaymentMethod === '-') return parsedPaymentMethod
  if (record?.type === 'paymentMethod') {
    return `Taxa do ${parsedPaymentMethod.toLowerCase()}`
  }
  return parsedPaymentMethod
}

export const uploadFile = async (
  signedUrl: string,
  file: File,
  attachment?: boolean
) => {
  return fetchUtils.fetchJson(signedUrl, {
    method: 'put',
    body: file,
    headers: new Headers({
      'Content-Type': file.type,
      'Content-Disposition': attachment
        ? `attachment; filename="${file.name}"`
        : '',
    }) as Headers,
  })
}

export const translateSystemRole = (role: RolesEnum) => {
  switch (role) {
    case 'admin':
      return 'Administrador Geral'
      break
    case 'clinic_admin':
      return 'Administrador da Clínica'
      break
    case 'clinic_group_admin':
      return 'Administrador da Rede'
      break
    case 'clinic_operator':
      return 'Operador da Clínica'
      break
    case 'enterprise':
      return 'Empresa'
      break
    case 'customer':
      return 'Cliente'
      break
    default:
      return '-'
      break
  }
}

export const parseUserRelatedClinics = (relatedClinics: IUserClinic[]) => {
  const firstClinic = relatedClinics[0]
    ? `${relatedClinics[0].name}`
    : undefined
  const secondClinic = relatedClinics[1]
    ? `${relatedClinics[1].name}`
    : undefined
  const thirdClinic = relatedClinics[2]
    ? `${relatedClinics[2].name}`
    : undefined
  const length = relatedClinics.length

  return `${firstClinic ? `${firstClinic}` : ''}${
    secondClinic ? `, ${secondClinic}` : ''
  }${thirdClinic ? `, ${thirdClinic}` : ''}${length > 3 ? ', ...' : ''}`
}

export const parseUserRelatedGroups = (
  relatedClinicGroups: IUserClinicGroup[]
) => {
  const firstClinic = relatedClinicGroups[0]
    ? `${relatedClinicGroups[0].name}`
    : undefined
  const secondClinic = relatedClinicGroups[1]
    ? `${relatedClinicGroups[1].name}`
    : undefined
  const thirdClinic = relatedClinicGroups[2]
    ? `${relatedClinicGroups[2].name}`
    : undefined
  const length = relatedClinicGroups.length

  return `${firstClinic ? `${firstClinic}` : ''}${
    secondClinic ? `, ${secondClinic}` : ''
  }${thirdClinic ? `, ${thirdClinic}` : ''}${length > 3 ? ', ...' : ''}`
}

export const getTimeDistance = (startDate: Date | number, endDate: Date) => {
  const startDateValue =
    typeof startDate === 'number' ? startDate : startDate.getTime()
  if (endDate.getTime() - startDateValue <= 0) {
    return '-'
  }
  return formatDistanceStrict(startDate, endDate, {
    locale: ptBR,
  })
}

export const parseOfferType = (type: string) => {
  switch (type) {
    case 'own':
      return 'Clínica'
      break
    case 'flipped':
      return 'Vacinas.net'
      break
    case 'hybrid':
      return 'Vacinas.net e Clínica'
      break

    default:
      break
  }
}

export const getOfferIdFromQueryString = () =>
  queryString.parse('?' + window.location.toString().split('?')[1])
    .jointPurchaseOfferId
    ? queryString.parse('?' + window.location.toString().split('?')[1])
        .jointPurchaseOfferId
    : JSON.parse(
        String(
          queryString.parse('?' + window.location.toString().split('?')[1])
            .filter
        )
      ).offerId

export const getClinicIdFromQueryString = () =>
  queryString.parse('?' + window.location.toString().split('?')[1]).clinicId
    ? queryString.parse('?' + window.location.toString().split('?')[1]).clinicId
    : JSON.parse(
        String(
          queryString.parse('?' + window.location.toString().split('?')[1])
            .filter
        )
      ).clinicId

export const getCommercialConditionFromQueryString = () => {
  const parsed = queryString.parse(window.location.href.split('?')[1])
  const parsedFilter = parsed.filter
    ? JSON.parse(String(parsed.filter))
    : undefined

  return parsedFilter
    ? parsedFilter.commercialConditionId
    : parsed.commercialConditionId
}
export const parseDeliveryWindowDate = (record: Record | undefined) => `
${
  record?.deliveryWindow && record?.deliveryWindow.startDate
    ? format(new Date(record?.deliveryWindow.startDate), 'HH:mm')
    : '-'
} - ${
  record?.deliveryWindow && record?.deliveryWindow.endDate
    ? format(new Date(record?.deliveryWindow.endDate), 'HH:mm')
    : '-'
}
  `
export const translateFirebaseError = (errorCode: string) => {
  switch (errorCode) {
    case 'auth/user-not-found':
      return 'Não foi encontrado nenhum usuário cadastrado com esse e-mail'
      break
    case 'auth/invalid-email':
      return 'Formato de e-mail inválido'
      break
    default:
      return errorCode
  }
}

export const parseIssuer = (issuer: IAttendanceInvoice['issuer']) => {
  switch (issuer) {
    case 'admin':
      return 'Vacinas.net'
      break
    case 'clinic':
      return 'Clínica'
      break
    default:
      return '-'
      break
  }
}

export const parseRecipient = (recipient: IAttendanceInvoice['recipient']) => {
  switch (recipient) {
    case 'admin':
      return 'Vacinas.net'
      break
    case 'clinic':
      return 'Clínica'
      break
    case 'customer':
      return 'Cliente'
      break
    default:
      return '-'
      break
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type FilterType = any
export const getFilters = (raFilters: FilterType) => {
  const filter: FilterType = {}
  Object.entries(raFilters || {}).forEach(([key, value]) => {
    if (key.includes('->')) {
      // Hack around dot issue for filters: https://github.com/marmelab/react-admin/issues/2102
      filter[`${key.replace(/->/g, '.')}`] = value
    } else if (key.includes('_multiple')) {
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      Object.entries(value as any).forEach(([key, value]) => {
        filter[key] = value
      })
    } else {
      filter[`${key}`] = value
    }
  })
  return filter
}

export const parseDeliveryWindowWithoutHours = (
  deliveryWindow: { startDate: string; endDate: string } | undefined
) =>
  `${
    deliveryWindow?.startDate
      ? format(new Date(deliveryWindow?.startDate), 'P', {
          locale: ptBR,
        })
      : ''
  } - ${
    deliveryWindow?.endDate
      ? format(new Date(deliveryWindow?.endDate), 'P', {
          locale: ptBR,
        })
      : ''
  }`

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const stopPropagation = (event: any) => {
  event.stopPropagation()
}

export const resolveCommissionInPercentage = (
  values: ProductsBulkActionsForm
) => {
  if (
    values.commercialCondition === 'aquisition' ||
    !values.commercialCondition
  ) {
    return undefined
  }
  if (values.commercialCondition !== 'comission') return 0
  return values.value ? values.value / 100 : undefined
}

export const resolveGestureInCents = (values: ProductsBulkActionsForm) => {
  if (!values.commercialCondition) {
    return undefined
  }
  if (values.commercialCondition !== 'gesture') return 0
  return values.value ? values.value * 100 : undefined
}

export const resolveProductAquisitionInCents = (
  values: ProductsBulkActionsForm
) => {
  if (!values.commercialCondition) {
    return undefined
  }
  if (values.commercialCondition !== 'aquisition') return 0
  return values.value ? values.value * 100 : undefined
}

export const resolveOperationType = (values: ProductsBulkActionsForm) => {
  if (
    values.commercialCondition === 'aquisition' ||
    values.commercialCondition === 'comission'
  ) {
    return 'own'
  }
  if (values.commercialCondition === 'gesture') {
    return 'flipped'
  }
  return undefined
}

export const parseCsvDataFromFile = async (file: File) => {
  return new Promise((resolve, reject) => {
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: async function (results) {
        resolve(results.data)
      },
      error: (err) => {
        reject(err)
      },
    })
  })
}
