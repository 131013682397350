import * as React from 'react'
import { LinearProgress, makeStyles, Theme } from '@material-ui/core'
import { ReduxState, useToggleSidebar } from 'react-admin'
import { useSelector } from 'react-redux'
import LoadingNotification from './LoadingNotification'

const useStyles = makeStyles<Theme>(() => ({
  loadingContainer: {
    backgroundColor: 'red',
    width: '100%',
    position: 'fixed',
    zIndex: 1400,
    top: 0,
    right: 0,
  },
  linearProgress: {
    height: 8,
  },
}))

const LoadingWrapper = () => {
  const [sidebarIsOpen] = useToggleSidebar()
  const loading = !!useSelector<ReduxState>((state) => state.admin.loading > 0)
  const classes = useStyles({ sidebarIsOpen })

  return (
    <>
      <LoadingNotification />
      <div className={classes.loadingContainer}>
        {loading && (
          <LinearProgress
            className={classes.linearProgress}
            color="secondary"
          />
        )}
      </div>
    </>
  )
}

export default LoadingWrapper
