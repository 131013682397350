import React, { useState } from 'react'
import {
  useNotify,
  usePermissions,
  useRefresh,
  useUnselectAll,
  useUpdateMany,
  useListContext,
} from 'ra-core'
import { useFormik } from 'formik'
import {
  TextField,
  Button,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core'
import { Block, PowerSettingsNew, Save } from '@material-ui/icons'

import { defaultValidationSchema } from '../utils'

import { IProductStock, isAdmin } from '@vacinas-net/shared'
import { mainTheme } from '../../../utils/mainTheme'
import { Confirm, BulkActionProps } from 'react-admin'
import {
  resolveCommissionInPercentage,
  resolveGestureInCents,
  resolveOperationType,
  resolveProductAquisitionInCents,
} from '../../../utils/utils'
import customHttpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'

export type ProductsBulkActionsForm = Partial<
  Pick<
    IProductStock,
    | 'totalQuantity'
    | 'commissionInPercentage'
    | 'gestureInCents'
    | 'productAquisitionInCents'
  > & {
    operationType: IProductStock['operationType'] | ''
    commercialCondition: 'gesture' | 'comission' | 'aquisition'
    value: number
  }
>

const ProductsBulkActions = ({ selectedIds }: BulkActionProps) => {
  const context = useListContext()
  const pageValues = Object.values(context.data)
  const selectedItems = pageValues.filter((value) =>
    selectedIds?.includes(value._id)
  )
  const { permissions } = usePermissions()
  const [updateMany, { loading }] = useUpdateMany()
  const notify = useNotify()
  const refresh = useRefresh()
  const unselectAll = useUnselectAll()

  const schema = defaultValidationSchema()
  const [open, setOpen] = useState(false)
  const [openPopup, setOpenPopup] = useState(false)
  const [isLoadingToggleRequest, setIsLoadingToggleRequest] = useState(false)

  const handleOpenConfirmation = () => {
    setOpen(true)
  }
  const handleCloseConfirmation = () => {
    setOpen(false)
  }

  const { values, errors, handleChange, handleSubmit } =
    useFormik<ProductsBulkActionsForm>({
      initialValues: {
        operationType: '',
      },
      validationSchema: schema,
      validateOnChange: true,
      onSubmit: handleOpenConfirmation,
    })

  async function updateManyProductStock() {
    const formattedValues: ProductsBulkActionsForm = {
      operationType: resolveOperationType(values),
      commissionInPercentage: resolveCommissionInPercentage(values),
      gestureInCents: resolveGestureInCents(values),
      productAquisitionInCents: resolveProductAquisitionInCents(values),
      totalQuantity: values.totalQuantity,
    }

    await updateMany('product', selectedIds, formattedValues, {
      onSuccess: () => {
        refresh()
        notify('Produtos atualizados com sucesso.')
        unselectAll('product')
      },
      onFailure: (error) => {
        // TODO: custom error parser?
        // TODO: call sentry
        notify(`Erro ao atualizar produtos: ${error}`, 'warning')
      },
    })
  }

  const shouldActivateSelectedItems = () => {
    return (selectedItems || []).some((item) => !item.isActive)
  }

  const toggleManyProductStatus = () => {
    setIsLoadingToggleRequest(true)
    customHttpClient(`${apiUrl}/product/status/batch`, {
      method: 'PATCH',
      body: JSON.stringify({
        active: shouldActivateSelectedItems(),
        ids: selectedIds,
      }),
    })
      .then(() => {
        refresh()
        notify('Produtos atualizados com sucesso.')
        unselectAll('product')
      })
      .catch((error) => {
        notify(`Erro ao atualizar produtos: ${error}`, 'error')
      })
      .finally(() => setIsLoadingToggleRequest(false))
  }

  return (
    <form
      onSubmit={handleSubmit}
      style={{
        display: 'inline-flex',
        gap: '16px',
        marginLeft: mainTheme.spacing(2),
        paddingBottom: mainTheme.spacing(1),
        paddingTop: mainTheme.spacing(1),
      }}
    >
      <TextField
        type="number"
        label="Quantidade estoque físico"
        variant="outlined"
        size="small"
        value={values.totalQuantity}
        onChange={handleChange('totalQuantity')}
        helperText={errors.totalQuantity}
      />
      {isAdmin(permissions) && (
        <>
          <FormControl
            variant="outlined"
            size="small"
            style={{ minWidth: 200 }}
          >
            <InputLabel>Condição comercial</InputLabel>
            <Select
              label="Condição comercial"
              value={values.commercialCondition}
              onChange={handleChange('commercialCondition')}
            >
              <MenuItem value="comission">Comissão</MenuItem>
              <MenuItem value="aquisition">Aquisição</MenuItem>
              <MenuItem value="gesture">Gesto</MenuItem>
              <MenuItem value={undefined}>Limpar</MenuItem>
            </Select>
            {errors.commercialCondition && (
              <FormHelperText>{errors.commercialCondition}</FormHelperText>
            )}
          </FormControl>

          {(values.commercialCondition === 'gesture' ||
            values.commercialCondition === 'aquisition') && (
            <TextField
              style={{ width: 150 }}
              type="number"
              label="Valor"
              variant="outlined"
              size="small"
              value={values.value}
              onChange={handleChange('value')}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              helperText={errors.value}
            />
          )}
          {values.commercialCondition === 'comission' && (
            <TextField
              style={{ width: 150 }}
              type="number"
              label="Valor"
              variant="outlined"
              size="small"
              value={values.value}
              onChange={handleChange('value')}
              InputProps={{
                inputProps: { min: 0, max: 100, step: 0.01 },
                endAdornment: <InputAdornment position="end">%</InputAdornment>,
              }}
              helperText={errors.value}
            />
          )}
        </>
      )}

      <Button
        type="submit"
        style={{ flex: '0 0 auto' }}
        color="primary"
        startIcon={<Save />}
        disabled={loading}
      >
        Salvar
      </Button>

      {shouldActivateSelectedItems() ? (
        <Button
          color="secondary"
          variant="outlined"
          startIcon={<PowerSettingsNew />}
          onClick={() => {
            setOpenPopup(true)
          }}
        >
          Ativar Produtos
        </Button>
      ) : (
        <Button
          style={{
            borderColor: mainTheme.palette.error.main,
            color: mainTheme.palette.error.main,
          }}
          variant="outlined"
          startIcon={<Block />}
          onClick={() => setOpenPopup(true)}
        >
          Desativar Produtos
        </Button>
      )}
      <Confirm
        isOpen={openPopup}
        loading={isLoadingToggleRequest}
        title={`Confirmar ${
          shouldActivateSelectedItems() ? 'ativação' : 'desativação'
        }`}
        content={`Você está ${
          shouldActivateSelectedItems() ? 'ativando' : 'desativando'
        }  ${
          selectedIds?.length
        } produto(s), está certo que deseja realizar essa operação`}
        onConfirm={toggleManyProductStatus}
        onClose={() => setOpenPopup(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />

      <Confirm
        isOpen={open}
        loading={loading}
        title="Replicar alterações para todos os produtos selecionados"
        content="Deseja confirmar essa ação?"
        onConfirm={updateManyProductStock}
        onClose={handleCloseConfirmation}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </form>
  )
}

export default ProductsBulkActions
