import { Typography } from '@material-ui/core'
import { IAllowedCustomerBatch, ICampaignV2 } from '@vacinas-net/shared'
import { keyBy } from 'lodash'
import React, { FC, useState } from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  ListContextProvider,
  Record,
  RecordMap,
  useEditContext,
  useNotify,
  useRecordContext,
} from 'react-admin'
import { apiUrl } from '../../../../components/AppDataProvider'
import customHttpClient from '../../../../components/CustomHttpClient'
import DownloadFileField from './DownloadFileField'
import EmptyList from './EmptyList'
import ToogleField from '../ToogleField'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AllowedCustomerBatchTabProps {}

const AllowedCustomerBatchList: FC<AllowedCustomerBatchTabProps> =
  (): JSX.Element => {
    const [patchLoading, setPatchLoading] = useState(false)
    const record = useRecordContext() as ICampaignV2
    const editContext = useEditContext()
    const notify = useNotify()
    const allowedCustomersBatches =
      record.allowedCustomersBatches?.filter(
        (batch) => batch.type === 'file'
      ) || []
    const sortedAllowedCustomersBatches = allowedCustomersBatches.sort(
      (itemA, itemB) => {
        if (!itemA.createdAt && !itemB.createdAt) return 0
        if (!!itemA.createdAt && !itemB.createdAt) return -1
        if (!itemA.createdAt && !!itemB.createdAt) return 1

        const firstCreatedAt = new Date(itemA.createdAt!)
        const secondCreatedAt = new Date(itemB.createdAt!)

        return secondCreatedAt.getTime() - firstCreatedAt.getTime()
      }
    )

    const patchStatus = async (id: string, status: boolean) => {
      setPatchLoading(true)
      try {
        await customHttpClient(
          `${apiUrl}/campaign/allowed-customer-batch/${id}`,
          {
            method: 'PATCH',
            body: JSON.stringify({ status: status ? 'active' : 'disabled' }),
          }
        )

        if (editContext?.refetch) {
          editContext.refetch()
        }
      } catch (error) {
        notify('Erro ao atualizar lista de beneficiados', { type: 'error' })
      } finally {
        setPatchLoading(false)
      }
    }

    if (sortedAllowedCustomersBatches.length === 0) {
      return <EmptyList />
    }

    return (
      <ListContextProvider
        value={{
          data: keyBy(
            sortedAllowedCustomersBatches,
            '_id'
          ) as unknown as RecordMap<Record>,
          ids: sortedAllowedCustomersBatches.map((item) => item._id),
          currentSort: { field: 'startDatetime', order: 'ASC' },
        }}
      >
        <Datagrid>
          <DateField
            locales="pt-BR"
            textAlign="center"
            source="createdAt"
            label="Data de upload"
            showTime
            emptyText="-"
          />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) => {
              const batch = record as unknown as IAllowedCustomerBatch
              return batch?.createdBy?.name || '-'
            }}
            label="Responsável"
            sortable={false}
          />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) => (
              <DownloadFileField id={record?._id as string} />
            )}
            label="Visualizar lista"
            sortable={false}
          />
          <FunctionField
            textAlign="center"
            label="Ativar/Desativar"
            render={(record: Record | undefined) => {
              const batch = record as unknown as IAllowedCustomerBatch
              const hasOrder = batch.allowedCustomers.some(
                (allowedCustomer) => allowedCustomer.usedVouchers.length > 0
              )
              return (
                <ToogleField
                  loading={patchLoading || !!editContext.loading}
                  protected={hasOrder}
                  protectedWarningMessage="Existem pedidos para esta lista"
                  checked={batch.status === 'active'}
                  onCheckChange={(status) => patchStatus(batch._id, status)}
                  Content={() => (
                    <div>
                      <Typography variant="body1">
                        Essa lista contém beneficiados que{' '}
                        <b>já agendaram a aplicação.</b>
                      </Typography>

                      <Typography variant="body1">
                        Tem certeza que deseja <b>desativar essa lista?</b>
                      </Typography>
                    </div>
                  )}
                />
              )
            }}
          />
        </Datagrid>
      </ListContextProvider>
    )
  }

export default AllowedCustomerBatchList
