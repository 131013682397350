import { makeStyles, Typography } from '@material-ui/core'
import { INotification } from '@vacinas-net/shared'
import { formatDistanceToNow } from 'date-fns'
import React from 'react'
import { mainTheme } from '../../../utils/mainTheme'
import ptBR from 'date-fns/locale/pt-BR'
import httpClient from '../../CustomHttpClient'
import { apiUrl } from '../../AppDataProvider'

const NotificationCard = (props: {
  notification: INotification
  resetNotificationArray?: () => void
  shrinkText?: boolean
}) => {
  const { notification, resetNotificationArray } = props

  const notificationCardStyles = makeStyles({
    cardContainer: {
      borderLeft: `10px solid ${notification.colorHex}`,
      cursor: 'pointer',
      backgroundColor: '#ffffff',
      position: 'relative',

      '&:hover': {
        backgroundColor: '#f5f5f5',
      },
    },
  })
  const classes = notificationCardStyles()

  const markNotificationAsRead = async () => {
    httpClient(`${apiUrl}/notification/${notification._id}/viewed`, {
      method: 'PATCH',
    })
      .catch((error) => {
        console.error(error)
      })
      .then(() => {
        resetNotificationArray && resetNotificationArray()
      })
  }

  return (
    <div className={classes.cardContainer}>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          borderTop: '1px solid #ECECEC',
          borderBottom: '1px solid #ECECEC',
        }}
        onClick={async () => {
          if (!resetNotificationArray) return
          if (!notification.viewed) {
            await markNotificationAsRead()
          }
          if (notification.link) {
            window.open(notification.link, '_blank')?.blur()
          }
        }}
      >
        {!notification.viewed && (
          <span
            style={{
              height: 16,
              width: 16,
              backgroundColor: mainTheme.palette.success.dark,
              borderRadius: '50%',
              display: 'inline-block',
              position: 'absolute',
              top: '0',
              right: '0',
              marginRight: mainTheme.spacing(2),
              marginTop: mainTheme.spacing(1),
            }}
          ></span>
        )}

        <div
          style={{
            marginTop: mainTheme.spacing(1),
            marginLeft: mainTheme.spacing(1),
            marginBottom: mainTheme.spacing(1),
          }}
        >
          <Typography
            style={{
              fontSize: props.shrinkText ? 12 : 'none',
            }}
            variant="h3"
          >
            {notification.title}
          </Typography>
        </div>
        <div
          style={{
            marginLeft: mainTheme.spacing(1),
            marginBottom: mainTheme.spacing(1),
          }}
        >
          <Typography
            style={{
              fontSize: props.shrinkText ? 12 : 'none',
            }}
            variant="body2"
          >
            {notification.message}
          </Typography>
        </div>
        <div
          style={{
            display: 'flex',
            marginLeft: mainTheme.spacing(1),
            marginBottom: mainTheme.spacing(1),
          }}
        >
          <Typography color="textSecondary" variant="subtitle1">
            Há{' '}
            {formatDistanceToNow(
              new Date(
                String(notification.createdAt ? notification.createdAt : '')
              ),
              {
                locale: ptBR,
              }
            )}
          </Typography>
          <Typography
            style={{
              marginLeft: 'auto',
              marginRight: mainTheme.spacing(1),
              color: mainTheme.palette.success.main,
              textDecoration: 'underline',
            }}
            variant="subtitle1"
          >{`Ver Mais>`}</Typography>
        </div>
      </div>
    </div>
  )
}

export default NotificationCard
