import React from 'react'
import { Filter, FilterProps } from 'react-admin'
import { CommercialConditionsFiltersArray } from './Filters'

const CommercialConditionsListFilters: React.FC<Omit<FilterProps, 'children'>> =
  (props) => {
    return (
      <Filter {...props}>
        {CommercialConditionsFiltersArray.map((item) => item.FilterInput)}
      </Filter>
    )
  }

export default CommercialConditionsListFilters
