import { format } from 'date-fns'
import React from 'react'
import {
  SimpleShowLayout,
  FunctionField,
  Record,
  DateField,
  TextField,
} from 'react-admin'
import DownloadReceiptButton from '../../../../../components/Buttons/DownloadReceiptButton'
import UploadReceiptButton from '../../../../../components/Buttons/UploadReceiptButton'
import showStyles from '../../../../../utils/showStyles'
import { valueInCentsToBRL } from '../../../../../utils/utils'

const InfoSection = () => {
  const classes = showStyles()
  return (
    <div className={classes.cardContainer}>
      <SimpleShowLayout className={classes.cardContent}>
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.totalValueInCents)
          }
          label="Valor"
          source="payoutValueInCents"
          className={classes.cardItem}
          sortable={false}
        />
        <DateField
          textAlign="center"
          label="Data de pagamento"
          source="settlementDate"
          className={classes.cardItem}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            `${format(
              new Date(record?.payoutDateRange.startDate),
              'dd/MM/yyyy'
            )} à ${format(
              new Date(record?.payoutDateRange.endDate),
              'dd/MM/yyyy'
            )}`
          }
          label="Referente à"
          className={classes.cardItem}
          sortable={false}
        />
        <TextField
          textAlign="center"
          label="Responsável"
          source="responsibleUser.name"
          emptyText="-"
          className={classes.cardItem}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.status === 'receipt_settled' ? (
              <DownloadReceiptButton id={record?.id} />
            ) : (
              <UploadReceiptButton id={record?.id} />
            )
          }
          label="Comprovante"
          source="payoutValueInCents"
          sortable={false}
        />
      </SimpleShowLayout>
    </div>
  )
}

export default InfoSection
