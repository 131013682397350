import { CircularProgress, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import { capitalize } from 'lodash'
import React from 'react'
import { useGetIdentity, usePermissions } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { translateSystemRole } from '../../../utils/utils'

const Header = () => {
  const { permissions, loading: loadingPermissions } = usePermissions()
  const { identity, loading: loadingIdentity } = useGetIdentity()

  return loadingPermissions || loadingIdentity ? (
    <CircularProgress size={30} color="primary" />
  ) : (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
      }}
    >
      <div>
        <Typography
          color="primary"
          variant="h1"
        >{`Olá, ${identity?.fullName}`}</Typography>
        <Typography variant="body1" color="textSecondary">
          {translateSystemRole(permissions[0])}
        </Typography>
      </div>
      <div style={{ marginLeft: 'auto', marginRight: mainTheme.spacing(2) }}>
        <Typography variant="body1" color="primary">
          {`${format(new Date(Date.now()), 'P', {
            locale: ptBR,
          })} | ${capitalize(
            format(new Date(Date.now()), 'cccc', {
              locale: ptBR,
            })
          )}`}
        </Typography>
      </div>
    </div>
  )
}

export default Header
