import React, { useEffect, useState } from 'react'
import { useNotify } from 'ra-core'
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  TextField,
  Record,
  usePermissions,
} from 'react-admin'
import AggregatedOfferStatusChip from '../../../components/chips/AggregatedOfferStatusChip'
import DeletePurchaseOfferItemButton from '../../../components/Offer/DeletePurchaseOfferItemButton'
import EditableQuantityTableField from '../../../components/EditableQuantityTableField'
import { DefaultPagination } from '../../../components/Pagination'
import {
  getClinicIdFromQueryString,
  getOfferIdFromQueryString,
  parseOfferType,
  valueInCentsToBRL,
} from '../../../utils/utils'
import OfferItemsClinicActions from './OfferItemsClinicActions'
import OfferItemsClinicFilters from './OfferItemsClinicFilters'
import httpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'

const OfferItemsClinic = (props: ListProps) => {
  const { permissions } = usePermissions()

  const [showActionButtons, setShowActionButtons] = useState()

  const notify = useNotify()

  useEffect(() => {
    httpClient(
      `${apiUrl}/joint-purchase/check-clinic-actions?offerId=${getOfferIdFromQueryString()}&clinicId=${getClinicIdFromQueryString()}`,
      {
        method: 'get',
      }
    )
      .then((res) => {
        setShowActionButtons(res.json.canHandleActions)
      })
      .catch((error) => {
        notify(`Erro ao obter as ações disponíveis: ${error}`, 'error')
      })
  }, [])

  const hideButton = (type: string) => {
    if (
      (permissions?.includes('clinic_admin') && type === 'flipped') ||
      (permissions?.includes('clinic_admin') && !showActionButtons)
    ) {
      return true
    }
    return false
  }

  return (
    <List
      actions={
        <OfferItemsClinicActions showActionButtons={showActionButtons} />
      }
      pagination={<DefaultPagination />}
      filters={<OfferItemsClinicFilters />}
      filterDefaultValues={{
        offerId: getOfferIdFromQueryString(),
        clinicId: getClinicIdFromQueryString(),
      }}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid stickyHeader>
        <TextField textAlign="center" source="product.name" label="Produto" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => parseOfferType(record?.type)}
          label="Comprado por"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.unitValueInCents)
          }
          label="Valor unitário"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.unitValueInCents * record?.amount)
          }
          label="Valor Total"
        />
        <TextField textAlign="center" source="rangeName" label="Faixa" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => (
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
              }}
            >
              <EditableQuantityTableField
                label="Editar"
                initialValue={record?.amount}
                id={record?._id}
                key={`${record?._id}-${record?.amount}`}
                hideButton={hideButton(record?.type)}
              />
              {hideButton(record?.type) ? (
                <></>
              ) : (
                <DeletePurchaseOfferItemButton offerId={record?._id} />
              )}
            </div>
          )}
          label="Editar quantidade"
        />
        <FunctionField
          textAlign="center"
          label="Status"
          render={(record: Record | undefined) => (
            <AggregatedOfferStatusChip status={record?.status} />
          )}
        />
      </Datagrid>
    </List>
  )
}

export default OfferItemsClinic
