import { GetProductsPricesDTO } from '@vacinas-net/shared'
import { apiUrl } from '../AppDataProvider'
import customHttpClient from '../CustomHttpClient'
import { CartProduct } from './OrderCreateContext'

export const fetchProductsPriceWithFiltersRequest = (
  params: URLSearchParams,
  currentProductsArray: CartProduct[],
  productsToSearch: {
    skuId: string
    name: string
  }[]
) => {
  const url = `${apiUrl}/product/price?${params}`
  return customHttpClient(url, {
    method: 'get',
  })
    .then((response) => {
      const newProductsArray: CartProduct[] = response.json.map(
        (responseItem: GetProductsPricesDTO) => {
          return {
            skuId: responseItem.skuId,
            priceInCents: responseItem.priceInCents,
            quantity:
              currentProductsArray.find(
                (item) => item.skuId === responseItem.skuId
              )?.quantity || 1,
            discountedPriceInCents: responseItem.priceInCents,
            name: productsToSearch.find(
              (item) => item.skuId === responseItem.skuId
            )?.name,
          }
        }
      )
      return newProductsArray
    })
    .catch((error) => {
      throw new error(error)
    })
}
