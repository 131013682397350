import React, { useEffect, useState } from 'react'
import { Paper, Typography, Button, CircularProgress } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import {
  useAuthenticated,
  useDataProvider,
  useNotify,
  useRedirect,
} from 'react-admin'
import MarkAsReadButton from '../../../resources/notifications/MarkAsReadButton'
import { INotification } from '@vacinas-net/shared'
import { Add, Notifications } from '@material-ui/icons'
import NotificationCard from '../../Notification/NotificationCard'

const NotificationWidget = (props: { shrinkText?: boolean }) => {
  const redirect = useRedirect()
  useAuthenticated()
  const notify = useNotify()
  const dataProvider = useDataProvider()
  const [notificationsArray, setNotificationsArray] = useState<INotification[]>(
    []
  )
  const [total, setTotal] = useState<number>(0)
  const [loading, setLoading] = useState(false)

  useEffect(() => {
    fetchNotifications()
  }, [])

  const fetchNotifications = () => {
    setLoading(true)
    dataProvider
      .getList('/notification', {
        filter: undefined,
        pagination: {
          page: 1,
          perPage: 3,
        },
        sort: {
          field: 'createdAt',
          order: 'DESC',
        },
      })
      .then((res) => {
        setNotificationsArray([
          ...notificationsArray,
          ...(res.data as INotification[]),
        ])
        setTotal(res.total)
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao obter notificações: ${error}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
        cursor: 'pointer',
      }}
      onClick={() => {
        redirect('/notification')
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
          marginBottom: mainTheme.spacing(2),
          paddingTop: mainTheme.spacing(1),
        }}
      >
        <Notifications
          style={{ marginLeft: mainTheme.spacing(1) }}
          color="secondary"
          fontSize="default"
        />
        <Typography
          style={{ marginLeft: mainTheme.spacing(2) }}
          align="left"
          variant="h2"
          color="textPrimary"
        >
          Notificações
        </Typography>
        <div style={{ marginLeft: 'auto' }}>
          <MarkAsReadButton />
        </div>
      </div>
      {loading && <CircularProgress color="secondary" size={40} />}
      {notificationsArray.filter((notification) => !notification.viewed)
        .length > 0 ? (
        <div style={{ width: '95%' }}>
          {notificationsArray
            .filter((notification) => !notification.viewed)
            .map((unseenNotification) => {
              return (
                <div
                  key={unseenNotification._id}
                  style={{
                    marginBottom: mainTheme.spacing(1),
                  }}
                >
                  <NotificationCard
                    shrinkText={props.shrinkText}
                    notification={unseenNotification}
                  />
                </div>
              )
            })}
          {notificationsArray.length < total && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                marginTop: mainTheme.spacing(2),
                marginBottom: mainTheme.spacing(2),
              }}
            >
              <Button
                onClick={() => {
                  redirect('/notification')
                }}
                variant="contained"
                endIcon={<Add />}
              >
                Ver mais
              </Button>
            </div>
          )}
        </div>
      ) : (
        !loading && (
          <Typography align="left" variant="body2">
            Não há novas notificações!
          </Typography>
        )
      )}
    </Paper>
  )
}

export default NotificationWidget
