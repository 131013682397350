import { AuthProvider } from 'react-admin'
import { firebaseProvider } from '../config/firebase/firebaseConfig'

const customFirebaseProvider: AuthProvider = {
  ...firebaseProvider,
  getPermissions: async () => {
    try {
      const user = await firebaseProvider.getAuthUser()
      if (user) {
        const token = await user.getIdTokenResult()
        const claims = token?.claims
        const roles = claims?.roles || []
        return roles
      }
    } catch (error) {
      return []
    }

    return []
  },
}

export default customFirebaseProvider
