/* eslint-disable @typescript-eslint/no-var-requires */

import * as Sentry from '@sentry/react'
import { Integrations } from '@sentry/tracing'

const packageJson = require('../../../package.json')
const packageName = 'vacinas-net-admin'

if (process.env.REACT_APP_NODE_ENV === 'production') {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: `${packageName}@${packageJson.version}`,
    environment: process.env.REACT_APP_NODE_ENV,
    debug: process.env.NODE_ENV === 'development',
    attachStacktrace: true,
    integrations: [new Integrations.BrowserTracing()],
    tracesSampleRate: 1.0,
  })
}
