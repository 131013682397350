import { Button, Dialog, DialogContent, MenuItem } from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import { IAttendance } from '@vacinas-net/shared'
import { apiUrl } from '../../AppDataProvider'
import { useNotify, useRefresh } from 'react-admin'
import NewAttendanceForm from '../../NewAttendanceForm'
import customHttpClient from '../../CustomHttpClient'
import { stopPropagation } from '../../../utils/utils'

interface ChangeClinicButtonProps {
  attendance: IAttendance
  isMenuItem?: boolean
}

const ChangeClinicButton = (props: ChangeClinicButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openClinicModal, setOpenClinicModal] = useState(false)

  const refresh = useRefresh()
  const notify = useNotify()

  const { attendance } = props

  const ChangeClinic = (data: IAttendance) => {
    setLoading(true)
    customHttpClient(`${apiUrl}/attendance/${attendance._id}/changeClinic`, {
      method: 'post',
      body: JSON.stringify({
        clinicId: data.clinic?._id,
        type: data.type,
        address: data.address || undefined,
        pickupPointId: data.pickupPointName || undefined,
      }),
    })
      .then(() => {
        notify(`Troca efetuada com sucesso`, 'success')
        setOpenClinicModal(false)
      })
      .catch((error) => {
        notify(`Erro ao trocar clínica: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        refresh()
      })
  }

  return (
    <div>
      {props.isMenuItem ? (
        <MenuItem
          onClick={() => setOpenClinicModal(true)}
          data-testid={'change-clinic'}
        >
          {' '}
          Trocar Clínica
        </MenuItem>
      ) : (
        <Button
          size="medium"
          color="secondary"
          variant="contained"
          onClick={() => setOpenClinicModal(true)}
          data-testid={'change-clinic'}
        >
          Trocar Clínica
        </Button>
      )}

      <Dialog
        open={openClinicModal}
        onClose={() => setOpenClinicModal(false)}
        maxWidth="md"
        fullWidth
        onKeyDown={stopPropagation}
      >
        <DialogContent data-testid={'dialog-content-new-attendance'}>
          <NewAttendanceForm
            isEdit
            onClose={() => {
              setOpenClinicModal(false)
            }}
            onSubmit={(data) => {
              ChangeClinic(data)
            }}
            loading={loading}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ChangeClinicButton
