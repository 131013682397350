import {
  Button,
  CircularProgress,
  Container,
  TextField,
  Typography,
} from '@material-ui/core'
import { ThemeProvider } from '@material-ui/styles'
import React, { useState } from 'react'
import { useLogin, useNotify, Notification } from 'react-admin'
import { mainTheme } from '../../utils/mainTheme'
import vacinasLogo from '../../assets/VacinasLogo.svg'
import vacinasWatermark from '../../assets/VacinasWatermark.svg'
import vacinasOnlyJennerLogo from '../../assets/Logo-Only-Jenner.png'
import ForgotPasswordButton from './ForgotPasswordButton'
import { authStyles } from './AuthStyles'
import customHttpClient from '../../components/CustomHttpClient'
import { apiUrl } from '../../components/AppDataProvider'

const CustomLoginPage: React.FC = () => {
  const classes = authStyles()
  const [loading, setLoading] = useState(false)
  const [email, setEmail] = useState(
    process.env.REACT_APP_NODE_ENV === 'development' ? 'teste@liven.tech' : ''
  )
  const [password, setPassword] = useState(
    process.env.REACT_APP_NODE_ENV === 'development' ? '123456' : ''
  )
  const login = useLogin()
  const notify = useNotify()
  const submit = (e: React.FormEvent<HTMLFormElement>) => {
    setLoading(true)
    e.preventDefault()

    login({ username: email, password })
      .catch(() => {
        notify('E-mail ou Senha incorretos')
      })
      .then(() => {
        customHttpClient(`${apiUrl}/user/me/last-signin`, {
          method: 'PATCH',
        }).catch((error) => {
          notify(`Erro ao registrar login: ${error}`)
        })
      })
      .finally(() => setLoading(false))
  }

  return (
    <ThemeProvider theme={mainTheme}>
      <Container className={classes.loginContainer} maxWidth={false}>
        <div className={`${classes.innerContainer} ${classes.expandMobile}`}>
          <div className={`${classes.showOnlyMobile}`}>
            <img
              src={vacinasOnlyJennerLogo}
              style={{
                width: '70%',
                zIndex: 1,
                marginBottom: mainTheme.spacing(2),
              }}
            />
          </div>
          <form className={classes.formStyles} onSubmit={submit}>
            <Typography
              style={{
                marginBottom: mainTheme.spacing(6),
                color: mainTheme.palette.secondary.dark,
              }}
              variant="h1"
            >
              Faça Login!
            </Typography>
            <TextField
              className={classes.formInput}
              label="E-mail"
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              variant="outlined"
              fullWidth
              style={{ zIndex: 2 }}
            />
            <TextField
              label="Senha"
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              variant="outlined"
              fullWidth
              style={{ zIndex: 2 }}
            />
            <ForgotPasswordButton />
            <div
              style={{
                textAlign: 'center',
                marginTop: mainTheme.spacing(3),
              }}
            >
              <Button
                variant="contained"
                color="secondary"
                size="large"
                type="submit"
                disabled={loading}
                fullWidth={false}
                style={{ zIndex: 2 }}
              >
                {loading ? (
                  <CircularProgress color="secondary" size={24} />
                ) : (
                  'Entrar'
                )}
              </Button>
            </div>
          </form>
          <div className={classes.watermarkWraper}>
            <img className={classes.watermark} src={vacinasWatermark} />
          </div>
          <Notification />
        </div>
        <div
          className={`${classes.innerContainer} ${classes.hideMobile}`}
          style={{
            backgroundColor: mainTheme.palette.primary.main,
          }}
        >
          <img src={vacinasLogo} style={{ width: '80%', zIndex: 1 }} />
        </div>
      </Container>
    </ThemeProvider>
  )
}

export default CustomLoginPage
