import React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  NumberField,
  Record,
  useRecordContext,
} from 'react-admin'
import {
  IAllowedCustomer,
  IAllowedCustomerBatch,
  ICampaignV2,
} from '@vacinas-net/shared'
import { DashboardPagination } from '../../../components/Pagination'
import { formatCPF } from '@brazilian-utils/brazilian-utils'
import { uniq } from 'lodash'
import AllowedCustomerActions from './AllowedCustomerActions'
import AllowedCustomerFilters from './AllowedCustomerFilters'
import EditAllowedCustomerVouchersInline from '../../../components/EditAllowedCustomerVouchersInline/EditAllowedCustomerVouchersInline'
import { isBefore } from 'date-fns'
import DeleteAllowedCustomerButton from '../../../components/DeleteAllowedCustomerButton'

type AlloedCustomerBatchTypeDictionary = {
  [key in Required<IAllowedCustomerBatch>['type']]: string
}

const allowedCustomerBatchTypeDictionary: AlloedCustomerBatchTypeDictionary = {
  file: 'Upload de lista',
  individual: 'Cadastro manual',
}

const AllowedCustomersList = ({ ...props }: ListProps) => {
  const record = useRecordContext() as ICampaignV2
  const campaignId = record._id

  const isCampaignActiveOfFuture = () => {
    const endDateFormatted = new Date(String(record.dateRange?.endDate))
    return isBefore(Date.now(), endDateFormatted)
  }

  if (!record) return <></>

  return (
    <List
      pagination={<DashboardPagination />}
      bulkActionButtons={false}
      basePath="/campaign/allowed-customer"
      resource="campaign/allowed-customer"
      filterDefaultValues={{
        campaignId: record._id,
      }}
      filters={<AllowedCustomerFilters />}
      sort={{ field: 'createdAt', order: 'DESC' }}
      actions={<AllowedCustomerActions />}
      {...props}
    >
      <Datagrid stickyHeader>
        <FunctionField
          textAlign="center"
          label="Responsável"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            if (!allowedCustomer.allowedCustomerBatches?.length) return '-'

            const createdByNames = allowedCustomer.allowedCustomerBatches
              .map((item) => item.createdBy.name || '')
              .filter((item) => !!item)

            return uniq(createdByNames).join(', ')
          }}
        />
        <FunctionField
          textAlign="center"
          label="Visualizar lista"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            if (!allowedCustomer.allowedCustomerBatches?.length) return '-'

            const rawTypes: Array<Required<IAllowedCustomerBatch>['type']> =
              allowedCustomer.allowedCustomerBatches
                .map((item) => item.type || 'file')
                .filter((item) => !!item)

            const translatedTypes = rawTypes.map(
              (type) => allowedCustomerBatchTypeDictionary[type]
            )

            return uniq(translatedTypes).join(', ')
          }}
        />
        <FunctionField
          textAlign="center"
          label="Nome"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            if (!allowedCustomer.fullNames?.length) return '-'

            return uniq(allowedCustomer.fullNames).join(', ')
          }}
        />
        <FunctionField
          textAlign="center"
          label="E-mail"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            if (!allowedCustomer.emails?.length) return '-'

            return uniq(allowedCustomer.emails).join(', ')
          }}
        />
        <FunctionField
          textAlign="center"
          label="CPF"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            return formatCPF(allowedCustomer.document)
          }}
        />
        <NumberField
          textAlign="center"
          label="Quantidade de vales"
          source="givenVoucherAmount"
        />
        <NumberField
          textAlign="center"
          label="Vales utilizados"
          source="usedVouchersAmount"
        />
        <FunctionField
          textAlign="center"
          label="Editar vales"
          render={(record?: Record) => {
            return (
              <EditAllowedCustomerVouchersInline
                allowedCustomer={record as unknown as IAllowedCustomer}
                disabled={!isCampaignActiveOfFuture()}
              />
            )
          }}
        />
        <FunctionField
          textAlign="center"
          label="Deletar beneficiário"
          render={(record?: Record) => {
            const allowedCustomer = record as unknown as IAllowedCustomer
            return (
              <DeleteAllowedCustomerButton
                campaignId={campaignId!}
                document={allowedCustomer.document}
                disabled={!isCampaignActiveOfFuture()}
              />
            )
          }}
        />
      </Datagrid>
    </List>
  )
}

export default AllowedCustomersList
