import { Chip, Typography, IconButton } from '@material-ui/core'
import { ArrowDownward, ArrowUpward, Close } from '@material-ui/icons'
import * as React from 'react'
import { useState } from 'react'
import { SelectedClinic } from '.'
import { IClinicGroupedByClinicGroup } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'

const ClinicGroupChips = (props: {
  clinicGroup: IClinicGroupedByClinicGroup
  selectClinic: (clinic: SelectedClinic) => void
  unselectClinicGroup: (clinicGroup: IClinicGroupedByClinicGroup) => void
  readOnly?: boolean
}) => {
  const { clinicGroup, selectClinic, readOnly } = props
  const [isExpanded, setIsExpanded] = useState(true)
  return (
    <div
      style={{
        marginTop: mainTheme.spacing(2),
      }}
    >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <IconButton
          size="small"
          onClick={() => {
            setIsExpanded(!isExpanded)
          }}
          style={{
            marginBottom: mainTheme.spacing(1),
            marginRight: mainTheme.spacing(1),
          }}
          data-testid={'button-collapse-clinics'}
        >
          {isExpanded ? <ArrowUpward /> : <ArrowDownward />}
        </IconButton>
        <Typography
          style={{
            marginBottom: mainTheme.spacing(1),
          }}
          variant="h2"
        >
          {clinicGroup &&
            clinicGroup.clinics &&
            clinicGroup.clinics[0].clinicGroup?.name}
        </Typography>
        {!readOnly && (
          <IconButton
            size="small"
            onClick={() => {
              props.unselectClinicGroup(clinicGroup)
            }}
            style={{
              marginBottom: mainTheme.spacing(1),
              marginLeft: mainTheme.spacing(1),
            }}
            data-testid={'button-unsellect-clinic-group'}
          >
            <Close />
          </IconButton>
        )}
      </div>
      <div
        data-testid={'container-clinics-chips'}
        style={{
          marginLeft: mainTheme.spacing(4),
        }}
      >
        {isExpanded &&
          clinicGroup &&
          clinicGroup.clinics &&
          clinicGroup.clinics.map((selectedClinic) => (
            <Chip
              label={selectedClinic.name}
              onDelete={() => selectClinic(selectedClinic)}
              disabled={readOnly}
              color="primary"
              style={{
                marginTop: mainTheme.spacing(1),
                marginLeft: mainTheme.spacing(1),
              }}
              key={selectedClinic._id}
            />
          ))}
      </div>
    </div>
  )
}

export default ClinicGroupChips
