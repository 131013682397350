import React from 'react'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  Button,
  Typography,
  DialogContent,
  Grid,
} from '@material-ui/core'
import { Link } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'

export interface LinkOption {
  label: string
  link: string
}

const RemainingAvailableAttendancesWarningModal = (props: {
  open: boolean
  onClose: (state: boolean) => void
  availableAttendancesFormLinks: LinkOption[]
}) => {
  const { open, onClose, availableAttendancesFormLinks } = props
  return (
    <Dialog
      onClose={() => {
        onClose(false)
      }}
      open={open}
      fullWidth
      maxWidth={'md'}
    >
      <DialogTitle color="primary">Negociação em uso!</DialogTitle>
      <DialogContent>
        <Typography>
          Essa negociação está sendo utilizada pelos formulários de agendamento
          listados abaixo.
          <br />
          <br />
          Altere para outra negociação cada um deles antes de tentar remover
          esta negociação
          <br />
          <br />
        </Typography>
        <Grid container alignItems="center" justifyContent="center">
          {availableAttendancesFormLinks.map((item, index) => {
            return (
              <Link
                key={index}
                to={{
                  pathname: item.link,
                }}
                target="blank"
                style={{ marginBottom: mainTheme.spacing(2) }}
              >
                <Typography
                  style={{
                    textDecoration: 'underline',
                    cursor: 'pointer',
                  }}
                  color="primary"
                  variant="body2"
                >
                  {item.label}
                </Typography>
              </Link>
            )
          })}
        </Grid>
      </DialogContent>
      <DialogActions>
        <Button
          onClick={() => onClose(false)}
          variant="contained"
          color="primary"
          size="medium"
        >
          Fechar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RemainingAvailableAttendancesWarningModal
