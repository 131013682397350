import { Typography } from '@material-ui/core'
import React from 'react'
import { FunctionField, SimpleShowLayout, TextField, Record } from 'react-admin'
import { NavLink } from 'react-router-dom'
import { mainTheme } from '../../../../utils/mainTheme'
import showStyles from '../../../../utils/showStyles'
import { parseAddress, parseDeliveryWindow } from '../../../../utils/utils'
import AttendanceItemsSection from './AttendanceItemsSection'
import { parseAttendanceType } from '@vacinas-net/shared'

const AttendanceDetailsClinic = (props: { record: Record | undefined }) => {
  const { record } = props
  const classes = showStyles()

  return (
    <div
      style={{ marginTop: mainTheme.spacing(4) }}
      className={classes.cardContainer}
    >
      <SimpleShowLayout className={classes.cardContentTop}>
        <NavLink
          className={`${classes.link} ${classes.marginLeftOnPrint}`}
          to={`/attendance/${record?.id}`}
        >
          <Typography variant="h2">{`Clínica: ${record?.clinic.name}`}</Typography>
        </NavLink>
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.cardContentMiddle}>
        <FunctionField
          render={(record: Record | undefined) => {
            return parseAttendanceType(record?.type)
          }}
          label="Tipo de atendimento"
          className={classes.cardItem}
        />
        <TextField
          textAlign="center"
          source="pickupPointName"
          label="Ponto de retirada"
          emptyText="-"
          className={classes.cardItem}
        />
        <FunctionField
          render={(record: Record | undefined) => parseAddress(record?.address)}
          label="Endereço"
          className={classes.cardItem}
        />
        <FunctionField
          render={(record: Record | undefined) =>
            parseDeliveryWindow(record?.deliveryWindow)
          }
          label="Agendamento do cliente"
          className={classes.cardItem}
        />
      </SimpleShowLayout>
      {record?.notes && (
        <SimpleShowLayout className={classes.cardContentMiddle}>
          <TextField
            className={classes.notes}
            source="notes"
            label="Observações"
          />
        </SimpleShowLayout>
      )}
      <AttendanceItemsSection status={record?.status} record={record} />
    </div>
  )
}

export default AttendanceDetailsClinic
