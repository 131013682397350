import React from 'react'
import { Create, CreateProps, Record, TopToolbar } from 'react-admin'
import { SelectedClinic } from '../../../components/ClinicSearch'

import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import UserForm from '../UserForm'

const UserCreateActions = () => {
  return (
    <TopToolbar>
      <HeaderWithGoBack title="Novo Usuário" path="/user" />
    </TopToolbar>
  )
}

const UserCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    return {
      ...data,
      name: data.name,
      email: data.email,
      roles: [data.role],
      relatedClinics:
        data?.clinicData?.selectedClinics.map((clinic: SelectedClinic) => {
          return { _id: clinic._id }
        }) || [],
      clinicData: undefined,
      role: undefined,
    }
  }

  return (
    <Create actions={<UserCreateActions />} transform={transform} {...props}>
      <UserForm />
    </Create>
  )
}

export default UserCreate
