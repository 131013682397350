import React from 'react'
import {
  Button,
  Grid,
  TextField,
  CircularProgress,
  Typography,
} from '@material-ui/core'
import { ArrowForward } from '@material-ui/icons'
import InputMask from 'react-input-mask'
import { IAttendance, IVaccineShot } from '@vacinas-net/shared'
import { apiUrl } from '../../AppDataProvider'
import { mainTheme } from '../../../utils/mainTheme'
import { isValidCPF } from '@brazilian-utils/brazilian-utils'

const GetAttendanceByDocumentInput = (props: {
  documentValue: string
  setDocumentValue: (value: string) => void
  campaignId: string
  setAttendance: (
    attendance: IAttendance & { vaccineShots?: IVaccineShot[] }
  ) => void
  setOpenModal: (open: boolean) => void
  loading: boolean
  setLoading: (open: boolean) => void
}) => {
  const {
    documentValue,
    setDocumentValue,
    setAttendance,
    campaignId,
    setOpenModal,
    loading,
    setLoading,
  } = props

  const fetchAttendanceByDocument = async () => {
    setLoading(true)
    try {
      const response = await fetch(
        `${apiUrl}/campaign/${campaignId}/attendance`,
        {
          method: 'post',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            document: documentValue.replace(/[\.\-]/g, ''),
          }),
        }
      )

      const responseJson = await response.json()
      if (response.status !== 200) {
        throw new Error(responseJson.message)
      }
      return responseJson as IAttendance & { vaccineShots?: IVaccineShot[] }
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }

  const cpfValidationError = documentValue !== '' && !isValidCPF(documentValue)

  return (
    <Grid style={{ marginTop: mainTheme.spacing(2) }} container>
      <Grid
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          paddingRight: mainTheme.spacing(1),
        }}
        item
        xs={6}
      >
        <InputMask
          mask="999.999.999-99"
          value={documentValue}
          onChange={(event) => setDocumentValue(event.target.value)}
        >
          {() => (
            <TextField
              error={cpfValidationError}
              label="CPF"
              variant="outlined"
              size="small"
              placeholder="000.000.000-00"
              inputProps={{
                'data-testid': 'document-input',
              }}
            />
          )}
        </InputMask>
      </Grid>
      <Grid
        style={{
          display: 'flex',
          paddingLeft: mainTheme.spacing(1),
        }}
        item
        xs={6}
      >
        <Button
          size="small"
          disabled={loading || !isValidCPF(documentValue)}
          startIcon={<ArrowForward />}
          color="primary"
          variant="contained"
          onClick={async () => {
            const fetchedAttendance = await fetchAttendanceByDocument()
            if (fetchedAttendance) {
              setAttendance(fetchedAttendance)
            }
            setOpenModal(true)
          }}
        >
          {loading ? (
            <CircularProgress color="inherit" size={30} />
          ) : (
            'Buscar atendimento'
          )}
        </Button>
      </Grid>
      {cpfValidationError && (
        <Typography
          style={{ marginTop: mainTheme.spacing(0.5) }}
          color="error"
          variant="body2"
        >
          CPF inválido
        </Typography>
      )}
    </Grid>
  )
}

export default GetAttendanceByDocumentInput
