import { Button } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import React from 'react'
import { Identifier, useNotify } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'

import httpClient from '../../CustomHttpClient'

const DownloadInvoiceButton = (props: { id: Identifier | undefined }) => {
  const notify = useNotify()

  const downloadInvoice = async () => {
    const url = `${apiUrl}/attendance-invoice/${props.id}/download`
    httpClient(url, {
      method: 'get',
    })
      .then((res) => {
        const imageLink = res.json.signedUrl
        window.open(imageLink, '_blank')
        notify('Nota fiscal baixada com sucesso!')
      })
      .catch((e) => {
        console.error(e)
        notify('Erro ao baixar Nota fiscal!')
      })
  }

  return (
    <div>
      <Button
        data-testid={`upload-button`}
        color="secondary"
        size="medium"
        variant="outlined"
        startIcon={<CloudDownload />}
        onClick={(e) => {
          e.stopPropagation()
          downloadInvoice()
        }}
      >
        Baixar Nota Fiscal
      </Button>
    </div>
  )
}

export default DownloadInvoiceButton
