import React from 'react'
import { Create, CreateProps, TopToolbar, Record } from 'react-admin'
import JointPurchaseForm from '../JointPurchaseFrom'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { IHandleChangeData } from '../../../components/ClinicSearch'
import { dateToCorrectISOString } from '../../../utils/utils'

const JointPurchaseActions = () => {
  return (
    <TopToolbar>
      <HeaderWithGoBack title="Criar compra" path="/joint-purchase" />
    </TopToolbar>
  )
}

const JointPurchaseCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    const clinicCampaignData: IHandleChangeData = data.clinics
    const resolveClinicsToInclude = () => {
      if (clinicCampaignData.selectAllClinics) {
        return []
      }

      return clinicCampaignData.selectedClinics.map((clinic) =>
        String(clinic._id)
      )
    }

    const resolveClinicsToExclude = () => {
      if (!clinicCampaignData.selectAllClinics) {
        return []
      }

      return clinicCampaignData.selectedClinics.map((clinic) =>
        String(clinic._id)
      )
    }

    const clinicsIdToInclude = resolveClinicsToInclude()
    const clinicsIdToExclude = resolveClinicsToExclude()

    const generateToAllClinics = clinicCampaignData
      ? clinicCampaignData.selectAllClinics
      : false

    return {
      ...data,
      clinics: {
        clinicsIdToInclude,
        clinicsIdToExclude,
        generateToAllClinics,
      },
      range: {
        endDate: dateToCorrectISOString(data.range.endDate),
        startDate: dateToCorrectISOString(data.range.startDate),
      },
    }
  }
  return (
    <Create transform={transform} actions={<JointPurchaseActions />} {...props}>
      <JointPurchaseForm />
    </Create>
  )
}

export default JointPurchaseCreate
