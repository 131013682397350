import * as React from 'react'
import { Filter, FilterProps } from 'react-admin'
import { ClinicConditionFiltersArray } from './Filters'

const OrdersFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      {ClinicConditionFiltersArray.map((item) => item.FilterInput)}
    </Filter>
  )
}

export default OrdersFilter
