import { Button } from '@material-ui/core'
import React from 'react'
import { useNotify } from 'react-admin'
import { apiUrl } from '../../../components/AppDataProvider'
import customHttpClient from '../../../components/CustomHttpClient'

const MarkAsReadButton = () => {
  const notify = useNotify()
  const markNotificationsAsRead = () => {
    customHttpClient(`${apiUrl}/notification/see-all`, {
      method: 'post',
    })
      .then(() => {
        notify(`As notificações foram marcadas como lidas!`, 'success')
      })
      .catch((error) => {
        console.error(`Erro ao marcar notificações como lidas: ${error}`)
        notify(`Erro ao marcar notificações como lidas: ${error}`, 'error')
      })
      .finally(() => {
        window.location.reload()
      })
  }
  return (
    <Button
      onClick={() => markNotificationsAsRead()}
      size="small"
      color="primary"
      variant="outlined"
    >
      Marcar todas como lidas
    </Button>
  )
}

export default MarkAsReadButton
