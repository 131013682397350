import * as React from 'react'
import _ from 'lodash'
import { DateInput, SelectInput } from 'react-admin'
import SearchFilterInput from '../../../../components/Inputs/SearchFilterInput'
import MultiAutocompleteInput from '../../../../components/Inputs/MultiAutocompleteInput/MultiAutocompleteInput'
import { inlineInputStyle } from '../../../../hooks/CustomListFilter'
import { cnpjFormatter } from '../../../../utils/formatters'

export const PayoutSettlementListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      label: 'Data de início',
      value: 'settlementDate(min)',
      FilterInput: (
        <DateInput
          label="Data de início"
          source="settlementDate(min)"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
          }}
        />
      ),
    },

    {
      label: 'Data de fim',
      value: 'settlementDate(max)',
      FilterInput: (
        <DateInput
          label="Data de fim"
          source="settlementDate(max)"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      label: 'Responsável',
      value: 'responsibleUser->name',
      FilterInput: (
        <SearchFilterInput
          label="Responsável"
          source="responsibleUser->name"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
          }}
        />
      ),
    },
    {
      label: 'Clínica',
      value: 'relatedClinic->name',
      FilterInput: (
        <SearchFilterInput
          label="Clínica"
          source="relatedClinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'pending_receipt', name: 'Pendente' },
            {
              id: 'receipt_settled',
              name: 'Concluído',
            },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'relatedClinic->_id',
      label: 'CNPJ da Clínica',
      FilterInput: (
        <MultiAutocompleteInput
          resourceToFetch="clinic"
          source="relatedClinic->_id"
          label="CNPJ da Clínica"
          variant={'outlined'}
          size={'small'}
          style={{
            ...inlineInputStyle,
            minWidth: 250,
            marginTop: 8,
            paddingBottom: 8,
          }}
          optionTypeLabel="cnpj"
          parseFilterToRequest={(filter: string) => {
            return filter.replace(/\D/g, '')
          }}
          parseLabel={(itemToParse: string) => {
            return String(cnpjFormatter.format(itemToParse))
          }}
        />
      ),
    },
  ],
  'label'
)
