import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const CommercialConditionRejectedCountChip = (props: {
  rejectedCount: string
}) => {
  let statusText
  let statusColor
  let color

  switch (props.rejectedCount) {
    case '-':
      statusText = props.rejectedCount
      statusColor = 'transparent'
      break
    default:
      statusText = props.rejectedCount
      statusColor = mainTheme.palette.error.main
      color = '#FFFFFF'
      break
  }
  return (
    <Chip style={{ backgroundColor: statusColor, color }} label={statusText} />
  )
}

export default CommercialConditionRejectedCountChip
