import { isAdmin } from '@vacinas-net/shared'
import * as React from 'react'
import { useListContext, usePermissions } from 'react-admin'
import AddClinicToCommercialCondition from '../../components/CommercialCondition/AddClinicToCommercialCondition'
import CustomListActions from '../../components/CustomListActions'
import HeaderWithGoBack from '../../components/HeaderWithGoBack'
import { mainTheme } from '../../utils/mainTheme'
import { getCommercialConditionFromQueryString } from '../../utils/utils'
import ClinicConditionHandlerChip from './ClinicConditionHandlerChip'
import _ from 'lodash'
import { ClinicConditionFiltersArray } from './Filters'

const OrdersListActions = () => {
  const { permissions } = usePermissions()
  const context = useListContext()
  const handler = _.first(Object.values(context.data))?.commercialCondition
    .handler

  return (
    <div>
      <div
        style={{
          marginBottom: mainTheme.spacing(1),
          display: 'flex',
          alignItems: 'center',
        }}
      >
        <HeaderWithGoBack
          title="Condição comercial da clínica"
          path={`/commercial-condition`}
        />
        {handler && (
          <div style={{ marginLeft: mainTheme.spacing(2) }}>
            <ClinicConditionHandlerChip handler={handler} />
          </div>
        )}
      </div>
      <CustomListActions
        filtersAvailable={ClinicConditionFiltersArray.map((item) => ({
          label: item.label,
          value: item.value,
        }))}
      >
        {isAdmin(permissions) && (
          <div style={{ marginLeft: mainTheme.spacing(2) }}>
            <AddClinicToCommercialCondition
              commercialConditionId={String(
                getCommercialConditionFromQueryString()
              )}
            />
          </div>
        )}
      </CustomListActions>
    </div>
  )
}

export default OrdersListActions
