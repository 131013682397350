import {
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useNotify } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { apiUrl } from '../../AppDataProvider'
import customHttpClient from '../../CustomHttpClient'

const ResendPatientLinkMail = (props: { attendanceId?: string }) => {
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  const resendEmail = async () => {
    setLoading(true)
    customHttpClient(
      `${apiUrl}/attendance/customerUrl/${props.attendanceId}/sendEmail`,
      {
        method: 'post',
      }
    )
      .then(() => {
        notify(`E-mail enviado com sucesso!`)
      })
      .catch((error) => {
        notify(`Erro ao enviar o e-mail: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpen(false)
      })
  }

  return (
    <div>
      <MenuItem
        color="secondary"
        onClick={() => {
          setOpen(true)
        }}
        data-testid={'send-mail-button'}
      >
        Reenviar E-mail
      </MenuItem>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Reenviar E-mail</DialogTitle>
        <DialogContent style={{ paddingBottom: mainTheme.spacing(2) }}>
          <Typography variant="body1">Deseja reenviar o e-mail ?</Typography>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              resendEmail()
            }}
            style={{ marginLeft: mainTheme.spacing(2) }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            data-testid={'submit-button'}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ResendPatientLinkMail
