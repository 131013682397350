import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FunctionField,
  Record,
  usePermissions,
} from 'react-admin'
import AttendanceStatusChip from '../../../components/chips/AttendanceStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import { parseDeliveryWindowDate } from '../../../utils/utils'
import { format, setHours, setMinutes, setSeconds } from 'date-fns'
import AttendanceListActions from './AttendanceListActions'
import AttendanceListFilters from './AttendanceListFilters'
import { formatISO } from 'date-fns/esm'
import { isAdmin, parseAttendanceType } from '@vacinas-net/shared'

const AttendanceList = ({ ...props }: ListProps) => {
  const setFilterInitialHour = () => {
    let dateToday = new Date(Date.now())
    dateToday = setHours(dateToday, 0)
    dateToday = setMinutes(dateToday, 0)
    dateToday = setSeconds(dateToday, 0)
    return dateToday
  }
  const { permissions } = usePermissions()
  return (
    <List
      pagination={<DefaultPagination />}
      actions={<AttendanceListActions />}
      filters={<AttendanceListFilters />}
      filterDefaultValues={{
        'deliveryWindow->startDate(min)': formatISO(setFilterInitialHour()),
      }}
      bulkActionButtons={false}
      sort={{ field: 'deliveryWindow.startDate', order: 'ASC' }}
      {...props}
    >
      <Datagrid stickyHeader rowClick="show">
        <FunctionField
          render={(record: Record | undefined) =>
            record?.deliveryWindow && record?.deliveryWindow.startDate
              ? format(new Date(record?.deliveryWindow.startDate), 'dd/MM/yy')
              : '-'
          }
          textAlign="center"
          source="deliveryWindow.startDate"
          label="Data Atendimento"
        />
        <TextField textAlign="center" source="aliasId" label="Atendimento nº" />
        <TextField textAlign="center" source="customer.name" label="Cliente" />
        {isAdmin(permissions) && (
          <TextField
            textAlign="center"
            source="vendorOrderId"
            label="Número do pedido"
          />
        )}
        <TextField textAlign="center" source="clinic.name" label="Clínica" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => {
            return parseAttendanceType(record?.type)
          }}
          label="Tipo de atendimento"
        />
        <TextField
          textAlign="center"
          source="pickupPointName"
          label="Ponto de retirada"
          emptyText="-"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            parseDeliveryWindowDate(record)
          }
          textAlign="center"
          label="Horario"
        />
        <FunctionField
          textAlign="center"
          label="Situação"
          source="status"
          render={(record: Record | undefined) => (
            <AttendanceStatusChip status={record?.status} />
          )}
        />
      </Datagrid>
    </List>
  )
}

export default AttendanceList
