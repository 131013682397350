import {
  IconButton,
  FormControl,
  TextField,
  Button,
  InputLabel,
  Select,
  MenuItem,
  InputAdornment,
  FormHelperText,
} from '@material-ui/core'
import { Close, Edit, Save } from '@material-ui/icons'
import { isAdmin } from '@vacinas-net/shared'
import { useFormik } from 'formik'
import React from 'react'
import {
  Record,
  useNotify,
  usePermissions,
  useRefresh,
  useUpdateMany,
} from 'react-admin'
import { mainTheme } from '../../utils/mainTheme'
import { ProductsBulkActionsForm } from '../../resources/products/ProductsList/ProductsBulkActions'
import { defaultValidationSchema } from '../../resources/products/utils'
import {
  resolveCommissionInPercentage,
  resolveGestureInCents,
  resolveOperationType,
  resolveProductAquisitionInCents,
} from '../../utils/utils'

const EditProductStockInline = (props: {
  record: Record | undefined
  setCurrentId: React.Dispatch<React.SetStateAction<string>>
  currentId: string
}) => {
  const { permissions } = usePermissions()

  const [updateMany, { loading }] = useUpdateMany()
  const notify = useNotify()
  const refresh = useRefresh()

  const schema = defaultValidationSchema()

  const { values, errors, handleChange, handleSubmit, setFieldValue } =
    useFormik<ProductsBulkActionsForm>({
      initialValues: {
        operationType: '',
      },
      validationSchema: schema,
      validateOnChange: true,
      onSubmit: () => updateSingleProductStock(),
    })

  const handleCloseEdit = () => {
    setFieldValue('commercialCondition', undefined)
    setFieldValue('value', undefined)
    props.setCurrentId('')
  }

  async function updateSingleProductStock() {
    const formattedValues: ProductsBulkActionsForm = {
      operationType: resolveOperationType(values),
      commissionInPercentage: resolveCommissionInPercentage(values),
      gestureInCents: resolveGestureInCents(values),
      productAquisitionInCents: resolveProductAquisitionInCents(values),
      totalQuantity: values.totalQuantity,
    }

    await updateMany('product', [props.record?._id], formattedValues, {
      onSuccess: () => {
        refresh()
        notify('Produtos atualizados com sucesso.')
        handleCloseEdit()
      },
      onFailure: (error) => {
        // TODO: custom error parser?
        // TODO: call sentry
        notify(`Erro ao atualizar produtos: ${error}`, 'warning')
      },
    })
  }

  const handleOpenEdit = () => props.setCurrentId(props.record?._id)

  return (
    <div style={{ position: 'relative' }}>
      <IconButton onClick={handleOpenEdit} size="small">
        <Edit />
      </IconButton>
      {props.currentId === props.record?._id && (
        <div
          style={{
            background: '#ebe4ee',
            position: 'absolute',
            top: '-84px',
            right: '-24px',
            zIndex: 3,
            borderTopLeftRadius: mainTheme.spacing(1),
            borderTopRightRadius: mainTheme.spacing(1),
          }}
        >
          <form
            onSubmit={handleSubmit}
            style={{
              display: 'inline-flex',
              gap: '16px',
              padding: mainTheme.spacing(2),
              alignItems: 'center',
            }}
          >
            <div>
              <IconButton
                onClick={handleCloseEdit}
                size="small"
                style={{
                  marginRight: mainTheme.spacing(4),
                }}
              >
                <Close />
              </IconButton>
            </div>
            <TextField
              type="number"
              label="Quantidade estoque físico"
              variant="outlined"
              size="small"
              style={{ width: 250 }}
              value={values.totalQuantity}
              onChange={handleChange('totalQuantity')}
              helperText={errors.totalQuantity}
            />
            {isAdmin(permissions) && (
              <>
                <FormControl
                  variant="outlined"
                  size="small"
                  style={{ minWidth: 200 }}
                >
                  <InputLabel>Condição comercial</InputLabel>
                  <Select
                    label="Condição comercial"
                    value={values.commercialCondition}
                    onChange={handleChange('commercialCondition')}
                  >
                    <MenuItem value="comission">Comissão</MenuItem>
                    <MenuItem value="aquisition">Aquisição</MenuItem>
                    <MenuItem value="gesture">Gesto</MenuItem>
                  </Select>
                  {errors.commercialCondition && (
                    <FormHelperText>
                      {errors.commercialCondition}
                    </FormHelperText>
                  )}
                </FormControl>

                {(values.commercialCondition === 'gesture' ||
                  values.commercialCondition === 'aquisition') && (
                  <TextField
                    required={true}
                    style={{ width: 150 }}
                    type="number"
                    label="Valor"
                    variant="outlined"
                    size="small"
                    value={values.value}
                    onChange={handleChange('value')}
                    InputProps={{
                      startAdornment: (
                        <InputAdornment position="start">R$</InputAdornment>
                      ),
                    }}
                  />
                )}
                {values.commercialCondition === 'comission' && (
                  <TextField
                    required={true}
                    style={{ width: 150 }}
                    type="number"
                    label="Valor"
                    variant="outlined"
                    size="small"
                    value={values.value}
                    onChange={handleChange('value')}
                    InputProps={{
                      inputProps: { min: 0, max: 100, step: 0.01 },
                      endAdornment: (
                        <InputAdornment position="end">%</InputAdornment>
                      ),
                    }}
                  />
                )}
              </>
            )}

            <Button
              type="submit"
              style={{ flex: '0 0 auto' }}
              color="primary"
              startIcon={<Save />}
              disabled={loading}
            >
              Salvar
            </Button>
          </form>
        </div>
      )}
    </div>
  )
}

export default EditProductStockInline
