import { Grid, WithWidthProps } from '@material-ui/core'
import {
  IAvailableAttendance,
  IClinicCondition,
  ICommercialCondition_V2,
  isAdmin,
  IUser,
  IVoucher,
  parseAttendanceType,
} from '@vacinas-net/shared'
import { format } from 'date-fns'
import React from 'react'
import {
  Datagrid,
  DateField,
  EditContextProvider,
  EditProps,
  FieldProps,
  FormTab,
  FunctionField,
  List,
  Record,
  SaveButton,
  TabbedForm,
  TextField,
  Toolbar,
  ToolbarProps,
  useEditController,
  usePermissions,
} from 'react-admin'
import { apiUrl } from '../../../components/AppDataProvider'
import CampaignEditForm from '../../../components/CampaignEditForm'
import OrderStatusChip from '../../../components/chips/OrderStatusChip'
import { OptionType } from '../../../components/CustomAutocomplete'
import DashboardHeaderBillingData from '../../../components/Dashboard/DashboardHeaderBillingData'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { DashboardPagination } from '../../../components/Pagination'
import { mainTheme } from '../../../utils/mainTheme'
import { dateToCorrectISOString } from '../../../utils/utils'
import ClinicListWithMap from './ClinicListWithMap'
import CampaignOrdersFilter from './CampaignOrderListFilters'
import CampaignOrdersListActions from './CampaignOrderactions'
import AvailableAttendancesTab from './AvailableAttendancesTab'
import AllowedCustomerBatchTab from './AllowedCustomerBatchTab'

const CustomCampaignEdit: React.FC<FieldProps> = (props) => {
  const controllerProps = useEditController(props)
  const received_record = controllerProps.record

  const startDate = received_record?.dateRange?.startDate
    ? format(
        new Date(String(received_record?.dateRange?.startDate)),
        'yyyy-MM-dd'
      )
    : undefined
  const endDate = received_record?.dateRange?.endDate
    ? format(
        new Date(String(received_record?.dateRange?.endDate)),
        'yyyy-MM-dd'
      )
    : undefined

  const vouchers = received_record?.vouchers
    ? received_record?.vouchers
        ?.map((voucher: IVoucher) => voucher.redemptionCode)
        .join('\n')
    : []

  const relatedUsers =
    received_record?.relatedUsers.map((user: IUser) => {
      return {
        label: user.email,
        value: user._id,
      }
    }) || []

  const commercialConditions =
    received_record?.commercialConditions.map(
      (commercialCondition: ICommercialCondition_V2) => {
        return {
          label: commercialCondition.name,
          value: commercialCondition._id,
        }
      }
    ) || []

  const productPriceInCents =
    received_record?.productPriceInCents / 100 || undefined

  const record: Record = {
    ...received_record,
    id: String(received_record?.id),
    vouchers: vouchers,
    relatedUsers: relatedUsers,
    commercialConditions: commercialConditions,
    dateRange: {
      startDate: startDate,
      endDate: endDate,
    },
    productPriceInCents: productPriceInCents,
  }

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        <HeaderWithGoBack title={`Campanha ${record?.name}`} />
        {React.cloneElement(props.children as React.ReactElement, {
          ...controllerProps,
          record,
        })}
      </div>
    </EditContextProvider>
  )
}

const CampaignEdit = (props: EditProps) => {
  const { permissions } = usePermissions()
  const controllerProps = useEditController(props)
  const received_record = controllerProps.record
  const availableAttendances =
    received_record?.availableAttendances as IAvailableAttendance[]

  const clinics: IClinicCondition['clinic'][] = []
  received_record?.fullCommercialConditions?.map(
    ({ clinicConditions }: ICommercialCondition_V2) =>
      clinicConditions.filter(
        ({ status, clinic }) => status === 'accepted' && clinics.push(clinic)
      )
  ) || []

  const transform = (data: Record) => {
    const startDate = dateToCorrectISOString(data.dateRange.startDate)
    const endDate = dateToCorrectISOString(data.dateRange.endDate)

    const relatedUsers =
      data.relatedUsers?.map((user: OptionType) => {
        return {
          _id: user.value,
          email: user.label,
        }
      }) || undefined

    const commercialConditions =
      data.commercialConditions?.map((condition: OptionType) => {
        return {
          _id: condition.value,
          name: condition.label,
        }
      }) || undefined

    return {
      dateRange: {
        startDate: startDate,
        endDate: endDate,
      },
      relatedUsers: relatedUsers,
      commercialConditions: commercialConditions,
      name: data.name,
      coupons: data.handler === 'vtex' ? data.coupons : undefined,
      promotions: data.handler === 'vtex' ? data.promotions : undefined,
    }
  }

  const CustomToolbar = (props: ToolbarProps & WithWidthProps) => {
    return (
      <Toolbar {...props}>
        <SaveButton label="Salvar" />
      </Toolbar>
    )
  }

  return (
    <CustomCampaignEdit
      mutationMode="pessimistic"
      transform={transform}
      {...props}
    >
      <TabbedForm toolbar={<CustomToolbar />}>
        <FormTab {...props} label="Configuração">
          <CampaignEditForm />
        </FormTab>
        <FormTab label="Dados da campanha">
          <Grid
            container
            xs={12}
            spacing={2}
            style={{
              marginTop: mainTheme.spacing(1),
              padding: mainTheme.spacing(1),
              width: '100%',
            }}
          >
            <Grid item xs={12}>
              <DashboardHeaderBillingData
                isAdmin={isAdmin(permissions)}
                requestUrl={`${apiUrl}/dashboard/header?purchasedAt(min)=${
                  received_record?.dateRange?.startDate &&
                  `${received_record.dateRange.startDate}`
                }&purchasedAt(max)=${
                  received_record?.dateRange?.endDate &&
                  `${received_record.dateRange.endDate}`
                }&relatedCampaigns._id=${received_record?._id}`}
                makingRequest={!!received_record}
                stopRequest={() => ({})}
              ></DashboardHeaderBillingData>
            </Grid>
            <Grid item xs={12}>
              <List
                pagination={<DashboardPagination />}
                bulkActionButtons={false}
                basePath="/order"
                resource="order"
                filterDefaultValues={{
                  'status!': ['cancelled', 'rejected'],
                  'relatedCampaigns._id': received_record?._id,
                }}
                filters={<CampaignOrdersFilter />}
                sort={{ field: 'createdAt', order: 'DESC' }}
                actions={<CampaignOrdersListActions />}
              >
                <Datagrid stickyHeader rowClick="show">
                  <DateField
                    locales="pt-BR"
                    textAlign="center"
                    source="purchasedAt"
                    label="Data do pedido"
                  />
                  <TextField
                    textAlign="center"
                    source="customer.name"
                    label="Cliente"
                  />
                  <TextField
                    textAlign="center"
                    source="orderId"
                    label="Número"
                  />
                  <FunctionField
                    textAlign="center"
                    render={(record: Record | undefined) =>
                      record?.relatedClinics &&
                      record?.relatedClinics.length > 0
                        ? record.relatedClinics
                            ?.map((clinic: { name: string }) => clinic.name)
                            .join(', ')
                        : '-'
                    }
                    label="Clínicas"
                    source="relatedClinics"
                  />
                  <FunctionField
                    textAlign="center"
                    render={(record: Record | undefined) => {
                      return parseAttendanceType(record?.shippingAddress?.type)
                    }}
                    label="Tipo de atendimento"
                  />
                  <FunctionField
                    textAlign="center"
                    render={(record: Record | undefined) =>
                      record?.attendances
                        ? record.attendances
                            ?.filter(
                              (attendance: {
                                pickupPointName: string
                                status: string
                              }) =>
                                !!attendance.pickupPointName &&
                                attendance.status !== 'relocated'
                            )
                            ?.map(
                              (attendance: { pickupPointName: string }) =>
                                attendance.pickupPointName
                            )
                            .join(', ')
                        : '-'
                    }
                    label="Pontos de retirada"
                  />
                  <FunctionField
                    textAlign="center"
                    label="Situação"
                    render={(record: Record | undefined) => {
                      return <OrderStatusChip status={record?.status} />
                    }}
                  />
                </Datagrid>
              </List>
            </Grid>
          </Grid>
        </FormTab>
        <FormTab label="Formulários de agendamento">
          <AvailableAttendancesTab
            availableAttendances={availableAttendances}
          />
        </FormTab>
        <FormTab label="Beneficiários">
          <AllowedCustomerBatchTab />
        </FormTab>
        <FormTab label="Rede Aceitação">
          <ClinicListWithMap
            clinics={clinics}
            campaignName={received_record?.name}
          />
        </FormTab>
      </TabbedForm>
    </CustomCampaignEdit>
  )
}

export default CampaignEdit
