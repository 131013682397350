import { Badge } from '@material-ui/core'
import { useLocation } from 'react-router-dom'
import { useEffect, useState } from 'react'
import httpClient from '../../components/CustomHttpClient'
import React from 'react'
import { apiUrl } from '../AppDataProvider'

interface NotificationCounterProps {
  path: string
}

const NotificationCounter: React.FC<NotificationCounterProps> = ({ path }) => {
  const location = useLocation()

  const [amount, setAmount] = useState(0)

  const getNotifications = () => {
    httpClient(`${apiUrl}/${path}`).then((res) => {
      if (path.includes('order/counter')) {
        setAmount(res.json.orderCounter)
      } else if (path.includes('attendance/counter')) {
        setAmount(res.json.attendanceCounter)
      } else if (path.includes('notification/counter')) {
        setAmount(res.json.notViewed)
      }
    })
  }

  useEffect(() => {
    getNotifications()
  }, [location])

  return <Badge color="error" max={99} badgeContent={amount}></Badge>
}

export default NotificationCounter
