import { format } from 'date-fns'
import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FunctionField,
  Record,
} from 'react-admin'
import PayoutStatusChip from '../../../components/chips/PayoutStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import { valueInCentsToBRL } from '../../../utils/utils'
import PayoutSummarizedActions from './PayoutPerClinicActions'
import PayoutSummarizedFilter from './PayoutSummarizedFilter'

const PayoutSummarized = ({ ...props }: ListProps) => {
  return (
    <List
      pagination={<DefaultPagination />}
      actions={<PayoutSummarizedActions />}
      filters={<PayoutSummarizedFilter />}
      bulkActionButtons={false}
      {...props}
    >
      <Datagrid stickyHeader>
        <TextField
          textAlign="center"
          source="aliasId"
          label="Número atendimento"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.attendanceFinishedAt
              ? format(new Date(record.attendanceFinishedAt), 'dd/MM/yy')
              : '-'
          }
          label="Data de finalização do atendimento"
          source="attendanceFinishedAt"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.payoutDate
              ? format(new Date(record.payoutDate), 'dd/MM/yy')
              : '-'
          }
          label="Data do repasse"
          source="payoutDate"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.valueInCents ? valueInCentsToBRL(record?.valueInCents) : '-'
          }
          label="Valor do repasse"
          source="valueInCents"
          sortable={false}
        />
        <TextField textAlign="center" source="clinic.name" label="Clínica" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => (
            <PayoutStatusChip status={record?.status} />
          )}
          label="Status"
          source="status"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

export default PayoutSummarized
