import React, { useContext } from 'react'
import {
  Typography,
  CircularProgress,
  Switch,
  FormControlLabel,
} from '@material-ui/core'
import AddProductsInput from './AddProductsInput'
import { mainTheme } from '../../utils/mainTheme'
import CouponAndZipcodeInputs from './CouponAndZipcodeInputs'
import ProductListRow from './ProductListRow'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import ProductsListHeader from './ProductsListHeader'

const ProductsSession = () => {
  const orderCreateContext = useContext(OrderCreateContext)
  const { productsArray, loadingTable, createMany, toggleCreateMany } =
    orderCreateContext!
  return (
    <div>
      <div style={{ marginBottom: mainTheme.spacing(2) }}>
        <FormControlLabel
          control={
            <Switch
              data-testid="createMany-switch"
              checked={createMany}
              onChange={() => toggleCreateMany()}
            />
          }
          label="Criar vários pedidos"
        />
      </div>
      <Typography variant="h1">Produtos</Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          paddingTop: mainTheme.spacing(4),
        }}
      >
        <AddProductsInput />
        {!createMany && <CouponAndZipcodeInputs />}
      </div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          paddingTop: mainTheme.spacing(2),
        }}
      >
        {productsArray.length > 0 && <ProductsListHeader />}
        {!loadingTable ? (
          productsArray.map((product) => (
            <ProductListRow key={product.skuId} product={product} />
          ))
        ) : (
          <div
            style={{
              padding: mainTheme.spacing(2),
              paddingTop: 0,
            }}
          >
            <CircularProgress size={30} />
          </div>
        )}
      </div>
    </div>
  )
}

export default ProductsSession
