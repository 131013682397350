import * as React from 'react'
import { TextInputProps } from 'react-admin'
import { TextField } from '@material-ui/core'
import { useInput, useListContext } from 'ra-core'
import { mainTheme } from '../../../utils/mainTheme'

const SearchFilterInput = (props: TextInputProps) => {
  const [value, setValue] = React.useState('')

  const { input } = useInput(props)
  const list = useListContext()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    if (event.target.value && props.type !== 'number') {
      event.target.value = '%' + event.target.value + '%'
    }

    input.onChange(event)
  }

  React.useEffect(() => {
    if (!!list) {
      const initialValue = list.filterValues[props.source]
      if (initialValue) {
        setValue(String(initialValue).replaceAll('%', ''))
      }
    }
  }, [])

  return (
    <div>
      <TextField
        className={props.className}
        style={{
          paddingBottom: mainTheme.spacing(1),
          marginTop: mainTheme.spacing(1),
          ...props.style,
        }}
        placeholder={props.placeholder}
        onChange={onChange}
        variant={props.variant || 'outlined'}
        value={value}
        label={props.label}
        size={props.size}
        type={props.type}
      />
    </div>
  )
}

export default SearchFilterInput
