import { Typography } from '@material-ui/core'
import React from 'react'
import {
  Create,
  CreateProps,
  DateInput,
  required,
  SimpleForm,
  TextInput,
  Record,
  TopToolbar,
  SelectInput,
} from 'react-admin'
import ClinicConditionInput from '../../../components/ClinicConditions/ClinicConditionInput'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import ProductsInput from '../../../components/Inputs/ProductsInput'
import { mainTheme } from '../../../utils/mainTheme'
import { dateToCorrectISOString } from '../../../utils/utils'

const CommercialConditionsActions = () => {
  return (
    <TopToolbar>
      <HeaderWithGoBack
        title="Criar Condição comercial"
        path="/commercial-condition"
      />
    </TopToolbar>
  )
}

const CommercialConditionsCreate = (props: CreateProps) => {
  const transform = (data: Record) => {
    const parsedClinicConditions = data.clinicConditions.map(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (condition: any) => {
        if (data.handler === 'vtex') {
          delete condition.shippingPolicy
        }
        delete condition.responsibleUser
        delete condition.status
        delete condition._id

        return condition
      }
    )
    const startDate = dateToCorrectISOString(data.dateRange.startDate)
    const endDate = dateToCorrectISOString(data.dateRange.endDate)
    return {
      ...data,
      dateRange: {
        startDate: startDate,
        endDate: endDate,
      },
      clinicConditions: parsedClinicConditions,
    }
  }

  return (
    <Create
      transform={transform}
      actions={<CommercialConditionsActions />}
      {...props}
    >
      <SimpleForm>
        <Typography style={{ marginBottom: mainTheme.spacing(1) }} variant="h2">
          Condições comerciais
        </Typography>
        <br />
        <Typography style={{ marginBottom: mainTheme.spacing(1) }} variant="h2">
          Tipo de condição comercial
        </Typography>
        <SelectInput
          defaultValue="jenner"
          source="handler"
          label="Tipo"
          variant="outlined"
          choices={[
            {
              id: 'jenner',
              name: 'Jenner',
            },
            {
              id: 'vtex',
              name: 'VTEX',
            },
          ]}
          style={{ width: 200 }}
          validate={required()}
        />
        <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
          <TextInput
            color="primary"
            variant="outlined"
            label="Nome da condição comercial"
            source="name"
            style={{ marginRight: mainTheme.spacing(1) }}
            validate={required()}
          />
          <DateInput
            label="Data de início"
            source="dateRange.startDate"
            variant="outlined"
            style={{ marginRight: mainTheme.spacing(1) }}
            validate={required()}
          />
          <DateInput
            label="Data de fim"
            source="dateRange.endDate"
            variant="outlined"
            validate={required()}
          />
        </div>
        <TextInput
          color="primary"
          variant="outlined"
          label="Descrição"
          source="description"
          style={{ width: 400 }}
          multiline
        />
        <ProductsInput source="products" validate={required()} />
        <ClinicConditionInput source="clinicConditions" validate={required()} />
      </SimpleForm>
    </Create>
  )
}

export default CommercialConditionsCreate
