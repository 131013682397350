import { isAdmin, ReportTypeEnum } from '@vacinas-net/shared'
import { usePermissions } from 'ra-core'
import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { CommercialConditionsFiltersArray } from './Filters'

const CampaignListActions = () => {
  const { permissions } = usePermissions()
  return (
    <CustomListActions
      filtersAvailable={CommercialConditionsFiltersArray.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      hasExport
      exportType={ReportTypeEnum.CommercialCondition}
      exportButtonText="Exportar Condições Comerciais"
      hasCreate={isAdmin(permissions)}
    />
  )
}

export default CampaignListActions
