import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { ProductsSkuListFiltersArray } from './Filters'

const ProductsSkuListActions = () => {
  return (
    <CustomListActions
      filtersAvailable={ProductsSkuListFiltersArray.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      hasCreate
    ></CustomListActions>
  )
}

export default ProductsSkuListActions
