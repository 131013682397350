import * as React from 'react'
import { makeStyles, ThemeProvider, Theme } from '@material-ui/core'
import { LayoutComponent, Notification, useToggleSidebar } from 'react-admin'
import Menu from './Menu'
import { mainTheme } from '../../utils/mainTheme'
import Appbar from './Appbar'
import LoadingWrapper from './loading/LoadingWrapper'

const useStyles = makeStyles<Theme, { sidebarIsOpen: boolean }>((theme) => ({
  root: {
    display: 'flex',
    flexDirection: 'column',
    zIndex: 1,
    backgroundColor: theme.palette.background.default,
    position: 'relative',
    height: '100vh',
  },
  appFrame: {
    display: 'flex',
    flexDirection: 'column',
    overflowX: 'auto',
    height: '100%',
  },
  contentWithSidebar: {
    display: 'flex',
    flexGrow: 1,
  },
  sidebarOpenContainer: {
    maxWidth: ({ sidebarIsOpen }) => (sidebarIsOpen ? 290 : 55),
    minWidth: ({ sidebarIsOpen }) => (sidebarIsOpen ? 290 : 55),
    ['@media print']: {
      display: 'none',
    },
    [mainTheme.breakpoints.down('sm')]: {
      minWidth: '100%',
    },
  },
  showOnlyOnMobile: {
    display: 'none',
    [mainTheme.breakpoints.down('sm')]: {
      display: 'block',
    },
  },
  content: {
    display: 'flex',
    flexDirection: 'column',
    flexGrow: 2,
    padding: theme.spacing(3),
    marginTop: 0,
  },
}))

const Layout: LayoutComponent = (props) => {
  const { children } = props
  const [sidebarIsOpen] = useToggleSidebar()
  const classes = useStyles({ sidebarIsOpen })

  return (
    <ThemeProvider theme={mainTheme}>
      <>
        <LoadingWrapper />
        <div className={classes.root}>
          <div className={classes.appFrame}>
            <div className={classes.showOnlyOnMobile}>
              <Appbar />
            </div>
            <main className={classes.contentWithSidebar}>
              <div className={classes.sidebarOpenContainer}>
                <Menu />
              </div>
              <div className={classes.content}>{children}</div>
            </main>
            <Notification />
          </div>
        </div>
      </>
    </ThemeProvider>
  )
}

export default Layout
