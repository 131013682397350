import { GetListParams, useDataProvider } from 'react-admin'
import { OptionType } from './CustomAutocomplete'
import { Autocomplete } from '@material-ui/lab'
import { TextField, CircularProgress } from '@material-ui/core'
import React, { useMemo, useState } from 'react'
import _ from 'lodash'
import qs from 'querystring'

export interface CustomMultiAutocompleteProps {
  resource: string
  onChange: (value: OptionType[]) => void
  currentValueArray: OptionType[] | undefined
  className?: string
  label?: string
  required?: boolean
  variant?: 'standard' | 'filled' | 'outlined' | undefined
  size?: 'small' | 'medium' | undefined
  searchTerm?: boolean
  filterByActiveClinics?: boolean
  optionTypeLabel: string
  optionTypeValue: string
  style?: React.CSSProperties
  readOnly?: boolean
  parseLabel?: (itemToParse: string) => string
  parseFilterToRequest?: (filter: string) => string

  defaultFilters?: string

  passFullOptionData?: boolean
}

const CustomMultiAutocomplete = (props: CustomMultiAutocompleteProps) => {
  const [loading, setLoading] = useState(false)
  const [filter, setFilter] = useState('')
  const [options, setOptions] = useState<OptionType[]>([])
  const [open, setOpen] = React.useState(false)

  const debounceFilter = useMemo(
    () =>
      _.debounce((filter: string) => {
        setFilter(filter)
      }, 500),
    []
  )

  const dataProvider = useDataProvider()

  const fetchData = async () => {
    try {
      setLoading(true)

      const parsedFilter = props.parseFilterToRequest
        ? props.parseFilterToRequest(filter)
        : filter

      const hasFilter =
        parsedFilter || props.filterByActiveClinics || props.defaultFilters

      const params: GetListParams = {
        filter: hasFilter && {
          ...(props.defaultFilters ? qs.parse(props.defaultFilters) : {}),
          [props.searchTerm ? 'searchTerm' : props.optionTypeLabel]:
            props.searchTerm ? parsedFilter : `%${parsedFilter}%`,
          status: props.filterByActiveClinics ? 'active' : undefined,
        },
        pagination: {
          page: 1,
          perPage: 25,
        },
        sort: {
          field: props.optionTypeLabel,
          order: 'asc',
        },
      }
      const response = await dataProvider.getList(props.resource, params)
      const data = response.data
      const formattedOptions = data
        .filter((option) => option[props.optionTypeLabel])
        .map((item) => ({
          ...(props.passFullOptionData ? item : {}),
          value: item[props.optionTypeValue],
          label: props.parseLabel
            ? props.parseLabel(item[props.optionTypeLabel])
            : item[props.optionTypeLabel],
        }))
      setOptions(formattedOptions)
    } catch (error) {
      console.error(
        '[Error] - Error to fetch autocomplete for resource ' + props.resource,
        error
      )
    } finally {
      setLoading(false)
    }
  }

  React.useEffect(() => {
    fetchData()
  }, [filter, props.defaultFilters, props.currentValueArray === null])

  React.useEffect(() => {
    setFilter('')
  }, [props.defaultFilters])

  return (
    <Autocomplete
      multiple={true}
      style={props.style ? props.style : { width: '100%' }}
      className={props.className}
      size={props.size ?? 'medium'}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option, value) => option.value === value.value}
      getOptionLabel={(option) => option.label}
      options={options}
      loading={loading}
      onChange={(e, value) => props.onChange(value)}
      value={props.currentValueArray}
      disabled={props.readOnly}
      filterOptions={(options) => {
        return options
      }}
      renderInput={(params) => (
        <TextField
          {...params}
          fullWidth
          className={props.className}
          size={props.size}
          label={props.label}
          onChange={(e: React.ChangeEvent<HTMLInputElement>) =>
            debounceFilter(e.target.value)
          }
          required={props.required}
          variant={props.variant || 'outlined'}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <React.Fragment>
                {loading ? (
                  <CircularProgress color="inherit" size={20} />
                ) : null}
                {params.InputProps.endAdornment}
              </React.Fragment>
            ),
          }}
        />
      )}
      disableCloseOnSelect={true}
    />
  )
}

export default CustomMultiAutocomplete
