import { isAdmin } from '@vacinas-net/shared'
import * as React from 'react'
import { usePermissions } from 'react-admin'
import CustomListActions from '../../../components/CustomListActions'
import { JointPurchaseFiltersArray } from './Filters'

const JointPurchaseActions = () => {
  const { permissions } = usePermissions()
  return (
    <CustomListActions
      filtersAvailable={JointPurchaseFiltersArray.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      hasCreate={isAdmin(permissions)}
    />
  )
}

export default JointPurchaseActions
