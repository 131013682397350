import { useState } from 'react'
import { Confirm, useListContext, useNotify } from 'react-admin'
import { IconButton } from '@material-ui/core'

import React from 'react'
import { Delete } from '@material-ui/icons'
import { apiUrl } from '../AppDataProvider'
import customHttpClient from '../CustomHttpClient'

const DeleteAllowedCustomerButton = (props: {
  disabled?: boolean
  campaignId: string
  document: string
}) => {
  const [loading, setLoading] = useState(false)
  const [open, setOpen] = useState(false)
  const list = useListContext()

  const notify = useNotify()

  const deleteAllowedCustomer = async () => {
    setLoading(true)
    try {
      await customHttpClient(`${apiUrl}/campaign/allowed-customer/banlist`, {
        body: JSON.stringify({
          campaignId: props.campaignId,
          document: props.document,
        }),
        method: 'PUT',
      })
      list.refetch()
      setOpen(false)
    } catch (error) {
      notify(`Erro ao deletar beneficiário: ${error}`, 'error')
    } finally {
      setLoading(false)
    }
  }
  return (
    <div>
      <IconButton
        onClick={() => {
          setOpen(!open)
        }}
        disabled={props.disabled || loading}
        size="small"
      >
        <Delete />
      </IconButton>
      <Confirm
        loading={loading}
        content="Deseja confirmar essa ação?"
        onClose={() => setOpen(false)}
        onConfirm={() => deleteAllowedCustomer()}
        title="Deletar beneficiário"
        isOpen={open}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default DeleteAllowedCustomerButton
