import * as React from 'react'
import { FilterProps, Filter } from 'react-admin'
import { PayoutSettlementListFiltersArray } from './Filters'

const PayoutSettlementFilters: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  return (
    <Filter {...props}>
      {PayoutSettlementListFiltersArray.map((item) => item.FilterInput)}
    </Filter>
  )
}

export default PayoutSettlementFilters
