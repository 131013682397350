import { Typography } from '@material-ui/core'
import { IAvailableAttendance } from '@vacinas-net/shared'
import React from 'react'
import { Link } from 'react-admin'
import qs from 'querystring'

export interface AvailableAttendanceOrderFieldProps {
  availableAttendance: IAvailableAttendance
  hasOrders: boolean
}

const AvailableAttendanceOrderField = ({
  availableAttendance,
  hasOrders,
}: AvailableAttendanceOrderFieldProps): JSX.Element => {
  const displayedFilters = {}
  const filter = {
    availableAttendanceId: availableAttendance._id,
    ['status!']: 'cancelled',
  }

  const queryString = qs.stringify({
    displayedFilters: JSON.stringify(displayedFilters),
    filter: JSON.stringify(filter),
  })

  if (hasOrders)
    return (
      <Link
        to={{
          pathname: '/order',
          search: queryString,
        }}
        target="blank"
      >
        <Typography
          style={{
            textDecoration: 'underline',
            cursor: 'pointer',
          }}
          color="textPrimary"
          variant="body2"
        >
          Ver pedidos
        </Typography>
      </Link>
    )

  return (
    <Typography color="textPrimary" variant="body2">
      Sem pedidos
    </Typography>
  )
}

export default AvailableAttendanceOrderField
