import React from 'react'
import { useSelector } from 'react-redux'
import Snackbar from '@material-ui/core/Snackbar'
import { ReduxState } from 'ra-core'

const LoadingNotification = () => {
  const open = useSelector<ReduxState>((state) => state.admin.loading > 0)

  return (
    <Snackbar
      anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
      open={!!open}
      message={'Carregando conteúdo'}
    />
  )
}

export default LoadingNotification
