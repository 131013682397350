import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  FormLabel,
} from '@material-ui/core'
import React from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { Record, useNotify, useRefresh } from 'react-admin'
import { useState } from 'react'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../CustomHttpClient'
import DeleteIcon from '@mui/icons-material/Delete'

const DeletePayoutPopUp = (props: { record: Record | undefined }) => {
  const [loading, setLoading] = useState(false)
  const [openDeletePayout, setOpenDeletePayout] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()

  const deletePayout = async () => {
    setLoading(true)
    const url = `${apiUrl}/payout/${props.record?._id}`
    httpClient(url, {
      method: 'delete',
    })
      .then(() => {
        notify('Repasse excluido com sucesso')
        setOpenDeletePayout(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao excluir repasse', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={() => setOpenDeletePayout(true)}
        data-testid={'button-delete-payout'}
      >
        <DeleteIcon />
      </Button>

      <Dialog
        open={openDeletePayout}
        onClose={() => setOpenDeletePayout(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent
          style={{
            paddingTop: mainTheme.spacing(3),
            alignItems: 'center',
            textAlign: 'center',
          }}
        >
          <Typography variant="h2">Confirmar exclusão do repasse.</Typography>
          <div
            style={{
              marginTop: mainTheme.spacing(2),
            }}
          >
            <FormLabel>Tem certeza que deseja excluir este repasse?</FormLabel>
            <div>
              <FormLabel>Essa ação não poderá ser revertida.</FormLabel>
            </div>
          </div>
        </DialogContent>
        <DialogActions
          style={{
            paddingTop: mainTheme.spacing(2),
            padding: mainTheme.spacing(3),
          }}
        >
          <Button onClick={() => setOpenDeletePayout(false)} variant="outlined">
            Cancelar
          </Button>
          <Button
            onClick={() => {
              deletePayout()
            }}
            style={{ marginLeft: 'auto' }}
            variant="contained"
            color="primary"
            type="submit"
            disabled={loading}
            data-testid={'button-delete-payout-submit'}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default DeletePayoutPopUp
