import { IClinicCondition, ShippingPolicy } from '@vacinas-net/shared'
import * as React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  Record,
  TextField,
} from 'react-admin'
import ClinicConditionChip from '../../components/chips/ClinicConditionStatusChip'

import { DefaultPagination } from '../../components/Pagination'
import { getCommercialConditionFromQueryString } from '../../utils/utils'
import ClinicConditionActionField from './ClinicConditionActionField'
import ClinicConditionListActions from './ClinicConditionListActions'
import ClinicConditionFilter from './ClinicConditionListFilter'
import {
  parseClinicConditionType,
  parseClinicConditionValue,
  shippingPolicyDictionary,
} from './utils'
import EditClinicConditionInLine from './EditClinicConditionInLine'

const ClinicConditionList = ({ ...props }: ListProps) => {
  return (
    <List
      sort={{ field: 'purchasedAt', order: 'DESC' }}
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      actions={<ClinicConditionListActions />}
      filters={<ClinicConditionFilter />}
      filterDefaultValues={{
        commercialConditionId: getCommercialConditionFromQueryString(),
      }}
      {...props}
    >
      <Datagrid stickyHeader rowClick="show">
        <TextField
          sortable={false}
          textAlign="center"
          source="clinic.name"
          label="Clínica"
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          render={(record: Record | undefined) => {
            return parseClinicConditionType(record?.type)
          }}
          label="Condição comercial"
          source="type"
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          render={(record: Record | undefined) => {
            return parseClinicConditionValue(record?.value, record?.type)
          }}
          label="Valor"
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          render={(record: Record | undefined) => {
            return record?.shippingPolicy
              ? shippingPolicyDictionary[
                  record.shippingPolicy as ShippingPolicy
                ]
              : '-'
          }}
          label="Política de envio"
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          label="Quantidade"
          render={(record: Record | undefined) => {
            const clinicCondition = record as unknown as IClinicCondition

            const totalAmount = clinicCondition.totalAmount || 0
            const boughtAmount = clinicCondition.boughtAmount || 0

            return `${boughtAmount} de ${totalAmount}`
          }}
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          label="Editar"
          onClick={(event: React.MouseEvent<HTMLSpanElement, MouseEvent>) =>
            event.stopPropagation()
          }
          render={(record: Record | undefined) => {
            return (
              <EditClinicConditionInLine
                disabled={!record}
                clinicCondition={record as unknown as IClinicCondition}
              />
            )
          }}
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          render={(record: Record | undefined) => (
            <ClinicConditionActionField
              clinicCondition={record as unknown as IClinicCondition}
            />
          )}
          label="Ações"
        />
        <FunctionField
          sortable={false}
          textAlign="center"
          label="Situação"
          render={(record: Record | undefined) => {
            return <ClinicConditionChip status={record?.status} />
          }}
        />
      </Datagrid>
    </List>
  )
}

export default ClinicConditionList
