import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  DateField,
  FunctionField,
  Record,
} from 'react-admin'
import PayoutSettlementStatusChip from '../../../../components/chips/PayoutSettlementStatusChip'
import DownloadReceiptButton from '../../../../components/Buttons/DownloadReceiptButton'
import UploadReceiptButton from '../../../../components/Buttons/UploadReceiptButton'
import { DefaultPagination } from '../../../../components/Pagination'
import { valueInCentsToBRL } from '../../../../utils/utils'
import PayoutSettlementActions from './PayoutSettlementActions'
import PayoutSettlementFilters from './PayoutSettlementFilters'

const PayoutSettlementList = ({ ...props }: ListProps) => {
  return (
    <List
      pagination={<DefaultPagination />}
      actions={<PayoutSettlementActions />}
      filters={<PayoutSettlementFilters />}
      bulkActionButtons={false}
      sort={{ field: 'settlementDate', order: 'DESC' }}
      {...props}
    >
      <Datagrid stickyHeader rowClick="show">
        <DateField
          textAlign="center"
          source="settlementDate"
          label="Data de pagamento"
          locales="pt-BR"
        />
        <TextField
          textAlign="center"
          source="responsibleUser.name"
          label="Responsável"
        />
        <TextField
          textAlign="center"
          source="relatedClinic.name"
          label="Clínica"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            valueInCentsToBRL(record?.totalValueInCents)
          }
          label="Valor"
          source="payoutValueInCents"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.status === 'receipt_settled' ? (
              <DownloadReceiptButton id={record?.id} />
            ) : (
              <UploadReceiptButton id={record?.id} />
            )
          }
          label="Comprovante"
          source="payoutValueInCents"
          sortable={false}
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => (
            <PayoutSettlementStatusChip status={record?.status} />
          )}
          label="Status"
          source="status"
          sortable={false}
        />
      </Datagrid>
    </List>
  )
}

export default PayoutSettlementList
