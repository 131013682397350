import * as React from 'react'
import { Typography, TextField, Button } from '@material-ui/core'
import { useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import InputMask from 'react-input-mask'
import { ArrowForward } from '@material-ui/icons'
import { useRedirect } from 'react-admin'
import { isValidCPF } from '@brazilian-utils/brazilian-utils'
import { CustomerAvailableCampaignDTO } from '@vacinas-net/shared'

const SignInForm = (props: {
  campaignId: string
  availableAttendanceId: string
  campaignData?: CustomerAvailableCampaignDTO
}) => {
  const [formData, setFormData] = useState<{
    fullName: string
    document: string
  }>({ fullName: '', document: '' })

  const redirect = useRedirect()

  const documentError =
    formData.document !== '' && !isValidCPF(formData.document)

  return (
    <>
      <Typography
        style={{ marginTop: mainTheme.spacing(2) }}
        color="textSecondary"
        variant="body2"
      >
        Para iniciar o registro de aplicação de doses insira seu nome e CPF{' '}
      </Typography>
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          marginTop: mainTheme.spacing(2),
        }}
      >
        <TextField
          style={{ width: 250 }}
          label="Nome"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          value={formData.fullName}
          onChange={(event) =>
            setFormData({
              ...formData,
              fullName: event.target.value,
            })
          }
          placeholder="Seu nome"
        />
        <div>
          <InputMask
            mask="999.999.999-99"
            value={formData.document}
            onChange={(event) =>
              setFormData({
                ...formData,
                document: event.target.value,
              })
            }
          >
            {() => (
              <TextField
                style={{
                  width: 250,
                  marginTop: mainTheme.spacing(2),
                }}
                label="CPF"
                variant="outlined"
                size="small"
                placeholder="000.000.000-00"
                InputLabelProps={{ shrink: true }}
                error={documentError}
              />
            )}
          </InputMask>
          {documentError && (
            <Typography
              style={{ marginTop: mainTheme.spacing(0.5) }}
              color="error"
              variant="body2"
            >
              CPF inválido
            </Typography>
          )}
        </div>
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-end',
        }}
      >
        <Button
          disabled={documentError || !formData.fullName}
          style={{ marginTop: mainTheme.spacing(4) }}
          startIcon={<ArrowForward />}
          color="primary"
          variant="contained"
          size="large"
          onClick={() => {
            localStorage.setItem('appliedBy', JSON.stringify(formData))
            localStorage.setItem(
              'availableAttendanceId',
              JSON.stringify(props.availableAttendanceId)
            )
            localStorage.setItem(
              'campaignData',
              JSON.stringify(props.campaignData)
            )
            redirect(
              `/register-vacineshot/table?campaignId=${props.campaignId}`
            )
          }}
        >
          Iniciar vacinação
        </Button>
      </div>
    </>
  )
}

export default SignInForm
