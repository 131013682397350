import { ReportTypeEnum } from '@vacinas-net/shared'
import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { ClinicListFiltersArray } from './Filters'

const ClinicListActions = () => (
  <CustomListActions
    filtersAvailable={ClinicListFiltersArray.map((item) => ({
      label: item.label,
      value: item.value,
    }))}
    hasExport
    exportType={ReportTypeEnum.Clinic}
    exportButtonText="Exportar Clínicas"
    hasCreate
  />
)

export default ClinicListActions
