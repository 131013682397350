import React, { useState } from 'react'
import {
  InputProps,
  SimpleForm,
  TextInput,
  useInput,
  SelectInput,
  SimpleFormProps,
  required,
  FormDataConsumer,
  Toolbar,
  SaveButton,
  ToolbarProps,
  useRecordContext,
} from 'react-admin'
import { IUserClinic, RolesEnum } from '@vacinas-net/shared'
import ClinicSearch, { IHandleChangeData } from '../../components/ClinicSearch'
import { translateSystemRole } from '../../utils/utils'
import useStyles from '../../utils/creationFormStyles'
import { useForm } from 'react-final-form'
import { Button, WithWidthProps } from '@material-ui/core'
import TemporaryPasswordPopup from '../../components/TemporaryPasswordPopup'

const CustomToolbar = (props: ToolbarProps & WithWidthProps) => (
  <Toolbar {...props}>
    <SaveButton label="Salvar" />
  </Toolbar>
)

const UserForm = (props: Omit<SimpleFormProps, 'children'>) => {
  const record = useRecordContext()
  const classes = useStyles()
  const systemRoles = Object.values(RolesEnum)

  const [openPassowordPopup, setOpenPassowordPopup] = useState(false)

  const handleClose = () => {
    setOpenPassowordPopup(false)
  }

  const ClinicSearchInput = (props: InputProps) => {
    const form = useForm()
    const formState = form.getState()
    const formValues = formState.values

    const initialClinicSearchValue =
      {
        selectAllClinics: false,
        selectedClinics:
          formValues['relatedClinics'] &&
          formValues['relatedClinics']?.map(
            (clinic: IUserClinic) => clinic._id
          ),
      } || []

    const { input } = useInput(props)

    const handleChange = (data: IHandleChangeData) => {
      input.onChange(data)
    }

    return (
      <ClinicSearch
        handleChange={handleChange}
        disableSelectAll
        initialValue={initialClinicSearchValue}
        readOnly={formValues.role === 'admin'}
        filterByActiveClinics
      />
    )
  }

  return (
    <SimpleForm toolbar={<CustomToolbar />} {...props}>
      <div
        style={{
          display: 'flex',
          justifyContent: 'flex-start',
          alignItems: 'center',
          width: '100%',
        }}
      >
        <TextInput
          source="name"
          label="Nome do Usuário"
          variant="outlined"
          className={classes.inputWMagin}
          validate={required()}
        />
        <TextInput
          source="email"
          label="E-mail"
          variant="outlined"
          className={classes.inputWMagin}
          validate={required()}
        />
        <SelectInput
          source="role"
          label="Perfil"
          variant="outlined"
          choices={systemRoles.map((role) => {
            return {
              id: role,
              name: translateSystemRole(role),
            }
          })}
          className={classes.inputWMagin}
          style={{ width: 200 }}
          validate={required()}
        />
        {props.redirect === 'list' && (
          <div style={{ marginBottom: 20, marginLeft: 'auto' }}>
            <Button
              onClick={() => {
                setOpenPassowordPopup(true)
              }}
              size="large"
              variant="contained"
              color="primary"
            >
              Gerar senha temporária
            </Button>
          </div>
        )}
      </div>
      <FormDataConsumer>
        {({ formData }) => {
          return formData.role !== 'admin' &&
            formData.role !== 'enterprise' &&
            formData.role !== 'customer' ? (
            <ClinicSearchInput source="clinicData" />
          ) : (
            <> </>
          )
        }}
      </FormDataConsumer>
      {record && (
        <TemporaryPasswordPopup
          userId={record._id}
          handleClose={handleClose}
          open={openPassowordPopup}
        />
      )}
    </SimpleForm>
  )
}

export default UserForm
