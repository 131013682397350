import { IVaccineShot } from '@vacinas-net/shared'

export const parseDose = (dose: IVaccineShot['dose']) => {
  switch (dose) {
    case 'first':
      return 'Primeira'
      break
    case 'second':
      return 'Segunda'
      break
    case 'third':
      return 'Terceira'
      break
    case 'anual':
      return 'Anual'
      break
    case 'booster':
      return 'Reforço'
      break
    default:
      '-'
      break
  }
}

export const parseOperationType = (
  operationType: 'gesture' | 'acquisition' | 'commission'
) => {
  if (operationType === 'commission' || operationType === 'acquisition') {
    return 'Próprio da clínica'
  }
  if (operationType === 'gesture') {
    return 'Flipagem'
  }
}
