import React from 'react'
import { Chip } from '@material-ui/core'
import { IUser } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'

const UserStatusChip = (props: { status: IUser['status'] }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'active':
      statusText = 'Ativo'
      statusColor = mainTheme.palette.primary.main
      break
    case 'inactive':
      statusText = 'Inativo'
      statusColor = mainTheme.palette.error.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default UserStatusChip
