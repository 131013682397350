import { Button, CircularProgress } from '@material-ui/core'
import React, { useState } from 'react'
import { Confirm, useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'
import {
  CustomerAvailableCampaignWithConditionDTO,
  IAvailableAttendance,
} from '@vacinas-net/shared'
import RemainingAvailableAttendancesWarningModal, {
  LinkOption,
} from './RemainingAvailableAttendancesWarningModal'
import { formatDate } from '../../../utils/utils'

const RemoveClinicFromCommercialConditionButton = (props: {
  conditionId: string
}) => {
  const { conditionId } = props
  const [loading, setLoading] = useState(false)
  const [openConfirmModal, setConfirmOpenModal] = useState(false)
  const [openRemainingModal, setOpenRemainingModal] = useState(false)
  const [availableAttendancesFormLinks, setAvailableAttendancesFormLinks] =
    useState<LinkOption[]>([])
  const notify = useNotify()
  const refresh = useRefresh()

  const handleConfirm = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/clinic-condition/${conditionId}`, {
      method: 'delete',
    })
      .then(() => {
        notify(`Clinica removida com sucesso`, 'success')
      })
      .catch((error) => {
        notify(`Erro ao remover clínica: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setConfirmOpenModal(false)
        refresh()
      })
  }

  const formatLinkLabel = (
    availableAttendance: IAvailableAttendance,
    campaign: CustomerAvailableCampaignWithConditionDTO
  ) => {
    const date = new Date(availableAttendance.startDatetime)
    const dateString = formatDate(date.toISOString())

    const label = [
      campaign.name,
      availableAttendance?.address?.name,
      dateString,
    ]
      .filter((item) => !!item) // removing undefined values
      .join(' - ')

    return label
  }

  const updateAvailableAttendanceFormLinkArray = (
    campaignsData: CustomerAvailableCampaignWithConditionDTO[]
  ) => {
    const availableAttendancesCopy: LinkOption[] = []
    campaignsData.forEach((campaign) => {
      const availableAttendances = campaign.availableAttendances || []
      const filteredAvailableAttendances = availableAttendances.filter(
        (attendance) => attendance.clinicCondition._id === conditionId
      )
      filteredAvailableAttendances.forEach((availableAttendance) => {
        availableAttendancesCopy.push({
          link: `/campaign/${campaign._id}/2?availableAttendanceId=${availableAttendance._id}`,
          label: formatLinkLabel(availableAttendance, campaign),
        })
      })
    })

    setAvailableAttendancesFormLinks(availableAttendancesCopy)

    return availableAttendancesCopy
  }

  const fetchConditionAvailableAttendances = async (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent>
  ) => {
    event.stopPropagation()
    setLoading(true)
    try {
      const result = await HttpClient(
        `${apiUrl}/campaign/clinic-condition/${conditionId}`,
        {
          method: 'get',
        }
      )
      const parsedCampaings =
        result.json as CustomerAvailableCampaignWithConditionDTO[]

      const links = updateAvailableAttendanceFormLinkArray(parsedCampaings)
      if (links.length > 0) {
        setOpenRemainingModal(true)
      } else {
        setConfirmOpenModal(true)
      }
    } catch (error) {
      notify(
        `Erro ao buscar atendimentos agendados na clínica: ${error}`,
        'error'
      )
    } finally {
      setLoading(false)
    }
  }

  return (
    <>
      <Button
        variant="contained"
        color="primary"
        onClick={async (e) => await fetchConditionAvailableAttendances(e)}
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          'Remover negociação'
        )}
      </Button>
      <Confirm
        isOpen={openConfirmModal}
        loading={loading}
        title="Você está removendo uma clínica"
        content="Deseja confirmar essa ação?"
        onConfirm={handleConfirm}
        onClose={() => setConfirmOpenModal(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
      <RemainingAvailableAttendancesWarningModal
        open={openRemainingModal}
        onClose={() => {
          setOpenRemainingModal(false)
          setAvailableAttendancesFormLinks([])
        }}
        availableAttendancesFormLinks={availableAttendancesFormLinks}
      />
    </>
  )
}

export default RemoveClinicFromCommercialConditionButton
