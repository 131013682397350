import { OpeningHours } from '@vacinas-net/shared'

export enum Weekdays {
  'Domingo',
  'Segunda-feira',
  'Terça-feira',
  'Quarta-feira',
  'Quinta-feira',
  'Sexta-feira',
  'Sábado',
}

export interface OpeningHourItem extends Partial<OpeningHours> {
  shiftIndex: number
  globalIndex: number
}
