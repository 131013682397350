import { Chip } from '@material-ui/core'
import React from 'react'

const AttendanceFormInputChip = (props: {
  label: string
  handleClick: () => void
}) => {
  const { label, handleClick } = props
  return <Chip color="primary" label={label} onClick={handleClick} />
}

export default AttendanceFormInputChip
