import { IconButton, Typography } from '@material-ui/core'
import { Add, Cancel } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { TextInputProps, useInput } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import { useFormState } from 'react-final-form'

/*
    This input returns an OptionType[] Object, maybe some data parsing will be needed to use it into a form
*/

const AutocompleteArrayInput = (
  props: TextInputProps & {
    resourceName: string
    resource: string
    optionTypeValue?: string
    optionTypeLabel?: string
  }
) => {
  const { resourceName, resource, optionTypeLabel, optionTypeValue, source } =
    props
  const { input } = useInput(props)
  const form = useFormState()

  const initialValue = form.values[source] ? form.values[source] : []

  const [items, setItems] = useState<(OptionType | null)[]>(initialValue)

  const addItem = () => {
    const newItem: OptionType | null = {
      label: '',
      value: '',
    }
    setItems([...items, newItem])
  }

  const removeProduct = (index: number) => {
    const arrayCopy = JSON.parse(JSON.stringify(items))
    arrayCopy.splice(index, 1)

    setItems(arrayCopy)
  }

  const updateReactAdminInput = () => {
    input.onChange(items)
  }

  useEffect(() => {
    setItems(input.value ? input.value : [])
  }, [])

  React.useEffect(() => updateReactAdminInput(), [items])

  useEffect(() => {
    if (
      initialValue &&
      JSON.stringify(initialValue) !== JSON.stringify(items)
    ) {
      setItems(initialValue)
    }
  }, [initialValue])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">{resourceName}</Typography>
        <IconButton
          color={'secondary'}
          onClick={() => addItem()}
          style={{ marginLeft: mainTheme.spacing(1) }}
        >
          <Add />
        </IconButton>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {items.map((item, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={index}
          >
            <CustomAutocomplete
              label={resourceName}
              resource={resource}
              currentValue={items[index]}
              onChange={(e) => {
                const arrayCopy = JSON.parse(JSON.stringify(items))
                arrayCopy[index] = e
                setItems(arrayCopy)
              }}
              style={{
                marginTop: mainTheme.spacing(1),
                marginBottom: mainTheme.spacing(1),
                width: 300,
              }}
              optionTypeValue={optionTypeValue || undefined}
              optionTypeLabel={optionTypeLabel || undefined}
            />
            <IconButton onClick={() => removeProduct(index)}>
              <Cancel />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  )
}

export default AutocompleteArrayInput
