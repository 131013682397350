import React, { useState } from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { Confirm, useNotify, usePermissions, useRefresh } from 'react-admin'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../CustomHttpClient'
import {
  ATTENDANCE_FINISHABLE_STATUS,
  IAttendance,
  isAdmin,
} from '@vacinas-net/shared'

interface FinishAttendanceButtonProps {
  attendance: IAttendance
  isMenuItem?: boolean
}

const finishButtonShouldBeActive = (
  attendance: IAttendance,
  permissions: string[] | undefined
) => {
  const attendanceStatus = attendance.status
  if (attendanceStatus === 'finished') return false
  if (isAdmin(permissions || [])) return true

  const productsWithoutVaccineShot = attendance.itemList?.filter(
    (product) => !product.vaccineShot
  )
  const existSomeProductWithoutVaccineShot =
    productsWithoutVaccineShot && productsWithoutVaccineShot.length > 0

  const attendanceIsInStatusFinishable =
    attendanceStatus === ATTENDANCE_FINISHABLE_STATUS

  return !existSomeProductWithoutVaccineShot && attendanceIsInStatusFinishable
}

const FinishAttendanceButton = (props: FinishAttendanceButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const [attendanceIdToFinish, setAttendanceIdToFinish] = useState('')

  const { permissions } = usePermissions()

  const notify = useNotify()
  const refresh = useRefresh()

  const finishAttendance = async (attendanceId: string) => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${attendanceId}/finish`
    httpClient(url, { method: 'post' })
      .then(() => {
        notify('Atendimento finalizado com sucesso')
        setOpenConfirm(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao finalizar o atendimento', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {props.isMenuItem ? (
        <MenuItem
          disabled={!finishButtonShouldBeActive(props.attendance, permissions)}
          onClick={() => {
            setOpenConfirm(true)
            setAttendanceIdToFinish(String(props.attendance._id))
          }}
          data-testid={`order-id-${props.attendance._id}`}
        >
          Finalizar
        </MenuItem>
      ) : (
        <Button
          disabled={!finishButtonShouldBeActive(props.attendance, permissions)}
          size="medium"
          color="secondary"
          variant="contained"
          onClick={() => {
            setOpenConfirm(true)
            setAttendanceIdToFinish(String(props.attendance._id))
          }}
          data-testid={`order-id-${props.attendance._id}`}
        >
          Finalizar
        </Button>
      )}

      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está finalizando o atendimento"
        content="Deseja confirmar essa ação?"
        onConfirm={() => finishAttendance(attendanceIdToFinish)}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default FinishAttendanceButton
export { finishButtonShouldBeActive }
