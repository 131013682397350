import * as React from 'react'
import OfferClinicItemsAcceptButton from '../../../components/Offer/OfferClinicItemsAcceptButton'
import CustomListActions from '../../../components/CustomListActions'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { mainTheme } from '../../../utils/mainTheme'
import {
  getClinicIdFromQueryString,
  getOfferIdFromQueryString,
} from '../../../utils/utils'
import OfferClinicItemsRejectButton from '../../../components/Offer/OfferClinicItemsCancelButton'
import InsertItemOnOfferButton from '../../../components/Offer/InsertItemOnOfferButton'

const OfferItemsClinicActions = (props: { showActionButtons?: boolean }) => {
  const { showActionButtons } = props

  return (
    <div>
      <div style={{ marginBottom: mainTheme.spacing(1) }}>
        <HeaderWithGoBack
          title="Intenção de compra"
          path={`/joint-purchase/aggregated-offer-items?jointPurchaseOfferId=${String(
            getOfferIdFromQueryString()
          )}`}
        />
      </div>
      <CustomListActions
        filtersAvailable={[
          { value: 'product->name', label: 'Produto' },
          { value: 'type', label: 'Comprado por' },
          { value: 'rangeName', label: 'Faixa' },
        ]}
      >
        {showActionButtons && (
          <>
            <div>
              <InsertItemOnOfferButton
                jointPurchaseOfferId={String(getOfferIdFromQueryString())}
                clinicInitialValue={String(getClinicIdFromQueryString())}
              />
            </div>
            <div>
              <OfferClinicItemsAcceptButton
                clinicId={String(getClinicIdFromQueryString())}
                offerId={String(getOfferIdFromQueryString())}
              />
            </div>
            <div style={{ marginLeft: mainTheme.spacing(2) }}>
              <OfferClinicItemsRejectButton
                clinicId={String(getClinicIdFromQueryString())}
                offerId={String(getOfferIdFromQueryString())}
              />
            </div>
          </>
        )}
      </CustomListActions>
    </div>
  )
}

export default OfferItemsClinicActions
