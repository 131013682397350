import React, { useContext } from 'react'
import {
  Badge,
  Typography,
  CircularProgress,
  Popover,
  makeStyles,
} from '@material-ui/core'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { mainTheme } from '../../utils/mainTheme'
import { parseAddress } from '../../utils/utils'
import { phoneFormatter } from '../../utils/formatters'

const useStyles = makeStyles({
  popover: {
    pointerEvents: 'none',
  },
  paper: {
    padding: mainTheme.spacing(2),
  },
})

const AvaliableClinics = () => {
  const classes = useStyles()

  const context = useContext(OrderCreateContext)
  const { createMany, clinicDisponibility, fetchingClinicDisponibility } =
    context!
  const [anchorEl, setAnchorEl] = React.useState<HTMLElement | null>(null)
  const [openedPopoverId, setOpenedPopoverId] =
    React.useState<string | null>(null)

  if (fetchingClinicDisponibility) {
    return (
      <div
        style={{
          padding: mainTheme.spacing(2),
        }}
      >
        <CircularProgress size={30} />
      </div>
    )
  }

  const handlePopoverOpen = (
    event: React.MouseEvent<HTMLElement>,
    clinicId?: string
  ) => {
    setAnchorEl(event.currentTarget)
    setOpenedPopoverId(clinicId || null)
  }

  const handlePopoverClose = () => {
    setAnchorEl(null)
    setOpenedPopoverId(null)
  }

  const open = Boolean(anchorEl)

  return !createMany && clinicDisponibility.length > 0 ? (
    <div
      style={{
        marginTop: mainTheme.spacing(2),
        marginBottom: mainTheme.spacing(2),
      }}
    >
      <Typography variant="h1">Clínicas disponíveis</Typography>
      <div style={{ margin: mainTheme.spacing(2) }}>
        {clinicDisponibility.map((clinic) => {
          return (
            <div
              style={{ marginBottom: mainTheme.spacing(1) }}
              key={clinic._id}
            >
              <div style={{ display: 'flex', alignContent: 'center' }}>
                <Typography color="textSecondary" variant="body1">
                  {clinic.name} ({Math.round(clinic.distance! / 1000)}Km)
                </Typography>
                {clinic.missingProducts && clinic.missingProducts?.length > 0 && (
                  <div>
                    <Badge
                      style={{ marginLeft: mainTheme.spacing(3) }}
                      color="error"
                      max={99}
                      badgeContent={'!'}
                      aria-owns={open ? `mouse-over-popover` : undefined}
                      aria-haspopup="true"
                      onMouseEnter={(e) => handlePopoverOpen(e, clinic._id)}
                      onMouseLeave={handlePopoverClose}
                    />
                    <Popover
                      id={`mouse-over-popover`}
                      className={classes.popover}
                      classes={{
                        paper: classes.paper,
                      }}
                      open={clinic._id === openedPopoverId}
                      anchorEl={anchorEl}
                      anchorOrigin={{
                        vertical: 'top',
                        horizontal: 'left',
                      }}
                      transformOrigin={{
                        vertical: 'bottom',
                        horizontal: 'left',
                      }}
                      onClose={handlePopoverClose}
                      disableRestoreFocus
                    >
                      <div>
                        <Typography variant="body1" color="error">
                          Essa clínica não possui os seguintes itens:{' '}
                        </Typography>
                        {clinic.missingProducts?.map((product) => {
                          return (
                            <Typography
                              key={product.skuId}
                              variant="body1"
                              color="error"
                            >
                              {product.name}
                            </Typography>
                          )
                        })}
                      </div>
                    </Popover>
                  </div>
                )}
              </div>

              <Typography
                style={{ display: 'inline-block' }}
                color="textSecondary"
                variant="body2"
              >
                {parseAddress(clinic.address)}
              </Typography>
              <Typography
                style={{ display: 'inline-block' }}
                color="primary"
                variant="body2"
              >
                {clinic.phone ? `, ${phoneFormatter.format(clinic.phone)}` : ''}
              </Typography>
            </div>
          )
        })}
      </div>
    </div>
  ) : (
    <></>
  )
}

export default AvaliableClinics
