import { IClinic, OpeningHours } from '@vacinas-net/shared'
import { isNumber } from 'lodash'
import { transformToUTCMinuteOfDay } from './OpeningHourRow/utils'

// eslint-disable-next-line @typescript-eslint/no-explicit-any
const parseForSource = (source: string, data?: any) => {
  const openingHours = (data[source] || []) as OpeningHours[]

  const formattedOpeningHours: OpeningHours[] = openingHours
    .filter((item?: Partial<OpeningHours>) => {
      const isWeekdayActive =
        data?.weekdayOpeningHours[source][`weekday-${item?.weekday}`]

      return (
        item &&
        isNumber(item.weekday) &&
        isNumber(item.startTimeInMinutes) &&
        isNumber(item.endTimeInMinutes) &&
        isWeekdayActive
      )
    })
    .map((item: OpeningHours) => ({
      weekday: item.weekday,
      startTimeInMinutes: transformToUTCMinuteOfDay(item.startTimeInMinutes)!,
      endTimeInMinutes: transformToUTCMinuteOfDay(item.endTimeInMinutes)!,
    }))

  return formattedOpeningHours
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const transformOpeningHours = (data?: any) => {
  const pickupOpeningHours: OpeningHours[] = data.pickupAttendanceEnabled
    ? parseForSource('pickupOpeningHours', data)
    : []
  const deliveryOpeningHours: OpeningHours[] = data.deliveryAttendanceEnabled
    ? parseForSource('deliveryOpeningHours', data)
    : []

  const openingHoursV2: IClinic['openingHoursV2'] = {
    delivery: deliveryOpeningHours,
    pickup: pickupOpeningHours,
  }

  return openingHoursV2
}
