import * as React from 'react'
import { FilterProps, Filter } from 'react-admin'
import { PayoutListFiltersArray } from './Filters'

const PayoutListFilter: React.FC<Omit<FilterProps, 'children'>> = (props) => {
  return (
    <Filter {...props}>
      {PayoutListFiltersArray.map((item) => item.FilterInput)}
    </Filter>
  )
}

export default PayoutListFilter
