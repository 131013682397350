import React, { useEffect } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import useStyles from '../../../utils/creationFormStyles'
import { useForm, useFormState } from 'react-final-form'
import {
  DeepPartial,
  IClinicFreightValueState,
  IFreightValueState,
} from '@vacinas-net/shared'
import { mainTheme } from '../../../utils/mainTheme'
import FreightValueCity from './FreightValueCity'
import { first } from 'lodash'

interface FreightValuesStateProps {
  disabled?: boolean
}

const FreightValuesState = (props: FreightValuesStateProps) => {
  const classes = useStyles()
  const form = useForm()
  const formState = useFormState()

  const values = formState?.values
  const freightValues: Array<DeepPartial<IClinicFreightValueState>> =
    values?.attendanceFreightValues?.delivery
  const firstFreightValue = first(freightValues)

  const uf = values?.address?.state?.value

  useEffect(() => {
    if (uf === firstFreightValue?.uf) return

    const adressState: IFreightValueState = values?.address?.state
    const state: DeepPartial<Omit<IClinicFreightValueState, '_id'>> = {
      cities: [{}],
      name: adressState?.name,
      uf,
      zipCodeRanges: adressState?.zipCodeRanges,
      fullState: false,
      priceInCents: 0,
    }
    form.change(`attendanceFreightValues.delivery.0`, state)
  }, [uf])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2" className={classes.header}>
          Localidade de atendimento
        </Typography>
        <IconButton
          color={'secondary'}
          onClick={() => {
            const currentCities = firstFreightValue?.cities || []
            const cicites = [...currentCities, {}]
            form.change(`attendanceFreightValues.delivery.0.cities`, cicites)
          }}
          style={{
            marginLeft: mainTheme.spacing(1),
            marginRight: mainTheme.spacing(1),
          }}
          disabled={props.disabled}
        >
          <Add />
        </IconButton>
      </div>

      {firstFreightValue?.cities?.map((item, idx) => {
        return (
          <FreightValueCity
            readOnly={props.disabled}
            idx={idx}
            key={idx}
            type="delivery"
            uf={uf}
          />
        )
      })}
    </div>
  )
}

export default FreightValuesState
