import React, { useEffect, useState } from 'react'
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  CircularProgress,
  TextField,
} from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { format, isValid } from 'date-fns'
import { useListContext } from 'react-admin'
import { dateToCorrectISOString, getFilters } from '../../../utils/utils'

export interface RequiredFiltersFieldsMap {
  [key: string]: { type: 'date'; label: string; 'data-testid'?: string }
}

export interface RequiredFiltersModalProps {
  open: boolean
  setOpen: (state: boolean) => void
  exportRequest: () => void
  loading: boolean

  fields: RequiredFiltersFieldsMap
}

const RequiredFiltersModal: React.FC<RequiredFiltersModalProps> = (
  props: RequiredFiltersModalProps
) => {
  const { open, setOpen, loading, exportRequest, fields } = props

  const { setFilters, filterValues, displayedFilters } = useListContext()
  const filters = getFilters(filterValues)
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const [filtersState, setFiltersState] = useState<any>({})

  useEffect(() => {
    const stateCopy = { ...filtersState }
    Object.entries(fields).forEach(([field, item]) => {
      if (item.type === 'date' && isValid(new Date(filters[field]))) {
        stateCopy[field] = format(new Date(filters[field]), 'yyyy-MM-dd')
      }
    })
    setFiltersState(stateCopy)
  }, [open === true])

  return (
    <Dialog
      open={open}
      onClose={() => {
        setOpen(false)
      }}
    >
      <DialogTitle>Escolha um período para o relatório</DialogTitle>
      <DialogContent>
        {Object.entries(fields).map(([field, item]) => {
          if (item.type === 'date') {
            return (
              <TextField
                key={field}
                type="date"
                variant="outlined"
                label={item.label}
                size="small"
                style={{
                  marginRight: mainTheme.spacing(1),
                }}
                InputLabelProps={{ shrink: true }}
                value={filtersState[field]}
                onChange={(ev) => {
                  const filtersStateCopy = { ...filtersState }
                  filtersStateCopy[field] = ev.target.value
                  setFiltersState(filtersStateCopy)
                  if (!isValid(new Date(filtersStateCopy[field]))) return
                  const newValue = dateToCorrectISOString(
                    filtersStateCopy[field]
                  )
                  setFilters(
                    { ...filterValues, [field]: newValue },
                    displayedFilters
                  )
                }}
                inputProps={{
                  'data-testid': item['data-testid'],
                  max: '2999-12-31T23:59',
                }}
              />
            )
          }
        })}
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => {
            setOpen(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            exportRequest()
            setOpen(false)
          }}
          disabled={Object.keys(fields).some((field) => !filters[field])}
        >
          {loading ? (
            <CircularProgress color="inherit" size={24} />
          ) : (
            'Exportar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default RequiredFiltersModal
