import React from 'react'
import { Chip } from '@material-ui/core'
import { IClinic } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'

const OrderStatusChip = (props: { status: IClinic['status'] }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'active':
      statusText = 'Ativada'
      statusColor = mainTheme.palette.secondary.main
      break
    case 'inactive':
      statusText = 'Desativada'
      statusColor = mainTheme.palette.error.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default OrderStatusChip
