import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { SelectInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const ClinicListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'name',
      label: 'Nome',
      FilterInput: (
        <SearchFilterInput
          label="Nome"
          source="name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'managerData->name',
      label: 'Gestor',
      FilterInput: (
        <SearchFilterInput
          label="Gestor"
          source="managerData->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinicGroup->name',
      label: 'Rede',
      FilterInput: (
        <SearchFilterInput
          label="Rede"
          source="clinicGroup->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'cnpj',
      label: 'CNPJ',
      FilterInput: (
        <SearchFilterInput
          label="CNPJ"
          source="cnpj"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'status',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="status"
          label="Status"
          choices={[
            { id: 'active', name: 'Ativada' },
            {
              id: 'inactive',
              name: 'Desativada',
            },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
