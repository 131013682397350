import { Typography } from '@material-ui/core'
import React from 'react'
import { ArrayField, Datagrid, SimpleShowLayout, TextField } from 'react-admin'
import showStyles from '../../../utils/showStyles'

const ProductsSection = () => {
  const classes = showStyles()
  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Produtos
      </Typography>
      <SimpleShowLayout className={classes.cardContent}>
        <ArrayField source="commercialCondition.products" label="">
          <Datagrid>
            <TextField
              label="Sku"
              source="skuId"
              className={classes.serviceProductsCell}
              textAlign="center"
            />
            <TextField
              label="Nome"
              source="name"
              className={classes.serviceProductsCell}
              textAlign="center"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </div>
  )
}

export default ProductsSection
