import { Button } from '@material-ui/core'
import { formatISO, setHours, setMinutes, setSeconds } from 'date-fns'
import * as React from 'react'
import { useListContext, usePermissions } from 'react-admin'
import CustomListActions from '../../../components/CustomListActions'
import { mainTheme } from '../../../utils/mainTheme'
import { ReportTypeEnum } from '@vacinas-net/shared'
import { generateAttendanceListFilters } from './Filters'

const AttendanceListActions = () => {
  const { setFilters, filterValues } = useListContext()
  const { permissions } = usePermissions()

  return (
    <CustomListActions
      filtersAvailable={generateAttendanceListFilters({ permissions }).map(
        (item) => ({
          label: item.label,
          value: item.value,
        })
      )}
      hasExport
      exportType={ReportTypeEnum.Attendance}
      exportButtonText="Exportar Atendimentos"
    >
      <Button
        style={{ marginLeft: mainTheme.spacing(2) }}
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => {
          let parseStartDate = new Date(Date.now())
          let parseEndDate = new Date(Date.now())
          parseStartDate = setHours(parseStartDate, 0)
          parseStartDate = setMinutes(parseStartDate, 0)
          parseStartDate = setSeconds(parseStartDate, 0)
          parseEndDate = setHours(parseEndDate, 23)
          parseEndDate = setMinutes(parseEndDate, 59)
          parseEndDate = setSeconds(parseEndDate, 59)
          setFilters(
            {
              ...filterValues,
              'deliveryWindow->startDate(min)': formatISO(
                new Date(parseStartDate)
              ),
              'deliveryWindow->endDate(max)': formatISO(new Date(parseEndDate)),
            },
            {}
          )
        }}
      >
        Hoje
      </Button>
      <Button
        style={{ marginLeft: mainTheme.spacing(2) }}
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => {
          setFilters(
            {
              ...filterValues,
              status: 'accepted',
            },
            {}
          )
        }}
      >
        Pronto para aplicação
      </Button>
    </CustomListActions>
  )
}

export default AttendanceListActions
