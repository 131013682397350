import React, { useState } from 'react'
import { Typography } from '@material-ui/core'
import customHttpClient from '../../../../components/CustomHttpClient'
import { apiUrl } from '../../../../components/AppDataProvider'
import { useNotify } from 'react-admin'
import { downloadFile } from '../../../../utils/downloadFile'

interface DownloadFileFieldProps {
  id?: string
}

const DownloadFileField = (props: DownloadFileFieldProps) => {
  const notify = useNotify()
  const [loading, setLoading] = useState(false)

  const onClick = async (id?: string) => {
    setLoading(true)
    try {
      const result = await customHttpClient(
        `${apiUrl}/campaign/allowed-customer-batch/${id}/file`,
        {
          method: 'GET',
        }
      )

      downloadFile(result.body, 'beneficiados.csv')
    } catch (error) {
      notify(`Erro ao baixar lista: ${error}`, {
        type: 'error',
      })
    } finally {
      setLoading(false)
    }
  }

  return (
    <Typography
      style={{
        textDecoration: 'underline',
        cursor: loading ? 'progress' : 'pointer',
      }}
      color="textPrimary"
      variant="body2"
      onClick={async (e) => {
        e.stopPropagation()
        await onClick(props.id)
      }}
    >
      Baixar lista
    </Typography>
  )
}

export default DownloadFileField
