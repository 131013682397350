import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import AddSingleAllowedCustomerButton from '../../campaign/CampaignEdit/AllowedCustomerBatchTab/AddSingleAllowedCustomerButton'
import { useRecordContext } from 'react-admin'
import { ICampaignV2 } from '@vacinas-net/shared'
import { generateAllowedCustomerFiltersArray } from './Filters'

const AllowedCustomerActions = () => {
  const record = useRecordContext() as ICampaignV2

  if (!record) return <></>
  return (
    <CustomListActions
      filtersAvailable={generateAllowedCustomerFiltersArray({ record }).map(
        (item) => ({
          label: item.label,
          value: item.value,
        })
      )}
      hasExport={false}
    >
      <AddSingleAllowedCustomerButton />
    </CustomListActions>
  )
}

export default AllowedCustomerActions
