import React, { useContext, useEffect, useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import CustomAutocomplete from '../CustomAutocomplete'
import { IAddress } from '@vacinas-net/shared'
import { apiUrl } from '../AppDataProvider'
import customHttpClient from '../CustomHttpClient'
import { useNotify } from 'react-admin'

const CampaignSession = () => {
  const context = useContext(OrderCreateContext)
  const {
    createMany,
    campaign,
    setCampaign,
    clinic,
    setClinic,
    pickupPointName,
    setPickupPointName,
    setPickupPointId,
    setEndDate,
    endDate,
    setStartDate,
    startDate,
  } = context!

  const [pickupPointData, setPickupPointData] = useState<
    {
      pickupPointName: string
      pickupPointId: string
      pickupPointAddress: IAddress
    }[]
  >([])

  const notify = useNotify()

  const fetchClinicRetreatPoints = () => {
    customHttpClient(`${apiUrl}/listClinicPickupPoints/${clinic?.value}`, {
      method: 'get',
    })
      .then((response) => {
        setPickupPointData(response.json)
      })
      .catch((error) => {
        notify(`Erro ao obter pontos de retirada: ${error}`, 'error')
      })
  }

  useEffect(() => {
    if (clinic) {
      fetchClinicRetreatPoints()
    }
  }, [clinic])

  return createMany ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: mainTheme.spacing(3),
      }}
    >
      <Typography align="center" variant="h1">
        Campanha
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: mainTheme.spacing(2),
        }}
      >
        <CustomAutocomplete
          style={{
            width: 300,
          }}
          currentValue={campaign}
          onChange={(ev) => {
            setCampaign(ev)
          }}
          resource="campaign"
          label="Campanha"
          dataTestId="campaign-autocomplete"
          required
        />
        <TextField
          type="datetime-local"
          style={{
            width: 300,
            marginLeft: mainTheme.spacing(4),
          }}
          label="Horário de Agendamento"
          variant="outlined"
          value={startDate}
          onChange={(event) => {
            setStartDate(event.target.value)
          }}
          inputProps={{
            'data-testid': `input-start-date`,
          }}
          size="small"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          type="time"
          style={{
            width: 300 - mainTheme.spacing(4),
            marginLeft: mainTheme.spacing(4),
          }}
          label="Tolerância máxima"
          variant="outlined"
          value={endDate}
          onChange={(event) => {
            setEndDate(event.target.value)
          }}
          inputProps={{
            'data-testid': `input-end-date`,
          }}
          size="small"
          InputLabelProps={{ shrink: true }}
          required
        />
      </div>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: mainTheme.spacing(2),
        }}
      >
        <CustomAutocomplete
          style={{
            width: 300,
          }}
          currentValue={clinic}
          onChange={(ev) => {
            setClinic(ev)
          }}
          resource="clinic"
          label="Clínica"
          dataTestId="clinic-autocomplete"
          filterByActiveClinics
          required
        />
        <FormControl
          variant="outlined"
          size="small"
          style={{ width: 600, marginLeft: mainTheme.spacing(4) }}
          required
        >
          <InputLabel>Ponto de retirada</InputLabel>
          <Select
            value={pickupPointName || null}
            label="Ponto de retirada"
            onChange={(event) => {
              setPickupPointName(String(event.target.value))
            }}
            SelectDisplayProps={{
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              //@ts-ignore
              'data-testid': `pickup-point-select`,
            }}
          >
            {pickupPointData.map((pickupPoint) => {
              return (
                <MenuItem
                  key={pickupPoint.pickupPointId}
                  value={pickupPoint.pickupPointId}
                  onClick={() => {
                    setPickupPointId(pickupPoint.pickupPointId)
                  }}
                >
                  {pickupPoint.pickupPointName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </div>
    </div>
  ) : (
    <></>
  )
}

export default CampaignSession
