import { ListProps } from '@material-ui/core'
import { IJointPurchaseOffer, isAdmin } from '@vacinas-net/shared'
import * as React from 'react'
import { useListContext, usePermissions } from 'react-admin'
import CancelOfferButton from '../../../components/Offer/CancelOfferButton'
import FinishOfferButton from '../../../components/Offer/FinishOfferButton'
import CustomListActions from '../../../components/CustomListActions'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import InsertItemOnOfferButton from '../../../components/Offer/InsertItemOnOfferButton'
import { mainTheme } from '../../../utils/mainTheme'
import { getOfferIdFromQueryString } from '../../../utils/utils'

const AggregatedOfferItemsActions = (props: ListProps) => {
  const { loading, total, data } = useListContext(props)
  const { permissions } = usePermissions()
  const showAdminContent = isAdmin(permissions || [])
  const offerStatus: IJointPurchaseOffer['status'] =
    Object.values(data)[0]?.jointPurchaseOffer?.status

  const mustShowCancelButton =
    showAdminContent && !loading && total > 0 && offerStatus === 'open'

  return (
    <div style={{ width: '100%' }}>
      <div style={{ marginBottom: mainTheme.spacing(1) }}>
        <HeaderWithGoBack title="Compra conjunta" path="/joint-purchase" />
      </div>
      <div style={{ display: 'flex', alignItems: 'space-between' }}>
        <CustomListActions
          filtersAvailable={[
            { value: 'clinic->name', label: 'Nome da Clínica' },
            { value: 'type', label: 'Comprado por' },
            { value: 'minPriceStatus', label: 'Status do pedido mínimo' },
            { value: 'status', label: 'Status do pedido' },
          ]}
        >
          {(mustShowCancelButton || total === 0) && (
            <InsertItemOnOfferButton
              jointPurchaseOfferId={String(getOfferIdFromQueryString())}
            />
          )}
        </CustomListActions>
        {mustShowCancelButton && (
          <>
            <div style={{ marginRight: mainTheme.spacing(1) }}>
              <FinishOfferButton
                jointPurchaseOfferId={String(getOfferIdFromQueryString())}
              />
            </div>
            <div>
              <CancelOfferButton
                jointPurchaseOfferId={String(getOfferIdFromQueryString())}
              />
            </div>
          </>
        )}
      </div>
    </div>
  )
}

export default AggregatedOfferItemsActions
