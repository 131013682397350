import React, { useContext, useState } from 'react'
import { Typography, IconButton } from '@material-ui/core'
import { Add } from '@material-ui/icons'
import { mainTheme } from '../../utils/mainTheme'
import PaymentsListHeader from './PaymentsListHeader'
import PaymentsListRow from './PaymentsListRow'
import AddPaymentForm from './AddPaymentForm'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import { valueInCentsToBRL } from '../../utils/utils'
import OrderSummary from './OrderSummary'

const PaymentsSession = () => {
  const context = useContext(OrderCreateContext)
  const { payments, getTotalPaymentValue, createMany } = context!

  const [open, setOpen] = useState(false)

  return !createMany ? (
    <div style={{ display: 'flex', marginTop: mainTheme.spacing(2) }}>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <Typography align="center" variant="h1">
            Pagamentos
          </Typography>
          <IconButton
            onClick={() => {
              setOpen(true)
            }}
            style={{ marginLeft: mainTheme.spacing(1) }}
            data-testid="add-payment-button"
          >
            <Add />
          </IconButton>
        </div>
        {payments.length > 0 && <PaymentsListHeader />}
        {payments.map((payment, index) => {
          return (
            <PaymentsListRow key={index} position={index} payment={payment} />
          )
        })}
        {payments.length > 0 && (
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              backgroundColor: mainTheme.palette.primary.main,
              maxWidth: 480,
              borderRadius: mainTheme.spacing(1),
              paddingTop: mainTheme.spacing(1),
              paddingBottom: mainTheme.spacing(1),
            }}
          >
            <div
              style={{
                width: 150,
              }}
            >
              <Typography
                style={{ color: '#ffffff' }}
                align="center"
                variant="body1"
              >
                Total:
              </Typography>
            </div>
            <div
              style={{
                paddingLeft: 200,
                width: 100,
              }}
            >
              <Typography
                style={{ color: '#ffffff' }}
                align="center"
                variant="body1"
              >
                {valueInCentsToBRL(getTotalPaymentValue)}
              </Typography>
            </div>
          </div>
        )}

        <AddPaymentForm open={open} onClose={() => setOpen(false)} />
      </div>
      {payments.length > 0 && (
        <div
          style={{
            marginTop: mainTheme.spacing(1 / 2),
            marginLeft: mainTheme.spacing(8),
          }}
        >
          <OrderSummary />
        </div>
      )}
    </div>
  ) : (
    <></>
  )
}

export default PaymentsSession
