import { Button, CircularProgress } from '@material-ui/core'
import { useNotify, useRefresh } from 'ra-core'
import React, { useState } from 'react'
import { Confirm } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'

const CancelOfferButton = (props: { jointPurchaseOfferId: string }) => {
  const { jointPurchaseOfferId } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const handleCancel = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/joint-purchase/${jointPurchaseOfferId}/cancel`, {
      method: 'post',
    })
      .then(() => {
        notify(`Oferta cancelada com sucesso`, 'success')
        refresh()
      })
      .catch((error) => {
        notify(`Erro ao cancelar oferta: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
      })
  }

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        size="medium"
        onClick={() => {
          setOpenConfirm(true)
        }}
      >
        {loading ? <CircularProgress color="inherit" size={20} /> : 'Cancelar'}
      </Button>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está cancelando a oferta"
        content="Deseja confirmar essa ação?"
        onConfirm={handleCancel}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </>
  )
}

export default CancelOfferButton
