import { MenuItem, Dialog, DialogTitle, DialogContent } from '@material-ui/core'
import React, { useState } from 'react'
import { mainTheme } from '../../../utils/mainTheme'
import LinkToClipboard from '../LinkToClipboard'

const GetRegisterPatientLinkPopup = (props: { attendanceUUID?: string }) => {
  const [open, setOpen] = useState(false)
  return (
    <div>
      <MenuItem
        color="secondary"
        onClick={() => {
          setOpen(true)
        }}
      >
        Resgatar Link
      </MenuItem>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        onClose={() => setOpen(false)}
      >
        <DialogTitle>Resgatar Link</DialogTitle>
        <DialogContent style={{ paddingBottom: mainTheme.spacing(2) }}>
          <LinkToClipboard uuid={props.attendanceUUID || '-'} />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default GetRegisterPatientLinkPopup
