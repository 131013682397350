import { InputProps } from 'ra-core'
import React from 'react'
import { useState } from 'react'
import { useInput } from 'react-admin'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import { useForm } from 'react-final-form'

type CustomInputProps = InputProps & {
  readOnly?: boolean
  label?: string
  resource: string
}

const ClinicGroupAutocompleteInput = (props: CustomInputProps) => {
  const { input } = useInput(props)
  const form = useForm()
  const formState = form.getState()
  const formValues = formState.values
  const { readOnly, resource, label } = props

  const initialValues = formValues.clinicGroup
    ? {
        label: formValues.clinicGroup.name,
        value: formValues.clinicGroup._id,
      }
    : null
  const [value, setValue] = useState<OptionType | null>(initialValues)

  const onChange = (option: OptionType | null) => {
    setValue(option)
    input.onChange(option?.value)
  }

  return (
    <CustomAutocomplete
      resource={resource}
      currentValue={value}
      onChange={onChange}
      size="small"
      readOnly={readOnly}
      label={label}
    />
  )
}

export default ClinicGroupAutocompleteInput
