import { Paper, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { useNotify } from 'react-admin'
import { XAxis, YAxis, CartesianGrid, Tooltip, AreaChart, Area } from 'recharts'
import { mainTheme } from '../../../utils/mainTheme'
import { apiUrl } from '../../AppDataProvider'
import customHttpClient from '../../CustomHttpClient'

interface ChartData {
  orderCounter: number
  orderDate: string
}

const LineChartCard = (props: {
  startDate?: string
  endDate?: string
  makingRequest: boolean
  stopRequest: () => void
  clinicId?: string
}) => {
  const { startDate, endDate, makingRequest, stopRequest, clinicId } = props
  const [chartData, setChartData] = useState<ChartData[]>()

  const notify = useNotify()

  useEffect(() => {
    if (!makingRequest) return

    customHttpClient(
      `${apiUrl}/financial/order-counter-chart?createdAt(min)=${
        startDate && `${startDate}`
      }&createdAt(max)=${endDate && `${endDate}`}${
        clinicId ? `&relatedClinics._id=${clinicId}` : ''
      }`,
      {
        method: 'get',
      }
    )
      .then((res) => {
        const data = res.json.orderCounterChart as ChartData[]

        setChartData(
          data.map((chart) => {
            return {
              ...chart,
              orderDate: format(new Date(chart.orderDate), 'dd/MM', {
                locale: ptBR,
              }),
            }
          })
        )
      })
      .catch((error) => {
        notify(`Erro ao obter informações do : ${error}`, 'error')
      })
      .finally(() => {
        stopRequest()
      })
  }, [makingRequest])
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
      }}
    >
      <Typography variant="h2">Número de pedidos</Typography>
      <AreaChart
        width={500}
        height={400}
        data={chartData}
        margin={{
          top: 30,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="orderDate" />
        <YAxis />
        <Tooltip />
        <Area
          type="monotone"
          dataKey="orderCounter"
          stroke="#8884d8"
          fill="#8884d8"
        />
      </AreaChart>
    </Paper>
  )
}

export default LineChartCard
