import React from 'react'
import { Chip } from '@material-ui/core'
import { IVoucherPackage } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'

const VoucherPackageStatusChip = (props: {
  status: IVoucherPackage['status']
}) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'approved':
      statusText = 'Disponibilizado'
      statusColor = mainTheme.palette.secondary.main
      break
    case 'pending':
      statusText = 'Solicitado'
      statusColor = mainTheme.palette.warning.main
      break
    case 'rejected':
      statusText = 'Rejeitado'
      statusColor = mainTheme.palette.error.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default VoucherPackageStatusChip
