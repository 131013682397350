import React, { useState } from 'react'
import { Button, MenuItem } from '@material-ui/core'
import { Confirm, useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { IAttendance, isAdmin } from '@vacinas-net/shared'

interface AcceptAttendanceButtonProps {
  attendance: IAttendance
  permissions: string[]
  isMenuItem?: boolean
}

const undoAcceptButtonShouldBeActive = (
  attendance: IAttendance,
  permissions: string[] | undefined
) => {
  const attendanceStatus = attendance.status
  if (attendanceStatus === 'accepted' && isAdmin(permissions)) return true
  return false
}

const UndoAcceptedAttendanceButton = (props: AcceptAttendanceButtonProps) => {
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)

  const { permissions } = props

  const notify = useNotify()
  const refresh = useRefresh()

  const undoAcceptAttendance = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendance._id}/undo-acceptance`
    httpClient(url, { method: 'post' })
      .then(() => {
        notify('Sucesso ao desfazer aceite do atendimento')
        setOpenConfirm(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao desfazer o aceite do atendimento: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      {props.isMenuItem ? (
        <MenuItem
          disabled={
            !undoAcceptButtonShouldBeActive(props.attendance, permissions)
          }
          onClick={() => {
            setOpenConfirm(true)
          }}
          data-testid={`button-order-id-${props.attendance._id}`}
        >
          Desfazer aceite
        </MenuItem>
      ) : (
        <Button
          disabled={
            !undoAcceptButtonShouldBeActive(props.attendance, permissions)
          }
          size="medium"
          color="secondary"
          variant="contained"
          onClick={() => {
            setOpenConfirm(true)
          }}
          data-testid={`button-order-id-${props.attendance._id}`}
        >
          Desfazer aceite
        </Button>
      )}

      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está interferindo no fluxo padrão"
        content="Essa ação pode gerar inconsistência no pedido e suas relações, está seguro que deseja continuar com essa operação?"
        onConfirm={() => undoAcceptAttendance()}
        onClose={() => setOpenConfirm(false)}
        confirm="Sim, continuar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default UndoAcceptedAttendanceButton
