import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'
import StateAndCitySearchInput from '../../../components/Inputs/StateAndCitySearchInput'

export const UnavailableProductsFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'name',
      label: 'Nome do cliente',
      FilterInput: (
        <SearchFilterInput
          label="Nome do cliente"
          source="name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'email',
      label: 'E-mail do cliente',
      FilterInput: (
        <SearchFilterInput
          label="E-mail do cliente"
          source="email"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'sku',
      label: 'SKU',
      FilterInput: (
        <SearchFilterInput
          label="SKU"
          source="sku"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'skuName',
      label: 'Nome do Produto',
      FilterInput: (
        <SearchFilterInput
          label="Nome do Produto"
          source="skuName"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: '_multiple',
      label: 'Região',
      FilterInput: (
        <StateAndCitySearchInput
          source="_multiple"
          stateKey="state"
          cityKey="city"
        />
      ),
    },
  ],
  'label'
)
