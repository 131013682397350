import { Typography, Link } from '@material-ui/core'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  SimpleShowLayout,
  TextField,
  Record,
  usePermissions,
} from 'react-admin'
import showStyles from '../../../../../utils/showStyles'
import {
  parsePayoutDescription,
  parsePayoutType,
  valueInCentsToBRL,
} from '../../../../../utils/utils'
import { IPayout, isAdmin } from '@vacinas-net/shared'

const AttendanceItemsSection = () => {
  const classes = showStyles()
  const { permissions } = usePermissions()

  return (
    <div className={classes.cardContainer}>
      <Typography className={classes.cardTitle} variant="h2">
        Repasses
      </Typography>
      <SimpleShowLayout className={classes.cardContentGrid}>
        <ArrayField source="payouts" label="">
          <Datagrid>
            {isAdmin(permissions) && (
              <TextField
                label="Pedido"
                source="vendorOrderId"
                className={classes.serviceProductsCell}
                textAlign="center"
                headerClassName={classes.tableHeaderStyles}
                cellClassName={classes.tableCellStyles}
              />
            )}
            <FunctionField
              label="N° do atendimento"
              className={classes.serviceProductsCell}
              textAlign="center"
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              render={(record?: Record) => {
                const payout = record as IPayout
                return (
                  <Link
                    target="blank"
                    href={`/#/attendance/${payout.attendanceId}/show`}
                  >
                    {payout.attendance?.aliasId}
                  </Link>
                )
              }}
            />

            <DateField
              label="Data de repasse"
              source="payoutDate"
              className={classes.serviceProductsCell}
              textAlign="center"
              locales="pt-BR"
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                record?.isCreatedManually
                  ? record.description
                  : parsePayoutDescription(record)
              }
              label="Descrição"
              sortable={false}
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                parsePayoutType(record?.type)
              }
              label="Tipo"
              sortable={false}
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            <FunctionField
              textAlign="center"
              render={(record: Record | undefined) =>
                valueInCentsToBRL(record?.payoutValueInCents)
              }
              label="Valor"
              source="payoutValueInCents"
              sortable={false}
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
            {isAdmin(permissions) && (
              <FunctionField
                textAlign="center"
                render={(record: Record | undefined) =>
                  valueInCentsToBRL(record?.holdValueInCents)
                }
                label="Valor Retido"
                source="holdValueInCents"
                sortable={false}
                className={classes.serviceProductsCell}
                headerClassName={classes.tableHeaderStyles}
                cellClassName={classes.tableCellStyles}
              />
            )}
            <TextField
              textAlign="center"
              source="orderItem.product.name"
              label="Produto"
              emptyText="-"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </div>
  )
}

export default AttendanceItemsSection
