import React, { useEffect } from 'react'
import WeekdayGroup from './WeekdayGroup'
import { useInput } from 'ra-core'
import { InputProps } from 'react-admin'
import { OpeningHours } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import { useForm } from 'react-final-form'

interface OpeningHoursScheduleInputProps extends InputProps {
  attendanceTypeEnabledSource: string
}

const OpeningHoursScheduleInput = (props: OpeningHoursScheduleInputProps) => {
  const { input } = useInput(props)
  const form = useForm()
  /**
   * fill weekdays without any opening hour with an empty array
   */
  useEffect(() => {
    const currentValue: Partial<OpeningHours>[] = input.value
      ? JSON.parse(JSON.stringify(input.value))
      : []

    for (let i = 0; i < 7; ++i) {
      if (currentValue.find((item) => item.weekday === i)) continue
      currentValue.push({
        weekday: i as OpeningHours['weekday'],
      })
    }

    if (currentValue.length !== input.value?.length)
      input.onChange(currentValue)
  }, [])

  const deleteShift = (globalIndex: number) => {
    form.change(`${props.source}.${globalIndex}`, undefined)
  }

  const addShift = (weekday: OpeningHours['weekday']) => {
    input.onChange([
      ...input.value,
      {
        weekday,
      },
    ])
    const globalIndex = input.value?.length || 0
    form.change(`${props.source}.${globalIndex}`, {
      weekday,
      startTimeInMinutes: null,
      endTimeInMinutes: null,
    })
  }

  const groups = [0, 1, 2, 3, 4, 5, 6] as Array<OpeningHours['weekday']>

  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        gap: mainTheme.spacing(2),
      }}
    >
      {groups.map((weekday) => {
        return (
          <WeekdayGroup
            key={weekday}
            addShift={(weekday) => addShift(weekday as OpeningHours['weekday'])}
            deleteShift={(index) => deleteShift(index)}
            weekday={weekday}
            inputBaseSource={props.source}
            attendanceTypeEnabledSource={props.attendanceTypeEnabledSource}
          />
        )
      })}
    </div>
  )
}

export default OpeningHoursScheduleInput
