import React from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import CustomAutocomplete from '../CustomAutocomplete'
import { mainTheme } from '../../utils/mainTheme'
import { AvailableAttendanceFormData } from './utils'
import { FormikErrors } from 'formik'

const VaccineDataSession = (props: {
  formData?: AvailableAttendanceFormData
  setFormData: (field: string, value: unknown) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any
  setErrors: (errors: FormikErrors<AvailableAttendanceFormData>) => void
}) => {
  const { formData, setFormData, errors, setErrors } = props

  return (
    <Grid
      style={{ marginTop: mainTheme.spacing(2) }}
      container
      spacing={2}
      xs={12}
    >
      <Grid item xs={12}>
        <Typography color="primary" variant="body1">
          Vacina aplicada
        </Typography>
      </Grid>
      <Grid item xs={3}>
        <CustomAutocomplete
          resource="product-sku"
          currentValue={formData?.vaccine || null}
          onChange={(event) => {
            setFormData('vaccine', event)
            setErrors({
              ...errors,
              vaccine: {
                value: '',
              },
            })
          }}
          label="Vacina*"
          optionTypeLabel="name"
          optionTypeValue="skuId"
          size="small"
        />
        {errors.vaccine?.value && (
          <Typography variant="body2" color="error">
            {errors.vaccine?.value}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <FormControl variant="outlined" size="small" fullWidth>
          <InputLabel
            style={{
              zIndex: 1,
              backgroundColor: '#ffffff',
              paddingRight: mainTheme.spacing(1 / 2),
            }}
            shrink
          >
            Dose
          </InputLabel>
          <Select
            value={formData?.dose || null}
            label="Dose"
            onChange={(event) => {
              setFormData('dose', String(event.target.value))
              setErrors({
                ...errors,
                dose: '',
              })
            }}
          >
            <MenuItem value={'first'}>Primeira</MenuItem>
            <MenuItem value={'second'}>Segunda</MenuItem>
            <MenuItem value={'third'}>Terceira</MenuItem>
            <MenuItem value={'booster'}>Reforço</MenuItem>
            <MenuItem value={'anual'}>Anual</MenuItem>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={3}>
        <TextField
          label="Lote*"
          variant="outlined"
          value={formData?.batchNumber}
          onChange={(event) => {
            setFormData('batchNumber', event.target.value)
            setErrors({
              ...errors,
              batchNumber: '',
            })
          }}
          size="small"
          fullWidth
          error={Boolean(errors.batchNumber)}
        />
        {errors.batchNumber && (
          <Typography variant="body2" color="error">
            {errors.batchNumber}
          </Typography>
        )}
      </Grid>
      <Grid item xs={3}>
        <TextField
          variant="outlined"
          label="Data de validade"
          value={formData?.expirationDate}
          onChange={(event) => {
            setFormData('expirationDate', event.target.value)
            setErrors({
              ...errors,
              expirationDate: '',
            })
          }}
          inputProps={{
            'data-testid': `input-vaccine-expirationdate`,
          }}
          type="date"
          size="small"
          fullWidth
          InputLabelProps={{ shrink: true }}
        />
      </Grid>
    </Grid>
  )
}

export default VaccineDataSession
