import { createMuiTheme } from '@material-ui/core/styles'

export const mainTheme = createMuiTheme({
  shape: {
    borderRadius: 8,
  },
  typography: {
    fontFamily: ['Ubuntu', 'sans-serif'].join(','),
    h1: {
      fontSize: 22,
      fontWeight: 'bold',
    },
    h2: {
      fontSize: 18,
      fontWeight: 'bold',
    },
    h3: {
      fontSize: 16,
      fontWeight: 'bold',
    },
    body1: {
      fontSize: 16,
    },
    body2: {
      fontSize: 14,
    },
    subtitle1: {
      fontSize: 12,
    },
    button: {
      textTransform: 'none',
    },
  },
  palette: {
    grey: {
      '100': '#F7F7F7',
      '200': '#ECECEC',
      '300': '#F2F2F2',
      '400': '#888888',
    },
    primary: {
      main:
        process.env.REACT_APP_NODE_ENV === 'development'
          ? '#888888 '
          : '#5B2674', //purple
      contrastText: '#fff',
    },
    secondary: {
      main: '#0CB4B7', //lightblue
      dark: '#1A9496', //darkblue
      contrastText: '#fff',
    },
    error: {
      main: '#ED1C24',
      dark: '#BD0016',
      light: '#FF5C5C',
    },
    success: {
      main: '#196AE2',
      dark: '#105494',
      light: '#4BA0EF',
    },
    warning: {
      main: '#F17E2B',
    },
    text: {
      primary: '#333',
    },
  },
  overrides: {
    RaFilterForm: {
      form: {
        marginTop: 0,
        minHeight: 0,
        width: '100%',
        backgroundColor: '#ECECEC',
        marginBottom: 16,
      },
    },
    RaListToolbar: {
      toolbar: {
        display: 'flex',
        flexDirection: 'column-reverse',
      },
    },
    RaBulkActionsToolbar: {
      toolbar: {
        height: 72,
      },
    },
    RaDatagrid: {
      headerCell: {
        backgroundColor: '#F7F7F7',
        border: '1px solid #ECECEC',
      },
    },
    MuiTableCell: {
      body: {
        border: '1px solid #ECECEC',
      },
    },
    RaFilterFormInput: {
      body: {
        alignItems: 'center',
      },
    },
  },
})
