import {
  MenuItem,
  Dialog,
  DialogTitle,
  DialogContent,
  Typography,
  DialogActions,
  Button,
} from '@material-ui/core'
import React, { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { apiUrl } from '../../AppDataProvider'
import customHttpClient from '../../CustomHttpClient'
import LinkToClipboard from '../LinkToClipboard'

const ResetRegisterPatientLinkPopup = (props: { attendanceId?: string }) => {
  const [open, setOpen] = useState(false)
  const [newUUID, setNewUUID] = useState('')
  const [loading, setLoading] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const resetLink = async () => {
    setLoading(true)
    customHttpClient(
      `${apiUrl}/attendance/customerUrl/${props.attendanceId}/resetUrl`,
      {
        method: 'post',
      }
    )
      .then((res) => {
        setNewUUID(res.json.uuid)
        notify(`Novo link gerado com sucesso!`)
      })
      .catch((error) => {
        notify(`Erro ao gerar o novo link: ${error}`, 'error')
      })
      .finally(() => setLoading(false))
  }
  return (
    <div>
      <MenuItem
        color="secondary"
        onClick={() => {
          setOpen(true)
        }}
        data-testid={'reset-link-button'}
      >
        Redefinir Link
      </MenuItem>
      <Dialog
        fullWidth
        maxWidth={'xs'}
        open={open}
        onClose={() => {
          setOpen(false)
          if (newUUID) {
            refresh()
          }
        }}
      >
        <DialogTitle>Redefinir Link</DialogTitle>
        <DialogContent style={{ paddingBottom: mainTheme.spacing(2) }}>
          {newUUID ? (
            <LinkToClipboard uuid={newUUID || '-'} />
          ) : (
            <Typography variant="body1">Deseja redefinir o link ?</Typography>
          )}
        </DialogContent>
        {!newUUID && (
          <DialogActions>
            <Button onClick={() => setOpen(false)} variant="outlined">
              Cancelar
            </Button>
            <Button
              onClick={() => {
                resetLink()
              }}
              style={{ marginLeft: mainTheme.spacing(2) }}
              variant="contained"
              color="primary"
              type="submit"
              disabled={loading}
              data-testid={'submit-button'}
            >
              Confirmar
            </Button>
          </DialogActions>
        )}
      </Dialog>
    </div>
  )
}

export default ResetRegisterPatientLinkPopup
