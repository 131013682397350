import {
  Button,
  CircularProgress,
  makeStyles,
  TextField,
  Typography,
} from '@material-ui/core'
import { IJointPurchaseItemEstimatedPrice } from '@vacinas-net/shared'
import React, { useEffect, useState } from 'react'
import { mainTheme } from '../../../utils/mainTheme'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import EstimateButton from '../EstimateButton'
import HttpClient from '../../CustomHttpClient'
import { apiUrl } from '../../AppDataProvider'
import { useNotify, useRefresh } from 'ra-core'
import { valueInCentsToBRL } from '../../../utils/utils'

const useStyles = makeStyles({
  input: {
    minWidth: 250,
    maxWidth: 250,
    margin: mainTheme.spacing(1),
  },
})

const InsertItemsOnOfferForm = (props: {
  jointPurchaseOfferId: string
  clinicInitialValue?: string
  handleClose: () => void
}) => {
  const classes = useStyles()
  const { jointPurchaseOfferId, handleClose, clinicInitialValue } = props
  const notify = useNotify()
  const refresh = useRefresh()

  const [quantity, setQuantity] = useState('')
  const [product, setProduct] = useState<OptionType | null>(null)
  const [clinic, setClinic] = useState<OptionType | null>(null)
  const [estimativeResponse, setEstimativeResponse] =
    useState<IJointPurchaseItemEstimatedPrice | undefined>()
  const [resquestError, setRequestError] = useState()
  const [loading, setLoading] = useState(false)

  const handleEstimative = (
    response: IJointPurchaseItemEstimatedPrice | undefined
  ) => setEstimativeResponse(response)

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  const handleRequestError = (error: any) => setRequestError(error)

  const addItemRequest = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/joint-purchase/item`, {
      method: 'post',
      body: JSON.stringify({
        jointPurchaseOfferId: jointPurchaseOfferId,
        clinicId: clinic?.value,
        skuId: product?.value,
        amount: quantity,
      }),
    })
      .then(() => {
        notify('Item adicionado com sucesso!', 'success')
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao adicionar o novo item: ${error}`)
      })
      .finally(() => {
        setLoading(false)
        handleClose()
        refresh()
      })
  }

  useEffect(() => {
    if (Boolean(clinicInitialValue)) {
      HttpClient(`${apiUrl}/clinic/${clinicInitialValue}`, {
        method: 'get',
      })
        .then((res) => {
          setClinic({
            label: res.json.name,
            value: res.json._id,
          })
        })
        .catch((error) => {
          notify(`Erro ao obter dados da clínica: ${error}`, 'error')
        })
    }
  }, [])

  useEffect(() => {
    setEstimativeResponse(undefined)
  }, [quantity, clinic, product])

  return (
    <form>
      <div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomAutocomplete
            label="Produto"
            resource="product-sku"
            currentValue={product}
            onChange={(event) => {
              setProduct(event)
            }}
            optionTypeValue="skuId"
            optionTypeLabel="name"
            className={classes.input}
            dataTestId="product-autocomplete"
          />
          <TextField
            label="Quantidade"
            variant="outlined"
            value={quantity}
            onChange={(event) => {
              setQuantity(event.target.value)
            }}
            inputProps={{
              'data-testid': `input-quantity`,
            }}
            size="small"
            className={classes.input}
            style={{ marginLeft: mainTheme.spacing(3) }}
            type="number"
          />
        </div>
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <CustomAutocomplete
            label="Clinica"
            resource="clinic"
            currentValue={clinic}
            onChange={(event) => {
              setClinic(event)
            }}
            className={classes.input}
            style={{ marginTop: 0 }}
            dataTestId="clinic-autocomplete"
          />
        </div>
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            marginBottom: mainTheme.spacing(1),
            marginLeft: mainTheme.spacing(2),
          }}
        >
          <EstimateButton
            purchaseId={jointPurchaseOfferId}
            clinicId={String(clinic?.value)}
            skuId={String(product?.value)}
            quantity={parseInt(quantity)}
            disabled={!quantity || !product || !clinic}
            handleResponse={handleEstimative}
            handleRequestError={handleRequestError}
          />
        </div>
        {estimativeResponse && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: mainTheme.spacing(2),
              marginTop: mainTheme.spacing(2),
            }}
          >
            <Typography>{`Preço Unitário: ${valueInCentsToBRL(
              estimativeResponse?.estimatedUnitPriceInCents
            )} - Preço Total: ${
              valueInCentsToBRL(
                estimativeResponse?.estimatedUnitPriceInCents *
                  parseInt(quantity)
              ) || ' -'
            }`}</Typography>
            <Typography>{`Faixa atual do produto: ${estimativeResponse.rangeName}`}</Typography>
          </div>
        )}
        {resquestError && (
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              marginLeft: mainTheme.spacing(2),
              marginTop: mainTheme.spacing(2),
            }}
          >
            <Typography color="error">{`Erro ao adicionar o novo item: ${resquestError}`}</Typography>
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            marginBottom: mainTheme.spacing(1),
          }}
        >
          <Button
            onClick={() => handleClose()}
            variant="contained"
            color="primary"
            style={{
              marginRight: mainTheme.spacing(1),
            }}
          >
            Cancelar
          </Button>
          <Button
            onClick={() => addItemRequest()}
            variant="contained"
            color="primary"
            disabled={
              !quantity ||
              !product ||
              !clinic ||
              !estimativeResponse ||
              resquestError
            }
          >
            {!loading ? (
              'Adicionar'
            ) : (
              <CircularProgress color="inherit" size={25} />
            )}
          </Button>
        </div>
      </div>
    </form>
  )
}

export default InsertItemsOnOfferForm
