import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FunctionField,
  Record,
  Identifier,
} from 'react-admin'
import { DefaultPagination } from '../../../components/Pagination'
import { valueInCentsToBRL } from '../../../utils/utils'
import { format } from 'date-fns'
import ManageAttendanceButtons from '../../../components/Buttons/ManageAttendanceButtons'

const AttendanceNotAccteptedList = ({ ...props }: ListProps) => {
  const postRowClick = (id: Identifier) => `/attendance/${id}/show`

  return (
    <List
      pagination={<DefaultPagination />}
      actions={<></>}
      bulkActionButtons={false}
      sort={{ field: 'attendanceDate.startDate', order: 'ASC' }}
      {...props}
    >
      <Datagrid stickyHeader rowClick={postRowClick}>
        <TextField
          textAlign="center"
          source="attendanceAliasId"
          label="Atendimento nº"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            record?.attendanceDate && record?.attendanceDate.startDate
              ? format(new Date(record?.attendanceDate.startDate), 'dd/MM/yy')
              : '-'
          }
          textAlign="center"
          source="attendanceDate.startDate"
          label="Data Atendimento"
        />
        <TextField
          textAlign="center"
          source="itemsCount"
          label="Quantidade de itens"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            record?.attendanceValueInCents
              ? valueInCentsToBRL(record.attendanceValueInCents)
              : '-'
          }
          textAlign="center"
          label="Valor do atendimento"
        />
        <TextField
          textAlign="center"
          source="installmentsCount"
          label="Parcelas"
        />
        <FunctionField
          textAlign="center"
          label="Ações"
          source="status"
          render={(record: Record | undefined) => (
            <ManageAttendanceButtons
              attendance={{
                _id: record?._id,
                status: 'awaiting_clinic_confirmation',
              }}
            />
          )}
        />
      </Datagrid>
    </List>
  )
}

export default AttendanceNotAccteptedList
