import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { formatISO, setHours, setMinutes, setSeconds } from 'date-fns'
import { useListContext } from 'react-admin'
import { CampaignListFiltersArray } from './Filters'
import { ReportTypeEnum } from '@vacinas-net/shared'

const CampaignListActions = () => {
  const { setFilters, filterValues } = useListContext()

  React.useEffect(() => {
    let parsedEndDate = new Date(Date.now())
    parsedEndDate = setHours(parsedEndDate, 0)
    parsedEndDate = setMinutes(parsedEndDate, 0)
    parsedEndDate = setSeconds(parsedEndDate, 0)
    setFilters(
      {
        ...filterValues,
        'dateRange->endDate(min)': formatISO(new Date(parsedEndDate)),
      },
      {}
    )
  }, [])

  return (
    <CustomListActions
      filtersAvailable={CampaignListFiltersArray.map((item) => ({
        label: item.label,
        value: item.value,
      }))}
      hasCreate
      hasExport
      exportType={ReportTypeEnum.Campaign}
      exportButtonText="Exportar Campanhas"
    ></CustomListActions>
  )
}

export default CampaignListActions
