import { Button, PropTypes } from '@material-ui/core'
import { CloudDownload } from '@material-ui/icons'
import React, { useState } from 'react'
import { useListContext, useNotify } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { apiUrl } from '../../AppDataProvider'
import customHttpClient from '../../CustomHttpClient'
import { stringify } from 'query-string'
import { getFilters } from '../../../utils/utils'
import { ReportTypeEnum } from '@vacinas-net/shared'
import RequiredFiltersModal, {
  RequiredFiltersFieldsMap,
} from './RequiredFiltersModal'

const ExportButton = (props: {
  color?: PropTypes.Color | undefined
  variant?: 'text' | 'outlined' | 'contained' | undefined
  exportType: ReportTypeEnum
  buttonText: string

  requiredFields?: RequiredFiltersFieldsMap
}) => {
  const listContext = useListContext()

  const [openRequiredFilterModal, setOpenRequiredFilterModal] = useState(false)

  const [loading, setLoading] = useState(false)
  const notify = useNotify()

  const exportRequest = () => {
    setLoading(true)
    const filters = getFilters(listContext.filterValues)

    customHttpClient(`${apiUrl}/report?${stringify(filters)}`, {
      method: 'post',
      body: JSON.stringify({
        type: props.exportType,
      }),
    })
      .then(() => {
        notify(
          'Exportando...\n\nUm e-mail será enviado para você quando a exportação estiver completa',
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          {
            multiLine: true,
          }
        )
      })
      .catch((error) => {
        notify(`Erro ao realizar exportação: ${error}`)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <Button
        style={{ marginRight: mainTheme.spacing(2) }}
        data-testid={`export-button`}
        color={props.color ? props.color : 'primary'}
        size="medium"
        variant={props.variant ? props.variant : 'contained'}
        disabled={loading}
        startIcon={<CloudDownload />}
        onClick={(e) => {
          e.stopPropagation()
          !!props.requiredFields
            ? setOpenRequiredFilterModal(true)
            : exportRequest()
        }}
      >
        {loading ? 'Processando Relatório' : props.buttonText}
      </Button>
      {!!props.requiredFields && (
        <RequiredFiltersModal
          exportRequest={exportRequest}
          loading={loading}
          open={openRequiredFilterModal}
          setOpen={(state: boolean) => {
            setOpenRequiredFilterModal(state)
          }}
          fields={props.requiredFields}
        />
      )}
    </div>
  )
}

export default ExportButton
