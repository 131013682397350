import * as React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const ClinicConditionHandlerChip = (props: { handler: 'jenner' | 'vtex' }) => {
  const { handler } = props
  return (
    <Chip
      style={{
        color: 'white',
        backgroundColor:
          handler === 'vtex'
            ? mainTheme.palette.secondary.main
            : mainTheme.palette.primary.main,
      }}
      label={handler === 'vtex' ? 'VTEX' : 'Jenner'}
    />
  )
}

export default ClinicConditionHandlerChip
