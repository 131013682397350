import { InputProps } from 'ra-core'
import React from 'react'
import { useInput } from 'react-admin'
import CustomAutocomplete from '../../CustomAutocomplete'

type CustomInputProps = InputProps & {
  readOnly?: boolean
  inputLabel?: string
  resource: string
  optionTypeLabel?: string
  optionTypeValue?: string
  additionalFilters?: string
  disabled?: boolean
  passFullOptionData?: boolean
}

const CustomAutocompleteInput = (props: CustomInputProps) => {
  const { input } = useInput(props)
  const { readOnly, resource, inputLabel, additionalFilters } = props

  return (
    <CustomAutocomplete
      style={props.style}
      resource={resource}
      currentValue={input.value}
      onChange={(option) => input.onChange(option)}
      size="small"
      readOnly={readOnly}
      label={inputLabel}
      optionTypeLabel={props.optionTypeLabel}
      optionTypeValue={props.optionTypeValue}
      additionalFilters={additionalFilters}
      disabled={props.disabled}
      passFullOptionData={props.passFullOptionData}
    />
  )
}

export default CustomAutocompleteInput
