import * as Yup from 'yup'

export const defaultValidationSchema = () =>
  Yup.object().shape({
    totalQuantity: Yup.number().optional(),
    value: Yup.number().when('commercialCondition', {
      is: (condition: 'gesture' | 'comission' | 'aquisition') =>
        condition !== undefined,
      then: Yup.number().required('Campo obrigatório'),
    }),
  })

export const adminValidationSchema = () =>
  Yup.object()
    .shape({
      operationType: Yup.string().oneOf(['own', 'flipped']).optional(),

      gestureInCents: Yup.number().when('operationType', {
        is: 'flipped',
        then: Yup.number().required('Campo obrigatório'),
      }),
    })
    .concat(defaultValidationSchema())

export const adminOperationClinicValidationSchema = () =>
  Yup.object()
    .shape(
      {
        commissionInPercentage: Yup.number().test(
          'commissionInPercentage',
          'Campo obrigatório',
          function (value) {
            const productAcquisitionValue = this.resolve<number>(
              Yup.ref('productAquisitionInCents')
            )

            if (!isNaN(productAcquisitionValue)) return true

            return !!value && !isNaN(value)
          }
        ),
        productAquisitionInCents: Yup.number().test(
          'productAquisitionInCents',
          'Campo obrigatório',
          function (value) {
            const commissionValue = this.resolve<number>(
              Yup.ref('commissionInPercentage')
            )

            if (!isNaN(commissionValue)) return true

            return !!value && !isNaN(value)
          }
        ),
      },
      [['commissionInPercentage', 'productAquisitionInCents']]
    )
    .concat(adminValidationSchema())
