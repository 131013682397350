import React, { useEffect, useState } from 'react'
import {
  Typography,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
} from '@material-ui/core'
import { IAttendance, IVaccineShot } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import { useNotify } from 'react-admin'
import { formatCPF } from '@brazilian-utils/brazilian-utils'
import { phoneFormatter } from '../../utils/formatters'
import {
  CalendarTodayOutlined,
  EmailOutlined,
  PersonOutlineOutlined,
  PhoneOutlined,
} from '@material-ui/icons'

import BadgeOutlinedIcon from '@mui/icons-material/BadgeOutlined' //this import doesn't exist on @material-ui/icons
import _ from 'lodash'
import { format } from 'date-fns'
import { apiUrl } from '../AppDataProvider'
import { Checkbox, FormControlLabel } from '@mui/material'

const AttendanceDataModal = (props: {
  open: boolean
  setOpen: (open: boolean) => void
  attendance?: IAttendance & { vaccineShots?: IVaccineShot[] }
  fetchPatientsTableData: () => Promise<void>
}) => {
  const notify = useNotify()
  const campaignOrderUrl = String(process.env.REACT_APP_CAMPAIGN_ORDER_URL)

  const [loading, setLoading] = useState(false)

  const { open, setOpen, attendance, fetchPatientsTableData } = props
  const vaccineShot = _.first(attendance?.vaccineShots)

  const [
    confirmDifferentAvailableAttendance,
    setConfirmDifferentAvailableAttendance,
  ] = useState(false)

  const availableAttendanceId = localStorage
    .getItem('availableAttendanceId')
    ?.split('"')
    .join('')

  const isSameAvailableAttendance =
    attendance?.order?.availableAttendanceId === availableAttendanceId

  const startDate = attendance?.deliveryWindow?.startDate
  const startDateLabel = startDate
    ? format(new Date(startDate), 'dd/MM/yyyy')
    : ''

  const placeName =
    attendance?.pickupPointName || attendance?.address?.name || ''

  const confirmVaccineShot = async () => {
    setLoading(true)
    try {
      const appliedByRaw = localStorage.getItem('appliedBy')
      const appliedBy = appliedByRaw ? JSON.parse(appliedByRaw) : undefined
      await fetch(`${apiUrl}/vaccine-shot/confirm`, {
        method: 'post',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({
          attendanceId: attendance?._id,
          availableAttendanceId: availableAttendanceId,
          appliedBy: {
            document: appliedBy?.document.replace(/[^\d]/g, ''),
            fullName: appliedBy?.fullName,
          },
        }),
      })
      notify(`Atendimento confirmado com sucesso`, 'success')
      setOpen(false)
    } catch (error) {
      console.error(error)
      notify(`Erro ao confirmar atendimento: ${error}`, 'error')
    } finally {
      setLoading(false)
      setOpen(false)
    }
  }

  useEffect(() => {
    setConfirmDifferentAvailableAttendance(false)
  }, [attendance?._id])

  if (!attendance)
    return (
      <Dialog
        open={open}
        onClose={() => setOpen(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent>
          <Typography
            data-testid={`attendance-not-found-content`}
            variant="body1"
          >
            Não encontramos <strong>nenhum agendamento com este CPF.</strong>
            <br />
            <br />
            Solicite ao cliente que realize o agendamento utilizando este CPF no
            link:
            <br />
            <a
              href={campaignOrderUrl}
              target="_blank"
              rel="noreferrer"
              style={{
                color: mainTheme.palette.primary.main,
                cursor: 'pointer',
              }}
            >
              {campaignOrderUrl}
            </a>
          </Typography>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="outlined"
            onClick={() => setOpen(false)}
            size="large"
          >
            Voltar
          </Button>
        </DialogActions>
      </Dialog>
    )
  return (
    <Dialog open={open} onClose={() => setOpen(false)} maxWidth="sm" fullWidth>
      <DialogContent>
        {!isSameAvailableAttendance && (
          <div>
            <Typography
              variant="h1"
              color="primary"
              style={{
                textAlign: 'center',
                marginBottom: mainTheme.spacing(1),
              }}
            >
              Agendamento em outra data!
            </Typography>
            <Typography variant="body1">
              Este paciente está agendado para o dia <b>{startDateLabel}</b> no
              local <b>{placeName}</b>
            </Typography>
            <FormControlLabel
              control={
                <Checkbox
                  checked={confirmDifferentAvailableAttendance}
                  onChange={() =>
                    setConfirmDifferentAvailableAttendance(
                      !confirmDifferentAvailableAttendance
                    )
                  }
                  color="primary"
                  data-testid={
                    'checkbox-select-confirm-different-available-attendance'
                  }
                />
              }
              label="Estou ciente e quero continuar"
            />
          </div>
        )}
        {isSameAvailableAttendance && (
          <Typography data-testid={`attendance-found-content`} variant="body1">
            Verifique os dados antes de confirmar a aplicação da dose
          </Typography>
        )}
        <br />
        <Typography color="primary" variant="h2">
          Dados pessoais:
        </Typography>
        <div style={{ display: 'flex', paddingTop: mainTheme.spacing(1) }}>
          <PersonOutlineOutlined
            style={{ marginRight: mainTheme.spacing(1) }}
            color="primary"
          />
          <Typography color="textSecondary" variant="body1">
            {vaccineShot?.patient?.name}
          </Typography>
        </div>
        <div style={{ display: 'flex', paddingTop: mainTheme.spacing(0.5) }}>
          <CalendarTodayOutlined
            style={{ marginRight: mainTheme.spacing(1) }}
            color="primary"
          />
          <Typography color="textSecondary" variant="body1">
            {vaccineShot?.patient?.birthDate &&
              format(new Date(vaccineShot?.patient?.birthDate), 'dd/MM/yyyy')}
          </Typography>
        </div>
        <div style={{ display: 'flex', paddingTop: mainTheme.spacing(0.5) }}>
          <EmailOutlined
            color="primary"
            style={{ marginRight: mainTheme.spacing(1) }}
          />
          <Typography color="textSecondary" variant="body1">
            {vaccineShot?.patient?.email}
          </Typography>
        </div>
        <div style={{ display: 'flex', paddingTop: mainTheme.spacing(0.5) }}>
          <BadgeOutlinedIcon
            color="primary"
            style={{
              marginRight: mainTheme.spacing(1),
              color: mainTheme.palette.primary.main,
            }}
          />
          <Typography color="textSecondary" variant="body1">
            {vaccineShot?.patient?.document &&
              formatCPF(vaccineShot?.patient?.document)}{' '}
          </Typography>
        </div>
        <div style={{ display: 'flex', paddingTop: mainTheme.spacing(0.5) }}>
          <PhoneOutlined
            color="primary"
            style={{ marginRight: mainTheme.spacing(1) }}
          />
          <Typography color="textSecondary" variant="body1">
            {phoneFormatter.format(vaccineShot?.patient?.phoneNumber)}
          </Typography>
        </div>
      </DialogContent>
      <DialogActions>
        <Button
          color="primary"
          variant="outlined"
          onClick={() => setOpen(false)}
          size="large"
        >
          Cancelar
        </Button>
        <Button
          disabled={
            loading ||
            (!isSameAvailableAttendance && !confirmDifferentAvailableAttendance)
          }
          color="primary"
          variant="contained"
          onClick={async () => {
            await confirmVaccineShot()
            await fetchPatientsTableData()
          }}
          size="large"
        >
          Confirmar aplicação
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default AttendanceDataModal
