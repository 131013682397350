import React from 'react'
import { ReportTypeEnum } from '@vacinas-net/shared'
import CustomListActions from '../../../components/CustomListActions'

const CampaignOrdersListActions = () => {
  return (
    <CustomListActions
      filtersAvailable={[
        { value: 'customer->name', label: 'Nome do cliente' },
        { value: 'customer->document', label: 'Cpf do cliente' },
        { value: 'relatedClinics->name', label: 'Nome da Clínica' },
        { value: 'orderId', label: 'Número do pedido' },
        { value: 'marketingData->couponCode', label: 'Código do cupom' },
        { value: 'status', label: 'Situação' },
        { value: 'shippingAddress->type', label: 'Tipo de atendimento' },
        { value: 'packages->pickupPointName', label: 'Ponto de retirada' },
        { value: 'payments->method', label: 'Meio de pagamento' },
        { value: 'purchasedAt(min)', label: 'Comprado depois de' },
        { value: 'purchasedAt(max)', label: 'Comprado antes de' },
        { value: 'finishedAt(min)', label: 'Finalizado depois de' },
        { value: 'finishedAt(max)', label: 'Finalizado antes de' },
      ]}
      hasExport
      exportType={ReportTypeEnum.Order}
      exportButtonText="Exportar"
    />
  )
}

export default CampaignOrdersListActions
