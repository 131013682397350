import { Typography } from '@material-ui/core'
import { ContentCopy } from '@mui/icons-material'
import { IconButton } from '@mui/material'
import React from 'react'
import { mainTheme } from '../../utils/mainTheme'

const TextToClipboard = (props: { text: string }) => {
  return (
    <div
      style={{
        border: `2px solid ${mainTheme.palette.primary.main}`,
        borderRadius: '8px',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between',
        maxWidth: 300,
        marginLeft: 'auto',
        marginRight: 'auto',
      }}
      onClick={() => {
        navigator.clipboard.writeText(props.text)
      }}
    >
      <Typography
        style={{
          margin: `${mainTheme.spacing(1)}px`,
          overflow: `auto`,
        }}
        variant="body1"
      >
        {props.text}
      </Typography>
      <IconButton style={{ color: mainTheme.palette.primary.main }}>
        <ContentCopy />
      </IconButton>
    </div>
  )
}

export default TextToClipboard
