import * as React from 'react'
import { TextInput, required } from 'react-admin'
import { cepFormatter } from '../../utils/formatters'
import { mainTheme } from '../../utils/mainTheme'
import useStyles from '../../utils/creationFormStyles'
import CustomAutocompleteInput from '../../components/Inputs/CustomAutocompleteInput'
import { useFormState, useForm } from 'react-final-form'
import qs from 'querystring'
import { Typography } from '@material-ui/core'

const AddressSession = (props: { readOnly?: boolean }) => {
  const classes = useStyles()
  const { values } = useFormState()
  const form = useForm()
  const [skipFirstState, setSkipFirstState] = React.useState(true)
  const [skipFirstCity, setSkipFirstCity] = React.useState(true)

  React.useEffect(() => {
    if (skipFirstState) return setSkipFirstState(false)

    form.change('address.city', '')
    form.change('address.neighborhood', '')
  }, [values?.address?.state?.value])

  React.useEffect(() => {
    if (skipFirstCity) return setSkipFirstCity(false)

    form.change('address.neighborhood', '')
  }, [values?.address?.city?.value])

  const { readOnly } = props
  return (
    <>
      <Typography variant="h2" className={classes.header}>
        Endereço
      </Typography>
      <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
        <CustomAutocompleteInput
          source="address.state"
          readOnly={readOnly}
          resource="state"
          inputLabel="Estado"
          style={{
            width: 200,
            marginRight: mainTheme.spacing(2),
            marginTop: mainTheme.spacing(1),
          }}
          optionTypeLabel="uf"
          optionTypeValue="uf"
          passFullOptionData={true}
        />

        <CustomAutocompleteInput
          source="address.city"
          readOnly={readOnly || !values?.address?.state}
          disabled={!values?.address?.state}
          resource="city"
          inputLabel="Cidade"
          style={{
            width: 200,
            marginRight: mainTheme.spacing(2),
            marginTop: mainTheme.spacing(1),
          }}
          optionTypeLabel="name"
          optionTypeValue="name"
          additionalFilters={qs.stringify({
            uf: values?.address?.state?.value,
          })}
        />

        <CustomAutocompleteInput
          source="address.neighborhood"
          readOnly={readOnly || !values?.address?.city}
          disabled={!values?.address?.city}
          resource="neighborhood"
          inputLabel="Bairro"
          optionTypeLabel="name"
          optionTypeValue="name"
          style={{
            width: 200,
            marginRight: mainTheme.spacing(2),
            marginTop: mainTheme.spacing(1),
          }}
          additionalFilters={qs.stringify({
            uf: values?.address?.state?.value,
            'city.name': values?.address?.city?.value,
          })}
        />

        <TextInput
          source="address.zipCode"
          label="CEP"
          variant="outlined"
          validate={required()}
          format={cepFormatter.format}
          parse={cepFormatter.parse}
          InputProps={{
            readOnly,
          }}
          className={classes.inputWMagin}
        />
      </div>
      <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
        <TextInput
          source="address.streetName"
          label="Rua"
          variant="outlined"
          validate={required()}
          InputProps={{
            readOnly,
          }}
          className={classes.inputWMagin}
        />
        <TextInput
          source="address.streetNumber"
          label="Número"
          variant="outlined"
          validate={required()}
          InputProps={{
            readOnly,
          }}
          className={classes.inputWMagin}
        />
        <TextInput
          source="address.complement"
          label="Complemento"
          variant="outlined"
          InputProps={{
            readOnly,
          }}
          className={classes.inputWMagin}
        />
      </div>
    </>
  )
}

export default AddressSession
