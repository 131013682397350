/* eslint-disable @typescript-eslint/no-explicit-any */
import { fetchUtils } from 'ra-core'
import { firebaseProvider } from '../config/firebase/firebaseConfig'

// fetchUtils.fetchJson receives this parameter
const customHttpClient = async (url: any, options: fetchUtils.Options = {}) => {
  const user = await firebaseProvider.getAuthUser()
  if (user) {
    const tokenResult = await user.getIdTokenResult()
    if (tokenResult && tokenResult.token) {
      const requestHeaders = (options.headers ||
        new Headers({
          Accept: 'application/json',
          'Accept-Language': 'pt',
        })) as Headers

      requestHeaders.set('Authorization', `Bearer ${tokenResult.token}`)
      options.headers = requestHeaders
    }
  }
  return fetchUtils.fetchJson(url, options)
}

export default customHttpClient
