import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  SimpleShowLayout,
  TextField,
  Record,
  FunctionField,
  useDataProvider,
  useRefresh,
  useNotify,
  DateField,
} from 'react-admin'
import { IAttendanceItem, IOrderItem, IVaccineShot } from '@vacinas-net/shared'
import showStyles from '../../../../utils/showStyles'
import { useState } from 'react'
import VaccineShotForm from '../../../../components/VaccineShotForm'
import { mainTheme } from '../../../../utils/mainTheme'
import HttpClient from '../../../../components/CustomHttpClient'
import { apiUrl } from '../../../../components/AppDataProvider'

const resolveAttendanceRecord = (record: Record | undefined) => {
  if (record) {
    const matchedArray = record.itemList.map((item: IAttendanceItem) => {
      const currentOrderItem = record.order?.items?.find(
        (orderItem: IOrderItem) => orderItem._id === item.orderItemId
      )
      return {
        ...currentOrderItem,
        id: `${currentOrderItem?._id}`,
        item: item,
      }
    })
    record.parsedItems = matchedArray
  }
}

const AttendanceItemsSection = (props: {
  record: Record | undefined
  status: string
}) => {
  const [isEdit, setIsEdit] = useState(false)
  const [formOpen, setFormOpen] = useState(false)
  const [formRecord, setFormRecord] = useState<Record | undefined>()
  const [modalLoading, setModalLoading] = useState(false)
  const classes = showStyles()
  const dataProvider = useDataProvider()
  const refresh = useRefresh()
  const notify = useNotify()

  resolveAttendanceRecord(props.record)

  const onClose = () => {
    setFormOpen(false)
    setIsEdit(false)
  }

  const onSubmitCreate = (data: IVaccineShot) => {
    setModalLoading(true)
    dataProvider
      .create('vaccine-shot', { data: { ...data, id: undefined } })
      .then(() => {
        setFormOpen(false)
        notify('Aplicação registrada com sucesso!')
        refresh()
      })
      .catch((err) => {
        console.error(err)
        notify('Falha ao registrar aplicação')
      })
      .finally(() => setModalLoading(false))
  }

  const onSubmitEdit = (data: IVaccineShot) => {
    setModalLoading(true)
    HttpClient(`${apiUrl}/vaccine-shot/${data?._id}`, {
      method: 'PATCH',
      body: JSON.stringify({ ...data, _id: undefined }),
    })
      .then(() => {
        setFormOpen(false)
        notify('Atualização de aplicação registrada com sucesso!')
        refresh()
      })
      .catch((err) => {
        console.error(err)
        notify('Falha ao atualizar aplicação')
      })
      .finally(() => setModalLoading(false))
  }

  return (
    <div>
      <SimpleShowLayout
        record={props.record}
        className={classes.cardContentGrid}
      >
        <ArrayField source="parsedItems" label="">
          <Datagrid>
            <FunctionField
              label="Vacina"
              render={(record: Record | undefined) => {
                const productAppliedName =
                  record?.item?.vaccineShot?.vaccineMeta?.productSkuApplied
                    ?.name
                const productBoughtName = record?.product?.name

                return productAppliedName || productBoughtName
              }}
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
            />
            <TextField
              label="Lote"
              source="item.vaccineShot.vaccineMeta.batchNumber"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
            />
            <DateField
              label="Validade da vacina"
              source="item.vaccineShot.vaccineMeta.expirationDate"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              locales="pt-BR"
              emptyText="-"
            />
            <TextField
              label="Paciente"
              source="item.vaccineShot.patient.name"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
            />
            <TextField
              label="CPF"
              source="item.vaccineShot.patient.document"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
            />
            <DateField
              label="Data de Nascimento"
              source="item.vaccineShot.patient.birthDate"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
              locales="pt-BR"
            />

            <DateField
              label="Data de aplicação"
              source="item.vaccineShot.shotDate"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              locales="pt-BR"
              emptyText="-"
            />

            <FunctionField
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              render={(record: Record | undefined) => {
                if (record?.item.vaccineShot) {
                  return (
                    <Button
                      data-testid={`button-create-vaccineshot-${record?.item.orderItemId}`}
                      onClick={() => {
                        setFormRecord(record)
                        setFormOpen(true)
                        setIsEdit(true)
                      }}
                      size="medium"
                      color="secondary"
                      variant="contained"
                    >
                      Atualizar aplicação
                    </Button>
                  )
                } else if (props.status === 'rejected') {
                  return 'Atendimento rejeitado'
                } else if (props.status === 'cancelled') {
                  return 'Atendimento cancelado'
                } else if (props.status === 'accepted') {
                  return (
                    <Button
                      data-testid={`button-create-vaccineshot-${record?.item.orderItemId}`}
                      onClick={() => {
                        setIsEdit(false)
                        setFormRecord(record)
                        setFormOpen(true)
                      }}
                      size="medium"
                      color="secondary"
                      variant="contained"
                    >
                      Registrar aplicação
                    </Button>
                  )
                } else if (props.status === 'awaiting_clinic_confirmation') {
                  return 'Necessário aceitar o atendimento'
                } else {
                  return '-'
                }
              }}
              label="Ação"
              className={classes.hideOnPrint}
              textAlign="center"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
      <Dialog maxWidth="lg" open={formOpen} onClose={() => setFormOpen(false)}>
        <DialogTitle style={{ marginLeft: mainTheme.spacing(1) }}>
          {`Registrar aplicação de ${formRecord?.product.name}`}
        </DialogTitle>
        <DialogContent data-testid={'dialog-content-vaccine-shot'}>
          <VaccineShotForm
            onSubmit={isEdit ? onSubmitEdit : onSubmitCreate}
            onClose={onClose}
            initialValues={formRecord}
            loading={modalLoading}
            isEdit={isEdit}
            record={props.record}
          ></VaccineShotForm>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default AttendanceItemsSection
