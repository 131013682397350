import { ReportTypeEnum } from '@vacinas-net/shared'
import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { UserListFiltersArray } from './Filters'

const UserListActions = () => (
  <CustomListActions
    filtersAvailable={UserListFiltersArray.map((item) => ({
      label: item.label,
      value: item.value,
    }))}
    hasCreate
    hasExport
    exportType={ReportTypeEnum.User}
    exportButtonText="Exportar Usuários"
  />
)

export default UserListActions
