import React from 'react'
import {
  Grid,
  Typography,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  TextField,
} from '@material-ui/core'
import { IClinicCondition, ShippingPolicy } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import { AvailableAttendanceFormData } from './utils'
import { FormikErrors } from 'formik'

const EventDataSession = (props: {
  formData?: AvailableAttendanceFormData
  setFormData: (field: string, value: unknown) => void
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  errors: any
  setErrors: (errors: FormikErrors<AvailableAttendanceFormData>) => void
}) => {
  const { formData, setFormData, errors, setErrors } = props

  const parseAvailableClinicCondition = (condition: IClinicCondition) => {
    if (condition.type === 'commission') {
      return `Comissão - ${condition.value}% - ${
        condition.shippingPolicy === ShippingPolicy.Clinic
          ? 'Clínica'
          : 'Empresa'
      }
      `
    }
    if (condition.type === 'gesture') {
      return `Gesto - R$${condition.value / 100} - ${
        condition.shippingPolicy === ShippingPolicy.Clinic
          ? 'Clínica'
          : 'Empresa'
      }
      `
    }
    return `Aquisição - R$${condition.value / 100} - ${
      condition.shippingPolicy === ShippingPolicy.Clinic ? 'Clínica' : 'Empresa'
    }`
  }

  return (
    <Grid container>
      <Grid item xs={12}>
        <Typography color="primary" variant="body1">
          Dados do evento
        </Typography>
      </Grid>
      <Grid
        container
        spacing={2}
        style={{ marginTop: mainTheme.spacing(2) }}
        xs={12}
      >
        <Grid item xs={6}>
          <FormControl
            style={{ width: '100%' }}
            variant="outlined"
            size="small"
            error={Boolean(errors.selectedClinicId)}
          >
            <InputLabel
              style={{
                zIndex: 1,
                backgroundColor: '#ffffff',
                paddingRight: mainTheme.spacing(1 / 2),
              }}
              shrink
            >
              Clínica responsável*
            </InputLabel>
            <Select
              value={formData?.selectedClinicId || null}
              label="Clínica responsável*"
              onChange={(e) => {
                setFormData('selectedClinicId', String(e.target.value))
                setErrors({
                  ...errors,
                  selectedClinicId: '',
                })
              }}
            >
              {formData?.availableClinics?.map((clinic) => {
                return (
                  <MenuItem key={clinic._id} value={clinic._id}>
                    {clinic.name}
                  </MenuItem>
                )
              })}
            </Select>
          </FormControl>
          {errors.selectedClinicId && (
            <Typography variant="body2" color="error">
              {errors.selectedClinicId}
            </Typography>
          )}
        </Grid>
        <Grid item xs={6}>
          <FormControl
            style={{ width: '100%' }}
            variant="outlined"
            size="small"
            error={Boolean(errors.clinicConditionId)}
          >
            <InputLabel
              style={{
                zIndex: 1,
                backgroundColor: '#ffffff',
                paddingRight: mainTheme.spacing(1 / 2),
              }}
              shrink
            >
              Negociação*
            </InputLabel>
            <Select
              value={formData?.clinicConditionId || null}
              onChange={(e) => {
                setFormData('clinicConditionId', String(e.target.value))
                setErrors({
                  ...errors,
                  clinicConditionId: '',
                })
              }}
              label="Negociação*"
              disabled={!formData?.selectedClinicId}
            >
              {formData?.availableClinicConditions
                ?.filter(
                  (clinicCondition) => clinicCondition.status === 'accepted'
                )
                .map((condition) => {
                  return (
                    <MenuItem key={condition._id} value={condition._id}>
                      {parseAvailableClinicCondition(condition)}
                    </MenuItem>
                  )
                })}
            </Select>
          </FormControl>
          {errors.clinicConditionId && (
            <Typography variant="body2" color="error">
              {errors.clinicConditionId}
            </Typography>
          )}
        </Grid>
      </Grid>
      <Grid
        style={{ marginTop: mainTheme.spacing(2) }}
        container
        spacing={2}
        xs={12}
      >
        <Grid item xs={3}>
          <TextField
            type="date"
            fullWidth
            label="Data de vacinação*"
            variant="outlined"
            value={formData?.shotDate}
            onChange={(event) => {
              setFormData('shotDate', event.target.value)
              setErrors({
                ...errors,
                shotDate: '',
              })
            }}
            size="small"
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.shotDate)}
          />
          {errors.shotDate && (
            <Typography variant="body2" color="error">
              {errors.shotDate}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="time"
            fullWidth
            label="Horário de início*"
            variant="outlined"
            value={formData?.startTime}
            onChange={(event) => {
              setFormData('startTime', event.target.value)
              setErrors({
                ...errors,
                startTime: '',
              })
            }}
            size="small"
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.startTime)}
          />
          {errors.startTime && (
            <Typography variant="body2" color="error">
              {errors.startTime}
            </Typography>
          )}
        </Grid>
        <Grid item xs={2}>
          <TextField
            type="time"
            fullWidth
            label="Horário de fim*"
            variant="outlined"
            value={formData?.endTime}
            onChange={(event) => {
              setFormData('endTime', event.target.value)
              setErrors({
                ...errors,
                endTime: '',
              })
            }}
            size="small"
            InputLabelProps={{ shrink: true }}
            error={Boolean(errors.endTime)}
          />
          {errors.endTime && (
            <Typography variant="body2" color="error">
              {errors.endTime}
            </Typography>
          )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default EventDataSession
