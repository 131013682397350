import React from 'react'

const CustomTimer = (props: { endDate: Date }) => {
  const [currentTimeString, setCurrentTimeString] = React.useState('')

  React.useEffect(() => {
    const timerInterval = setInterval(() => {
      const distance = props.endDate.getTime() - new Date().getTime()
      const days = Math.floor(distance / (1000 * 60 * 60 * 24))
      const hours = Math.floor(
        (distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60)
      )
      const minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60))
      const seconds = Math.floor((distance % (1000 * 60)) / 1000)
      setCurrentTimeString(
        days + 'd ' + hours + 'h ' + minutes + 'm ' + seconds + 's '
      )
      if (distance < 0) {
        setCurrentTimeString('Finalizado')
      }
    }, 1000)
    return () => {
      clearInterval(timerInterval)
    }
  }, [])

  return <>{currentTimeString}</>
}

export default CustomTimer
