import {
  Badge,
  CircularProgress,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  makeStyles,
  Paper,
  Typography,
} from '@material-ui/core'
import { FiberManualRecord, HomeWork } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { useNotify, usePermissions, useRedirect } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { ClinicWithoutStockDTO, IAddress, isAdmin } from '@vacinas-net/shared'
import httpClient from '../../CustomHttpClient'
import { apiUrl } from '../../AppDataProvider'

const useStyles = makeStyles({
  campaignLink: {
    '&:hover': {
      textDecoration: 'underline',
      textDecorationColor: mainTheme.palette.primary.main,
    },
  },
  seeMoreLink: {
    '&:hover': {
      textDecoration: 'underline',
    },
  },
})

const parseCityAndState = (address: IAddress) => {
  return `- ${address.city} - ${address.state}`
}

const NoStocksClinicsCard = () => {
  const [clinicsWithMissingStock, setClinicsWithMissingStock] =
    useState<ClinicWithoutStockDTO[]>()
  const [total, setTotal] = useState<number>()
  const [loading, setLoading] = useState(true)
  const [showAll, setShowAll] = useState(false)

  const notify = useNotify()
  const redirect = useRedirect()
  const classes = useStyles()
  const { permissions } = usePermissions()

  useEffect(() => {
    httpClient(`${apiUrl}/clinicsWithoutProductStock`, {
      method: 'get',
    })
      .then((res) => {
        const data = res.json as ClinicWithoutStockDTO[]
        const total = data.length
        setClinicsWithMissingStock(data)
        setTotal(total)
      })
      .catch((error) => {
        notify(`Erro ao obter informações das clinicas: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }, [])

  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
      }}
    >
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          width: '100%',
          position: 'relative',
          marginBottom: mainTheme.spacing(2),
          paddingTop: mainTheme.spacing(1),
        }}
      >
        <HomeWork
          style={{ marginLeft: mainTheme.spacing(1) }}
          color="secondary"
          fontSize="default"
        />
        <Typography
          style={{ marginLeft: mainTheme.spacing(2) }}
          align="center"
          variant="h2"
          color="textPrimary"
        >
          {isAdmin(permissions)
            ? 'Clínicas sem Estoque'
            : 'Vacinas sem Estoque'}
        </Typography>
      </div>
      {!loading ? (
        <List style={{ alignSelf: 'flex-start', width: '100%' }}>
          {clinicsWithMissingStock &&
            clinicsWithMissingStock
              .slice(0, showAll ? total! : 10)
              .map((clinic) => (
                <ListItem
                  style={{ paddingBottom: 0, paddingTop: 0, cursor: 'pointer' }}
                  key={clinic.clinicId}
                  onClick={(ev: { stopPropagation: () => void }) => {
                    ev.stopPropagation()
                    redirect(
                      `/product?displayedFilters=%7B"clinic->_id"%3Atrue%2C"isActive"%3Atrue%7D&filter=%7B"clinic->_id"%3A"${clinic.clinicId}"%2C"isActive"%3Atrue%7D&page=1&perPage=10&sort=totalQuantity&order=ASC`
                    )
                  }}
                >
                  <ListItemIcon style={{ minWidth: 16 }}>
                    <FiberManualRecord
                      color="primary"
                      style={{ fontSize: 10 }}
                    />
                  </ListItemIcon>

                  <ListItemText
                    style={{ paddingRight: 16 }}
                    className={classes.campaignLink}
                    primary={`${clinic.clinicName} ${
                      clinic.clinicAddress?.city && clinic.clinicAddress?.state
                        ? parseCityAndState(clinic.clinicAddress)
                        : ''
                    }`}
                    primaryTypographyProps={{
                      gutterBottom: false,
                    }}
                  />
                  <Badge
                    style={{ position: 'absolute', right: 16 }}
                    color="primary"
                    max={99}
                    badgeContent={clinic.productCountWithoutStock}
                  />
                </ListItem>
              ))}
        </List>
      ) : (
        <CircularProgress size={40} color="secondary" />
      )}
      {!!(!total && !loading) && (
        <Typography variant="body2">Não há produtos sem estoque.</Typography>
      )}
      {!!(total && total > 10) && (
        <div onClick={() => setShowAll(!showAll)}>
          {showAll ? (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Typography
                className={classes.seeMoreLink}
                style={{ marginRight: mainTheme.spacing(1) }}
                variant="body2"
              >
                Fechar
              </Typography>
            </div>
          ) : (
            <div
              style={{
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                cursor: 'pointer',
              }}
            >
              <Typography
                className={classes.seeMoreLink}
                style={{ marginRight: mainTheme.spacing(1) }}
                variant="body2"
              >
                Ver mais
              </Typography>
            </div>
          )}
        </div>
      )}
    </Paper>
  )
}

export default NoStocksClinicsCard
