import * as React from 'react'
import _ from 'lodash'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { SelectInput, TextInput } from 'react-admin'
import { inlineInputStyle } from '../../../hooks/CustomListFilter'

export const ProductsListFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'operationType',
      label: 'Tipo de operação',
      FilterInput: (
        <SelectInput
          source="operationType"
          label="Tipo de operação"
          choices={[
            { id: 'own', name: 'Própria da clínica' },
            { id: 'flipped', name: 'Vacinas.net' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'product->skuId',
      label: 'Sku',
      FilterInput: (
        <TextInput
          label="Sku"
          source="product->skuId"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'product->name',
      label: 'Nome do Produto',
      FilterInput: (
        <SearchFilterInput
          label="Nome do Produto"
          source="product->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->_id',
      label: 'ID da Clínica',
      FilterInput: (
        <TextInput
          label="ID da Clínica"
          source="clinic->_id"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'clinic->name',
      label: 'Nome da Clínica',
      FilterInput: (
        <SearchFilterInput
          label="Nome da Clínica"
          source="clinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'isActive',
      label: 'Status',
      FilterInput: (
        <SelectInput
          source="isActive"
          label="Status"
          choices={[
            { id: true, name: 'Ativo' },
            { id: false, name: 'Inativo' },
          ]}
          style={{
            ...inlineInputStyle,
            minWidth: 180,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
