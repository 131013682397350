import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const PayoutStatusChip = (props: { status: string }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'processing':
      statusText = 'Em Processamento'
      statusColor = mainTheme.palette.warning.main
      break
    case 'readyToPay':
      statusText = 'Pronto para Repasse'
      statusColor = mainTheme.palette.secondary.main
      break
    case 'paid':
      statusText = 'Repasse Realizado'
      statusColor = mainTheme.palette.info.main
      break
    case 'cancelled':
      statusText = 'Repasse Cancelado'
      statusColor = mainTheme.palette.error.dark
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default PayoutStatusChip
