const format = (cep?: string) => {
  if (!cep) return cep
  let onlyNumberValues = cep.match(/\d+/g)?.join('') || ''
  if (onlyNumberValues.length === 0) return ''
  if (onlyNumberValues.length > 8)
    onlyNumberValues = onlyNumberValues?.slice(0, 8)
  if (onlyNumberValues.length === 8)
    return onlyNumberValues
      .replace(/\D/g, '')
      .replace(/^(\d{2})(\d{3})?(\d{3})?/, '$1.$2-$3')
  return onlyNumberValues
}
const parse = (cep?: string) => {
  if (!cep) return cep
  return cep.match(/\d+/g)?.join('').slice(0, 8)
}

export const cepFormatter = {
  format,
  parse,
}
