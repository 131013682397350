import React, { useContext } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { OrderCreateContext } from '../Contexts/OrderCreateContext'
import {
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'

const VaccineShotSession = () => {
  const context = useContext(OrderCreateContext)
  const {
    createMany,
    setBatch,
    batch,
    setExpirationDate,
    expirationDate,
    setShotDate,
    shotDate,
    setDose,
    dose,
  } = context!

  return createMany ? (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'flex-start',
        marginTop: mainTheme.spacing(3),
      }}
    >
      <Typography align="center" variant="h1">
        Aplicações
      </Typography>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginTop: mainTheme.spacing(2),
        }}
      >
        <TextField
          type="date"
          style={{
            width: 300,
          }}
          label="Data de aplicação"
          variant="outlined"
          value={shotDate}
          onChange={(event) => {
            setShotDate(event.target.value)
          }}
          inputProps={{
            'data-testid': `input-vaccine-shot-date`,
          }}
          size="small"
          InputLabelProps={{ shrink: true }}
          required
        />
        <FormControl
          variant="outlined"
          size="small"
          style={{ width: 136, marginLeft: mainTheme.spacing(4) }}
          required
        >
          <InputLabel
            style={{
              zIndex: 1,
              backgroundColor: '#ffffff',
              paddingRight: mainTheme.spacing(1 / 2),
            }}
          >
            Dose
          </InputLabel>
          <Select
            value={dose || null}
            label="Dose"
            onChange={(event) =>
              setDose(
                String(event.target.value) as
                  | 'first'
                  | 'second'
                  | 'third'
                  | 'booster'
                  | 'anual'
                  | undefined
              )
            }
            inputProps={{
              'data-testid': `dose-input`,
            }}
          >
            <MenuItem value={'first'}>Primeira</MenuItem>
            <MenuItem value={'second'}>Segunda</MenuItem>
            <MenuItem value={'third'}>Terceira</MenuItem>
            <MenuItem value={'booster'}>Reforço</MenuItem>
            <MenuItem value={'anual'}>Anual</MenuItem>
          </Select>
        </FormControl>
        <TextField
          style={{
            marginLeft: mainTheme.spacing(4),
            width: 200,
          }}
          label="Lote"
          variant="outlined"
          value={batch}
          onChange={(event) => {
            setBatch(event.target.value)
          }}
          inputProps={{
            'data-testid': `batch-input`,
          }}
          size="small"
          InputLabelProps={{ shrink: true }}
          required
        />
        <TextField
          type="date"
          style={{
            marginLeft: mainTheme.spacing(4),
            width: 200,
          }}
          label="Validade"
          variant="outlined"
          value={expirationDate}
          onChange={(event) => {
            setExpirationDate(event.target.value)
          }}
          inputProps={{
            'data-testid': `input-vaccine-shot-expiration-date`,
          }}
          size="small"
          InputLabelProps={{ shrink: true }}
          required
        />
      </div>
    </div>
  ) : (
    <></>
  )
}

export default VaccineShotSession
