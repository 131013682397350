import React from 'react'
import { Chip } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

const VaccineShotStatus = (props: { status: 'applied' | 'not_applied' }) => {
  let statusText
  let statusColor

  switch (props.status) {
    case 'applied':
      statusText = 'Aplicada'
      statusColor = mainTheme.palette.primary.main
      break
    case 'not_applied':
      statusText = 'Não Aplicada'
      statusColor = mainTheme.palette.warning.main
      break
    default:
      statusText = 'Indefinido'
      statusColor = 'default'
      break
  }
  return (
    <Chip
      style={{ backgroundColor: statusColor, color: '#FFFFFF' }}
      label={statusText}
    />
  )
}

export default VaccineShotStatus
