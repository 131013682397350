import React from 'react'
import {
  EditContextProvider,
  EditProps,
  FieldProps,
  useEditController,
  SimpleForm,
  TextInput,
  required,
  Record,
  Toolbar,
  SaveButton,
  ToolbarProps,
} from 'react-admin'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import { mainTheme } from '../../../utils/mainTheme'
import { WithWidthProps } from '@material-ui/core'

const CustomProductsSkuEdit: React.FC<FieldProps> = (props) => {
  const controllerProps = useEditController(props)
  const record = controllerProps.record

  return (
    <EditContextProvider value={controllerProps}>
      <div>
        <HeaderWithGoBack title={`Editar produto`} />
        {React.cloneElement(props.children as React.ReactElement, {
          ...controllerProps,
          record,
        })}
      </div>
    </EditContextProvider>
  )
}

const CustomToolbar = (props: ToolbarProps & WithWidthProps) => (
  <Toolbar {...props}>
    <SaveButton label="Salvar" />
  </Toolbar>
)

const ProductsSkuEdit = (props: EditProps) => {
  const transform = (data: Record) => {
    return {
      name: data.name,
    }
  }
  return (
    <CustomProductsSkuEdit
      mutationMode="pessimistic"
      component="div"
      transform={transform}
      {...props}
    >
      <SimpleForm toolbar={<CustomToolbar />} submitOnEnter={false}>
        <TextInput
          color="primary"
          variant="outlined"
          label="Nome do produto"
          source="name"
          style={{ marginTop: mainTheme.spacing(2) }}
          validate={required()}
        />
      </SimpleForm>
    </CustomProductsSkuEdit>
  )
}

export default ProductsSkuEdit
