import * as React from 'react'
import {
  TableContainer,
  Table,
  TableHead,
  TableRow,
  TableCell,
  Paper,
  Typography,
  ThemeProvider,
  CircularProgress,
  TableBody,
} from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import { IPatient } from '@vacinas-net/shared'
import { format } from 'date-fns'

const VaccinatedPatientsTable = (props: {
  patientDataArray?: {
    patient: IPatient
    shotDate?: Date
  }[]
  loading: boolean
}) => {
  const { loading, patientDataArray } = props

  if (loading)
    return (
      <ThemeProvider theme={mainTheme}>
        <div
          style={{
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: mainTheme.spacing(2),
          }}
        >
          <CircularProgress color="inherit" size={20} />
        </div>
      </ThemeProvider>
    )

  if (patientDataArray?.length === 0 || !patientDataArray) {
    return (
      <Typography
        data-testid={`patient-data-not-found-content`}
        variant="body1"
        color="textSecondary"
      >
        As doses aplicadas serão exibidas aqui
      </Typography>
    )
  }
  return (
    <div>
      <TableContainer
        style={{ marginTop: mainTheme.spacing(2) }}
        component={Paper}
        data-testid={`patient-data-table`}
      >
        <Table>
          <TableHead style={{ backgroundColor: mainTheme.palette.grey[200] }}>
            <TableRow>
              <TableCell align="center">Horário de aplicação</TableCell>
              <TableCell align="center">Nome</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {patientDataArray?.map((patientData) => (
              <TableRow key={patientData.patient.name}>
                <TableCell align="center">
                  {patientData.shotDate
                    ? format(new Date(patientData.shotDate), 'dd/MM/yyyy')
                    : '-'}
                </TableCell>
                <TableCell align="center">{patientData.patient.name}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  )
}

export default VaccinatedPatientsTable
