import { IconButton, TextField, Typography } from '@material-ui/core'
import { Add, Cancel } from '@material-ui/icons'
import React, { useEffect, useState } from 'react'
import { TextInputProps, useInput } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { useFormState } from 'react-final-form'

const CouponsInput = (props: TextInputProps) => {
  const { input } = useInput(props)
  const form = useFormState()
  const initialValue = form.values.coupons ? form.values.coupons : []
  const [coupons, setCoupons] = useState<string[]>(initialValue)

  const addCoupon = () => {
    const newCoupon = ''

    setCoupons([...coupons, newCoupon])
  }

  const removeCoupon = (index: number) => {
    const arrayCopy = JSON.parse(JSON.stringify(coupons))
    arrayCopy.splice(index, 1)

    setCoupons(arrayCopy)
  }

  const updateReactAdminInput = () => {
    input.onChange(coupons)
  }

  React.useEffect(() => updateReactAdminInput(), [coupons])

  useEffect(() => {
    if (
      initialValue &&
      JSON.stringify(initialValue) !== JSON.stringify(coupons)
    ) {
      setCoupons(initialValue)
    }
  }, [initialValue])

  useEffect(() => {
    setCoupons(input.value ? input.value : [])
  }, [])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Cupons</Typography>
        <IconButton
          color={'secondary'}
          onClick={() => addCoupon()}
          style={{ marginLeft: mainTheme.spacing(1) }}
        >
          <Add />
        </IconButton>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {coupons.map((coupon, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={index}
          >
            <TextField
              variant="outlined"
              size="small"
              label="Cupom"
              value={coupons[index]}
              onChange={(e) => {
                const arrayCopy = JSON.parse(JSON.stringify(coupons))
                arrayCopy[index] = e.target.value
                setCoupons(arrayCopy)
              }}
              style={{
                marginTop: mainTheme.spacing(1),
                marginBottom: mainTheme.spacing(1),
                width: 300,
              }}
            />
            <IconButton onClick={() => removeCoupon(index)}>
              <Cancel />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  )
}

export default CouponsInput
