import React from 'react'
import { Datagrid, FunctionField, List, Record, TextField } from 'react-admin'
import { Paper, Typography } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { DashboardPagination } from '../../Pagination'
import { valueInCentsToBRL } from '../../../utils/utils'

const CampaignsDashboardTable = () => {
  return (
    <Paper style={{ padding: mainTheme.spacing(2) }}>
      <Typography variant="h2">Campanhas</Typography>
      <List
        pagination={<DashboardPagination />}
        bulkActionButtons={false}
        basePath="/"
        resource="campaign"
        actions={<></>}
      >
        <Datagrid>
          <TextField
            textAlign="center"
            label="Nome da campanha"
            source="name"
          />

          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) =>
              record?.ordersQuantity || '-'
            }
            label="Quantidade de pedidos"
            source="ordersQuantity"
          />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) =>
              record?.campaignTotalSoldInCents
                ? valueInCentsToBRL(record?.campaignTotalSoldInCents)
                : '-'
            }
            label="Valor total de vendas"
            source="campaignTotalSoldInCents"
          />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) =>
              record?.campaignTotalHoldValueInCents
                ? valueInCentsToBRL(record?.campaignTotalHoldValueInCents)
                : '-'
            }
            label="Valor faturado Vacinas.net"
            source="campaignTotalHoldValueInCents"
          />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) =>
              record?.campaignTotalPayoutValueCents
                ? valueInCentsToBRL(record?.campaignTotalPayoutValueCents)
                : '-'
            }
            label="Valor faturado Clínica"
            source="campaignTotalPayoutValueCents"
          />
        </Datagrid>
      </List>
    </Paper>
  )
}

export default CampaignsDashboardTable
