import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Show,
  ShowProps,
  TopToolbar,
  useShowController,
  Record,
  usePermissions,
} from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import HeaderWithGoBack from '../../../components/HeaderWithGoBack'
import CustomerSection from './ShowSections/CustomerSection'
import showStyles from '../../../utils/showStyles'
import AttendanceActions from '../../../components/Buttons/AttendanceActionButtons'
import {
  ATTENDANCE_FINISHABLE_STATUS,
  IAttendance,
  isAdmin,
} from '@vacinas-net/shared'
import { useRef } from 'react'
import logoHorizontal from '../../../assets/LogoHorizontal.png'
import PrintSignatureSection from './ShowSections/PrintSignatureSection'
import AttendanceDetailsClinic from './ShowSections/AttendanceDetails'
import AttendanceDetailsProducts from './ShowSections/AttendanceDetailsProducts'
import FinishAttendanceButton from '../../../components/FinishAttendanceButton'

export const useStyles = makeStyles({
  ordersActionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: mainTheme.spacing(1),
  },
  showOnlyOnPrint: {
    display: 'none',
    '@media print': {
      display: 'block',
    },
  },
  printScale: {
    '@media print': {
      zoom: '60%',
    },
  },
})

const AttendanceShowActions = (props: {
  record: Record | undefined
  componentRef: React.MutableRefObject<null>
}) => {
  const classes = useStyles()
  const showClasses = showStyles()
  const attendanceId = String(props.record?._id)
  const attendanceStatus = String(props.record?.status) as IAttendance['status']
  const uuid = String(props.record?.uuid)
  const itemList = props.record?.itemList || []
  const { componentRef } = props
  const { permissions } = usePermissions()

  return (
    <TopToolbar className={classes.ordersActionContainer}>
      <div
        style={{
          display: 'flex',
          alignItems: 'center',
          marginRight: 'auto',
        }}
      >
        <HeaderWithGoBack
          title={`Atendimento ${props.record?.aliasId}`}
          linkTo={
            isAdmin(permissions || [])
              ? `/order/${props.record?.orderId}/show`
              : undefined
          }
        />
      </div>
      <div className={showClasses.headerButtons}>
        <AttendanceActions
          attendanceProps={{
            _id: attendanceId,
            status: attendanceStatus,
            itemList: itemList,
            uuid: uuid,
          }}
          componentRef={componentRef}
        />
      </div>
    </TopToolbar>
  )
}

const AttendanceShow = (props: ShowProps) => {
  const componentRef = useRef(null)
  const classes = useStyles()

  const getPageMargins = () => {
    return `@page { margin: 16px !important; }`
  }

  const { record, loaded } = useShowController(props)
  if (!loaded) {
    return <></>
  }
  return (
    <div ref={componentRef} className={classes.printScale}>
      <style>{getPageMargins()}</style>
      <div className={classes.showOnlyOnPrint}>
        <img src={logoHorizontal} />
      </div>
      <Show
        actions={
          <AttendanceShowActions record={record} componentRef={componentRef} />
        }
        component="div"
        {...props}
      >
        <div>
          <CustomerSection />
          <AttendanceDetailsProducts record={record} />
          <AttendanceDetailsClinic record={record} />
          <PrintSignatureSection />
          {record?.status === ATTENDANCE_FINISHABLE_STATUS && (
            <div
              style={{
                display: 'flex',
                justifyContent: 'flex-end',
                width: '100%',
                marginTop: mainTheme.spacing(2),
              }}
            >
              <FinishAttendanceButton
                attendance={{
                  _id: record?._id,
                  itemList: record?.itemList,
                  status: record?.status,
                }}
              />
            </div>
          )}
        </div>
      </Show>
    </div>
  )
}

export default AttendanceShow
