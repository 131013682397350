import { makeStyles } from '@material-ui/core'

const useStyles = makeStyles(() => ({
  input: {
    maxWidth: 200,
    zIndex: 1,
  },
}))

const inlineInputStyle = {
  maxWidth: 200,
  zIndex: 1,
}

export { useStyles, inlineInputStyle }
