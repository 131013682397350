import * as React from 'react'
import _ from 'lodash'
import { SelectInput } from 'react-admin'
import SearchFilterInput from '../../components/Inputs/SearchFilterInput'
import { inlineInputStyle } from '../../hooks/CustomListFilter'

export const ClinicConditionFiltersArray: {
  value: string
  label: string
  FilterInput: JSX.Element
}[] = _.sortBy(
  [
    {
      value: 'clinic->name',
      label: 'Nome da clínica',
      FilterInput: (
        <SearchFilterInput
          label="Nome da clínica"
          source="clinic->name"
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'type',
      label: 'Condição comercial',
      FilterInput: (
        <SelectInput
          source="type"
          label="Condição comercial"
          choices={[
            { id: 'commission', name: 'Comissão' },
            { id: 'gesture', name: 'Gesto' },
            { id: 'acquisition', name: 'Aquisição' },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
    {
      value: 'status',
      label: 'Situação',
      FilterInput: (
        <SelectInput
          source="status"
          label="Situação"
          choices={[
            { id: 'awaiting', name: 'Sem resposta' },
            { id: 'accepted', name: 'Confirmada' },
            { id: 'rejected', name: 'Rejeitada' },
          ]}
          style={{
            ...inlineInputStyle,
          }}
          variant={'outlined'}
          size={'small'}
        />
      ),
    },
  ],
  'label'
)
