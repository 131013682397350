import {
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  TextField,
  InputAdornment,
  Grid,
  Typography,
} from '@material-ui/core'
import React, { useState } from 'react'
import { mainTheme } from '../../utils/mainTheme'
import { apiUrl } from '../AppDataProvider'
import httpClient from '../../components/CustomHttpClient'
import { useNotify, useRecordContext, useRefresh } from 'react-admin'
import { useStyles } from '../../hooks/CustomListFilter'
import { parseISO } from 'date-fns'

const VouchersListActionsPopup = (props: {
  voucherPackageId: string
  openDialog: boolean
  handleCloseDialog: () => void
}) => {
  const record = useRecordContext()
  const { voucherPackageId, openDialog, handleCloseDialog } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const [vouchers, setVouchers] = useState<string>()
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState<number>()
  const [date, setDate] = useState<string>()
  const classes = useStyles()

  const requestVouchers = async () => {
    setLoading(true)
    const parsedVouchers: string[] = String(vouchers)
      .split('\n')
      .filter((voucher) => !!voucher)

    const url = `${apiUrl}/voucherPackage/${voucherPackageId}/addVouchers`
    httpClient(url, {
      method: 'post',
      body: JSON.stringify({
        vouchers: parsedVouchers,
        voucherValueInCents: value,
        expirationDate: parseISO(date!),
      }),
    })
      .then(() => {
        notify('Vales disponibilizados com sucesso')
        handleCloseDialog()
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao disponibilizar vales', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }
  return (
    <Dialog open={openDialog} onClose={() => handleCloseDialog()} maxWidth="sm">
      <DialogTitle
        style={{
          textAlign: 'center',
          paddingBottom: 0,
        }}
      >
        Disponibilização de vales
      </DialogTitle>
      <DialogContent>
        <DialogContentText>
          Insira os vales disponibilizados no campo abaixo:
        </DialogContentText>
        <Grid
          style={{
            display: 'flex',
            justifyContent: 'space-between',
          }}
        >
          <TextField
            style={{
              width: 200,
            }}
            type="date"
            label="Data de validade"
            className={classes.input}
            variant={'outlined'}
            size={'small'}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => setDate(event.target.value)}
            inputProps={{
              'data-testid': `input-date`,
            }}
          />
          <TextField
            style={{
              width: 200,
            }}
            type="number"
            label="Valor"
            variant="outlined"
            size="small"
            InputLabelProps={{ shrink: true }}
            value={parseFloat(String(value)) / 100 || null}
            onChange={(event) => setValue(parseFloat(event.target.value) * 100)}
            InputProps={{
              startAdornment: (
                <InputAdornment position="start">R$</InputAdornment>
              ),
            }}
            inputProps={{
              'data-testid': `input-value`,
            }}
          />
        </Grid>
        <TextField
          style={{ marginTop: mainTheme.spacing(2), width: 440 }}
          color="primary"
          variant="outlined"
          label="Vales"
          multiline
          rows={10}
          required
          onChange={(event) => {
            setVouchers(event.target.value)
          }}
          inputProps={{
            'data-testid': `input-vouchers`,
          }}
        />
      </DialogContent>
      <Typography
        style={{
          marginLeft: mainTheme.spacing(4),
        }}
        variant="body1"
        color="textSecondary"
      >
        Vales solicitados: {record?.requestedQuantity}
      </Typography>
      <DialogActions
        style={{
          marginTop: mainTheme.spacing(1),
          marginBottom: mainTheme.spacing(1),
          marginRight: mainTheme.spacing(2),
        }}
      >
        <Button color="primary" variant="contained" onClick={handleCloseDialog}>
          Cancelar
        </Button>
        <Button
          color="primary"
          variant="contained"
          onClick={() => {
            requestVouchers()
          }}
          disabled={!vouchers}
        >
          {loading ? (
            <CircularProgress color="inherit" size={25} />
          ) : (
            'Confirmar'
          )}
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default VouchersListActionsPopup
