import * as React from 'react'
import {
  Datagrid,
  FunctionField,
  List,
  ListProps,
  Record,
  TextField,
} from 'react-admin'
import { DefaultPagination } from '../../../components/Pagination'
import ProductsSkuListFilters from './ProductsSkuListFilters'
import ProductsSkuListActions from './ProductsSkuListActions'

const ProductSkuList = ({ ...props }: ListProps) => {
  return (
    <List
      {...props}
      pagination={<DefaultPagination />}
      bulkActionButtons={false}
      filters={<ProductsSkuListFilters />}
      actions={<ProductsSkuListActions />}
      sort={{ field: 'createdAt', order: 'DESC' }}
    >
      <Datagrid stickyHeader rowClick="edit">
        <TextField source="skuId" textAlign="center" label="SKU" />
        <TextField source="name" textAlign="center" label="Nome" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.handler === 'vtex' ? 'VTEX' : 'Jenner'
          }
          label="Tipo"
          source="handler"
        />
      </Datagrid>
    </List>
  )
}

export default ProductSkuList
