import React, { useState } from 'react'
import { IconButton, TextField, Button } from '@material-ui/core'
import { Add, Close, Edit, Remove, Save } from '@material-ui/icons'
import { IClinicCondition } from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import { useListContext, useNotify } from 'react-admin'
import customHttpClient from '../../components/CustomHttpClient'
import { apiUrl } from '../../components/AppDataProvider'

interface EditEditClinicConditionInLineProps {
  clinicCondition?: IClinicCondition
  disabled?: boolean
}

const EditClinicConditionInLine = (
  props: EditEditClinicConditionInLineProps
) => {
  const currentTotalAmount = props.clinicCondition?.totalAmount || 0
  const [open, setOpen] = useState(false)
  const [loading, setLoading] = useState(false)
  const [value, setValue] = useState(currentTotalAmount)

  const notify = useNotify()
  const list = useListContext()

  const patchAllowedCustomerVouchers = async () => {
    setLoading(true)
    try {
      await customHttpClient(
        `${apiUrl}/clinic-condition/${props.clinicCondition?._id}`,
        {
          body: JSON.stringify({
            totalAmount: value,
          }),
          method: 'PATCH',
        }
      )
      list.refetch()
      setOpen(false)
    } catch (error) {
      notify(`Erro ao atualizar condição de clínica: ${error}`, 'error')
    } finally {
      setLoading(false)
    }
  }

  const decreaseCounter = () => {
    value! - 1 > 0 && setValue(value - 1)
  }

  const increaseCounter = () => {
    setValue(value! + 1)
  }

  return (
    <div style={{ position: 'relative' }}>
      <IconButton
        disabled={props.disabled}
        onClick={() => {
          setOpen(!open)
        }}
        size="small"
      >
        <Edit />
      </IconButton>
      {open && (
        <div
          style={{
            display: 'flex',
            background: '#ebe4ee',
            position: 'absolute',
            top: -74,
            right: -18,
            zIndex: 3,
            borderTopLeftRadius: mainTheme.spacing(1),
            borderTopRightRadius: mainTheme.spacing(1),
            padding: mainTheme.spacing(2),
          }}
        >
          <IconButton onClick={() => setOpen(false)} size="small">
            <Close />
          </IconButton>
          <IconButton
            style={{
              marginLeft: mainTheme.spacing(1),
            }}
            onClick={decreaseCounter}
            size="small"
          >
            <Remove />
          </IconButton>
          <div>
            <TextField
              style={{
                width: 48,
                marginLeft: mainTheme.spacing(2),
                marginRight: mainTheme.spacing(2),
              }}
              value={value}
              size="small"
              onChange={(ev) => {
                setValue(parseInt(ev.target.value))
              }}
              inputProps={{
                min: 1,
              }}
              type="number"
            />
          </div>
          <IconButton onClick={increaseCounter} size="small">
            <Add />
          </IconButton>
          <Button
            style={{
              marginLeft: mainTheme.spacing(1),
            }}
            startIcon={<Save />}
            onClick={() => patchAllowedCustomerVouchers()}
            size="small"
            variant="outlined"
            disabled={value === currentTotalAmount || loading}
          >
            Salvar
          </Button>
        </div>
      )}
    </div>
  )
}

export default EditClinicConditionInLine
