import * as React from 'react'
import { TextInputProps } from 'react-admin'
import { TextField } from '@material-ui/core'
import { useInput, useListContext } from 'ra-core'
import { mainTheme } from '../../../utils/mainTheme'
import { useEffect, useState } from 'react'
import { formatISO } from 'date-fns'

const CustomDateInput = (
  props: TextInputProps & { hours: number; minutes: number; seconds: number }
) => {
  const [value, setValue] = useState('')
  const { input } = useInput(props)
  const list = useListContext()

  const onChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value)
    const [year, month, day] = event.target.value.split('-')
    const date = new Date(
      Number(year),
      Number(month) - 1,
      Number(day),
      props.hours,
      props.minutes,
      props.seconds
    )
    input.onChange(date.toISOString())
  }

  useEffect(() => {
    if (!!list) {
      const initialValue = list.filterValues[props.source]
      if (initialValue) {
        setValue(formatISO(new Date(initialValue), { representation: 'date' }))
      }
    }
  }, [list])

  return (
    <div>
      <TextField
        className={props.className}
        style={{
          paddingBottom: mainTheme.spacing(1),
          marginTop: mainTheme.spacing(1),
          ...props.style,
        }}
        placeholder={props.placeholder}
        onChange={onChange}
        variant={props.variant || 'outlined'}
        value={value}
        label={props.label}
        size={props.size}
        type="date"
        InputLabelProps={{ shrink: true }}
      />
    </div>
  )
}

export default CustomDateInput
