import { TextInputProps, useInput } from 'react-admin'
import React, { useEffect } from 'react'
import { useFormState } from 'react-final-form'
import { IconButton, TextField } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { Add, Cancel } from '@material-ui/icons'
import { IFinancialGroupData } from '@vacinas-net/shared'

const PixKeysInput = (props: TextInputProps) => {
  const { input } = useInput(props)
  const form = useFormState()
  const pixKeys: NonNullable<IFinancialGroupData['pixKeys']> =
    form.values.financialGroupData?.pixKeys || []

  const addkey = () => {
    input.onChange([
      ...pixKeys,
      {
        type: 'unknown',
        value: '',
      },
    ])
  }

  const removeKey = (index: number) => {
    const arrayCopy = JSON.parse(JSON.stringify(pixKeys))
    arrayCopy.splice(index, 1)

    input.onChange(arrayCopy)
  }

  useEffect(() => {
    if (pixKeys.length === 0) {
      input.onChange([
        {
          type: 'unknown',
          value: '',
        },
      ])
    }
  }, [])

  return (
    <div>
      <div style={{ display: 'flex', flexDirection: 'column', width: 1000 }}>
        {pixKeys.map((pixkey, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={index}
          >
            <TextField
              variant="outlined"
              size="small"
              label="Chave PIX"
              value={pixkey.value}
              onChange={(e) => {
                const arrayCopy = JSON.parse(JSON.stringify(pixKeys))
                arrayCopy[index] = { value: e.target.value, type: 'unknown' }
                input.onChange(arrayCopy)
              }}
              style={{
                marginTop: mainTheme.spacing(1),
                marginBottom: mainTheme.spacing(1),
                marginRight: mainTheme.spacing(2),
                width: 200,
              }}
            />
            {index !== 0 && (
              <IconButton
                style={{
                  marginRight: mainTheme.spacing(2),
                }}
                onClick={() => removeKey(index)}
              >
                <Cancel />
              </IconButton>
            )}
            {index === pixKeys.length - 1 && (
              <IconButton color={'secondary'} onClick={() => addkey()}>
                <Add />
              </IconButton>
            )}
          </div>
        ))}
      </div>
    </div>
  )
}

export default PixKeysInput
