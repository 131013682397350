import React from 'react'
import { CircularProgress, Typography } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { useState, useEffect } from 'react'
import { useNotify } from 'react-admin'
import { valueInCentsToBRLWithSpace } from '../../../utils/utils'
import httpClient from '../../CustomHttpClient'
import HeaderDataCard from '../DashboardHeaderDataCard'
import { Divider, Grid } from '@mui/material'
import { DashboardHeadersBillingDataDTO } from '@vacinas-net/shared'

const DashboardHeaderBillingData = (props: {
  requestUrl: string
  isAdmin: boolean
  makingRequest: boolean
  stopRequest: () => void
}) => {
  const notify = useNotify()
  const [content, setContent] = useState<DashboardHeadersBillingDataDTO>()
  const [loading, setLoading] = useState(true)
  const { requestUrl, isAdmin, makingRequest, stopRequest } = props

  useEffect(() => {
    if (!makingRequest) return
    httpClient(requestUrl, {
      method: 'get',
    })
      .then((response) => {
        setContent(response.json)
      })
      .catch((error) => {
        notify(`Erro ao obter dados do dashboard: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        stopRequest()
      })
  }, [makingRequest])
  return (
    <Grid
      container
      xs={12}
      style={{
        padding: mainTheme.spacing(1),
        border: '1px #0000001f',
        borderStyle: 'solid none',
        display: 'flex',
        flexDirection: 'row',
        justifyContent: 'center',
        alignItems: 'center',
      }}
    >
      {content ? (
        <Grid container xs={12}>
          <HeaderDataCard
            cardPrimary={{
              title: 'Pedidos',
              value: String(content.orderCount),
            }}
          />
          <Divider
            orientation="vertical"
            flexItem
            style={{
              marginTop: mainTheme.spacing(2),
              marginBottom: mainTheme.spacing(2),
            }}
          />
          {isAdmin && content.total && (
            <>
              <HeaderDataCard
                cardPrimary={{
                  title: 'Vendas totais',
                  value: valueInCentsToBRLWithSpace(
                    content.total.totalBilledInCents || 0
                  ),
                }}
                cardSecondary={{
                  title: 'Ticket médio total',
                  value: valueInCentsToBRLWithSpace(
                    content.total.averageTicketInCents || 0
                  ),
                }}
              />

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: mainTheme.spacing(2),
                  marginBottom: mainTheme.spacing(2),
                }}
              />
            </>
          )}
          <HeaderDataCard
            cardPrimary={{
              title: 'Faturamento das clínicas',
              value: valueInCentsToBRLWithSpace(
                content.clinics?.totalBilledInCents || 0
              ),
            }}
            cardSecondary={{
              title: 'Ticket médio clínicas',
              value: valueInCentsToBRLWithSpace(
                content.clinics?.averageTicketInCents || 0
              ),
            }}
          />
          {isAdmin && content.admin && (
            <>
              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: mainTheme.spacing(2),
                  marginBottom: mainTheme.spacing(2),
                }}
              />

              <HeaderDataCard
                cardPrimary={{
                  title: 'Vales utilizados',
                  value: String(content.voucherCount),
                }}
              />

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: mainTheme.spacing(2),
                  marginBottom: mainTheme.spacing(2),
                }}
              />

              <HeaderDataCard
                cardPrimary={{
                  title: 'Vacinas aplicadas',
                  value: String(content.appliedVaccineShotCount),
                }}
              />

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: mainTheme.spacing(2),
                  marginBottom: mainTheme.spacing(2),
                }}
              />

              <HeaderDataCard
                cardPrimary={{
                  title: 'Vacinas a aplicar',
                  value: String(content.unappliedVaccineShotCount),
                }}
              />

              <Divider
                orientation="vertical"
                flexItem
                style={{
                  marginTop: mainTheme.spacing(2),
                  marginBottom: mainTheme.spacing(2),
                }}
              />

              <HeaderDataCard
                cardPrimary={{
                  title: 'Faturamento Vacinas.net',
                  value: valueInCentsToBRLWithSpace(
                    content.admin.totalBilledInCents
                  ),
                }}
                cardSecondary={{
                  title: 'Ticket médio Vacinas.net',
                  value: valueInCentsToBRLWithSpace(
                    content.admin.averageTicketInCents
                  ),
                }}
              />
            </>
          )}
        </Grid>
      ) : loading ? (
        <CircularProgress size={20} color="secondary" />
      ) : (
        <Typography variant="h6">Nenhum dado encontrado</Typography>
      )}
    </Grid>
  )
}

export default DashboardHeaderBillingData
