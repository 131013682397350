import { Paper, Typography } from '@material-ui/core'
import { format } from 'date-fns'
import { ptBR } from 'date-fns/locale'
import React, { useEffect, useState } from 'react'
import { useNotify, usePermissions } from 'react-admin'
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts'
import { mainTheme } from '../../../utils/mainTheme'
import { valueInCentsToBRL } from '../../../utils/utils'
import { apiUrl } from '../../AppDataProvider'
import customHttpClient from '../../CustomHttpClient'
import { TooltipProps } from 'recharts'
import {
  ValueType,
  NameType,
} from 'recharts/src/component/DefaultTooltipContent'
import { isAdmin } from '@vacinas-net/shared'

interface ChartInvoices {
  clinicInvoicesValueInCents: number
  vacinasInvoicesValueInCents: number
  totalInvoicesValueInCents: number
  billingStartDate: string
  billingEndDate: string
  clinicInvoicesValueInReais?: string
  vacinasInvoicesValueInReais?: string
  totalInvoicesValueInReais?: string
}

const currencyFormatter = (tick: string | number | Date) => {
  return valueInCentsToBRL(parseFloat(String(tick)))
}

const StackedBarCard = (props: {
  startDate?: string
  endDate?: string
  makingRequest: boolean
  stopRequest: () => void
  clinicId?: string
}) => {
  const { startDate, endDate, makingRequest, stopRequest, clinicId } = props

  const CustomTooltip = ({
    active,
    payload,
    label,
  }: TooltipProps<ValueType, NameType>) => {
    if (active) {
      return (
        <div
          style={{
            backgroundColor: 'white',
            padding: mainTheme.spacing(2),
            opacity: 0.85,
            border: `1px solid ${mainTheme.palette.grey[300]} `,
          }}
        >
          <Typography variant="body1">{`${label}`}</Typography>

          <Typography variant="body1">{`${
            isAdmin(permissions)
              ? `Faturamento total: ${valueInCentsToBRL(
                  payload?.[0].payload.totalInvoicesValueInCents
                )}`
              : `Faturamento da clínica: ${valueInCentsToBRL(
                  payload?.[0].payload.clinicInvoicesValueInCents
                )}`
          }`}</Typography>
          {isAdmin(permissions) && (
            <Typography
              color="primary"
              variant="body1"
            >{`Vacinas.net: ${valueInCentsToBRL(
              payload?.[0].payload.vacinasInvoicesValueInCents
            )}`}</Typography>
          )}
        </div>
      )
    }

    return null
  }
  const [chartData, setChartData] = useState<ChartInvoices[]>()

  const notify = useNotify()
  const { permissions } = usePermissions()

  useEffect(() => {
    if (!makingRequest) return

    customHttpClient(
      `${apiUrl}/financial/total-billing-chart?createdAt(min)=${
        startDate && `${startDate}`
      }&createdAt(max)=${endDate && `${endDate}`}${
        clinicId ? `&relatedClinics._id=${clinicId}` : ''
      }`,
      {
        method: 'get',
      }
    )
      .then((res) => {
        const data = res.json.totalBillingChart as ChartInvoices[]

        setChartData(
          data.map((chart) => {
            return {
              ...chart,
              clinicInvoicesValueInReais: valueInCentsToBRL(
                chart.clinicInvoicesValueInCents
              ),
              vacinasInvoicesValueInReais: valueInCentsToBRL(
                chart.vacinasInvoicesValueInCents
              ),
              billingDateRange: `${format(
                new Date(chart.billingStartDate),
                'dd.MM',
                {
                  locale: ptBR,
                }
              )} - ${format(new Date(chart.billingEndDate), 'dd.MM', {
                locale: ptBR,
              })}`,
            }
          })
        )
      })
      .catch((error) => {
        notify(`Erro ao obter informações do : ${error}`, 'error')
      })
      .finally(() => {
        stopRequest()
      })
  }, [makingRequest])
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
      }}
    >
      <Typography variant="h2">
        {isAdmin(permissions)
          ? 'Valor total e faturamento Vacinas'
          : 'Faturamento da clínica'}
      </Typography>
      <BarChart
        width={600}
        height={300}
        data={chartData}
        margin={{
          top: 30,
          right: 30,
          left: 50,
          bottom: 5,
        }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="billingDateRange" />
        <YAxis
          // eslint-disable-next-line @typescript-eslint/ban-ts-comment
          //@ts-ignore
          tickFormatter={currencyFormatter}
        />
        <Tooltip content={<CustomTooltip />} />
        <Legend />
        <Bar
          barSize={24}
          dataKey="clinicInvoicesValueInCents"
          name="Clínica"
          stackId="a"
          fill={mainTheme.palette.secondary.main}
        />
        {isAdmin(permissions) && (
          <Bar
            barSize={24}
            dataKey="vacinasInvoicesValueInCents"
            name="Vacinas.net"
            stackId="a"
            fill={mainTheme.palette.primary.main}
          />
        )}
      </BarChart>
    </Paper>
  )
}

export default StackedBarCard
