import React from 'react'
import { Divider, List, ListItem, ListItemText } from '@material-ui/core'
import { parseAddress } from '../../../utils/utils'
import { IClinic } from '@vacinas-net/shared'

const ClinicMapList = (props: { clinicsArray: IClinic[] }) => {
  const { clinicsArray } = props
  return (
    <List style={{ maxHeight: 580, overflow: 'auto' }}>
      {clinicsArray.map((clinic) => (
        <>
          <ListItem key={clinic._id}>
            <ListItemText
              primary={`${clinic.name} ${
                clinic.address?.city ? `- ${clinic.address.city}` : ''
              } ${clinic.address?.state ? `- ${clinic.address.state}` : ''}`}
              secondary={parseAddress(clinic.address)}
            />
          </ListItem>
          <Divider />
        </>
      ))}
      {clinicsArray.length === 0 && (
        <ListItem>
          <ListItemText
            primary="Nenhuma clínica encontrada"
            secondary="Por favor limpe a barra de pesquisa ou adicione mais condições comerciais!"
          />
        </ListItem>
      )}
    </List>
  )
}

export default ClinicMapList
