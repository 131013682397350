import * as React from 'react'
import { Paper, Typography, Grid, makeStyles } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import { Alarm, Person, PinDrop } from '@material-ui/icons'
import { IAddress } from '@vacinas-net/shared'
import { parseAddress } from '../../utils/utils'
import { format } from 'date-fns'

export const useStyles = makeStyles({
  campaignInfoContainer: {
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-start',
    marginRight: mainTheme.spacing(1),
    [mainTheme.breakpoints.down('sm')]: {
      marginBottom: mainTheme.spacing(2),
    },
  },
  registerVaccineShotContainer: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'center',
    marginTop: mainTheme.spacing(6),
    padding: mainTheme.spacing(2),
  },
})
const AvailableAttendanceFormHeader = (props: {
  camapaignName?: string
  startDatetime?: string | Date
  endDatetime?: string | Date
  clinicName?: string
  address?: IAddress
  totalAvailableAttendances?: number
}) => {
  const classes = useStyles()
  const {
    camapaignName,
    startDatetime,
    endDatetime,
    clinicName,
    address,
    totalAvailableAttendances,
  } = props

  return (
    <Paper
      style={{
        padding: mainTheme.spacing(2),
        backgroundColor: '#F9F0FF',
      }}
      color="primary"
      elevation={1}
    >
      <Grid
        container
        spacing={2}
        style={{
          alignItems: 'center',
        }}
      >
        <Grid item md={6} sm={12}>
          <div className={classes.campaignInfoContainer}>
            <Typography
              style={{
                fontWeight: 'bold',
                maxWidth: 240,
              }}
              color="primary"
              variant="body1"
            >
              {camapaignName}
            </Typography>
            <div
              style={{
                display: 'flex',
                marginTop: mainTheme.spacing(2),
              }}
            >
              <Alarm
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              />
              <Typography
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              >
                {startDatetime && format(new Date(startDatetime), 'dd/MM/yyyy')}
              </Typography>
              <Typography
                color="textSecondary"
                style={{
                  marginRight: mainTheme.spacing(1 / 2),
                }}
              >
                Inicio
              </Typography>
              <Typography
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              >
                {startDatetime && format(new Date(startDatetime), 'HH:mm')}
              </Typography>
              <Typography
                color="textSecondary"
                style={{
                  marginRight: mainTheme.spacing(1 / 2),
                }}
              >
                Fim
              </Typography>
              <Typography
                style={{ marginRight: mainTheme.spacing(1 / 2) }}
                color="primary"
              >
                {endDatetime && format(new Date(endDatetime), 'HH:mm')}
              </Typography>
            </div>
            <div
              style={{
                display: 'flex',
                marginTop: mainTheme.spacing(2),
              }}
            >
              <Person
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              />
              <Typography
                style={{ marginRight: mainTheme.spacing(1) }}
                color="primary"
              >
                {totalAvailableAttendances && totalAvailableAttendances}
              </Typography>
              <Typography color="textSecondary">agendamentos</Typography>
            </div>
          </div>
        </Grid>

        <Grid item md={6} sm={12}>
          <div
            style={{
              display: 'flex',
            }}
          >
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
              }}
            >
              <PinDrop color="primary" />
            </div>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'flex-start',
                marginLeft: mainTheme.spacing(1),
              }}
            >
              <Typography
                color="textSecondary"
                style={{
                  marginRight: mainTheme.spacing(1 / 2),
                  maxWidth: 240,
                }}
              >
                {clinicName}
              </Typography>
              <Typography
                color="textSecondary"
                style={{
                  marginRight: mainTheme.spacing(1 / 2),
                  maxWidth: 240,
                }}
              >
                {parseAddress(address)}
              </Typography>
            </div>
          </div>
        </Grid>
      </Grid>
    </Paper>
  )
}
export default AvailableAttendanceFormHeader
