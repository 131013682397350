import * as React from 'react'
import { makeStyles } from '@material-ui/core'
import {
  Show,
  ShowProps,
  TopToolbar,
  useShowController,
  usePermissions,
} from 'react-admin'
import { mainTheme } from '../../../../utils/mainTheme'
import HeaderWithGoBack from '../../../../components/HeaderWithGoBack'
import InfoSection from './ShowSections/InfoSection'
import PayoutsTableSection from './ShowSections/PayoutsTableSection'
import { isClinicAdmin } from '@vacinas-net/shared'
import DeletePayoutSettlementButton from '../../../../components/Buttons/DeletePayoutSettlementButton'

export const useStyles = makeStyles({
  ordersActionContainer: {
    display: 'flex',
    alignItems: 'center',
    marginBottom: mainTheme.spacing(1),
  },
})

const PayoutSettlementActions = (props: {
  payoutSettlementId: string
  status: string
}) => {
  const { permissions } = usePermissions()

  const classes = useStyles()

  return (
    <TopToolbar className={classes.ordersActionContainer}>
      <div style={{ marginRight: 'auto' }}>
        <HeaderWithGoBack title="Detalhes do repasse" />
      </div>
      {(isClinicAdmin(permissions) && props.status) !== 'receipt_settled' && (
        <DeletePayoutSettlementButton
          payoutSettlementId={props.payoutSettlementId}
        />
      )}
    </TopToolbar>
  )
}

const PayoutSettlementShow = (props: ShowProps) => {
  const { record, loaded } = useShowController(props)

  return (
    <Show
      actions={
        <PayoutSettlementActions
          payoutSettlementId={record?._id}
          status={record?.status}
        />
      }
      component="div"
      {...props}
    >
      <>
        {loaded && (
          <>
            <InfoSection />
            <PayoutsTableSection />
          </>
        )}
      </>
    </Show>
  )
}

export default PayoutSettlementShow
