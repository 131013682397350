/* eslint-disable @typescript-eslint/no-explicit-any */
import { Typography } from '@material-ui/core'
import { IAddress, IClinicExternalData } from '@vacinas-net/shared'
import * as React from 'react'
import {
  FormTab,
  required,
  SimpleFormProps,
  TabbedForm,
  TextInput,
} from 'react-admin'
import ClinicGroupAutocompleteInput from '../../components/Inputs/ClinicGroupAutocompleteInput'
import useStyles from '../../utils/creationFormStyles'
import { cnpjFormatter, phoneFormatter } from '../../utils/formatters'
import { OptionType } from '../../components/CustomAutocomplete'
import CreateNewClinicGroupButton from '../../components/Buttons/CreateNewClinicGroupButton'
import { mainTheme } from '../../utils/mainTheme'
import ScheduleTimeForm from './ScheduleTimeForm'
import PixKeysInput from '../../components/Inputs/PixKeysInput'
import AddressSession from './AddressSession'
import CustomImageInput from '../../components/Inputs/CustomImageInput'

type CustomFormProps = Omit<SimpleFormProps, 'children'> & {
  readOnly?: boolean
  autoCompleteInitialValues?: OptionType | null
  vtexData: GetAllExternalDataAndAddressType[]
  id?: string
  isEdit?: boolean
}

type GetAllExternalDataAndAddressType = {
  externalData: Omit<IClinicExternalData, '_id'>
} & { address: IAddress }

const ClinicForm = (props: CustomFormProps) => {
  const classes = useStyles()
  const { readOnly } = props

  return (
    <TabbedForm {...props} sanitizeEmptyValues={false}>
      <FormTab label="Dados da clínica">
        <div className={classes.tabContainer}>
          <Typography variant="h2" className={classes.header}>
            Informações cadastrais
          </Typography>

          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
            }}
          >
            <div>
              <div
                style={{ display: 'flex', alignContent: 'center', width: 648 }}
              >
                <TextInput
                  source="cnpj"
                  label="CNPJ"
                  variant="outlined"
                  validate={required()}
                  format={cnpjFormatter.format}
                  parse={cnpjFormatter.parse}
                  InputProps={{
                    readOnly,
                  }}
                  className={classes.inputWMagin}
                />
                <TextInput
                  source="companyName"
                  label="Razão Social"
                  variant="outlined"
                  validate={required()}
                  InputProps={{
                    readOnly,
                  }}
                  className={classes.inputWMagin}
                />
                <TextInput
                  source="name"
                  label="Nome fantasia"
                  variant="outlined"
                  validate={required()}
                  InputProps={{
                    readOnly,
                  }}
                  className={classes.inputWMagin}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignContent: 'center',
                  width: 648,
                }}
              >
                <TextInput
                  source="phone"
                  label="Telefone"
                  variant="outlined"
                  validate={required()}
                  format={phoneFormatter.format}
                  parse={phoneFormatter.parse}
                  InputProps={{
                    readOnly,
                  }}
                  className={classes.inputWMagin}
                />
                <TextInput
                  source="website"
                  label="Website"
                  variant="outlined"
                  InputProps={{
                    readOnly,
                  }}
                  className={classes.inputWMagin}
                />
              </div>
            </div>
            <div>
              <CustomImageInput source="logoUrl" />
            </div>
          </div>
          <AddressSession readOnly={readOnly} />
          <Typography variant="h2" className={classes.header}>
            Dados do gestor
          </Typography>
          <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
            <TextInput
              source="managerData.name"
              label="Nome completo"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="managerData.responsibility"
              label="Cargo"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="managerData.phone"
              label="Telefone"
              variant="outlined"
              format={phoneFormatter.format}
              parse={phoneFormatter.parse}
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="managerData.email"
              label="E-mail"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
              type="email"
            />
          </div>
          <Typography variant="h2" className={classes.header}>
            Dados financeiros
          </Typography>
          <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
            <TextInput
              source="financialGroupData.name"
              label="Nome completo"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="financialGroupData.responsibility"
              label="Cargo"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="financialGroupData.phone"
              label="Telefone"
              variant="outlined"
              format={phoneFormatter.format}
              parse={phoneFormatter.parse}
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="financialGroupData.email"
              label="E-mail"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
              type="email"
            />
          </div>
          <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
            <TextInput
              source="financialGroupData.bankAccount.bankName"
              label="Banco"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="financialGroupData.bankAccount.account"
              label="Conta"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
            <TextInput
              source="financialGroupData.bankAccount.agency"
              label="Agência"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.inputWMagin}
            />
          </div>
          <PixKeysInput
            source="financialGroupData.pixKeys"
            label="PIX"
            variant="outlined"
            InputProps={{
              readOnly,
            }}
          />
          <Typography variant="h2" className={classes.header}>
            Instruções de retirada
          </Typography>
          <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
            <TextInput
              source="deliveryInstructions"
              label="Instruções de retirada"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              style={{
                width: 416,
              }}
              type="text"
              multiline
            />
          </div>
          <Typography variant="h2" className={classes.header}>
            Configurações de pagamento
          </Typography>
          <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
            <TextInput
              source="payoutConfig.maxInstallmentCount"
              label="Quantidade máxima de parcelas"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.customLargerInput}
              type="number"
            />

            <TextInput
              source="payoutConfig.daysToPayout"
              label="Dias para o primeiro pagamento"
              variant="outlined"
              InputProps={{
                readOnly,
              }}
              className={classes.customLargerInput}
              type="number"
            />
          </div>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              marginBottom: mainTheme.spacing(1),
            }}
          >
            <Typography variant="h2" className={classes.header}>
              Rede
            </Typography>
            <div style={{ marginLeft: mainTheme.spacing(1) }}>
              <CreateNewClinicGroupButton />
            </div>
          </div>
          <div style={{ display: 'flex', alignContent: 'center', width: 1000 }}>
            <ClinicGroupAutocompleteInput
              source="clinicGroup._id"
              readOnly={readOnly}
              resource="clinic-group"
              label="Rede"
            />
          </div>
        </div>
      </FormTab>
      <FormTab {...props} label="Atendimento na Clínica">
        <ScheduleTimeForm
          attendanceTypeEnabledSource="pickupAttendanceEnabled"
          source={'pickupOpeningHours'}
          enableCheckboxLabel="A clínica atende presencialmente?"
          attendanceCapacityPerHourSource="attendanceCapacityPerHour"
        />
      </FormTab>
      <FormTab {...props} label="Atendimento Domiciliar">
        <ScheduleTimeForm
          attendanceTypeEnabledSource="deliveryAttendanceEnabled"
          source={'deliveryOpeningHours'}
          enableCheckboxLabel="A clínica realiza atendimento domiciliar"
          showFreightValue={true}
          attendanceCapacityPerHourSource="deliveryAttendanceCapacityPerHour"
        />
      </FormTab>
    </TabbedForm>
  )
}

export default ClinicForm
