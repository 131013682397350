import * as React from 'react'
import {
  Datagrid,
  DateField,
  FunctionField,
  List,
  ListProps,
  Record,
  TextField,
} from 'react-admin'
import OrderStatusChip from '../../../components/chips/OrderStatusChip'
import { DefaultPagination } from '../../../components/Pagination'
import OrdersListActions from './OrdersListActions'
import OrdersFilter from './OrdersListFilter'
import { parseAttendanceType } from '@vacinas-net/shared'

const OrdersList = ({ ...props }: ListProps) => {
  return (
    <List
      sort={{ field: 'purchasedAt', order: 'DESC' }}
      bulkActionButtons={false}
      pagination={<DefaultPagination />}
      actions={<OrdersListActions />}
      filters={<OrdersFilter />}
      {...props}
    >
      <Datagrid stickyHeader rowClick="show">
        <DateField
          locales="pt-BR"
          textAlign="center"
          source="purchasedAt"
          label="Data do pedido"
        />
        <TextField textAlign="center" source="customer.name" label="Cliente" />
        <TextField textAlign="center" source="orderId" label="Número" />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.relatedClinics && record?.relatedClinics.length > 0
              ? record.relatedClinics
                  ?.map((clinic: { name: string }) => clinic.name)
                  .join(', ')
              : '-'
          }
          label="Clínicas"
          source="relatedClinics"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) => {
            return parseAttendanceType(record?.shippingAddress?.type)
          }}
          label="Tipo de atendimento"
        />
        <FunctionField
          textAlign="center"
          render={(record: Record | undefined) =>
            record?.attendances
              ? record.attendances
                  ?.filter(
                    (attendance: { pickupPointName: string; status: string }) =>
                      !!attendance.pickupPointName &&
                      attendance.status !== 'relocated'
                  )
                  ?.map(
                    (attendance: { pickupPointName: string }) =>
                      attendance.pickupPointName
                  )
                  .join(', ')
              : '-'
          }
          label="Pontos de retirada"
        />
        <FunctionField
          textAlign="center"
          label="Situação"
          render={(record: Record | undefined) => {
            return <OrderStatusChip status={record?.status} />
          }}
        />
      </Datagrid>
    </List>
  )
}

export default OrdersList
