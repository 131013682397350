import { IUser } from '@vacinas-net/shared'
import { apiUrl } from '../../../components/AppDataProvider'
import customHttpClient from '../../../components/CustomHttpClient'

export const toggleUserStatus = async (
  userId: string,
  status: IUser['status']
) => {
  const newStatus = status === 'active' ? 'inactive' : 'active'
  const url = `${apiUrl}/user/${userId}/status/${newStatus}`
  await customHttpClient(url, {
    method: 'PATCH',
  })
}
