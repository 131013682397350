import { Button, Dialog, DialogContent, DialogTitle } from '@material-ui/core'
import React, { useState } from 'react'
import { mainTheme } from '../../../utils/mainTheme'
import InsertItemsOnOfferForm from '../InsertItemsOnOfferForm'

const insertItemOnOfferButton = (props: {
  jointPurchaseOfferId: string
  clinicInitialValue?: string
}) => {
  const { jointPurchaseOfferId, clinicInitialValue } = props
  const [open, setOpen] = useState(false)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <Button
        onClick={() => {
          setOpen(true)
        }}
        color="primary"
        variant="contained"
        style={{
          marginLeft: mainTheme.spacing(2),
          marginRight: mainTheme.spacing(2),
        }}
      >
        Adicionar Item
      </Button>
      <Dialog open={open} onClose={() => handleClose()} maxWidth="sm" fullWidth>
        <DialogTitle>Adicionar Item a Compra</DialogTitle>
        <DialogContent>
          <InsertItemsOnOfferForm
            jointPurchaseOfferId={jointPurchaseOfferId}
            clinicInitialValue={clinicInitialValue}
            handleClose={handleClose}
          />
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default insertItemOnOfferButton
