import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  InputAdornment,
  TextField,
  Typography,
} from '@material-ui/core'
import { CheckCircleRounded, Edit, ErrorOutline } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { useNotify, useRefresh } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { DropzoneArea } from 'material-ui-dropzone'
import { uploadFile } from '../../../utils/utils'
import { IAttendanceInvoice } from '@vacinas-net/shared'
import DownloadInvoiceButton from '../DownloadInvoicesButton'

const EditInvoiceButton = (props: {
  attendanceId: string
  initialValues: IAttendanceInvoice
}) => {
  const { initialValues, attendanceId } = props

  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [invoice, setInvoice] = useState<File>()
  const [invoiceNumber, setInvoiceNumber] = useState(initialValues.number)
  const [invoiceUrl, setInvoiceUrl] = useState(initialValues.url || '')
  const [value, setValue] = useState<number | undefined>(
    initialValues.valueInCents ? initialValues.valueInCents : undefined
  )

  const refresh = useRefresh()
  const notify = useNotify()

  const editInvoice = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance-invoice/${initialValues._id}`
    try {
      await httpClient(url, {
        method: 'PATCH',
        body: JSON.stringify({
          number: invoiceNumber || undefined,
          valueInCents: value || undefined,
          url: invoiceUrl || undefined,
        }),
      })

      if (invoice) {
        const invoiceId = initialValues._id
        const getSignedUrlPath = `${apiUrl}/attendance-invoice/${invoiceId}/upload`
        const signedUrlResponse = await httpClient(getSignedUrlPath, {
          method: 'put',
          body: JSON.stringify({
            contentType: invoice?.type,
          }),
        })
        const signedUrl = signedUrlResponse?.json?.signedUrl

        await uploadFile(signedUrl, invoice, true)
      }

      notify('Nota fiscal editada com sucesso!')
    } catch (error) {
      console.error(error)
      notify('Erro ao editar a nota fiscal', 'error')
    } finally {
      setLoading(false)
      setOpenModal(false)
      refresh()
    }
  }

  return (
    <div>
      <Button
        size="medium"
        color="secondary"
        variant="contained"
        onClick={() => setOpenModal(true)}
        data-testid={'add-invoice-button'}
        startIcon={<Edit />}
      >
        Editar
      </Button>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="sm"
        fullWidth
      >
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Typography
            style={{ marginBottom: mainTheme.spacing(2) }}
            variant="h2"
          >
            Editar nota fiscal
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextField
              value={invoiceNumber}
              label="Número"
              onChange={(event) => {
                setInvoiceNumber(event.target.value)
              }}
              variant="outlined"
              size="small"
              inputProps={{
                'data-testid': 'invoice-number-input',
              }}
              fullWidth
              required
            />
            <TextField
              style={{ marginLeft: mainTheme.spacing(1) }}
              type="number"
              label="Valor"
              variant="outlined"
              size="small"
              InputLabelProps={{ shrink: true }}
              value={parseFloat(String(value)) / 100 || null}
              onChange={(event) =>
                setValue(parseFloat(event.target.value) * 100)
              }
              inputProps={{
                'data-testid': `monetary-input`,
              }}
              InputProps={{
                startAdornment: (
                  <InputAdornment position="start">R$</InputAdornment>
                ),
              }}
              fullWidth
              required
            />
          </div>
          <div>
            <TextField
              value={invoiceUrl}
              label="Url"
              style={{ marginTop: mainTheme.spacing(1) }}
              onChange={(event) => {
                setInvoiceUrl(event.target.value)
              }}
              variant="outlined"
              size="small"
              inputProps={{
                'data-testid': 'invoice-url-input',
              }}
              fullWidth
            />
          </div>
          <div>
            <Typography
              style={{
                marginBottom: mainTheme.spacing(1),
                marginTop: mainTheme.spacing(1),
              }}
              variant="h2"
            >
              Documento Atual
            </Typography>
            <DownloadInvoiceButton id={attendanceId} />
            <Typography
              style={{
                marginTop: mainTheme.spacing(1),
              }}
              variant="h2"
            >
              Atualizar nota fiscal
            </Typography>
            <Typography
              style={{
                marginBottom: mainTheme.spacing(1),
              }}
              variant="body1"
              color="error"
            >
              Essa ação substituirá o documento atual!
            </Typography>
            <DropzoneArea
              acceptedFiles={['application/pdf', 'image/png', 'image/jpeg']}
              filesLimit={1}
              onChange={(files) => {
                setInvoice(files[0])
              }}
              dropzoneText="Arraste Aqui o arquivo ou clique para Selecionar"
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ErrorOutline />}
            onClick={() => setOpenModal(false)}
            size="large"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<CheckCircleRounded />}
            onClick={() => {
              editInvoice()
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={loading || !invoiceNumber || !value}
            data-testid={`confirm-button`}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default EditInvoiceButton
