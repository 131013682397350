import { format } from 'date-fns'
import React from 'react'
import {
  Datagrid,
  FunctionField,
  Identifier,
  List,
  ListProps,
  Record,
  TextField,
} from 'react-admin'
import VaccineShotStatus from '../../../components/chips/VaccineShotStatus'
import { DefaultPagination } from '../../../components/Pagination'
import { parseDose, parseOperationType } from '../utils'
import VaccinesListActions from './VaccinesListActions'
import VaccinesListFilters from './VaccinesListFilters'

const VaccinesList = (props: ListProps) => {
  const listRowClick = (id: Identifier, resource: string, record: Record) =>
    `/attendance/${record?.attendanceId}/show`

  return (
    <List
      {...props}
      pagination={<DefaultPagination />}
      bulkActionButtons={false}
      actions={<VaccinesListActions />}
      filters={<VaccinesListFilters />}
      filterDefaultValues={{
        status: 'applied',
      }}
      sort={{ field: 'shotDate', order: 'ASC' }}
    >
      <Datagrid rowClick={listRowClick} stickyHeader>
        <FunctionField
          render={(record: Record | undefined) =>
            record?.shotDate
              ? format(new Date(record.shotDate), 'dd/MM/yy')
              : '-'
          }
          textAlign="center"
          source="shotDate"
          label="Data de Aplicação"
        />
        <TextField
          textAlign="center"
          source="patient.name"
          label="Paciente"
          emptyText="-"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            record?.patient?.birthDate
              ? format(new Date(record.patient.birthDate), 'dd/MM/yy')
              : '-'
          }
          textAlign="center"
          source="patient.birthDate"
          label="Data de Nascimento"
        />
        <TextField
          textAlign="center"
          source="vendorOrderId"
          label="Número do pedido"
          emptyText="-"
        />
        <TextField
          textAlign="center"
          source="vaccineMeta.productSkuApplied.skuId"
          label="SKU"
          emptyText="-"
        />
        <TextField
          textAlign="center"
          source="vaccineMeta.productSkuApplied.name"
          label="Produto"
          emptyText="-"
        />
        <TextField
          textAlign="center"
          source="clinic.name"
          label="Clínica"
          emptyText="-"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            record?.dose ? parseDose(record.dose) : '-'
          }
          textAlign="center"
          source="shotDate"
          label="Dose"
        />
        <FunctionField
          textAlign="center"
          label="Situação"
          render={(record: Record | undefined) => {
            return <VaccineShotStatus status={record?.status} />
          }}
          source="status"
        />
        <FunctionField
          render={(record: Record | undefined) =>
            record?.operationType
              ? parseOperationType(record?.operationType)
              : '-'
          }
          textAlign="center"
          source="operationType"
          label="Tipo de operação"
        />
      </Datagrid>
    </List>
  )
}

export default VaccinesList
