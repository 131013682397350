import {
  Checkbox,
  FormControl,
  FormControlLabel,
  FormGroup,
  IconButton,
  InputAdornment,
  InputLabel,
  MenuItem,
  Select,
  TextField,
} from '@material-ui/core'
import { Cancel } from '@material-ui/icons'
import { IClinic, IClinicCondition, ShippingPolicy } from '@vacinas-net/shared'
import React, { useEffect, useState } from 'react'
import { mainTheme } from '../../../utils/mainTheme'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'
import { useFormState } from 'react-final-form'
import _ from 'lodash'
import { useNotify } from 'react-admin'
import { shippingPolicyDictionary } from '../../../resources/clinicCondition/utils'

const ClinicCondition = (props: {
  canSelectAllClinics: () => boolean
  clinicCondition: Partial<IClinicCondition>
  index: number
  removeClinicCondition: (index: number) => void
  handleInputChange: (
    field: string,
    data: string | Pick<IClinic, '_id' | 'name'> | boolean | number,
    index: number
  ) => void
}) => {
  const {
    removeClinicCondition,
    index,
    handleInputChange,
    clinicCondition,
    canSelectAllClinics,
  } = props

  const [clinic, setClinic] = useState<OptionType | null>(null)
  const [isAllClinicsChecked, setIsAllClinicsChecked] = useState(false)

  const handleChange = (
    field: string,
    data: string | Pick<IClinic, '_id' | 'name'> | boolean | number
  ) => {
    handleInputChange(field, data, index)
  }

  const parseOptionTypeToClinic = (
    event: OptionType | null
  ): Pick<IClinic, '_id' | 'name'> => {
    return {
      _id: event?.value,
      name: event?.label,
    }
  }

  useEffect(() => {
    if (clinicCondition.clinic) {
      setClinic({
        label: String(clinicCondition.clinic.name),
        value: String(clinicCondition.clinic._id),
      })
    }
  }, [clinicCondition.clinic])

  const notify = useNotify()

  const form = useFormState()
  const handler = form.values.handler
  const formClinicConditions: Partial<
    IClinicCondition & { allClinics: boolean }
  >[] = form.values.clinicConditions

  return (
    <div style={{ display: 'flex', alignItems: 'center' }}>
      <CustomAutocomplete
        size="small"
        style={{ width: 350, marginRight: mainTheme.spacing(1) }}
        currentValue={clinic}
        onChange={(event) => {
          if (handler === 'vtex') {
            const selectedClinics = formClinicConditions.map(
              (condition) => condition.clinic
            )
            if (
              _.find(selectedClinics, {
                _id: parseOptionTypeToClinic(event)._id,
              })
            ) {
              notify(
                'Não é possível selecionar duas clínicas iguais quando a condição comercial é VTEX',
                'error'
              )
              return
            }
          }
          setClinic(event)
          handleChange('clinic', parseOptionTypeToClinic(event))
        }}
        resource={'/clinic'}
        label="Clínica"
        readOnly={isAllClinicsChecked}
        filterByActiveClinics
      />

      <FormGroup>
        <FormControlLabel
          control={
            <Checkbox
              onClick={() => {
                if (isAllClinicsChecked) {
                  setIsAllClinicsChecked(false)
                  handleChange('allClinics', false)
                }
                if (!isAllClinicsChecked && canSelectAllClinics()) {
                  setIsAllClinicsChecked(true)
                  handleChange('allClinics', true)
                }
              }}
              checked={isAllClinicsChecked}
            />
          }
          label="Selecionar todas"
        />
      </FormGroup>

      <FormControl
        variant="outlined"
        size="small"
        style={{ width: 200, marginRight: mainTheme.spacing(1) }}
      >
        <InputLabel>Tipo</InputLabel>
        <Select
          value={clinicCondition?.type || null}
          label="Tipo"
          onChange={(event) => handleChange('type', String(event.target.value))}
          inputProps={{
            'data-testid': `type-input`,
          }}
        >
          <MenuItem value={'commission'}>Comissão</MenuItem>
          <MenuItem value={'gesture'}>Gesto</MenuItem>
          <MenuItem value={'acquisition'}>Aquisição</MenuItem>
        </Select>
      </FormControl>
      {(clinicCondition?.type === 'acquisition' ||
        clinicCondition?.type === 'gesture') && (
        <TextField
          style={{ width: 150, marginRight: mainTheme.spacing(1) }}
          type="number"
          label="Valor"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          value={parseFloat(String(clinicCondition?.value)) / 100 || null}
          onChange={(event) =>
            handleChange('value', parseFloat(event.target.value) * 100)
          }
          inputProps={{
            'data-testid': `monetary-input`,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">R$</InputAdornment>
            ),
          }}
        />
      )}
      {clinicCondition?.type === 'commission' && (
        <TextField
          style={{ width: 150, marginRight: mainTheme.spacing(1) }}
          type="number"
          label="Valor"
          variant="outlined"
          size="small"
          InputLabelProps={{ shrink: true }}
          value={parseFloat(String(clinicCondition?.value)) * 100 || null}
          onChange={(event) =>
            handleChange('value', parseFloat(event.target.value) / 100)
          }
          inputProps={{
            'data-testid': `percent-input`,
          }}
          InputProps={{
            endAdornment: <InputAdornment position="end">%</InputAdornment>,
          }}
        />
      )}
      <TextField
        style={{ width: 150, marginRight: mainTheme.spacing(1) }}
        type="number"
        label="Quantidade"
        variant="outlined"
        size="small"
        InputLabelProps={{ shrink: true }}
        value={clinicCondition?.totalAmount || null}
        onChange={(event) => handleChange('totalAmount', event.target.value)}
      />

      {handler === 'jenner' && (
        <FormControl style={{ width: 200 }} variant="outlined" size="small">
          <InputLabel
            shrink={true}
            style={{
              backgroundColor: 'white',
              paddingLeft: 2,
              paddingRight: 2,
            }}
          >
            Política de envio
          </InputLabel>
          <Select
            displayEmpty
            value={clinicCondition?.shippingPolicy || null}
            label="Política de envio"
            onChange={(event) => {
              if (event.target.value === 'clinic') {
                if (
                  formClinicConditions.find(
                    (condition) =>
                      condition.clinic?._id === clinicCondition.clinic?._id &&
                      condition.shippingPolicy === 'clinic'
                  )
                ) {
                  notify(
                    'Não é possível a mesma clínica ter mais de uma negociação com a política de envio "clínica"',
                    'error'
                  )
                  return
                }
              }
              handleChange('shippingPolicy', String(event.target.value))
            }}
          >
            <MenuItem value={ShippingPolicy.Clinic}>
              {shippingPolicyDictionary[ShippingPolicy.Clinic]}
            </MenuItem>
            <MenuItem value={ShippingPolicy.Company}>
              {shippingPolicyDictionary[ShippingPolicy.Company]}
            </MenuItem>
          </Select>
        </FormControl>
      )}
      <IconButton
        onClick={() => {
          setIsAllClinicsChecked(false)
          removeClinicCondition(index)
        }}
      >
        <Cancel />
      </IconButton>
    </div>
  )
}

export default ClinicCondition
