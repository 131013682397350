import { IconButton, Typography } from '@material-ui/core'
import { Add, Cancel } from '@material-ui/icons'
import React, { useState } from 'react'
import { TextInputProps, useInput } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import CustomAutocomplete, { OptionType } from '../../CustomAutocomplete'

const ProductsInput = (props: TextInputProps) => {
  const [products, setProducts] = useState<(OptionType | null)[]>([])
  const { input } = useInput(props)

  const addProduct = () => {
    const newProduct: OptionType | null = {
      label: '',
      value: '',
    }
    setProducts([...products, newProduct])
  }

  const removeProduct = (index: number) => {
    const arrayCopy = JSON.parse(JSON.stringify(products))
    arrayCopy.splice(index, 1)

    setProducts(arrayCopy)
  }

  const updateReactAdminInput = () => {
    input.onChange(
      products.map((product) => {
        return {
          name: product?.label,
          skuId: product?.value,
        }
      })
    )
  }

  React.useEffect(() => updateReactAdminInput(), [products])

  return (
    <div>
      <div
        style={{
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
        }}
      >
        <Typography variant="h2">Produtos</Typography>
        <IconButton
          color={'secondary'}
          onClick={() => addProduct()}
          style={{ marginLeft: mainTheme.spacing(1) }}
          data-testid={`add-button`}
        >
          <Add />
        </IconButton>
      </div>
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        {products.map((product, index) => (
          <div
            style={{
              display: 'flex',
              flexDirection: 'row',
              alignItems: 'center',
            }}
            key={index}
          >
            <CustomAutocomplete
              label="Produto"
              resource="product-sku"
              currentValue={products[index]}
              onChange={(e) => {
                const arrayCopy = JSON.parse(JSON.stringify(products))
                arrayCopy[index] = e
                setProducts(arrayCopy)
              }}
              optionTypeValue="skuId"
              optionTypeLabel="name"
              style={{
                marginTop: mainTheme.spacing(1),
                marginBottom: mainTheme.spacing(1),
                width: 300,
              }}
            />
            <IconButton
              data-testid={`remove-button-${index}`}
              onClick={() => removeProduct(index)}
            >
              <Cancel />
            </IconButton>
          </div>
        ))}
      </div>
    </div>
  )
}

export default ProductsInput
