import React from 'react'
import { Chip, makeStyles } from '@material-ui/core'
import { Record } from 'react-admin'
import { mainTheme } from '../../../../utils/mainTheme'
import { resolveRelatedCampaignsLabel } from '../../../../utils/utils'

const useStyles = makeStyles({
  chip: {
    marginLeft: 12,
    backgroundColor: mainTheme.palette.secondary.main,
    color: mainTheme.palette.secondary.contrastText,
  },
})

const CampaignLabel = (props: { data: Record | undefined }) => {
  const classes = useStyles()
  const { data } = props

  const label = resolveRelatedCampaignsLabel(data?.relatedCampaigns)
  if (!label) return null

  return <Chip className={classes.chip} label={label} />
}

export default CampaignLabel
