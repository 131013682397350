import React, { useEffect, useState } from 'react'
import {
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  TextField,
  CircularProgress,
} from '@material-ui/core'
import { Record, useNotify, useRefresh } from 'react-admin'
import { format } from 'date-fns'
import { mainTheme } from '../../utils/mainTheme'
import ReactInputMask from 'react-input-mask'
import customHttpClient from '../CustomHttpClient'
import { apiUrl } from '../AppDataProvider'
import { dateToCorrectISOString } from '../../utils/utils'

interface PatientData {
  name?: string
  birthDate?: string
  document?: string
  email?: string
  phoneNumber?: string
}

const RegisterPatient = (props: {
  innerRecord: Record | undefined
  open: boolean
  setOpen: (value: React.SetStateAction<boolean>) => void
  attendanceId: string
}) => {
  const { innerRecord, open, setOpen, attendanceId } = props
  const notify = useNotify()
  const refresh = useRefresh()
  const [formData, setFormData] = useState<PatientData>({})
  const [loading, setLoading] = useState(false)

  const parseFormValues = () => {
    return {
      ...formData,
      birthDate: new Date(dateToCorrectISOString(formData.birthDate!)),
    }
  }

  const submit = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${attendanceId}/patient-data/${innerRecord?._id}`
    customHttpClient(url, {
      method: 'put',
      body: JSON.stringify(parseFormValues()),
    })
      .then(() => {
        notify('Paciente registrado com sucesso!')
        setOpen(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao registrar paciente: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const isSaveButtonDisabled = () => {
    if (formData.name && formData.birthDate && formData.document) {
      return false
    }
    return true
  }

  useEffect(() => {
    setFormData(
      innerRecord?.vaccineShot?.patient
        ? {
            name: innerRecord.vaccineShot.patient.name,
            phoneNumber: innerRecord.vaccineShot.patient.phoneNumber,
            document: innerRecord.vaccineShot.patient.document,
            email: innerRecord.vaccineShot.patient.email,
            birthDate: format(
              new Date(innerRecord.vaccineShot.patient.birthDate),
              'yyyy-MM-dd'
            ),
          }
        : {}
    )
  }, [innerRecord])

  return (
    <div>
      <Dialog
        maxWidth={'lg'}
        open={open}
        onClose={() => {
          if (!innerRecord?.vaccineShot?.patient) {
            setFormData({})
          }
          setOpen(false)
        }}
      >
        <DialogTitle color="primary">Registro de Paciente</DialogTitle>
        <DialogContent>
          <form onSubmit={() => submit()}>
            <div
              style={{
                display: 'flex',
                flexDirection: 'column',
                alignItems: 'center',
              }}
            >
              <TextField
                required={true}
                color="primary"
                label="Nome"
                size="small"
                variant="outlined"
                fullWidth
                value={formData.name}
                onChange={(event) => {
                  setFormData({
                    ...formData,
                    name: event.target.value,
                  })
                }}
                style={{
                  marginTop: mainTheme.spacing(2),
                }}
                InputLabelProps={{ shrink: Boolean(formData.name) }}
              />
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: mainTheme.spacing(2),
                }}
              >
                <ReactInputMask
                  mask="999.999.999-99"
                  disabled={false}
                  value={formData.document}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      document: event.target.value,
                    })
                  }}
                >
                  {() => (
                    <TextField
                      required={true}
                      color="primary"
                      label="CPF"
                      size="small"
                      variant="outlined"
                      fullWidth
                      style={{ marginRight: mainTheme.spacing(1) }}
                      InputLabelProps={{ shrink: Boolean(formData.document) }}
                    />
                  )}
                </ReactInputMask>
                <TextField
                  required={true}
                  type="date"
                  color="primary"
                  label="Data de Nascimento"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={formData.birthDate}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      birthDate: event.target.value,
                    })
                  }}
                  InputLabelProps={{ shrink: true }}
                  style={{ marginLeft: mainTheme.spacing(1) }}
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: mainTheme.spacing(2),
                }}
              >
                <TextField
                  color="primary"
                  label="Email"
                  size="small"
                  variant="outlined"
                  fullWidth
                  value={formData.email}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      email: event.target.value,
                    })
                  }}
                  style={{ marginRight: mainTheme.spacing(1) }}
                  InputLabelProps={{ shrink: Boolean(formData.email) }}
                />
                <ReactInputMask
                  mask="(99) 99999-9999"
                  disabled={false}
                  value={formData.phoneNumber}
                  onChange={(event) => {
                    setFormData({
                      ...formData,
                      phoneNumber: event.target.value,
                    })
                  }}
                >
                  {() => (
                    <TextField
                      color="primary"
                      label="Telefone"
                      size="small"
                      variant="outlined"
                      fullWidth
                      style={{ marginLeft: mainTheme.spacing(1) }}
                      InputLabelProps={{
                        shrink: Boolean(formData.phoneNumber),
                      }}
                    />
                  )}
                </ReactInputMask>
              </div>
              <div
                style={{
                  display: 'flex',
                  width: '100%',
                  marginTop: mainTheme.spacing(2),
                  justifyContent: 'flex-end',
                }}
              >
                <Button
                  type="submit"
                  size="medium"
                  variant="contained"
                  color="primary"
                  disabled={isSaveButtonDisabled()}
                >
                  {loading ? (
                    <CircularProgress color="inherit" size={20} />
                  ) : (
                    'Salvar'
                  )}
                </Button>
              </div>
            </div>
          </form>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default RegisterPatient
