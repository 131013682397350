import React, { FC, useState } from 'react'
import { Button } from '@material-ui/core'
import { mainTheme } from '../../../../utils/mainTheme'
import AllowedCustomerBatchList from './AllowedCustomerBatchList'
import DownloadTemplateButton from './DownloadTemplateButton'
import UploadFileDialog from './UploadFileDialog'

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface AllowedCustomerBatchListTabProps {}

const AllowedCustomerBatchListTab: FC<AllowedCustomerBatchListTabProps> =
  (): JSX.Element => {
    const [isOpen, setIsOpen] = useState(false)

    return (
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          gap: mainTheme.spacing(2),
        }}
      >
        <UploadFileDialog isOpen={isOpen} onClose={() => setIsOpen(false)} />

        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            gap: mainTheme.spacing(2),
          }}
        >
          <Button
            color="primary"
            variant="contained"
            onClick={() => setIsOpen(true)}
          >
            Adicionar lista
          </Button>
          <DownloadTemplateButton />
        </div>

        <AllowedCustomerBatchList />
      </div>
    )
  }

export default AllowedCustomerBatchListTab
