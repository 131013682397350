import React from 'react'
import { SidebarToggleButton } from 'react-admin'
import { mainTheme } from '../../utils/mainTheme'
import vacinasOnlyJennerLogo from '../../assets/Logo-Only-Jenner.png'

const Appbar = () => {
  return (
    <div
      style={{
        width: '100%',
        backgroundColor: '#ffffff',
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        zIndex: 1,
      }}
    >
      <div>
        <SidebarToggleButton />
      </div>
      <img
        src={vacinasOnlyJennerLogo}
        style={{
          width: '30%',
          zIndex: 1,
          marginLeft: 'auto',
          marginRight: 'auto',
          paddingRight: mainTheme.spacing(6),
        }}
      />
    </div>
  )
}

export default Appbar
