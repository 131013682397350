import React from 'react'
import {
  Button,
  DialogActions,
  DialogContent,
  Dialog,
  Typography,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
} from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'
import { CheckCircleRounded, ErrorOutline } from '@material-ui/icons'
import { useState } from 'react'
import { useNotify, useRefresh } from 'react-admin'
import httpClient from '../CustomHttpClient'
import { apiUrl } from '../AppDataProvider'
import { AttendanceRenderType } from '../../resources/orders/OrdersShow/utils'

const EditAttendanceItemPopUp = (props: {
  open: boolean
  handleClose: () => void
  attendances: AttendanceRenderType[]
  tableData: { orderItemId: string; attendanceId: string } | undefined
}) => {
  const { open, handleClose, attendances, tableData } = props
  const [newAttendance, setNewAttendance] = useState('')
  const [loading, setLoading] = useState(false)

  const refresh = useRefresh()
  const notify = useNotify()

  const exchangeAttendance = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/changeOrderItem`
    httpClient(url, {
      method: 'put',
      body: JSON.stringify({
        newAttendanceId: newAttendance,
        orderItemId: tableData?.orderItemId,
        oldAttendanceId: tableData?.attendanceId,
      }),
    })
      .then(() => {
        notify('Troca realizada com sucesso!')
        handleClose()
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify('Erro ao realizar troca', 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const attendanceDisabled = (attendance: AttendanceRenderType) => {
    return (
      attendance.attendanceData?.status === 'cancelled' ||
      attendance.attendanceData?.status === 'rejected' ||
      attendance.attendanceData?.status === 'relocated' ||
      attendance.id === tableData?.attendanceId
    )
  }

  return (
    <Dialog open={open} onClose={handleClose} maxWidth="xs" fullWidth>
      <DialogContent style={{ overflowY: 'hidden' }}>
        <Typography style={{ marginBottom: mainTheme.spacing(2) }} variant="h2">
          Trocar item de atendimento
        </Typography>

        <FormControl
          variant="outlined"
          size="small"
          style={{ width: 400, marginRight: mainTheme.spacing(2) }}
        >
          <InputLabel>Atendimento</InputLabel>
          <Select
            value={newAttendance || null}
            label="atendimento"
            onChange={(event) => setNewAttendance(String(event.target.value))}
            inputProps={{
              'data-testid': `select-attendance`,
            }}
          >
            {attendances.map((attendance, index) => {
              return (
                <MenuItem
                  disabled={attendanceDisabled(attendance)}
                  key={attendance.id}
                  value={attendance.id}
                >
                  Atendimento {index + 1} -{' '}
                  {attendance.attendanceData?.clinicName}
                </MenuItem>
              )
            })}
          </Select>
        </FormControl>
      </DialogContent>
      <DialogActions>
        <Button
          startIcon={<ErrorOutline />}
          onClick={handleClose}
          size="large"
          disabled={loading}
        >
          Cancelar
        </Button>
        <Button
          data-testid={'button-edit-item-submit'}
          startIcon={<CheckCircleRounded />}
          onClick={() => {
            exchangeAttendance()
          }}
          color="primary"
          autoFocus
          size="large"
          disabled={loading}
        >
          Confirmar
        </Button>
      </DialogActions>
    </Dialog>
  )
}

export default EditAttendanceItemPopUp
