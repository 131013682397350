import { IAttendance } from '@vacinas-net/shared'
import React, { useState } from 'react'
import { Confirm, useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { MenuItem } from '@material-ui/core'

interface UndoFinishAttendanceButtonProps {
  attendance: IAttendance
  permissions: string[]
  isMenuItem?: boolean
}

const UndoFinishAttendanceButton = (props: UndoFinishAttendanceButtonProps) => {
  const [openConfirm, setOpenConfirm] = useState(false)
  const [loading, setLoading] = useState(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const undoFinishAttendance = async () => {
    setLoading(true)
    const url = `${apiUrl}/attendance/${props.attendance._id}/undo-finish`
    httpClient(url, { method: 'post' })
      .then(() => {
        notify('Sucesso ao desfazer finalização do atendimento')
        setOpenConfirm(false)
        refresh()
      })
      .catch((error) => {
        console.error(error)
        notify(`Erro ao desfazer finalização do atendimento: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <div>
      <MenuItem
        onClick={() => {
          setOpenConfirm(true)
        }}
      >
        Desfazer finalização
      </MenuItem>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está interferindo no fluxo padrão"
        content="Essa ação pode gerar inconsistência no pedido e suas relações, está seguro que deseja continuar com essa operação?"
        onConfirm={() => undoFinishAttendance()}
        onClose={() => setOpenConfirm(false)}
        confirm="Sim, continuar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default UndoFinishAttendanceButton
