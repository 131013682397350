import * as React from 'react'
import { Admin, Resource, RouteWithoutLayout } from 'react-admin'
import CustomLoginPage from './containers/authentication/CustomLoginPage'
import AppDataProvider from './components/AppDataProvider'
import CustomAuthProvider from './components/AuthProvider'
import { mainTheme } from './utils/mainTheme'
import ResetPasswordPage from './containers/authentication/ResetPasswordPage'
import OrdersList from './resources/orders/OrdersList'
import ProductsList from './resources/products/ProductsList'
import OrdersShow from './resources/orders/OrdersShow'
import ClinicList from './resources/clinic/ClinicList'
import Layout from './components/CustomLayout/Layout'
import CampaignCreate from './resources/campaign/CampaignCreate'
import AttendanceEdit from './resources/attendance/AttendanceEdit'
import PayoutList from './resources/payout/PayoutList'
import PayoutPerClinicList from './resources/payout/PayoutPerClinicList'
import AttendanceList from './resources/attendance/AttendanceList'
import AttendanceShow from './resources/attendance/AttendaceShow'
import ErrorBoundary from './components/ErrorBoundary'
import PayoutSettlementList from './resources/payout/PayoutSettlement/PayoutSettlementList'
import { isAdmin, isClinicAdmin, isClinicOperator } from '@vacinas-net/shared'
import PayoutSettlementShow from './resources/payout/PayoutSettlement/PayoutSettlementShow'
import PayoutSettlementCreate from './resources/payout/PayoutSettlement/PayoutSettlementCreate'
import ClinicCreate from './resources/clinic/ClinicCreate'
import ClinicEdit from './resources/clinic/ClinicEdit'
import UserCreate from './resources/user/UserCreate'
import UserList from './resources/user/UserList'
import UserEdit from './resources/user/UserEdit'
import JointPurchaseList from './resources/jointOffer/JointPurchaseList'
import JointPurchaseCreate from './resources/jointOffer/JointPurchaseCreate'
import AggregatedOfferItems from './resources/jointOffer/AggregatedOfferItems'
import OfferItemsClinic from './resources/jointOffer/OfferItemsClinic'
import { Route } from 'react-router-dom'
import CommercialConditionsCreate from './resources/commercialConditions/commercialConditionsCreate'
import CommercialConditionsList from './resources/commercialConditions/commercialConditionsList'
import ClinicConditionList from './resources/clinicCondition'
import ClinicConditionShow from './resources/clinicCondition/ClinicConditionShow'
import CampaignList from './resources/campaign/CampaignList'
import CampaignEdit from './resources/campaign/CampaignEdit'
import NotificationList from './resources/notifications/NotificationList'
import NotificationCreate from './resources/notifications/NotificationCreate'
import VouchersPackageList from './resources/voucherPackages/VouchersList'
import Dashboard from './components/Dashboard'
import OrdersCreate from './resources/orders/OrdersCreate'
import AttendanceNotAccteptedList from './resources/attendanceNotAccepted/AttendanceNotAcceptedList'
import PayoutSummarized from './resources/payout/PayoutSummarized'
import VaccinesList from './resources/vaccines/VaccinesList'
import RegisterVaccineShotTable from './customRoutes/RegisterVaccineShotTable'
import RegisterVaccineShot from './customRoutes/RegisterVaccineShot'
import ProductsSkuCreate from './resources/productsSku/ProductsSkuCreate'
import ProductSkuList from './resources/productsSku/ProductsSkuList'
import ProductsSkuEdit from './resources/productsSku/ProductsSkuEdit'
import UnavailableProductsList from './resources/unavailableProducts/unavailableProductsList'

const dataProvider = AppDataProvider()

const App = () => {
  return (
    <ErrorBoundary>
      <Admin
        dashboard={Dashboard}
        layout={Layout}
        theme={mainTheme}
        customRoutes={[
          <RouteWithoutLayout
            exact
            path="/register-vacineshot/table"
            component={RegisterVaccineShotTable}
            key={'RegisterVaccineShotTable'}
            noLayout
          />,
          <RouteWithoutLayout
            key="reset-password"
            path="/reset-password"
            component={ResetPasswordPage}
            noLayout
            exact
          />,
          <Route
            exact
            path="/notification"
            component={NotificationList}
            key={'Notification'}
          />,
          <RouteWithoutLayout
            exact
            path="/register-vacineshot/signin"
            component={RegisterVaccineShot}
            key={'RegisterVaccineShot'}
            noLayout
          />,
        ]}
        loginPage={CustomLoginPage}
        authProvider={CustomAuthProvider}
        dataProvider={dataProvider}
      >
        {(permissions) => {
          const showAdminContent = isAdmin(permissions || [])
          const showClinicAdminContent = isClinicAdmin(permissions || [])
          const showClinicOperatorContent = isClinicOperator(permissions || [])
          return [
            <Resource
              key={'attendance-resource'}
              name="attendance"
              list={AttendanceList}
              edit={AttendanceEdit}
              show={AttendanceShow}
            />,
            <Resource
              key={'attendance-not-accepted-resource'}
              name="not-accepted-attendance"
              list={AttendanceNotAccteptedList}
            />,
            showAdminContent ? (
              <Resource name="vaccine-shot" list={VaccinesList} />
            ) : null,
            showAdminContent ? (
              <Resource
                name="order"
                show={OrdersShow}
                list={OrdersList}
                create={OrdersCreate}
              />
            ) : null,
            showClinicOperatorContent ? (
              <Resource name="product" list={ProductsList} />
            ) : null,
            showAdminContent ? (
              <Resource
                name="unavailable-product"
                list={UnavailableProductsList}
              />
            ) : null,
            showAdminContent ? <Resource name="clinic-group" /> : null,
            showAdminContent ? (
              <Resource
                name="clinic"
                list={ClinicList}
                create={ClinicCreate}
                edit={ClinicEdit}
              />
            ) : null,
            showAdminContent ? (
              <Resource
                name="campaign"
                list={CampaignList}
                create={CampaignCreate}
                edit={CampaignEdit}
              />
            ) : null,
            showAdminContent ? (
              <Resource name="campaign/allowed-customer" />
            ) : null,
            showAdminContent ? (
              <Resource name="clinic/grouped/clinic-group" />
            ) : null,
            showAdminContent ? (
              <Resource
                name="payout/grouped/clinic"
                list={PayoutPerClinicList}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource name="payout" list={PayoutList} />
            ) : null,
            <Resource
              key={'payout-summary-resource'}
              name="payout-summary"
              list={PayoutSummarized}
            />,
            showClinicAdminContent ? (
              <Resource
                name="payout-settlement"
                list={PayoutSettlementList}
                show={PayoutSettlementShow}
                create={PayoutSettlementCreate}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource
                name="user"
                list={UserList}
                create={UserCreate}
                edit={UserEdit}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource
                name="joint-purchase/aggregated-offer-items"
                list={AggregatedOfferItems}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource
                name="joint-purchase/offer-items-clinic"
                list={OfferItemsClinic}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource
                name="joint-purchase"
                list={JointPurchaseList}
                create={JointPurchaseCreate}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource
                name="commercial-condition"
                list={CommercialConditionsList}
                create={CommercialConditionsCreate}
              />
            ) : null,
            showClinicAdminContent ? (
              <Resource
                name="clinic-condition"
                list={ClinicConditionList}
                show={ClinicConditionShow}
              />
            ) : null,
            showAdminContent ? (
              <Resource name="voucherPackage" list={VouchersPackageList} />
            ) : null,
            <Resource
              key="notification"
              name="notification"
              create={NotificationCreate}
            />,
            showAdminContent ? (
              <Resource
                name="product-sku"
                list={ProductSkuList}
                create={ProductsSkuCreate}
                edit={ProductsSkuEdit}
              />
            ) : null,
          ]
        }}
      </Admin>
    </ErrorBoundary>
  )
}

export default App
