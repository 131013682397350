import { Typography } from '@material-ui/core'
import {
  AttendanceDTOItemList,
  IAttendanceInvoice,
  Installment,
  isAdmin,
} from '@vacinas-net/shared'
import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  Record,
  SimpleShowLayout,
  TextField,
  usePermissions,
} from 'react-admin'
import AddPreRegisteredInvoiceButton from '../../../../components/Invoices/AddPreRegisteredInvoiceButton'
import DeleteInvoiceButton from '../../../../components/Invoices/DeleteInvoiceButton'
import DownloadInvoiceButton from '../../../../components/Invoices/DownloadInvoicesButton'
import EditInvoiceButton from '../../../../components/Invoices/EditInvoiceButton'
import { mainTheme } from '../../../../utils/mainTheme'
import showStyles from '../../../../utils/showStyles'
import {
  parseIssuer,
  parseRecipient,
  valueInCentsToBRL,
} from '../../../../utils/utils'

const AttendanceDetailsProducts = (props: { record: Record | undefined }) => {
  const { record } = props
  const classes = showStyles()
  const { permissions } = usePermissions()

  const canRegisterInvoice = (record: Record | undefined) => {
    if (record?.status !== 'pending') return false
    if (isAdmin(permissions)) return true
    if (record?.issuer === 'clinic') return true
  }

  const canEditInvoice = () => isAdmin(permissions)

  const renderItemFullPrice = React.useCallback(
    (item: AttendanceDTOItemList) => {
      return item.orderItem?.priceInCents
        ? `Valor total: ${valueInCentsToBRL(
            (item.orderItem?.priceInCents || 0) -
              (item.orderItem?.discountInCents || 0)
          )}`
        : ''
    },
    []
  )
  const renderItemPayoutPrice = React.useCallback(
    (item: AttendanceDTOItemList) => {
      return item.payoutValueInCents
        ? `${valueInCentsToBRL(item.payoutValueInCents || 0)}`
        : ''
    },
    []
  )

  return (
    <div
      style={{ marginTop: mainTheme.spacing(4) }}
      className={classes.cardContainer}
    >
      <Typography className={classes.cardTitle} variant="h2">
        Detalhes do atendimento
      </Typography>
      <SimpleShowLayout className={classes.cardContentTop}>
        <Typography variant="h2">Produtos</Typography>
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.cardContentMiddleColumn}>
        {record?.itemList &&
          record?.itemList.map((item: AttendanceDTOItemList) => {
            return (
              <Typography
                style={{ marginLeft: mainTheme.spacing(2) }}
                key={item.orderItemId}
                variant="body1"
              >
                {`${item.orderItem?.product?.name}`}
                {' - '}
                {renderItemPayoutPrice(item)}
                {isAdmin(permissions) && ` - ${renderItemFullPrice(item)}`}
              </Typography>
            )
          })}
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.cardContentMiddle}>
        <Typography variant="h2">Você receberá por esse atendimento</Typography>
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.cardContentMiddleColumn}>
        {record?.installments &&
          record?.installments.map((installment: Installment) => {
            return (
              <Typography
                style={{ marginLeft: mainTheme.spacing(2) }}
                key={installment.date}
                variant="body1"
              >
                {`${installment.number}º parcela de ${
                  record?.installments.length
                } - ${
                  installment.date && installment.date
                } - ${valueInCentsToBRL(installment.valueInCents!)}`}
              </Typography>
            )
          })}
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.cardContentMiddle}>
        <Typography variant="h2">Notas fiscais do atendimento</Typography>
      </SimpleShowLayout>
      <SimpleShowLayout className={classes.cardContentBottom}>
        <ArrayField source="invoices" label="">
          <Datagrid>
            <TextField
              label="Número"
              source="number"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
            />
            <FunctionField
              textAlign="center"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              render={(record: Record | undefined) =>
                valueInCentsToBRL(record?.valueInCents)
              }
              label="Valor"
              source="valueInCents"
              align="center"
            />
            <FunctionField
              textAlign="center"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              render={(record: Record | undefined) =>
                (record?.responsibleEntity === 'admin'
                  ? 'Vacinas.net'
                  : record?.responsibleUser?.name) || '-'
              }
              label="Responsável"
              source="responsibleEntity"
              align="center"
            />
            <FunctionField
              textAlign="center"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              render={(record: Record | undefined) =>
                parseIssuer(record?.issuer || '-')
              }
              label="Emissor"
              align="center"
            />
            <FunctionField
              textAlign="center"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              render={(record: Record | undefined) =>
                parseRecipient(record?.recipient)
              }
              label="Receptor"
              align="center"
            />
            <DateField
              label="Data"
              source="createdAt"
              className={classes.serviceProductsCell}
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              emptyText="-"
              locales="pt-BR"
            />
            <FunctionField
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              textAlign="center"
              render={(record: Record | undefined) =>
                record?.status === 'registered' ? (
                  <DownloadInvoiceButton id={record?._id} />
                ) : (
                  '-'
                )
              }
              label="Nota fiscal"
              className={classes.serviceProductsCell}
            />
            <FunctionField
              label="Ações"
              textAlign="center"
              headerClassName={classes.tableHeaderStyles}
              cellClassName={classes.tableCellStyles}
              className={classes.serviceProductsCell}
              render={(record: Record | undefined) => (
                <div
                  style={{
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                >
                  {record?.status !== 'pending' && 'NF registrada'}
                  &nbsp;&nbsp;
                  {canEditInvoice() && (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                      }}
                    >
                      <div>
                        <EditInvoiceButton
                          attendanceId={record?._id}
                          initialValues={record as IAttendanceInvoice}
                        />
                      </div>
                      <div style={{ marginLeft: mainTheme.spacing(1) }}>
                        <DeleteInvoiceButton invoiceId={record?._id} />
                      </div>
                    </div>
                  )}
                  {canRegisterInvoice(record) && (
                    <div style={{ marginLeft: mainTheme.spacing(1) }}>
                      <AddPreRegisteredInvoiceButton
                        attendanceId={record?._id}
                        initialValues={record as IAttendanceInvoice}
                      />
                    </div>
                  )}
                </div>
              )}
              align="center"
            />
          </Datagrid>
        </ArrayField>
      </SimpleShowLayout>
    </div>
  )
}

export default AttendanceDetailsProducts
