import { makeStyles } from '@material-ui/core'
import { mainTheme } from '../../utils/mainTheme'

export const useVaccineShotFormStyles = makeStyles({
  input: {
    margin: mainTheme.spacing(1),
  },
  autocompleteInput: {
    marginLeft: mainTheme.spacing(1 / 2),
    marginRight: mainTheme.spacing(2),
  },
  actionButtonsContainer: {
    margin: mainTheme.spacing(1),
    marginRight: mainTheme.spacing(1),
    display: 'flex',
    justifyContent: 'flex-end',
  },
})

export default useVaccineShotFormStyles
