import { Typography, Button, Grid, CircularProgress } from '@material-ui/core'
import React from 'react'
import { AvailableAttendanceFormData } from './utils'
import { mainTheme } from '../../utils/mainTheme'
import { parseAvailableAttendanceData } from './utils'
import { IAvailableAttendance } from '@vacinas-net/shared'

const WarningMessage = (props: {
  formData?: AvailableAttendanceFormData
  availableAttendanceId: string
  campaignId: string
  setOpen: (state: boolean) => void
  editAvailableAttendance: (
    newAvailableAttendance: Partial<IAvailableAttendance> & {
      campaignId?: string | undefined
    }
  ) => Promise<void>
  loading: boolean
}) => {
  return (
    <>
      <Typography>
        Esse formulário de agendamento contém clientes que{' '}
        <b>já agendaram a aplicação</b>. Alguns desses pedidos podem não se
        encaixar nas novas configurações desse formulário
        <br />
        <br />
        Você pode conferir os agendamentos em <b>{'"Pedidos"'}</b>
        <br />
        <br />
        Tem certeza que deseja <b>editar esse formulário?</b>
      </Typography>
      <Grid
        style={{
          marginTop: mainTheme.spacing(2),
          marginBottom: mainTheme.spacing(2),
        }}
        container
        spacing={2}
        xs={12}
      >
        <Button
          style={{ marginLeft: 'auto' }}
          size="large"
          variant="outlined"
          color="primary"
          onClick={() => props.setOpen(false)}
        >
          Voltar
        </Button>
        <Button
          style={{ marginLeft: mainTheme.spacing(1), width: 110 }}
          size="large"
          variant="contained"
          color="primary"
          onClick={async () => {
            const newAvailableAttendance = parseAvailableAttendanceData(
              props.formData!,
              props.campaignId,
              true
            )
            await props.editAvailableAttendance(newAvailableAttendance)
          }}
        >
          {props.loading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            'Continuar'
          )}
        </Button>
      </Grid>
    </>
  )
}

export default WarningMessage
