const parseTime = (value: number) =>
  Number(value).toLocaleString('pt-BR', {
    minimumIntegerDigits: 2,
  })

export const hoursPatternToMinutes = (value: string) => {
  const splitted = value.split(':')
  const hoursInMinute = Number(splitted[0]) * 60
  const minutes = Number(splitted[1])

  const minuteOfDay = hoursInMinute + minutes

  return minuteOfDay
}

export const minutesToHoursPattern = (value?: number) => {
  if (!value || isNaN(value)) return null

  const hours = Math.floor(value / 60)
  const minutes = value % 60

  return `${parseTime(hours)}:${parseTime(minutes)}`
}

export const localizeMinuteOfDay = (value?: number) => {
  if (!value || isNaN(value)) return null
  const timezoneOffset = new Date().getTimezoneOffset()
  const timezonedValue = value - timezoneOffset
  const finalValue =
    timezonedValue >= 0 ? timezonedValue : timezonedValue + 1440

  return finalValue
}

export const transformToUTCMinuteOfDay = (value?: number) => {
  if (!value || isNaN(value)) return null
  const timezoneOffset = new Date().getTimezoneOffset()
  const timezonedValue = value + timezoneOffset

  const finalValue =
    timezonedValue <= 1440 ? timezonedValue : timezonedValue - 1440

  return finalValue
}
