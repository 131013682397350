import {
  Button,
  CircularProgress,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core'
import React from 'react'
import { useState } from 'react'
import useVaccineShotFormStyles from './styles'
import CustomAutocomplete, { OptionType } from '../CustomAutocomplete'
import { Record, usePermissions } from 'react-admin'
import {
  IAttendanceItem,
  IProduct,
  isAdmin,
  IVaccineMeta,
  IVaccineShot,
} from '@vacinas-net/shared'
import { mainTheme } from '../../utils/mainTheme'
import format from 'date-fns/format'
import InputMask from 'react-input-mask'
import { dateToCorrectISOString } from '../../utils/utils'
import AttendanceFormInputChip from '../chips/AttendanceFormInputChip'
import { IPatient } from '@vacinas-net/shared'
import _ from 'lodash'
import { isValidCPF } from '@brazilian-utils/brazilian-utils'

interface VaccineShotData {
  _id: string
  name: string
  dose: 'first' | 'second' | 'third' | 'booster' | 'anual'
  document: string
  birthDate: string
  email: string
  phone: string
  shotDate: string
  vaccineInfo: OptionType | null
  batchNumber: string
  observations: string
  expirationDate: string
}

type VaccineShotErrors = {
  [k in keyof VaccineShotData]?: string
}

const VaccineShotForm = (props: {
  initialValues: Record | undefined
  onClose: () => void
  onSubmit: (data: IVaccineShot) => void
  loading: boolean
  isEdit?: boolean
  record?: Record
}) => {
  const { onClose, initialValues, onSubmit, loading, isEdit, record } = props

  const initialProduct: IProduct | undefined =
    initialValues?.item.vaccineShot?.vaccineMeta?.productSkuApplied ||
    initialValues?.product

  const initialVaccineShotData: VaccineShotData = {
    _id: initialValues?.item?.vaccineShot?._id,
    name: initialValues?.item?.vaccineShot?.patient?.name || '',
    batchNumber:
      initialValues?.item?.vaccineShot?.vaccineMeta?.batchNumber || '',
    birthDate: initialValues?.item?.vaccineShot?.patient?.birthDate
      ? format(
          new Date(initialValues?.item?.vaccineShot?.patient?.birthDate),
          'yyyy-MM-dd'
        )
      : '',
    dose: initialValues?.item?.vaccineShot?.dose || '',
    document: initialValues?.item?.vaccineShot?.patient?.document || '',
    email: initialValues?.item?.vaccineShot?.patient?.email || '',
    observations: initialValues?.item?.vaccineShot?.notes || '',
    phone: initialValues?.item?.vaccineShot?.patient?.phoneNumber || '',
    shotDate: initialValues?.item?.vaccineShot?.shotDate
      ? format(
          new Date(initialValues?.item?.vaccineShot?.shotDate),
          'yyyy-MM-dd'
        )
      : format(Date.now(), 'yyyy-MM-dd'),
    vaccineInfo: {
      label: initialProduct?.name || '',
      value: initialProduct?.skuId || '',
    },
    expirationDate: initialValues?.item?.vaccineShot?.vaccineMeta
      ?.expirationDate
      ? format(
          new Date(
            initialValues?.item?.vaccineShot?.vaccineMeta?.expirationDate
          ),
          'yyyy-MM-dd'
        )
      : '',
  }

  const initialVaccineShotErrors: VaccineShotErrors = {
    document:
      initialVaccineShotData.document &&
      !isValidCPF(initialVaccineShotData.document)
        ? 'CPF Inválido'
        : undefined,
  }

  const classes = useVaccineShotFormStyles()

  const [vaccineShotData, setVaccineShotData] = useState<VaccineShotData>(
    initialVaccineShotData
  )
  const [vaccineShotErrors, setVaccineShotErrors] = useState<VaccineShotErrors>(
    initialVaccineShotErrors
  )

  const { permissions } = usePermissions()

  const parseVaccineShotData = (): IVaccineShot => {
    const shotDate = vaccineShotData?.shotDate
      ? new Date(dateToCorrectISOString(String(vaccineShotData?.shotDate)))
      : null
    const birthDate = vaccineShotData?.birthDate
      ? new Date(dateToCorrectISOString(String(vaccineShotData?.birthDate)))
      : null

    const expirationDate = vaccineShotData?.expirationDate
      ? new Date(
          dateToCorrectISOString(String(vaccineShotData?.expirationDate))
        )
      : null

    return {
      _id: vaccineShotData?._id,
      orderItemId: initialValues?.item.orderItemId,
      shotDate: shotDate as IVaccineShot['shotDate'],
      patient: {
        birthDate: birthDate as IPatient['birthDate'],
        document: vaccineShotData?.document,
        name: vaccineShotData?.name,
        email: vaccineShotData?.email,
        phoneNumber: vaccineShotData?.phone,
      },
      notes: vaccineShotData?.observations,
      vaccineMeta: {
        productSkuApplied: {
          name: vaccineShotData?.vaccineInfo?.label,
          skuId: vaccineShotData?.vaccineInfo?.value,
        },
        batchNumber: vaccineShotData.batchNumber,
        expirationDate: expirationDate as IVaccineMeta['expirationDate'],
      },
      ...(vaccineShotData?.dose && { dose: vaccineShotData?.dose }),
    }
  }

  const submitForm = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault()
    if (vaccineShotData.document && !isValidCPF(vaccineShotData.document)) {
      setVaccineShotErrors({
        ...vaccineShotErrors,
        document: 'CPF inválido',
      })
      return
    } else {
      onSubmit(parseVaccineShotData())
    }
  }

  const changeVaccineShot = (
    field: string,
    data: string | OptionType | null
  ) => {
    const vaccineShotCopy = JSON.parse(JSON.stringify(vaccineShotData))
    vaccineShotCopy[field] = data
    setVaccineShotData(vaccineShotCopy)
  }

  const getAllPatientsData = () => {
    const data: IPatient[] = []

    const recordItems: IAttendanceItem[] = record?.itemList
    recordItems.forEach((item) => {
      if (item.vaccineShot?.patient?.name) {
        data.push(item.vaccineShot?.patient)
      }
    })

    data.push({
      name: record?.customer.name,
      document: record?.customer.document,
      email: record?.customer.email,
      phoneNumber: record?.customer.phone,
    })

    return _.uniqBy(data, 'name')
  }

  const fillFormWithPatientData = (patient: IPatient) => {
    setVaccineShotData({
      ...vaccineShotData,
      name: patient.name || '',
      document: patient.document || '',
      phone: patient.phoneNumber || '',
      birthDate: patient?.birthDate
        ? format(new Date(patient?.birthDate), 'yyyy-MM-dd')
        : '',
      email: patient.email || '',
    })
  }

  return (
    <form onSubmit={submitForm}>
      <div>
        <div
          style={{
            display: 'flex',
            flexDirection: 'row',
            alignItems: 'center',
          }}
        >
          {getAllPatientsData().map((patient) => {
            return (
              <div
                key={patient.name}
                style={{ marginLeft: mainTheme.spacing(1) }}
              >
                <AttendanceFormInputChip
                  label={patient.name!}
                  handleClick={() => fillFormWithPatientData(patient)}
                />
              </div>
            )
          })}
        </div>
        <Typography variant="h2" className={classes.input}>
          Dados do Paciente
        </Typography>
        <div>
          <TextField
            label="Nome"
            variant="outlined"
            value={vaccineShotData?.name}
            onChange={(event) => {
              changeVaccineShot('name', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-pacient-name`,
            }}
            className={classes.input}
            required={!isAdmin(permissions)}
            size="small"
          />
        </div>
        <div style={{ display: 'flex' }}>
          <div className={classes.input}>
            <InputMask
              mask="999.999.999-99"
              value={vaccineShotData?.document}
              onChange={(event) => {
                changeVaccineShot('document', event.target.value)
                setVaccineShotErrors({
                  ...vaccineShotErrors,
                  document: undefined,
                })
              }}
            >
              {() => (
                <TextField
                  label="CPF"
                  variant="outlined"
                  required={!isAdmin(permissions)}
                  size="small"
                  fullWidth
                  inputProps={{
                    'data-testid': `input-pacient-document`,
                  }}
                  error={Boolean(vaccineShotErrors.document)}
                />
              )}
            </InputMask>
            {vaccineShotErrors.document && (
              <Typography variant="body2" color="error">
                {vaccineShotErrors.document}
              </Typography>
            )}
          </div>
          <TextField
            label="Data de Nascimento"
            variant="outlined"
            type="date"
            InputLabelProps={{ shrink: true }}
            value={vaccineShotData?.birthDate}
            onChange={(event) => {
              changeVaccineShot('birthDate', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-pacient-birthdate`,
            }}
            className={classes.input}
            required={!isAdmin(permissions)}
            size="small"
            fullWidth
          />
        </div>
        <div>
          <TextField
            label="E-mail"
            variant="outlined"
            type="email"
            value={vaccineShotData?.email}
            onChange={(event) => {
              changeVaccineShot('email', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-pacient-email`,
            }}
            className={classes.input}
            size="small"
          />
          <InputMask
            mask="(99) 99999-9999"
            value={vaccineShotData?.phone}
            onChange={(event) => {
              changeVaccineShot('phone', event.target.value)
            }}
          >
            {() => (
              <TextField
                label="Telefone"
                variant="outlined"
                className={classes.input}
                size="small"
                inputProps={{
                  'data-testid': `input-pacient-phone`,
                }}
              />
            )}
          </InputMask>
        </div>
        <Typography variant="h2" className={classes.input}>
          Dados da Vacina
        </Typography>
        <div
          style={{
            paddingRight: mainTheme.spacing(1),
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'center',
            marginTop: mainTheme.spacing(2),
            marginBottom: mainTheme.spacing(1),
          }}
        >
          <CustomAutocomplete
            resource="product-sku"
            currentValue={vaccineShotData?.vaccineInfo || null}
            onChange={(event) => {
              changeVaccineShot('vaccineInfo', event)
            }}
            className={classes.autocompleteInput}
            label="Vacina"
            optionTypeLabel="name"
            optionTypeValue="skuId"
            size="small"
          />
          <FormControl
            variant="outlined"
            size="small"
            style={{ width: '30%' }}
            required={!isAdmin(permissions)}
          >
            <InputLabel
              style={{
                zIndex: 1,
                backgroundColor: '#ffffff',
                paddingRight: mainTheme.spacing(1 / 2),
              }}
            >
              Dose
            </InputLabel>
            <Select
              value={vaccineShotData?.dose || null}
              label="Dose"
              onChange={(event) =>
                changeVaccineShot('dose', String(event.target.value))
              }
              inputProps={{
                'data-testid': `dose-input`,
              }}
            >
              <MenuItem value={'first'}>Primeira</MenuItem>
              <MenuItem value={'second'}>Segunda</MenuItem>
              <MenuItem value={'third'}>Terceira</MenuItem>
              <MenuItem value={'booster'}>Reforço</MenuItem>
              <MenuItem value={'anual'}>Anual</MenuItem>
            </Select>
          </FormControl>
        </div>
        <div style={{ display: 'flex' }}>
          <TextField
            label="Data de aplicação"
            variant="outlined"
            value={vaccineShotData?.shotDate}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              changeVaccineShot('shotDate', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-vaccine-shotdate`,
            }}
            className={classes.input}
            type="date"
            required={!isAdmin(permissions)}
            size="small"
            fullWidth
          />
          <TextField
            label="Lote"
            variant="outlined"
            value={vaccineShotData?.batchNumber}
            onChange={(event) => {
              changeVaccineShot('batchNumber', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-vaccine-batchnumber`,
            }}
            className={classes.input}
            required={!isAdmin(permissions)}
            size="small"
            fullWidth
          />
          <TextField
            label="Data de validade"
            variant="outlined"
            value={vaccineShotData?.expirationDate}
            InputLabelProps={{ shrink: true }}
            onChange={(event) => {
              changeVaccineShot('expirationDate', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-vaccine-expirationdate`,
            }}
            className={classes.input}
            type="date"
            required={!isAdmin(permissions)}
            size="small"
            fullWidth
          />
        </div>
        <div style={{ paddingRight: mainTheme.spacing(2) }}>
          <TextField
            fullWidth
            label="Observações"
            variant="outlined"
            value={vaccineShotData?.observations}
            onChange={(event) => {
              changeVaccineShot('observations', event.target.value)
            }}
            inputProps={{
              'data-testid': `input-vaccine-notes`,
            }}
            className={classes.input}
            multiline
            size="small"
          />
        </div>
      </div>
      <div className={classes.actionButtonsContainer}>
        <Button
          onClick={() => onClose()}
          size="medium"
          color="primary"
          variant="outlined"
        >
          Cancelar
        </Button>
        {isEdit ? (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginLeft: mainTheme.spacing(1) }}
            data-testid={'edit-button-vaccine-form-submit'}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                data-testid={'button-circular-progress'}
                color="inherit"
                size={20}
              />
            ) : (
              'Salvar'
            )}
          </Button>
        ) : (
          <Button
            size="medium"
            color="primary"
            variant="contained"
            type="submit"
            style={{ marginLeft: mainTheme.spacing(1) }}
            data-testid={'button-vaccine-form-submit'}
            disabled={loading}
          >
            {loading ? (
              <CircularProgress
                data-testid={'button-circular-progress'}
                color="inherit"
                size={20}
              />
            ) : (
              'Confirmar'
            )}
          </Button>
        )}
      </div>
    </form>
  )
}

export default VaccineShotForm
