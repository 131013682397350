import { CircularProgress, IconButton } from '@material-ui/core'
import { CancelOutlined } from '@material-ui/icons'
import React, { useState } from 'react'
import { Confirm, useNotify, useRefresh } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import { apiUrl } from '../../AppDataProvider'
import HttpClient from '../../CustomHttpClient'

const DeletePurchaseOfferItemButton = (props: { offerId: string }) => {
  const { offerId } = props
  const [loading, setLoading] = useState(false)
  const [openConfirm, setOpenConfirm] = useState(false)
  const notify = useNotify()
  const refresh = useRefresh()

  const handleConfirm = () => {
    setLoading(true)
    HttpClient(`${apiUrl}/joint-purchase/offer-item/${offerId}`, {
      method: 'delete',
    })
      .then(() => {
        notify(`Item excluído com sucesso`, 'success')
        refresh()
      })
      .catch((error) => {
        notify(`Erro ao excluir o item: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenConfirm(false)
      })
  }
  return (
    <div style={{ marginLeft: mainTheme.spacing(1 / 2) }}>
      <IconButton
        color="secondary"
        onClick={() => {
          setOpenConfirm(true)
        }}
        data-testid="delete-button-id"
      >
        {loading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          <CancelOutlined />
        )}
      </IconButton>
      <Confirm
        isOpen={openConfirm}
        loading={loading}
        title="Você está excluindo um item"
        content="Deseja confirmar essa ação?"
        onConfirm={handleConfirm}
        onClose={() => setOpenConfirm(false)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </div>
  )
}

export default DeletePurchaseOfferItemButton
