import React from 'react'
import ClinicMarker from '../../../assets/ClinicMarker.png'

// eslint-disable-next-line @typescript-eslint/no-unused-vars
const CustomMarker = (props: { lat: number; lng: number }) => (
  <div>
    <img
      style={{
        position: 'absolute',
        top: '100%',
        left: '50%',
        width: 20,
        height: 20,
        transform: 'translate(-50%, -100%)',
      }}
      src={ClinicMarker}
    />
  </div>
)

export default CustomMarker
