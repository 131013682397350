import { ReportTypeEnum } from '@vacinas-net/shared'
import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { PayoutSummarizedListFiltersArray } from './Filters'

const PayoutSummarizedActions = () => (
  <CustomListActions
    filtersAvailable={PayoutSummarizedListFiltersArray.map((item) => ({
      value: item.value,
      label: item.label,
    }))}
    hasExport
    exportType={ReportTypeEnum.PayoutSummary}
    exportButtonText="Exportar relatório"
  />
)

export default PayoutSummarizedActions
