import * as React from 'react'
import { FilterProps, Filter, SelectInput } from 'react-admin'
import SearchFilterInput from '../../../components/Inputs/SearchFilterInput'
import { useStyles } from '../../../hooks/CustomListFilter'

const OfferItemsClinicFilters: React.FC<Omit<FilterProps, 'children'>> = (
  props
) => {
  const classes = useStyles()
  return (
    <Filter {...props}>
      <SearchFilterInput
        label="Produto"
        source="product->name"
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="type"
        label="Comprado por"
        choices={[
          { id: 'flipped', name: 'Vacinas.net' },
          { id: 'own', name: 'Clínica' },
        ]}
        style={{ minWidth: 200 }}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
      <SelectInput
        source="rangeName"
        label="Faixa"
        choices={[
          { id: 'Faixa única', name: 'Faixa única' },
          { id: 'Faixa 1', name: 'Faixa 1' },
          { id: 'Faixa 2', name: 'Faixa 2' },
          { id: 'Faixa 3', name: 'Faixa 3' },
          { id: 'Faixa 4', name: 'Faixa 4' },
        ]}
        style={{ minWidth: 200 }}
        className={classes.input}
        variant={'outlined'}
        size={'small'}
      />
    </Filter>
  )
}

export default OfferItemsClinicFilters
