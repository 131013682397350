import React, { useEffect } from 'react'
import { Typography, Button } from '@material-ui/core'
import {
  SimpleShowLayout,
  TextField,
  Record,
  ArrayField,
  Datagrid,
  FunctionField,
  usePermissions,
} from 'react-admin'
import { useState } from 'react'
import { valueInCentsToBRL } from '../../../../utils/utils'
import { getAttendancesFromOrder } from '../utils'
import AttendanceStatusChip from '../../../../components/chips/AttendanceStatusChip'
import showStyles from '../../../../utils/showStyles'
import AttendanceActions from '../../../../components/Buttons/AttendanceActionButtons'
import { mainTheme } from '../../../../utils/mainTheme'
import { NavLink } from 'react-router-dom'
import EditAttendanceItemPopUp from '../../../../components/EditAttendanceItemPopUp'
import { IOrderItem, isAdmin } from '@vacinas-net/shared'
import AddProductsToAttendance from '../../../../components/AddProductsToAttendance'
import RegisterPatient from '../../../../components/RegisterPatientForm'

const AttendancesSection = (props: { record: Record | undefined }) => {
  const attendances = getAttendancesFromOrder(props.record)
  const classes = showStyles()
  const [openClinicModal, setOpenClinicModal] = useState(false)
  const [openPatientModal, setOpenPatientModal] = useState(false)
  const [currentPatientModalData, setCurrentPatientModalDate] =
    useState<Record | undefined>()

  const [currentPopupRecord, setCurrentPopupRecord] =
    useState<{ orderItemId: string; attendanceId: string } | undefined>()

  const { permissions } = usePermissions()
  const mustRenderEditItemAttendance = isAdmin(permissions)

  const unassignedItems: IOrderItem[] = props.record?.items.filter(
    (item: IOrderItem) => item.isRelatedToAttendance === 0
  )

  useEffect(() => {
    if (currentPatientModalData) {
      setOpenPatientModal(true)
    }
  }, [currentPatientModalData])

  return (
    <div className={classes.cardContainer}>
      <Typography
        variant="h2"
        style={{
          margin: mainTheme.spacing(2),
          marginLeft: 0,
        }}
      >
        Atendimentos
      </Typography>
      {attendances.map((attendance, index) => (
        <div
          style={{ marginBottom: mainTheme.spacing(2), position: 'relative' }}
          key={index}
        >
          <SimpleShowLayout
            record={attendance}
            className={classes.cardContentTop}
          >
            <Typography
              style={{
                marginRight: mainTheme.spacing(2),
                width: '100%',
              }}
              noWrap
              variant="h2"
            >
              <NavLink
                className={classes.link}
                to={`/attendance/${attendance.id}/show`}
              >
                Atendimento {index + 1} -{' '}
                {attendance?.attendanceData?.clinicName}
              </NavLink>
            </Typography>
            <FunctionField
              className={classes.customFunctionField}
              textAlign="center"
              addLabel={false}
              render={(record: Record | undefined) => (
                <div
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'space-between',
                  }}
                >
                  <AttendanceStatusChip
                    status={record?.attendanceData.status}
                  />
                  <div
                    style={{
                      marginLeft: mainTheme.spacing(2),
                    }}
                  >
                    <AttendanceActions
                      attendanceProps={{
                        _id: String(attendance.id),
                        itemList: attendance.attendanceData?.itemList,
                        status: attendance.attendanceData?.status,
                        uuid: attendance.attendanceData?.uuid,
                      }}
                      payoutProps={props.record?.payouts}
                    />
                  </div>
                </div>
              )}
            />
          </SimpleShowLayout>
          <SimpleShowLayout
            record={attendance}
            className={classes.cardContentMiddle}
          >
            <TextField
              className={classes.cardItem}
              source="attendanceData.type"
              label="Tipo de atendimento"
            />
            <TextField
              source="attendanceData.pickupPointName"
              label="Ponto de retirada"
              emptyText="-"
              className={classes.cardItem}
            />
            <TextField
              className={classes.cardItem}
              source="attendanceData.address"
              label="Endereço"
            />
            <TextField
              className={classes.cardItem}
              source="attendanceData.customerApointment"
              label="Agendamento do cliente"
            />
            <FunctionField
              className={classes.cardItem}
              render={(record: Record | undefined) =>
                valueInCentsToBRL(record?.attendanceData.shippingPriceInCents)
              }
              label="Taxa de entrega"
            />
          </SimpleShowLayout>
          {attendance.attendanceData?.notes && (
            <SimpleShowLayout
              record={attendance}
              className={classes.cardContentMiddle}
            >
              <TextField
                className={classes.notes}
                source="attendanceData.notes"
                label="Observações"
              />
            </SimpleShowLayout>
          )}
          <SimpleShowLayout className={classes.cardContentMiddle}>
            {unassignedItems.length === 0 && (
              <Typography
                style={{ paddingTop: mainTheme.spacing(1) }}
                variant="h2"
              >
                Produtos
              </Typography>
            )}
          </SimpleShowLayout>
          <SimpleShowLayout
            record={attendance}
            className={classes.cardContentGrid}
          >
            <ArrayField source="products" label="">
              <Datagrid style={{ borderRadius: 8 }}>
                <TextField
                  headerClassName={classes.tableHeaderStyles}
                  cellClassName={classes.tableCellStyles}
                  textAlign="center"
                  source="product.skuId"
                  label="SkuID"
                />
                <TextField
                  headerClassName={classes.tableHeaderStyles}
                  cellClassName={classes.tableCellStyles}
                  textAlign="center"
                  source="product.name"
                  label="Produto"
                />
                <FunctionField
                  headerClassName={classes.tableHeaderStyles}
                  cellClassName={classes.tableCellStyles}
                  textAlign="center"
                  render={(record: Record | undefined) =>
                    valueInCentsToBRL(record?.priceInCents)
                  }
                  label="Valor Unitário"
                  source="totalPriceInCents"
                />
                <FunctionField
                  headerClassName={classes.tableHeaderStyles}
                  cellClassName={classes.tableCellStyles}
                  textAlign="center"
                  render={(record: Record | undefined) =>
                    valueInCentsToBRL(record?.discountInCents)
                  }
                  label="Valor do Desconto"
                />
                <FunctionField
                  headerClassName={classes.tableHeaderStyles}
                  cellClassName={classes.tableCellStyles}
                  textAlign="center"
                  render={(record: Record | undefined) =>
                    valueInCentsToBRL(
                      record?.priceInCents - record?.discountInCents
                    )
                  }
                  label="Valor final"
                />
                {mustRenderEditItemAttendance && (
                  <FunctionField
                    headerClassName={classes.tableHeaderStyles}
                    cellClassName={classes.tableCellStyles}
                    label="Ações"
                    textAlign="center"
                    render={(innerRecord: Record | undefined) =>
                      attendance.attendanceData?.status !== 'finished' &&
                      attendance.attendanceData?.status !== 'relocated' ? (
                        <Button
                          data-testid={'button-edit-attendance'}
                          color="primary"
                          variant="contained"
                          onClick={() => {
                            setOpenClinicModal(true)
                            setCurrentPopupRecord({
                              attendanceId: attendance.id,
                              orderItemId: innerRecord?._id,
                            })
                          }}
                        >
                          Trocar item de atendimento
                        </Button>
                      ) : (
                        '-'
                      )
                    }
                  />
                )}
                <FunctionField
                  headerClassName={classes.tableHeaderStyles}
                  cellClassName={classes.tableCellStyles}
                  label="Ações (Paciente)"
                  textAlign="center"
                  render={(innerRecord: Record | undefined) =>
                    attendance.attendanceData?.status !== 'finished' &&
                    attendance.attendanceData?.status !== 'relocated' &&
                    attendance.attendanceData?.status !== 'rejected' &&
                    props.record?.status !== 'cancelled' &&
                    props.record?.status !== 'finished' ? (
                      <Button
                        data-testid={'button-register-patient'}
                        color="primary"
                        variant="contained"
                        onClick={() => {
                          setCurrentPatientModalDate(innerRecord)
                        }}
                      >
                        {innerRecord?.vaccineShot?.patient
                          ? 'Editar paciente'
                          : 'Registrar paciente'}
                      </Button>
                    ) : (
                      '-'
                    )
                  }
                />
              </Datagrid>
            </ArrayField>
            {(attendance.attendanceData?.status === 'under_analysis' ||
              attendance.attendanceData?.status ===
                'awaiting_clinic_confirmation') &&
              unassignedItems.length > 0 &&
              attendance.products.length === 0 && (
                <AddProductsToAttendance
                  clinicId={attendance.attendanceData.clinicId!}
                  avaliableProducts={unassignedItems}
                  attendanceId={attendance.id}
                />
              )}
            <EditAttendanceItemPopUp
              open={openClinicModal}
              handleClose={() => {
                setOpenClinicModal(false)
              }}
              attendances={attendances}
              tableData={currentPopupRecord}
            />
            <RegisterPatient
              open={openPatientModal}
              setOpen={setOpenPatientModal}
              innerRecord={currentPatientModalData}
              attendanceId={attendance.id}
            />
          </SimpleShowLayout>
        </div>
      ))}
    </div>
  )
}

export default AttendancesSection
