import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  TextField,
  Typography,
} from '@material-ui/core'
import { Add, CheckCircleRounded, ErrorOutline } from '@material-ui/icons'
import React from 'react'
import { useState } from 'react'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { useNotify } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'

const CreateNewClinicGroupButton = () => {
  const [loading, setLoading] = useState(false)
  const [openModal, setOpenModal] = useState(false)

  const [clinicGroup, setClinicGroup] = useState('')

  const notify = useNotify()

  const handleSubmit = () => {
    setLoading(true)
    httpClient(`${apiUrl}/clinic-group`, {
      method: 'post',
      body: JSON.stringify({
        name: clinicGroup,
      }),
    })
      .then(() => {
        notify(`Rede criada com sucesso`, 'success')
      })
      .catch((error) => {
        notify(`Erro ao criar rede: ${error}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setOpenModal(false)
      })
  }

  return (
    <div>
      <Button
        size="medium"
        color="primary"
        variant="contained"
        onClick={() => setOpenModal(true)}
        data-testid={'add-clinic-group-button'}
        startIcon={<Add />}
      >
        Registrar nova rede
      </Button>
      <Dialog
        open={openModal}
        onClose={() => setOpenModal(false)}
        maxWidth="xs"
        fullWidth
      >
        <DialogContent style={{ overflowY: 'hidden' }}>
          <Typography
            style={{ marginBottom: mainTheme.spacing(2) }}
            variant="h2"
          >
            Registrar nova rede
          </Typography>
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center',
            }}
          >
            <TextField
              value={clinicGroup}
              label="Rede"
              onChange={(event) => {
                setClinicGroup(event.target.value)
              }}
              variant="outlined"
              size="small"
              inputProps={{
                'data-testid': 'clinic-group-input',
              }}
              required
            />
          </div>
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ErrorOutline />}
            onClick={() => setOpenModal(false)}
            size="large"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<CheckCircleRounded />}
            onClick={() => {
              handleSubmit()
            }}
            color="primary"
            autoFocus
            size="large"
            disabled={loading || !clinicGroup}
            data-testid={`confirm-button`}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default CreateNewClinicGroupButton
