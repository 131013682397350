/* eslint-disable @typescript-eslint/no-explicit-any */
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from '@material-ui/core'
import {
  CheckCircleRounded,
  CloudUpload,
  ErrorOutline,
} from '@material-ui/icons'
import { DropzoneArea } from 'material-ui-dropzone'
import React from 'react'
import { useState } from 'react'
import { Identifier, useNotify, useRefresh } from 'react-admin'
import { apiUrl } from '../../AppDataProvider'
import httpClient from '../../CustomHttpClient'
import { IPayoutSettlement } from '@vacinas-net/shared'
import { uploadFile } from '../../../utils/utils'

const UploadReceiptButton = (props: { id: Identifier | undefined }) => {
  const [openUpload, setOpenUpload] = useState(false)
  const [receipt, setReceipt] = useState<File>()
  const [loading, setLoading] = useState(false)

  const notify = useNotify()
  const refresh = useRefresh()

  const uploadFiles = async () => {
    setLoading(true)
    const statusToBeSetted: IPayoutSettlement['status'] = 'receipt_settled'
    try {
      const getSignedUrlPath = `${apiUrl}/payout-settlement/${props.id}/upload/receipt`
      const signedUrlResponse = await httpClient(getSignedUrlPath, {
        method: 'put',
        body: JSON.stringify({
          contentType: receipt?.type,
        }),
      })
      const signedUrl = signedUrlResponse?.json?.signedUrl
      if (!receipt) {
        throw new Error('Select a file')
      }
      await uploadFile(signedUrl, receipt, true)
      await httpClient(`${apiUrl}/payout-settlement/${props.id}/status`, {
        method: 'PATCH',
        body: JSON.stringify({
          status: statusToBeSetted,
        }),
      })
      notify('Comprovante enviado com sucesso!')
    } catch (error) {
      console.error(error)
      notify('Erro ao enviar o comprovante!')
    } finally {
      setLoading(false)
      setOpenUpload(false)
      refresh()
    }

    return
  }

  const handleDialogClick = (
    e: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    e.stopPropagation()
  }

  return (
    <div>
      <Button
        data-testid={`upload-button`}
        color="primary"
        size="medium"
        variant="outlined"
        startIcon={<CloudUpload />}
        onClick={(e) => {
          setOpenUpload(true)
          e.stopPropagation()
        }}
      >
        Comprovante
      </Button>
      <Dialog
        open={openUpload}
        maxWidth="sm"
        fullWidth
        onClose={() => setOpenUpload(false)}
        onClick={handleDialogClick}
      >
        <DialogTitle>Enviar comprovante</DialogTitle>
        <DialogContent>
          <DropzoneArea
            acceptedFiles={['application/pdf', 'image/png', 'image/jpeg']}
            filesLimit={1}
            onChange={(files) => {
              setReceipt(files[0])
            }}
            dropzoneText="Arraste Aqui o arquivo ou clique para Selecionar"
          />
        </DialogContent>
        <DialogActions>
          <Button
            startIcon={<ErrorOutline />}
            onClick={() => setOpenUpload(false)}
            size="large"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button
            startIcon={<CheckCircleRounded />}
            onClick={() => uploadFiles()}
            color="primary"
            autoFocus
            size="large"
            disabled={loading}
            data-testid={`confirm-button`}
          >
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default UploadReceiptButton
