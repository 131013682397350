import { CircularProgress, Paper, Typography } from '@material-ui/core'
import React, { useEffect, useState } from 'react'
import { useNotify, useRedirect } from 'react-admin'
import { mainTheme } from '../../../utils/mainTheme'
import httpClient from '../../CustomHttpClient'

const DashboardOperationalCard = (props: {
  requestUrl: string
  label: string
  children?: React.ReactNode
  responseField: string
  redirectTo?: string
}) => {
  const redirect = useRedirect()
  const notify = useNotify()
  const [content, setContent] = useState<string>()
  const { requestUrl, label, children, responseField, redirectTo } = props

  useEffect(() => {
    httpClient(requestUrl, {
      method: 'get',
    })
      .then((response) => {
        setContent(String(response.json[responseField]))
      })
      .catch((error) => {
        notify(`Erro ao obter dados do card ${label}: ${error}`, 'error')
      })
  }, [])
  return (
    <Paper
      style={{
        display: 'flex',
        flexDirection: 'column',
        alignItems: 'center',
        justifyContent: 'center',
        padding: mainTheme.spacing(3),
        minHeight: 150,
        cursor: 'pointer',
      }}
      onClick={() => {
        redirectTo && redirect(redirectTo)
      }}
    >
      {children && children}
      <div
        style={{
          marginTop: mainTheme.spacing(2),
          marginBottom: mainTheme.spacing(2),
        }}
      >
        {content ? (
          <Typography align="center" variant="h1" color="secondary">
            {content}
          </Typography>
        ) : (
          <CircularProgress size={20} color="secondary" />
        )}
      </div>
      <Typography align="center" variant="h2" color="textPrimary">
        {label}
      </Typography>
    </Paper>
  )
}

export default DashboardOperationalCard
