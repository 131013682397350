import * as React from 'react'
import {
  List,
  ListProps,
  Datagrid,
  TextField,
  FunctionField,
  Confirm,
} from 'react-admin'
import { IProductStock, isAdmin, isClinicAdmin } from '@vacinas-net/shared'
import ProductsBulkActions from './ProductsBulkActions'
import { Record, useNotify, useRefresh } from 'ra-core'
import { valueInCentsToBRL } from '../../../utils/utils'
import ProductsFilter from './ProductsListFilter'
import ProductsListActions from './ProductsListActions'
import { FormGroup, Switch } from '@material-ui/core'
import httpClient from '../../../components/CustomHttpClient'
import { apiUrl } from '../../../components/AppDataProvider'
import { DefaultPagination } from '../../../components/Pagination'
import { useState } from 'react'
import EditProductStockInline from '../../../components/EditOrdersInline'

const ProductsList = ({ permissions, ...props }: ListProps) => {
  const notify = useNotify()
  const refresh = useRefresh()

  const [productToToggle, setProductToToggle] = useState<string | null>(null)
  const [loading, setLoading] = useState(false)
  const [currentId, setCurrentId] = useState('')
  const [productToToggleStatus, setProductToToggleStatus] = useState()

  const parseOperationType = (
    operationType: IProductStock['operationType']
  ): string => {
    switch (operationType) {
      case 'own':
        return 'Própria da clínica'
      case 'flipped':
        return 'Vacinas.net'
      default:
        return String(operationType)
    }
  }

  const resolveAvailableQuantity = (record: Record | undefined) => {
    const totalQuantity = record?.totalQuantity || 0
    const reservedQuantity = record?.reservedQuantity || 0
    return Math.max(totalQuantity - reservedQuantity, 0)
  }

  const handleSwitchClick = () => {
    setLoading(true)
    if (!productToToggle) return
    httpClient(`${apiUrl}/product/status/batch`, {
      method: 'PATCH',
      body: JSON.stringify({
        active: !productToToggleStatus,
        ids: [productToToggle],
      }),
    })
      .then(() => {
        notify(`Estoque atualizado com sucesso!`)
      })
      .catch((err) => {
        notify(`Erro ao Ativar/Desativar estoque: ${err}`, 'error')
      })
      .finally(() => {
        setLoading(false)
        setProductToToggle(null)
        refresh()
      })
  }

  return (
    <>
      <List
        {...props}
        filters={<ProductsFilter />}
        filterDefaultValues={{
          isActive: true,
        }}
        pagination={<DefaultPagination />}
        bulkActionButtons={<ProductsBulkActions />}
        actions={<ProductsListActions />}
        sort={{ field: 'product.name', order: 'ASC' }}
      >
        <Datagrid
          stickyHeader
          isRowSelectable={(record) =>
            record.operationType === 'own' || isAdmin(permissions)
          }
        >
          {isAdmin(permissions) && (
            <TextField
              textAlign="center"
              source="product.skuId"
              label="SkuID"
            />
          )}

          <TextField textAlign="center" source="product.name" label="Produto" />
          <TextField textAlign="center" source="clinic.name" label="Clínica" />
          <FunctionField
            textAlign="center"
            source="totalQuantity"
            label="Disponível"
            render={resolveAvailableQuantity}
          />
          <FunctionField
            textAlign="center"
            render={(record: Record | undefined) =>
              parseOperationType(record?.operationType)
            }
            sortable={false}
            label="Tipo de Operação"
            source="operationType"
          />

          {isClinicAdmin(permissions) && [
            <FunctionField
              textAlign="center"
              key="gestureInCents"
              render={(record: Record | undefined) => {
                if (record?.gestureInCents !== 0) {
                  return 'Gesto'
                }
                if (record?.productAquisitionInCents !== 0) {
                  return 'Aquisição'
                }
                if (record?.commissionInPercentage !== 0) {
                  return 'Comissão'
                }
                return 'Não Configurada'
              }}
              label="Condição comercial"
              align="center"
            />,
            <FunctionField
              textAlign="center"
              key="gestureInCents"
              render={(record: Record | undefined) => {
                if (record?.gestureInCents !== 0) {
                  return valueInCentsToBRL(record?.gestureInCents)
                }
                if (record?.productAquisitionInCents !== 0) {
                  return valueInCentsToBRL(record?.productAquisitionInCents)
                }
                if (record?.commissionInPercentage !== 0) {
                  return `${(record?.commissionInPercentage).toLocaleString(
                    undefined,
                    {
                      style: 'percent',
                      minimumFractionDigits: 0,
                      maximumFractionDigits: 2,
                    }
                  )}`
                }
                return '-'
              }}
              label="Valor"
              align="center"
            />,
          ]}
          <FunctionField
            label="Editar"
            textAlign="center"
            render={(record: Record | undefined) => (
              <EditProductStockInline
                record={record}
                setCurrentId={setCurrentId}
                currentId={currentId}
              />
            )}
            align="center"
          />
          <FunctionField
            label="Ativar/Desativar"
            textAlign="center"
            render={(record: Record | undefined) => (
              <FormGroup style={{ alignItems: 'center' }}>
                <Switch
                  checked={record?.isActive}
                  onClick={() => {
                    setProductToToggle(record?._id)
                    setProductToToggleStatus(record?.isActive)
                  }}
                />
              </FormGroup>
            )}
            align="center"
          />
        </Datagrid>
      </List>
      <Confirm
        isOpen={!!productToToggle}
        loading={loading}
        title="Você está ativando/desativando um estoque"
        content="Deseja confirmar essa ação?"
        onConfirm={() => handleSwitchClick()}
        onClose={() => setProductToToggle(null)}
        confirm="Confirmar"
        cancel="Cancelar"
      />
    </>
  )
}

export default ProductsList
