import React, { useEffect, useState } from 'react'
import { useNotify, useRedirect } from 'ra-core'
import { useHistory } from 'react-router-dom'
import { useFormik } from 'formik'
import firebase from 'firebase/app'
import qs from 'qs'

import {
  Button,
  CircularProgress,
  TextField,
  Typography,
} from '@material-ui/core'
import { authStyles } from './AuthStyles'
import { mainTheme } from '../../utils/mainTheme'

const qsStringToObject = (data: string) =>
  qs.parse(data, { ignoreQueryPrefix: true })

const ResetPasswordForm: React.FC = () => {
  const classes = authStyles()
  const history = useHistory()
  const notify = useNotify()
  const redirect = useRedirect()

  const [authCode, setAuthCode] = useState<string>('')
  const [urlRedirect, setUrlRedirect] = useState('')

  const {
    values,
    handleSubmit: onSubmit,
    handleChange,
    isSubmitting,
    setSubmitting,
  } = useFormik({
    initialValues: {
      newPassword: '',
      confirmPassword: '',
    },
    onSubmit: (values) => handleSubmit(values.newPassword),
  })

  async function handleSubmit(password: string) {
    try {
      await firebase.auth().confirmPasswordReset(authCode, password)

      notify('Senha redefinida com sucesso!', 'success', undefined, false, 5000)
      redirect((urlRedirect && String(urlRedirect)) || '/login')
    } catch {
      notify('Erro ao redefinir a senha!', 'error', undefined, false, 5000)
    } finally {
      setSubmitting(false)
    }
  }

  useEffect(() => {
    const { oobCode: authCode, continueUrl: urlRedirect } = qsStringToObject(
      history.location.search
    )

    if (!authCode) {
      return redirect('/')
    }

    setAuthCode(authCode as string)
    setUrlRedirect(urlRedirect as string)
  }, [history.location.search])

  return (
    <form className={classes.formStyles} onSubmit={onSubmit}>
      <Typography
        style={{
          marginBottom: mainTheme.spacing(6),
          color: mainTheme.palette.secondary.dark,
        }}
        variant="h1"
      >
        Esqueci a senha
      </Typography>
      <TextField
        variant="outlined"
        className={classes.formInput}
        type="password"
        label="Nova Senha"
        value={values.newPassword}
        onChange={handleChange('newPassword')}
        fullWidth
      />

      <TextField
        variant="outlined"
        className={classes.formInput}
        type="password"
        label="Confirmar senha"
        value={values.confirmPassword}
        onChange={handleChange('confirmPassword')}
        fullWidth
      />
      <div
        style={{
          textAlign: 'center',
          marginTop: mainTheme.spacing(3),
        }}
      >
        <Button
          variant="contained"
          color="primary"
          size="large"
          type="submit"
          disabled={isSubmitting}
          fullWidth={false}
        >
          {isSubmitting ? (
            <CircularProgress size={24} color="secondary" />
          ) : (
            'Salvar'
          )}
        </Button>
      </div>
    </form>
  )
}

export default ResetPasswordForm
