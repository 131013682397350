import { Typography, InputAdornment } from '@material-ui/core'
import React from 'react'
import {
  TextInput,
  DateInput,
  SelectInput,
  FormDataConsumer,
} from 'react-admin'
import CouponsInput from '../../components/Inputs/CouponsInput'
import { mainTheme } from '../../utils/mainTheme'
import AutocompleteArrayInput from '../../components/Inputs/AutocompleteArrayInput'
import CustomPromotionsArrayInput from '../Inputs/CustomPromotionsArrayInput'

const CampaignEditForm = () => {
  return (
    <div>
      <Typography
        style={{
          marginBottom: mainTheme.spacing(1),
          marginTop: mainTheme.spacing(1),
        }}
        variant="h2"
      >
        Tipo de campanha
      </Typography>
      <SelectInput
        disabled={true}
        source="handler"
        label="Tipo"
        variant="outlined"
        choices={[
          {
            id: 'jenner',
            name: 'Jenner',
          },
          {
            id: 'vtex',
            name: 'VTEX',
          },
        ]}
        style={{ width: 200 }}
      />
      <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
        <div>
          <Typography
            style={{
              marginBottom: mainTheme.spacing(1),
            }}
            variant="h2"
          >
            Dados base
          </Typography>
          <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
            <TextInput
              color="primary"
              variant="outlined"
              label="Nome da campanha"
              source="name"
              style={{ marginRight: mainTheme.spacing(1) }}
            />
            <DateInput
              label="Data de início"
              source="dateRange.startDate"
              variant="outlined"
              style={{ marginRight: mainTheme.spacing(1) }}
            />
            <DateInput
              label="Data de fim"
              source="dateRange.endDate"
              variant="outlined"
            />
          </div>
          <FormDataConsumer>
            {({ formData }) => {
              return (
                formData.handler === 'jenner' && (
                  <div>
                    <Typography
                      style={{
                        marginTop: mainTheme.spacing(1),
                        marginBottom: mainTheme.spacing(1),
                      }}
                      variant="h2"
                    >
                      Preço negociado
                    </Typography>
                    <TextInput
                      disabled={true}
                      color="primary"
                      variant="outlined"
                      label="Preço"
                      source="productPriceInCents"
                      style={{
                        marginRight: mainTheme.spacing(1),
                        maxWidth: 200,
                      }}
                      InputProps={{
                        startAdornment: (
                          <InputAdornment position="start">R$</InputAdornment>
                        ),
                      }}
                      type="number"
                    />
                  </div>
                )
              )
            }}
          </FormDataConsumer>
          <AutocompleteArrayInput
            resource="user"
            resourceName="Usuários"
            source="relatedUsers"
            optionTypeLabel="email"
            optionTypeValue="_id"
          />
          <div
            style={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <FormDataConsumer>
              {({ formData }) => {
                return (
                  formData.handler === 'vtex' && (
                    <>
                      <Typography
                        style={{
                          marginTop: mainTheme.spacing(1),
                          marginBottom: mainTheme.spacing(1),
                        }}
                        variant="h2"
                      >
                        Promoções
                      </Typography>
                      <CustomPromotionsArrayInput />
                      <CouponsInput source="coupons" />
                      <Typography
                        style={{
                          marginTop: mainTheme.spacing(1),
                          marginBottom: mainTheme.spacing(1),
                        }}
                        variant="h2"
                      >
                        Vales
                      </Typography>
                      <TextInput
                        multiline={true}
                        rows={5}
                        source="vouchers"
                        label="Vales"
                        variant="outlined"
                        InputProps={{
                          readOnly: true,
                        }}
                        disabled
                        style={{ width: 400 + mainTheme.spacing(1) }}
                      />
                    </>
                  )
                )
              }}
            </FormDataConsumer>

            <AutocompleteArrayInput
              resource="commercial-condition"
              resourceName="Condições comerciais"
              source="commercialConditions"
              optionTypeLabel="name"
              optionTypeValue="_id"
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export default CampaignEditForm
