/* eslint-disable @typescript-eslint/no-explicit-any */
import { DataProvider } from 'ra-core'
import { stringify } from 'query-string'
import httpClient from './CustomHttpClient'
import { getFilters, getOfferIdFromQueryString } from '../utils/utils'
import _ from 'lodash'

export const apiUrl = process.env.REACT_APP_API_ENTRYPOINT as string

const AppDataProvider = () =>
  ({
    getList: async (resource, params) => {
      const { page, perPage } = params.pagination
      const { field, order } = params.sort
      const filters = _.omitBy(getFilters(params.filter), (filter) => !filter)
      let offerId = undefined

      if (resource === 'joint-purchase/aggregated-offer-items') {
        offerId = getOfferIdFromQueryString()
      }

      const query = {
        limit: perPage,
        page: page - 1,
        sortOrder: order,
        sort: field,
        jointPurchaseOfferId: offerId,
        ...filters,
      }

      const url = `${apiUrl}/${resource}?${stringify(query)}`

      return httpClient(url).then(({ json }) => ({
        data: json.results.map((result: any, index: number) => ({
          ...result,
          id: result._id || index * (page + 1),
        })),
        total: json.total,
      }))
    },
    getMany: (resource, params) => {
      const query = {
        _id: params.ids.map((id) => id),
        limit: params.ids.length,
        page: 0,
      }
      const url = `${apiUrl}/${resource}?${stringify(query)}`
      return httpClient(url).then(({ json }) => ({
        data: json.results.map((result: any) => ({
          ...result,
          id: result._id,
        })),
      }))
    },
    getOne: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`).then(
        ({ json }) => ({
          data: {
            ...json,
            id: json._id,
          },
        })
      )
    },
    create: (resource, { data }) => {
      const url = `${apiUrl}/${resource}`

      const dataToSend = data

      if (resource === 'clinic') {
        const logo = dataToSend.logoUrl?.rawFile

        const formData = new FormData()
        logo && formData.append('file', logo)

        const parsedData = dataToSend
        delete parsedData.logoUrl

        formData.append('data', JSON.stringify(parsedData))

        return httpClient(url, {
          method: 'post',
          body: formData,
        }).then(({ json }) => ({
          data: {
            ...json,
            id: json._id,
          },
        }))
      }

      if (resource === 'notification') {
        return httpClient(url, {
          method: 'post',
          body: JSON.stringify(dataToSend),
        }).then(({ json }) => ({
          data: {
            ...json,
            id: 'any-id',
          },
        }))
        /**
         * this is necessary because notification create route has no return,
         * so its necessary to fill the id prop of the returned data beacuse
         * it's impossible to access undefined._id
         */
      }

      return httpClient(url, {
        method: 'post',
        body: JSON.stringify(dataToSend),
      }).then(({ json }) => ({
        data: {
          ...json,
          id: json._id,
        },
      }))
    },
    update: (resource, params) => {
      const sendData = {
        ...params.data,
        id: undefined,
      }

      if (resource === 'clinic') {
        const logo = sendData.logoUrl?.rawFile

        const formData = new FormData()
        logo && formData.append('file', logo)

        const parsedData = sendData
        delete parsedData.logoUrl

        formData.append('data', JSON.stringify(parsedData))

        return httpClient(`${apiUrl}/${resource}/${params.id}`, {
          method: 'PATCH',
          body: formData,
        }).then(({ json }) => ({
          data: {
            ...json,
            id: json._id,
          },
        }))
      }

      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'PATCH',
        body: JSON.stringify(sendData),
      }).then(({ json }) => ({
        data: {
          ...json,
          id: json._id,
        },
      }))
    },
    updateMany: (resource, { ids, data }) => {
      const url = `${apiUrl}/${resource}`
      const dataToSend = ids.map((id) => ({
        _id: id,
        ...data,
      }))
      return httpClient(url, {
        method: 'put',
        body: JSON.stringify(dataToSend),
      }).then(({ json }) => ({ data: json }))
    },
    delete: (resource, params) => {
      return httpClient(`${apiUrl}/${resource}/${params.id}`, {
        method: 'delete',
      }).then(({ json }) => ({
        data: json,
      }))
    },
  } as DataProvider)

export default AppDataProvider
