import _ from 'lodash'
import { Typography } from '@material-ui/core'
import {
  FinishJointPurchaseOfferResult,
  ItemsNotAcceptedError,
  MinPriceNotAchievedError,
} from '@vacinas-net/shared'
import React from 'react'
import { valueInCentsToBRL } from '../../../utils/utils'
import { mainTheme } from '../../../utils/mainTheme'

const FinishOfferErrorContainer = (props: {
  errors: FinishJointPurchaseOfferResult['errors']
}) => {
  const { errors } = props
  const groupedErrors = _.groupBy(errors, 'type')
  const minPriceNotAchievedErrors = groupedErrors[
    'min_price_not_achieved'
  ] as MinPriceNotAchievedError[]
  const itemsNotAcceptedErrors = groupedErrors[
    'items_not_accepted'
  ] as ItemsNotAcceptedError[]

  return (
    <div>
      <Typography
        variant="h5"
        style={{
          marginBottom: mainTheme.spacing(2),
          textAlign: 'center',
          fontWeight: 'bold',
        }}
      >
        Erros ao finalizar oferta
      </Typography>
      {minPriceNotAchievedErrors && (
        <div>
          <Typography variant="h6">
            As seguintes clínicas não atingiram o pedido mínimo:
          </Typography>
          <ul style={{ marginTop: mainTheme.spacing(1) }}>
            {minPriceNotAchievedErrors.map((error, idx) => {
              return (
                <li key={idx}>
                  {error.clinic.name} -{' '}
                  {valueInCentsToBRL(error.minValueInCents)}
                </li>
              )
            })}
          </ul>
        </div>
      )}
      {itemsNotAcceptedErrors && (
        <div>
          <Typography variant="h6">
            As seguintes clínicas não aceitaram o pedido:
          </Typography>
          <ul style={{ marginTop: mainTheme.spacing(1) }}>
            {itemsNotAcceptedErrors.map((error, idx) => {
              return <li key={idx}>{error.clinic.name}</li>
            })}
          </ul>
        </div>
      )}
    </div>
  )
}

export default FinishOfferErrorContainer
