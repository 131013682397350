import * as React from 'react'
import CustomListActions from '../../../components/CustomListActions'
import { Button } from '@material-ui/core'
import { mainTheme } from '../../../utils/mainTheme'
import { useListContext } from 'react-admin'
import { VaccinesListFiltersArray } from './Filters'

const VaccinesListActions = () => {
  const { setFilters, filterValues } = useListContext()

  return (
    <CustomListActions
      filtersAvailable={VaccinesListFiltersArray.map((item) => ({
        value: item.value,
        label: item.label,
      }))}
    >
      <Button
        style={{ marginLeft: mainTheme.spacing(2) }}
        size="medium"
        variant="contained"
        color="primary"
        onClick={() => {
          setFilters(
            {
              ...filterValues,
              status: 'not_applied',
            },
            {}
          )
        }}
      >
        Não aplicadas
      </Button>
    </CustomListActions>
  )
}

export default VaccinesListActions
